export const shownStatus = {
  new_request: 'Nova solicitação',
  dealing: 'Negociando',
  canceled: 'Negociação cancelada',
  pending_signature: 'Aguardando assinatura',
  approved: 'Negociação aprovada',
  rented: 'Alugado',
};

export const availableStatuses = [
  { value: 'new_request', label: 'Nova solicitação' },
  { value: 'dealing', label: 'Negociando' },
  { value: 'canceled', label: 'Negociação cancelada' },
  {
    value: 'pending_signature',
    label: 'Aguardando assinatura',
  },
  { value: 'approved', label: 'Negociação aprovada' },
  { value: 'rented', label: 'Alugado' },
];
