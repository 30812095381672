import React, { useState } from 'react';

import { DarkScreen } from './Components/DarkScreen';
import { Hamburger } from './Components/Hamburger';
import { Dashboard } from './Components/Dashboard';

export function Menu({ setOpen, open }) {
  const [close, setClose] = useState(false);

  return (
    <>
      <DarkScreen className="blackScreen" close={close} setClose={setClose} />
      <Hamburger close={close} setClose={setClose} />
      <Dashboard
        close={close}
        setClose={setClose}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
}
