import styled from 'styled-components';

export const Container = styled.div`
  z-index: 999;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  height: 80px;
  padding: 24px;
  border-bottom: ${props => props.theme.border};
  background: ${props => props.theme.header.background};

  @media screen and (max-width: 600px) {
    padding: 12px;
    margin-left: 0;
    width: 100%;
  }
`;
