import { validateArray } from 'util/Context/Shared/validateArray';

const typeOfUserDocuments = ({ cpfOrCnpj, order }) =>
  ({
    1: cpfOrCnpj.length === 11 ? 'cpf' : 'cnpj',
    2: cpfOrCnpj.length === 11 ? 'rg' : 'reg_commercial',
    3: 'wedding_certificate',
  }[order]);

function addElementOfDocuments(data) {
  const documents = data;
  const cpfOrCnpj = documents[0].document;
  const newDocuments = documents.map((doc, index) => {
    const order = index + 1;
    return {
      ...doc,
      type: typeOfUserDocuments({ cpfOrCnpj, order }),
      file: validateArray({
        data: doc.file ? doc.file : [],
        attributes: 'file',
      }),
    };
  });
  return newDocuments;
}

export const handleDocumentsData = data => {
  return {
    documents: addElementOfDocuments(
      validateArray({ data, attributes: 'document' })
    ),
  };
};
