import jarvis from 'util/jarvis';

export async function SendDocumentsData(documents, id) {
  const { data } = await jarvis.post(`/user/documents/${id}`, documents);

  return data.documents;
}

export async function SendDocumentFile(data) {
  for (const item of data) {
    if (item.file.length > 0) {
      for (const [key, file] of item.file.entries()) {
        const formData = new FormData();
        formData.append(`file`, file.file);
        formData.append(`order`, key);
        await jarvis.post(`/document/${item.id}/file`, formData);
      }
    }
  }
}

export async function SendDeleteDocumentsPhoto(photoID) {
  const { data } = await jarvis.delete(`/document/file/${photoID}`);
  return data;
}

export async function SendEditDocumentsData(documentData, userID, documents) {
  const documentCpfOrCnpj = documents.find(
    document => document.type === 'cpf' || document.type === 'cnpj'
  );
  const documentRgOrReg = documents.find(
    document => document.type === 'rg' || document.type === 'reg_commercial'
  );

  const newDocuments = documentData.documents.map(document => {
    if (document.type === 'cpf' || document.type === 'cnpj') {
      return {
        ...document,
        id: documentCpfOrCnpj?.id || null,
      };
    }
    if (document.type === 'rg' || document.type === 'reg_commercial') {
      return {
        ...document,
        id: documentRgOrReg?.id || null,
      };
    }
    return document;
  });

  const { data } = await jarvis.put(`/user/documents/${userID}`, {
    documents: newDocuments,
  });
  return data.documents;
}
