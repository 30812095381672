import styled from 'styled-components';

export const ContainerDownload = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  > div {
    > a {
      display: flex;
      background: #055eff;
      color: #ffff;
      text-decoration: none;
      align-items: center;
      font-size: 16px;
      padding: 16px 24px;
      border-radius: 8px;
    }
  }
`;
