import styled from 'styled-components';

export const RentalContainer = styled.li`
  display: flex;
  position: relative;
  padding: 16px 24px;
  border: ${props => props.theme.border};
  border-radius: 8px;
  background: ${props => props.theme.container.background};
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 8px;
    background: blue;
    border-radius: 8px 0 0 8px;
  }

  div {
    display: flex;
    gap: 16px;
  }

  .svg-container {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .address-container {
    display: flex;
    align-items: center;
    p {
      font-size: 16px;
    }
  }

  .status-container {
    display: flex;
    align-items: center;
    background: blue;
    border-radius: 16px;
    padding: 8px 16px;
    margin-left: auto;
    p {
      font-size: 16px;
    }
  }

  &.canceled-left::before {
    background: #d8010e;
  }
  &.running-left::before {
    background: #0daf7a;
  }
  &.analyzing-left::before {
    background: #ffc800;
  }
  &.waiting_payment-left::before {
    background: orange;
  }

  .canceled {
    background: #d8010e;
  }
  .running {
    background: #0daf7a;
  }
  .analyzing {
    background: #ffc800;
    p {
      color: #242424;
    }
  }
  .waiting_payment {
    background: orange;
    p {
      color: #242424;
    }
  }

  @media (max-width: 990px) {
    flex-direction: column;
    .status-container {
      width: 100%;
      justify-content: center;
    }
  }
`;
