import jarvis from 'util/jarvis';
import { useQuery } from 'react-query';

export async function loadOptionsUserOwner(search, loadedOptions, { page }) {
  const response = await jarvis.get(
    `/users?page=${page}&filter=owner&search=${search}`
  );

  const checkUsers = response.data.data.map(tenant => ({
    value: tenant.id,
    label: `${tenant.name + ' ' + tenant.lastName} - ${tenant.email}`,
  }));

  const hasMore = page < response.data.lastPage;

  return {
    options: checkUsers,
    hasMore: hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function loadOptionsUserByBroker(search, loadedOptions, { page }) {
  const response = await jarvis.get(
    `/users/broker?page=${page}&search=${search}`,
    {
      params: {
        filter: { role: 'owner' },
        perPage: 15,
      },
    }
  );

  const checkUsers = response.data.data.map(tenant => ({
    value: tenant.id,
    label: `${tenant.name + ' ' + tenant.lastName} - ${tenant.email}`,
  }));

  const hasMore = page < response.data.lastPage;

  return {
    options: checkUsers,
    hasMore: hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export function AdminQuery(currentParams, ownerId, addressSearch) {
  const { data, isLoading } = useQuery(
    ['/properties', currentParams, ownerId],
    async () => {
      if (addressSearch) {
        return jarvis
          .get(`/properties`, {
            params: {
              search: currentParams.search,
            },
          })
          .then(res => {
            return res.data;
          });
      } else {
        return jarvis
          .get(`/properties?filter[ownerId]=${ownerId.value}`)
          .then(res => {
            return res.data;
          });
      }
    },
    {
      enabled: currentParams.search !== null || ownerId !== '',
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: data,
    isLoading: isLoading,
  };
}

export function BrokerQuery(currentParams, ownerId, addressSearch, brokerId) {
  const { data, isLoading } = useQuery(
    ['/properties', currentParams, ownerId, brokerId],
    async () => {
      if (addressSearch) {
        return jarvis
          .get(`/properties?filter[brokerId]=${brokerId}`, {
            params: {
              search: currentParams.search,
            },
          })
          .then(res => {
            return res.data;
          });
      } else {
        return jarvis
          .get(
            `/properties?filter[ownerId]=${ownerId.value}&[brokerId]=${brokerId}`
          )
          .then(res => {
            return res.data;
          });
      }
    },
    {
      enabled: currentParams.search !== null || ownerId !== '',
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: data,
    isLoading: isLoading,
  };
}
