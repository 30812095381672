import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  ContentScroll,
  Filter,
  FilterContainer,
  EmptyList,
} from 'components/Shared';
import { TransactionDetails } from './components/TransactionDetails';
import { Pagination } from 'components/Pagination';

import { getTransaction } from './Service';

export function TransactionList({ setSelectedTransaction }) {
  const { id } = useParams();
  const [currentParams, setCurrentParams] = useState({
    page: 1,
    perPage: 10,
    order: 'DESC',
    status: 'all',
  });

  const { data, isLoading } = getTransaction({
    params: currentParams,
    id,
  });

  async function handlePageChange({ selected }) {
    const newParams = { ...currentParams, page: selected };
    setCurrentParams(newParams);
  }

  return (
    <>
      <FilterContainer style={{ marginBottom: 16 }}>
        <Filter
          filter={[
            {
              value: 'all',
              label: 'Todos',
            },
            {
              value: 'waiting_payment',
              label: 'Em Espera',
            },
            {
              value: 'processing',
              label: 'Processando',
            },
            {
              value: 'paid',
              label: 'Pago',
            },
          ]}
          active={currentParams.status}
          handleFilter={filter => {
            setCurrentParams({
              ...currentParams,
              status: filter.value,
            });
          }}
        />
      </FilterContainer>
      {isLoading ||
        (data?.transactions?.length > 0 ? (
          <>
            <ContentScroll>
              {data.transactions.map(transaction => (
                <TransactionDetails
                  key={transaction.id}
                  transaction={transaction}
                  setSelectedTransaction={setSelectedTransaction}
                />
              ))}
            </ContentScroll>
            <Pagination
              currentPage={currentParams.page}
              pagesSize={currentParams.perPage}
              totalCount={data.totalPage}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <EmptyList />
        ))}
    </>
  );
}
