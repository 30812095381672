import React from 'react';

import { Profile } from './components/Profile';

import { Container } from './styles';

export function Header() {
  return (
    <Container>
      <Profile />
    </Container>
  );
}
