import React from 'react';

import { Container, Buttons, Button, LinkButton } from 'components/Shared';
import { AdditionalForm } from '../../Components/Forms/AdditionalForm';

export function Additional({ additionalData, submitValues }) {
  function sendData(data) {
    submitValues(data);
  }
  return (
    <Container>
      <AdditionalForm sendData={sendData} additionalData={additionalData}>
        <Buttons>
          <LinkButton typeColor="disable" type="button" to="/dashboard">
            Cancelar
          </LinkButton>
          <Button type="submit" typeColor="primary">
            Confirmar e Finalizar
          </Button>
        </Buttons>
      </AdditionalForm>
    </Container>
  );
}
