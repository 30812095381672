import styled from 'styled-components';

export const Content = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    > p {
      font-size: 16px;
    }
    > img {
      width: 180px;
      height: 180px;
    }
    > button {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      background: #055eff;
      padding: 8px 16px;
      border-radius: 8px;
    }
  }
`;
