import jarvis from 'util/jarvis';

export async function GuaranteeOptions(search, loadedOptions, { page }) {
  const response = await jarvis.get(
    `/guarantees?page=${page}&filter=activated&search=${search}`
  );

  const checkGuarantee = response.data.data.map(guarantee => ({
    value: guarantee.id,
    label: `${guarantee.name}`,
  }));

  const hasMore = page < response.data.lastPage;

  return {
    options: checkGuarantee,
    hasMore: hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function AdmFeeOptions(search, loadedOptions, { page }) {
  const response = await jarvis.get(
    `/fees?page=${page}&filter=activated&search=${search}`
  );

  const checkFee = response.data.data.map(fee => ({
    value: fee.id,
    label: `${fee.percentage}%`,
  }));

  const hasMore = page < response.data.lastPage;

  return {
    options: checkFee,
    hasMore: hasMore,
    additional: {
      page: page + 1,
    },
  };
}

export async function ReadjustmentOptions(search, loadedOptions, { page }) {
  const response = await jarvis.get(
    `/readjustments?page=${page}&filter=activated&search=${search}`
  );

  const checkReadjustment = response.data.data.map(readjustment => ({
    value: readjustment.id,
    label: `${readjustment.name} - ${readjustment.percentage}%`,
  }));

  const hasMore = page < response.data.lastPage;

  return {
    options: checkReadjustment,
    hasMore: hasMore,
    additional: {
      page: page + 1,
    },
  };
}
