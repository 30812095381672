import React, { useRef } from 'react';
import { Titles } from 'components/Titles';
import {
  Box,
  BackButton,
  Buttons,
  Button,
  LinkButton,
} from 'components/Shared';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { ReadjustmentForm } from '../Components/ReadjustmentForm';
import { SendReadjustmentForm } from '../Services';

export function CreateReadjustment() {
  const formRef = useRef(null);
  const history = useHistory();

  async function ChangeReadjustment(data) {
    try {
      await SendReadjustmentForm(data);
      toast.success('Reajuste criada com sucesso!');
      history.push('/dashboard/lista-de-reajuste');
    } catch (err) {
      toast.error('Erro ao enviar reajuste!');
    }
  }

  return (
    <Box>
      <Titles
        title="Indice de Reajuste"
        subtitle="Criar novo indice de reajuste"
      />
      <BackButton
        to="/dashboard/lista-de-reajuste"
        style={{ marginBottom: 16 }}
      />
      <ReadjustmentForm
        formRef={formRef}
        ChangeReadjustment={ChangeReadjustment}
        type="create"
      >
        <Buttons>
          <LinkButton to="/dashboard/lista-de-reajuste" typeColor="secondary">
            Cancelar
          </LinkButton>
          <Button typeColor="primary" type="submit">
            Criar Taxa
          </Button>
        </Buttons>
      </ReadjustmentForm>
    </Box>
  );
}
