import * as Yup from 'yup';

export const formValidation = Yup.object().shape({
  address: Yup.object().shape({
    zipcode: Yup.string().required('Digite o seu CEP'),
    district: Yup.string().required('Digite o seu Bairro'),
    number: Yup.string().required('Digite o seu Número'),
    street: Yup.string().required('Digite o seu Endereço'),
    city: Yup.string().required('Digite a sua Cidade'),
    state: Yup.string().required('Digite o seu Estado'),
  }),
  price: Yup.number()
    .typeError('Digite o valor do aluguel')
    .required('Digite o valor do aluguel'),
  specification: Yup.object().shape({
    iptu_price: Yup.number()
      .typeError('Digite o valor do IPTU')
      .required('Digite o valor do IPTU'),
    bedroom: Yup.number()
      .typeError('Escolha a quantidade de quartos')
      .required('Escolha a quantidade de quartos'),
    bathroom: Yup.number()
      .typeError('Escolha a quantidade de banheiros')
      .required('Escolha a quantidade de banheiros'),
  }),
});
