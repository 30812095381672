import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';

import {
  Content,
  SpaceLine,
  InputForm,
  InputSelect,
  InputDocument,
  InputPhone,
} from 'components/Shared';

import { pixOptions, typeAccount } from './Options';
import { handleSubmit } from './Context';

import { bankList } from 'util/banks';

export function BankAccountForm({ formRef, sendData, dataBank, children }) {
  const [typePixSelect, setTypePixSelect] = useState({
    value: null,
    label: 'nenhum',
  });
  const [filedData, setFiledData] = useState();

  useEffect(() => {
    setFiledData(dataBank);
  }, [dataBank]);

  useLayoutEffect(() => {
    if (filedData) {
      formRef.current.setData(filedData);
      setTimeout(() => {
        formRef.current.setFieldValue('pix[0].type', filedData?.pix?.[0]?.type);
      }, 300);
    }
  }, [filedData, formRef]);

  const submit = useCallback(
    async data => {
      await handleSubmit(data, sendData, formRef);
    },
    [sendData, formRef]
  );

  const handleChangeType = {
    document: (
      <InputDocument
        name="pix[0].key"
        title="Codigo PIX"
        placeholder="Digite seu codigo pix"
      />
    ),
    phone: (
      <InputPhone
        name="pix[0].key"
        title="Codigo PIX"
        placeholder="Digite seu codigo pix"
      />
    ),
    email: (
      <InputForm
        name="pix[0].key"
        title="Codigo PIX"
        placeholder="Digite seu codigo pix"
      />
    ),
    key: (
      <InputForm
        name="pix[0].key"
        title="Codigo PIX"
        placeholder="Digite seu codigo pix"
      />
    ),
    null: <></>,
  };

  return (
    <>
      <Content ref={formRef} onSubmit={submit}>
        <div>
          <span>
            <InputForm
              name="bankAccount.legalName"
              title="Nome do titular"
              placeholder="Digite o nome do titular"
              required={true}
              maxLength={30}
            />
          </span>
          <span>
            <InputDocument
              name="bankAccount.documentNumber"
              title="Documento (CPF/CNPJ)"
              placeholder="Digite o documento"
              required={true}
            />
          </span>
        </div>
        <div>
          <span>
            <InputSelect
              name="bankAccount.bankCode"
              title="Banco"
              placeholder="Selecione o banco"
              required={true}
              options={bankList}
            />
          </span>
          <span>
            <InputForm
              name="bankAccount.bankBranch"
              title="Agência"
              placeholder="Digite a agência aqui"
              required={true}
              maxLength={4}
            />
          </span>
        </div>
        <div>
          <span>
            <InputForm
              name="bankAccount.bankBranchDv"
              title="DV - Agência"
              placeholder="Digite o digitos verificadores da agência"
              maxLength={1}
            />
          </span>
          <span>
            <InputForm
              name="bankAccount.account"
              title="Conta Bancária"
              placeholder="Digite a conta bancária aqui"
              required={true}
              maxLength={13}
            />
          </span>
        </div>
        <div>
          <span>
            <InputSelect
              name="bankAccount.type"
              title="Tipo de Conta"
              placeholder="Selecione a conta bancária"
              required={true}
              options={typeAccount}
            />
          </span>
          <span>
            <InputForm
              name="bankAccount.accountDv"
              title="DV - Conta Bancária"
              placeholder="Digite o codigo verificador"
              required={true}
              maxLength={2}
            />
          </span>
        </div>
        <div>
          <span>
            <SpaceLine />
          </span>
        </div>
        <div>
          <span>
            <InputSelect
              name="pix[0].type"
              title="Tipo de PIX"
              placeholder="Selecione o PIX"
              options={pixOptions}
              onChange={e => {
                setTypePixSelect(e);
              }}
            />
          </span>
          <span>{handleChangeType[typePixSelect?.value]}</span>
        </div>
        {children}
      </Content>
    </>
  );
}
