import React from 'react';

import { UserLink } from './styles';

export function UserInformation({ user }) {
  const roles = {
    owner: 'Proprietário',
    tenant: 'Inquilino',
    broker: 'Corretor',
  };

  return (
    <UserLink to={`/dashboard/clientes/detalhes/${user?.id}`}>
      <div className="photo">
        <p>{user.initials}</p>
      </div>
      <div className="info">
        <p className="name">
          <strong>
            {user.name} {user.lastName}
          </strong>
        </p>
        <p>{roles[user?.role]}</p>
      </div>
    </UserLink>
  );
}
