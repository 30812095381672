import React from 'react';

import { InputSelectPaginate } from 'components/Shared';
import {
  ReadjustmentOptions,
  GuaranteeOptions,
  AdmFeeOptions,
} from './Services';

export function Tax({
  setReadjustmentLabel,
  setGuaranteeLabel,
  setAdmFeeLabel,
}) {
  return (
    <>
      <div>
        <span>
          <InputSelectPaginate
            name="negotiation.readjustmentId"
            title="Índice de reajuste"
            loadOptions={ReadjustmentOptions}
            required={true}
            additional={{
              page: 1,
            }}
            placeholder="Pesquisar índice"
            onChange={e => {
              setReadjustmentLabel(e);
            }}
          />
        </span>
        <span>
          <InputSelectPaginate
            name="negotiation.guaranteeId"
            title="Garantia"
            loadOptions={GuaranteeOptions}
            required={true}
            additional={{
              page: 1,
            }}
            placeholder="Pesquisar gantantia"
            onChange={e => {
              setGuaranteeLabel(e);
            }}
          />
        </span>
      </div>
      <div className="half">
        <span>
          <InputSelectPaginate
            name="negotiation.admFeeId"
            title="Taxa de administração"
            placeholder="Selecione uma Taxa"
            loadOptions={AdmFeeOptions}
            additional={{
              page: 1,
            }}
            required={true}
            onChange={e => {
              setAdmFeeLabel(e);
            }}
          />
        </span>
      </div>
    </>
  );
}
