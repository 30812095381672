import useFetch from 'services/ReactQuery';

export function getStates() {
  const { data, isLoading, isError } = useFetch.Get({
    url: '/states',
  });

  if (!isLoading) {
    data.sort((a, b) => (a.cities.length > b.cities.length ? -1 : 1));
  }

  return {
    data: data,
    isLoading: isLoading || isError || null,
  };
}
