import styled from 'styled-components';

export const SignatariusContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  > h1 {
    font-size: 24px;
    align-self: center;
  }
`;
