import React from 'react';
import useFetch from 'services/ReactQuery';
import { LoadingScreen, ErrorScreen } from 'components/Shared';

export function getProperties(params) {
  const { data, isLoading, isError } = useFetch.Get({
    url: '/properties',
    params: params,
  });

  return {
    data: data,
    isLoading:
      (isLoading && <LoadingScreen />) || (isError && <ErrorScreen />) || null,
  };
}
