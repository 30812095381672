import styled from 'styled-components';

export const Box = styled.div`
  padding: 24px;
  width: 100%;

  .titleMixed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      width: 100%;
    }
  }

  @media (max-width: 990px) {
    .titleMixed {
      flex-direction: column;
    }
  }
`;
