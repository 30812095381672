import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ContextTheme } from 'context/ContextTheme';

import GlobalStyles from './styles/global';
import Routes from './routes/';

import history from './services/service.history';
import { store, persistor } from './store';

function App() {
  return (
    <Provider store={store}>
      <ContextTheme>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <Routes />
            <GlobalStyles />
            <ToastContainer autoClose={3500} />
          </Router>
        </PersistGate>
      </ContextTheme>
    </Provider>
  );
}

export default App;
