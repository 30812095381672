import styled from 'styled-components';
import { Form } from '@unform/web';

export const Content = styled(Form)`
  > div {
    display: flex;
    margin-top: 32px;

    &: first-child {
      margin-top: 0;
    }

    span {
      width: 100%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .half {
    width: 50%;
    padding-right: 8px;
  }
  @media (max-width: 990px) {
    padding: 46px 16px;
    width: 100%;
    > div {
      flex-direction: column;
      width: 100%;
      span {
        margin-right: 0;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .half {
      width: 100%;
      padding-right: 0;
    }
  }
`;
