import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  ContentScroll,
  EmptyList,
  Filter,
  FilterContainer,
} from 'components/Shared';
import { CycleDetails } from './Components/CycleDetails';
import { getCycles } from './Service';

export function CycleList({ setSelectedCycle }) {
  const { id } = useParams();
  const [currentParams, setCurrentParams] = useState({
    page: 1,
    perPage: 10,
    order: 'DESC',
    status: 'all',
  });

  const { data, isLoading } = getCycles({ params: currentParams, id });

  return (
    <>
      <FilterContainer style={{ marginBottom: 16 }}>
        <Filter
          filter={[
            {
              label: 'Todos',
              value: 'all',
            },
            {
              label: 'Ativos',
              value: 'active',
            },
            {
              label: 'Inativos',
              value: 'deleted',
            },
          ]}
          active={currentParams.status}
          handleFilter={filter => {
            setCurrentParams({
              ...currentParams,
              status: filter.value,
            });
          }}
        />
      </FilterContainer>
      <ContentScroll>
        {isLoading ||
          (data.length > 0 ? (
            data.map(cycle => (
              <CycleDetails
                key={cycle.id}
                cycle={cycle}
                currentParams={currentParams}
                setSelectedCycle={setSelectedCycle}
              />
            ))
          ) : (
            <EmptyList />
          ))}
      </ContentScroll>
    </>
  );
}
