import React from 'react';

import { Modal, BlackScreen } from './styles';

export function ModalScreen({ setOpen, open, children }) {
  return (
    <>
      <BlackScreen onClick={() => setOpen(false)} open={open} />
      <Modal open={open}>{children}</Modal>
    </>
  );
}
