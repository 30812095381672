import React from 'react';
import { Box } from 'components/Shared';

import { Information } from './styles';
import { useSelector } from 'react-redux';
export function GuestDashboard() {
  const user = useSelector(state => state.user.profile);

  return (
    <>
      <Box>
        <Information>
          <h1>Bem-vindo à Wizzer {user.name}!</h1>
          <p>
            <strong>Unidades:</strong> Explore as plantas dos imóveis e
            documentos relacionados à propriedade para entender melhor sua
            disposição.
          </p>
          <p>
            <strong>Suporte:</strong> Caso precise de assistência ou informações
            adicionais, não hesite em entrar em contato conosco. Estamos à
            disposição para ajudá-lo.
          </p>
        </Information>
      </Box>
    </>
  );
}
