import React from 'react';

import {
  ReelsContainer,
  ButtonReel,
  Container,
  Information,
  Confirmation,
  ConfirmationContent,
} from './styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
export function ClientDashboard() {
  const user = useSelector(state => state.user.profile);
  const isConfirmation = false;
  const termAndCondition = () => {
    if (isConfirmation) {
      return (
        <ConfirmationContent>
          <div>
            <h2>TERMOS DE CONDIÇÕES Última Atualização: 16/10/2023</h2>
            <p>
              Bem-vindo a Wizzer! Estes Termos de Condições regem o uso do nosso
              site e a coleta e uso de informações pessoais. Ao acessar e usar o
              site, você concorda com os seguintes termos e condições.
              Certifique-se de ler atentamente este documento. 1. Coleta de
              Informações 1.1. Ao utilizar nosso site, podemos coletar
              informações pessoais, incluindo, mas não se limitando a, nome,
              endereço de e-mail, e outras informações relevantes. 1.2. As
              informações coletadas serão usadas exclusivamente para fins
              internos, como melhorar nossos serviços, entender as necessidades
              dos usuários e aprimorar a experiência geral do site. 2. Uso das
              Informações 2.1. As informações coletadas podem ser usadas para:
              a. Personalizar sua experiência no site. b. Enviar informações
              relevantes sobre nossos produtos e serviços. c. Melhorar nosso
              site e serviços. d. Realizar pesquisas de mercado e análises de
              dados internas. 3. Compartilhamento de Informações 3.1. Não
              venderemos, alugaremos ou divulgaremos suas informações pessoais a
              terceiros, exceto quando exigido por lei. 4. Cookies e Tecnologias
              Semelhantes 4.1. Podemos utilizar cookies e outras tecnologias
              para coletar informações não pessoais sobre o seu uso do site,
              visando melhorar a funcionalidade e a experiência do usuário. 5.
              Segurança 5.1. Implementamos medidas de segurança adequadas para
              proteger suas informações pessoais. No entanto, nenhum sistema é
              100% seguro, e não podemos garantir a segurança completa de suas
              informações. 6. Alterações nos Termos de Condições 6.1.
              Reservamo-nos o direito de atualizar ou modificar estes Termos de
              Condições a qualquer momento. Quaisquer alterações entrarão em
              vigor imediatamente após a publicação no site. 7. Contato 7.1. Se
              você tiver alguma dúvida ou preocupação sobre estes Termos de
              Condições ou o uso de suas informações pessoais, entre em contato
              conosco em [endereço de e-mail de contato]. 8. Aceitação dos
              Termos 8.1. Ao continuar a usar o site, você concorda com estes
              Termos de Condições e com nossa coleta e uso de informações
              pessoais de acordo com a nossa Política de Privacidade. Obrigado
              por utilizar [Nome do Site]!
            </p>
            <Confirmation>
              <span>
                <input type="checkbox" /> Eu aceito os termos de condições
              </span>
              <button>Continuar</button>
            </Confirmation>
          </div>
        </ConfirmationContent>
      );
    }
  };

  const history = useHistory();

  return (
    <>
      <ReelsContainer>
        <ButtonReel onClick={() => history.push('/dashboard/informacoes')}>
          Unidades
        </ButtonReel>
        <ButtonReel onClick={() => history.push('/dashboard/fotos')}>
          Fotos da Obra
        </ButtonReel>
        <ButtonReel onClick={() => history.push('/dashboard/suporte')}>
          Contatos
        </ButtonReel>
      </ReelsContainer>
      <Container>
        <Information>
          <h1>Bem-vindo à Wizzer {user.name}!</h1>
          <p>
            <strong>Unidades:</strong> Visualize as plantas dos imóveis e
            documentos que agora são seus. Veja como cada unidade foi projetada
            para atender às suas necessidades.
          </p>
          <p>
            <strong>Fotos:</strong> Explore galerias de fotos e vídeos para ter
            uma visão completa dos empreendimentos, do design de interiores à
            localização, agora que você é parte dessa comunidade.
          </p>
          <p>
            <strong>Suporte:</strong> Mantenha contato conosco para obter
            suporte e informações adicionais. Estamos à disposição para ajudá-lo
            a aproveitar ao máximo sua propriedade.
          </p>
        </Information>

        {termAndCondition()}
      </Container>
    </>
  );
}
