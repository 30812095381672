import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import TokenService from '../services/service.token';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const signed = TokenService.getAccessToken();
  const userOrFail = useSelector(state => state.user?.profile);
  const currentURL = window.location.pathname;
  const guestPage = currentURL.startsWith('/guest/');
  if (!signed && isPrivate) {
    window.location.href = '/login';
  }

  if (signed && !isPrivate) {
    if (userOrFail?.nopass === true) {
      return <Redirect to="/new-password" />;
    }

    if (!guestPage) {
      return <Redirect to="/dashboard" />;
    }

    // return <Redirect to="/dashboard" />;
  }

  return <Route {...rest} component={Component} />;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
