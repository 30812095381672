import React from 'react';

import { PropertyList } from '../styles';
import { MdHouse } from 'react-icons/md';

export function PropertySelected({ property }) {
  return (
    <>
      <PropertyList className="fill">
        <span className="selectedTitle">Imóvel Selecionado:</span>
        <div>
          <div className="photo">
            <MdHouse />
          </div>
          <div className="info">
            <p className="name">
              <strong>
                {property.address.street}, {property.address.number} -{' '}
                {property.address.district}, {property.address.city}/
                {property.address.state}
              </strong>
            </p>
            <p>{property.address?.complement}</p>
          </div>
        </div>
        <div className="responsibleContainer">
          <div className="responsible">
            <span className={'selectedTitle secondTitle'}>
              Corretor Responsável:
            </span>
            <div className={'clientContainer'}>
              <div className="clientPhoto">
                <p>
                  {property.broker.name.charAt(0)}
                  {property.broker.lastName.charAt(0)}
                </p>
              </div>
              <div className="info">
                <p>
                  <strong>
                    {property.broker.name} {property.broker.lastName}
                  </strong>
                </p>
                <p>{property.broker.email}</p>
              </div>
            </div>
          </div>
          <div className="responsible">
            <span className={'selectedTitle secondTitle'}>
              Proprietario Responsável:
            </span>
            <div className={'clientContainer'}>
              <div className="clientPhoto">
                <p>
                  {property.owner.name.charAt(0)}
                  {property.owner.lastName.charAt(0)}
                </p>
              </div>
              <div className="info">
                <p>
                  <strong>
                    {property.owner.name} {property.owner.lastName}
                  </strong>
                </p>
                <p>{property.owner.email}</p>
              </div>
            </div>
          </div>
        </div>
      </PropertyList>
    </>
  );
}
