import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 16px;
  border: ${props => props.theme.border};
  background: ${props => props.theme.container.background};
  border-radius: 8px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

export const InformationContent = styled(NavLink)`
  display: flex;
  margin-right: 24px;
  width: -webkit-fill-available;
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  > span {
    display: flex;
    width: 104px;
    height: 72px;
    background: #d8010e;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    > svg {
      width: 42px;
      height: 42px;
      color: white;
    }
  }
  > div {
    > h3 {
      font-size: 24px;
    }
    > p {
      font-size: 16px;
    }
  }

  .active {
    background: #0b8e63;
  }

  @media (max-width: 990px) {
    > span {
      width: 90px;
      height: 56px;
      > svg {
        width: 36px;
        height: 36px;
      }
    }
    > div {
      > p {
        display: none;
      }
    }
  }
`;

export const DescriptionMobile = styled.div`
  display: none;
  width: 100%;
  margin: 16px 0;
  > p {
    font-size: 16px;
  }

  @media (max-width: 990px) {
    display: flex;
  }
`;

export const StatusButtons = styled.div`
  display: flex;
  > button {
    padding: 16px 24px;
    width: 130px;
    text-align: center;
    font-size: 16px;
    border-radius: 8px 0 0 8px;
    color: #aaaaaa;
    background: #ebebeb;
    transition: all 0.3s ease-in-out;
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  .active {
    background: #055eff;
    color: #fff;
  }

  @media (max-width: 990px) {
    width: 100%;
    > button {
      width: 100%;
    }
  }
`;
