import React, { useState } from 'react';

import { PropertySummary } from '../../Summaries/PropertySummary';
import { GetPropertyList } from './Services';

export function Property({ userID }) {
  const [currentParams, setCurrentParams] = useState({
    page: 1,
    perPage: 10,
    search: null,
    filter: { status: '' },
  });
  const { data, isLoading } = GetPropertyList(currentParams, userID);

  return (
    <PropertySummary
      properties={data}
      currentParams={currentParams}
      setCurrentParams={setCurrentParams}
      isLoading={isLoading}
    />
  );
}
