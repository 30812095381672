import React from 'react';
import FormatCurrency from 'util/FormatCurrency';

import { ContentData, ContentGroup, TitleContainer } from 'components/Shared';

export function RentalSummary({ data, children }) {
  const { negotiation, rental, labels } = data;

  return (
    <>
      <TitleContainer>
        <h1>Dados do Aluguel:</h1>
      </TitleContainer>
      <ContentGroup>
        <div>
          <h2>Detalhes do aluguel</h2>
          <ContentData>
            <p>
              Valor do aluguel:{' '}
              <strong>{FormatCurrency(rental?.amount / 100)}</strong>
            </p>
            <p>
              Valor do Condominío:{' '}
              <strong>{FormatCurrency(rental?.amountTownhouse / 100)}</strong>
            </p>
          </ContentData>
        </div>
        <div>
          <h2>Dia do pagamento:</h2>
          <ContentData>
            <p>
              Dia do mês: <strong>{negotiation?.dayPayment}</strong>
            </p>
          </ContentData>
        </div>
        <div>
          <h2>Taxas e Seguro:</h2>
          <ContentData>
            <p>
              Taxa de administração: <strong>{labels?.fee.label}%</strong>
            </p>
            <p>
              Inclui seguro incendio?{' '}
              <strong>{negotiation?.insuranceFire ? 'Sim' : 'Não'}</strong>
            </p>
            {negotiation?.insuranceFireAmount && (
              <p>
                Valor do seguro incendio:
                <strong>
                  {' '}
                  {FormatCurrency(negotiation?.insuranceFireAmount / 100)}
                </strong>
              </p>
            )}
            <p>
              Declara imposto de renda?{' '}
              <strong>{negotiation?.incomeTax ? 'Sim' : 'Não'}</strong>
            </p>
          </ContentData>
        </div>
      </ContentGroup>
      {children}
    </>
  );
}
