import jarvis from 'util/jarvis';
import { useQueryClient, useMutation } from 'react-query';

const UpdateNegotiationTenantRoute = data => {
  return jarvis.put(`/negotiation/${data.id}/tenant`, data.tenant);
};

export function UpdateNegotiationTenant() {
  const queryClient = useQueryClient();
  return useMutation(UpdateNegotiationTenantRoute, {
    onSuccess: data => {
      const newData = data.data;
      queryClient.invalidateQueries(['GetNegotiation', `${newData.id}`]);
      queryClient.setQueryData(['/GetNegotiation', `${newData.id}`], newData);
    },
  });
}
