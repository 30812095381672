import styled from 'styled-components'
import { Form } from '@unform/web'
import { NavLink } from 'react-router-dom'

export const Container = styled(Form)`
  margin: 0;
`

export const RedirectLink = styled(NavLink)`
  display: flex;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: #fff;
  color: #055EFF;
  font-size: 18px;
  border: 1px solid #055EFF;
  box-sizing: border-box;
  border-radius: 4px;
  flex: 1;

  > svg {
    margin-left: 18px;
  }
`

export const StepTitle = styled.button`
  height: 100%;
  padding: 16px;
  border-bottom: 3px solid #fff;
  box-sizing: border-box;
  background: none;
  margin: 0 10px;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #E1E1E1;
  transition: all 500ms ease-in-out;

  ${({ active, completed }) => {
    if (active) {
      return `
          color: #055EFF;
          border-bottom: 3px solid #055EFF;
        `
    }
    if (completed) {
      return `
        color: #055EFF;
      `
    }
  }}

  > svg {
    margin-right: 8px;
    font-size: 20px;
  }

  @media screen and (max-width: 600px) {
    display: ${({ active }) => active ? 'flex' : 'none'};
  }
`

export const StepContent = styled.div`
  width: 100%;

  ${({ active }) => {
    if (!active) {
      return `
          display: none;
        `
    }
  }}
`

export const PropTypeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #989BA1;
  background: #fff;
  border: 2px solid #989BA1;
  width: 142px;
  height: 142px;
  border-radius: 8px;
  margin: auto;

  & + button {
    margin-left: 16px;
  }

  > img {
    margin-bottom: 8px;
  }

  ${({ active }) => {
    if (active) {
      return `
          border: 2px solid #055EFF;
          color: #055EFF;
        `
    }
  }}
`

export const Table = styled.table`
`

export const Td = styled.td`
  margin-right: 4px;
`

export const Tr = styled.tr`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 8px 8px 0;
  margin: 8px 8px 8px 0;

  > svg {
    margin-right: 4px;
    border: 1px solid #989BA1;
    color: #989BA1;
    border-radius: 4px;
  }

  ${({ nearby }) => {
    if (nearby) {
      return `
          text-weight: bold;

          > svg {
            color: #055EFF;
            background: rgba(5, 94, 255, 0.1);
            border: 2px solid #055EFF;
            border-radius: 4px;
          }
        `
    }
  }}
`
