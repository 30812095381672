import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 160px;
  align-items: center;

  > span {
    margin-top: 36px;
    font-size: 24px;
    font-weight: 500;
  }
  .loading {
    position: absolute;
    transform: translate(-50%, -50%) rotate(75deg);
    width: 15px;
    height: 15px;
  }

  .loading::before,
  .loading::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    border-radius: 15px;
    animation: loading 1.5s infinite linear;
  }

  .loading::before {
    box-shadow: 15px 15px #055eff, -15px -15px #0b8e63;
  }

  .loading::after {
    box-shadow: 15px 15px #2693ff, -15px -15px #11be9a;
    transform: translate(-50%, -50%) rotate(90deg);
  }

  @keyframes loading {
    50% {
      height: 45px;
    }
  }
  @media screen and (max-width: 990px) {
    padding: 42px 8px;
  }
`;
