import React, { useRef, useState } from 'react';

import { Buttons, Button, Box, Filter } from 'components/Shared';
import { NegotiationForm } from '../../Forms/NegotiatonForm';
import { TaxForm } from '../../Forms/TaxForm';
import { NegotiationSummary } from '../../Summary/NegotiationSummary';

import { UpdateNegotiation, UpdateNegotiationRules } from './Services';
import { toast } from 'react-toastify';

export function NegotiationData({ negotiationData, negotiationID }) {
  const formRef = useRef();
  const { mutateAsync: mutateNegotiation } = UpdateNegotiation();
  const { mutateAsync: mutateNegotiationRules } = UpdateNegotiationRules();
  const [edit, setEdit] = useState(false);
  const [form, setForm] = useState('negotiation');

  const negotiation = {
    negotiation: {
      ...negotiationData,
      insuranceFire: negotiationData?.insuranceFire ? true : false,
      insuranceFireAmount: negotiationData?.insuranceFire?.amount,
      startDate: negotiationData?.periodRental?.startDate,
      endDate: negotiationData?.periodRental?.leaseDate,
    },
    labels: {
      guarantee: {
        label: negotiationData?.guarantee.name,
        value: negotiationData?.guarantee.id,
      },
      fee: {
        label: negotiationData?.admFee.percentage,
        value: negotiationData?.admFee.id,
      },
      readjustment: {
        label: negotiationData?.readjustment.name,
        value: negotiationData?.readjustment.id,
      },
    },
  };

  async function sendUpdateNegotiation(data) {
    data = {
      ...data,
      id: negotiationID,
    };

    try {
      await mutateNegotiation(data);
      setEdit(false);
    } catch (error) {
      toast.error('Erro ao atualizar negociação!');
    }
  }

  async function sendUpdateNegotiationRules(data) {
    data = {
      rules: data,
      id: negotiationID,
    };
    try {
      await mutateNegotiationRules(data);
      setEdit(false);
    } catch (err) {
      const errorMessage = err.response.data.name;
      if (errorMessage === 'InvalidAdmFeeError') {
        toast.error('Opa! Verifique a taxa de administração!');
      } else if (errorMessage === 'InvalidReadjustmentError') {
        toast.error('Opa! Verifique a taxa de reajuste!');
      } else if (errorMessage === 'InvalidGuaranteeError') {
        toast.error('Opa! Verifique a garantia!');
      } else {
        toast.error('Erro ao atualizar as taxas!');
      }
    }
  }

  return (
    <Box>
      {!edit ? (
        <>
          <NegotiationSummary negotiationData={negotiation}>
            {negotiationData.status === 'analyzing' && (
              <Buttons style={{ marginTop: 24 }}>
                <Button
                  typeColor="primary"
                  type="button"
                  onClick={() => setEdit(true)}
                >
                  Editar
                </Button>
              </Buttons>
            )}
          </NegotiationSummary>
        </>
      ) : (
        <>
          <Filter
            filter={[
              { value: 'negotiation', label: 'Negociação' },
              { value: 'tax', label: 'Taxas' },
            ]}
            active={form}
            handleFilter={filter => setForm(filter.value)}
            style={{ marginBottom: 24 }}
          />
          {form === 'negotiation' ? (
            <>
              <NegotiationForm
                formRef={formRef}
                negotiationData={negotiation}
                getDataForm={sendUpdateNegotiation}
              >
                <Buttons>
                  <Button
                    typeColor="secondary"
                    type="button"
                    onClick={() => setEdit(false)}
                  >
                    Cancelar
                  </Button>
                  <Button typeColor="primary" type="submit">
                    Salvar
                  </Button>
                </Buttons>
              </NegotiationForm>
            </>
          ) : (
            <TaxForm
              formRef={formRef}
              negotiationData={negotiation}
              getDataForm={sendUpdateNegotiationRules}
            >
              <Buttons>
                <Button
                  typeColor="secondary"
                  type="button"
                  onClick={() => setEdit(false)}
                >
                  Cancelar
                </Button>
                <Button typeColor="primary" type="submit">
                  Salvar
                </Button>
              </Buttons>
            </TaxForm>
          )}
        </>
      )}
    </Box>
  );
}
