import React from 'react';
import { RentalSummary } from '../../components/RentalSummary';

export function RentalDetails({ rental }) {
  const { negotiation, amount, amountTownhouse } = rental;

  const newNegotiation = {
    rental: {
      amount: amount,
      amountTownhouse: amountTownhouse,
    },
    negotiation: {
      ...negotiation,
      insuranceFire: negotiation?.insuranceFire ? true : false,
      insuranceFireAmount: negotiation?.insuranceFire?.amount,
      startDate: negotiation?.periodRental?.startDate,
      endDate: negotiation?.periodRental?.leaseDate,
    },
    labels: {
      fee: {
        label: negotiation?.admFee?.percentage || null,
      },
    },
  };

  return (
    <>
      <RentalSummary data={newNegotiation} />
    </>
  );
}
