import React from 'react';

import {
  MdPersonAdd,
  MdPersonSearch,
  MdHome,
  MdHomeWork,
  MdLocationCity,
  MdSpaceDashboard,
} from 'react-icons/md';
import { FaHandsHelping, FaKey } from 'react-icons/fa';
import { BsShieldFillCheck } from 'react-icons/bs';
import { GiReceiveMoney } from 'react-icons/gi';
import { HiAdjustments } from 'react-icons/hi';
import { MdPhotoSizeSelectActual, MdLocalPhone } from 'react-icons/md';

const painel = {
  title: 'PAINEL',
  routes: [
    {
      title: 'Meu Painel',
      icon: () => <MdSpaceDashboard />,
      path: '/dashboard',
    },
  ],
};

const client = {
  title: 'CLIENTES',
  routes: [
    {
      title: 'Adicionar Cliente',
      icon: () => <MdPersonAdd />,
      path: '/dashboard/clientes/criar',
    },
    {
      title: 'Lista de Clientes',
      icon: () => <MdPersonSearch />,
      path: '/dashboard/clientes',
    },
  ],
};

const property = {
  title: 'IMÓVEIS',
  routes: [
    {
      title: 'Adicionar Imóvel',
      icon: () => <MdHome />,
      path: '/dashboard/registrar-imovel',
    },
    {
      title: 'Lista de Imóveis',
      icon: () => <MdHomeWork />,
      path: '/dashboard/imoveis',
    },
  ],
};

const negotiation = {
  title: 'Negociações',
  icon: () => <FaHandsHelping />,
  path: '/dashboard/negociacoes',
};

const rental = {
  title: 'Aluguéis',
  icon: () => <FaKey />,
  path: '/dashboard/alugueis',
};

const location = {
  title: 'NEGOCIAÇÕES',
  routes: [negotiation, rental],
};

const brokerLoaction = {
  title: 'NEGOCIAÇÕES',
  routes: [negotiation],
};

const tenancy = {
  title: 'TAXAS',
  routes: [
    {
      title: 'Garantias',
      icon: () => <BsShieldFillCheck />,
      path: '/dashboard/lista-de-garantias',
    },
    {
      title: 'Reajuste',
      icon: () => <HiAdjustments />,
      path: '/dashboard/lista-de-reajuste',
    },
    {
      title: 'Taxas Administrativas',
      icon: () => <GiReceiveMoney />,
      path: '/dashboard/lista-de-taxa-de-administracao',
    },
  ],
};

const announce = {
  title: 'PORTAL DE ANÚNCIOS',
  routes: [
    {
      title: 'Gerenciamento do Site',
      icon: () => <MdLocationCity />,
      path: '/dashboard/portal',
    },
  ],
};

const ownerPainel = {
  title: 'GERAIS',
  routes: [
    {
      title: 'Meu Painel',
      icon: () => <MdSpaceDashboard />,
      path: '/dashboard',
    },
    {
      title: 'Unidades',
      icon: () => <MdHome />,
      path: '/dashboard/informacoes',
    },
    {
      title: 'Fotos da obra',
      icon: () => <MdPhotoSizeSelectActual />,
      path: '/dashboard/fotos',
    },

    {
      title: 'Suporte',
      icon: () => <MdLocalPhone />,
      path: '/dashboard/suporte',
    },
  ],
};

const guestPainel = {
  title: 'GERAIS',
  routes: [
    {
      title: 'Meu Painel',
      icon: () => <MdSpaceDashboard />,
      path: '/dashboard',
    },
    {
      title: 'Unidades',
      icon: () => <MdHome />,
      path: '/dashboard/informacoes',
    },
    {
      title: 'Suporte',
      icon: () => <MdLocalPhone />,
      path: '/dashboard/suporte',
    },
  ],
};

export const routeByRole = role =>
  ({
    admin: [painel, client, property, location, tenancy, announce],
    broker: [painel, client, property, brokerLoaction],
    owner: [ownerPainel],
    guest: [guestPainel],
  }[role] || [painel]);
