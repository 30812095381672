import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const ButtonStyles = styled(NavLink)`
  display: flex;
  background: ${props => props.background};
  padding: 16px 24px;
  color: ${props => props.color};
  border-radius: 8px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  > img {
    margin-right: 8px;
  }
`;
