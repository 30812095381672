import * as Yup from 'yup';

export const DocumentsAgentValidation = Yup.object().shape({
  documents: Yup.array().of(
    Yup.object().shape({
      document: Yup.string().required('Digite o documento'),
    })
  ),
});

export const SpouseAgentValidation = Yup.object().shape({
  documents: Yup.array().of(
    Yup.object().shape({
      document: Yup.string().test('document', 'Digite o documento', function() {
        return this.path === 'documents[2].document' || this.parent.document;
      }),
    })
  ),
});
