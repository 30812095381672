import styled from 'styled-components'

export const Container = styled.label`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;

  i {
    position: absolute;
    top: 25%;
    right: 2%;

    svg {
      width: 24px;
      height: 24px;
    }
  }

`