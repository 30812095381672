import React from 'react';
import { useHistory } from 'react-router-dom';
import { FaPaperPlane } from 'react-icons/fa';
import { Container } from './styles';
import { Button } from 'components/Shared';

import { SendSignatarius } from './Services';
import { toast } from 'react-toastify';

export function FinishContract({
  getContractsInformation,
  selectedCoSignatarius,
  negotiationData,
  setLoadingSubmit,
}) {
  const history = useHistory();

  async function handleFinishContract() {
    setLoadingSubmit(true);
    try {
      await SendSignatarius(getContractsInformation.id, selectedCoSignatarius);
      history.push(
        `/dashboard/negociacao-detalhes/${negotiationData.id}/contrato/${getContractsInformation.id}`,
        {
          negotiationData: negotiationData,
        }
      );
      toast.success('Sucesso ao criar contrato');
      setLoadingSubmit(false);
    } catch (error) {
      toast.error('Erro ao criar contrato');
      setLoadingSubmit(false);
    }
  }

  return (
    <Container>
      <div>
        <FaPaperPlane className="planeAnimation" />
      </div>
      <h1>Signatários adicionados com sucesso!</h1>
      <Button typeColor="primary" onClick={() => handleFinishContract()}>
        Enviar Contrato!
      </Button>
    </Container>
  );
}
