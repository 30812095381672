import React, { useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { MdCancel, MdVerified, MdVpnKey } from 'react-icons/md';
import { Titles } from 'components/Titles';
import {
  Box,
  Button,
  Buttons,
  LoadingScreen,
  NavigationContainer,
  ConfirmExecution,
  ModalScreen,
} from 'components/Shared';
import { PropertyDetails } from './Components/PropertyDetails';
import { BorderContainer } from './styles';
import { ContractData } from './Components/Template/ContractData';
import { NegotiationData } from './Components/Template/NegotiationData';
import {
  GetNegotiation,
  UpdateNegotiationStatus,
  createRentalRoute,
} from './Services';

import { PropertyData } from './Components/Template/PropertyData';
import { TenantData } from './Components/Template/TenantData';
import { toast } from 'react-toastify';
import { GetContracts } from './Components/Template/ContractData/Services';
import { useSelector } from 'react-redux';

export function NegotiationDetails() {
  const userProfile = useSelector(state => state.user.profile);
  const [rejectWarning, setRejectWarning] = useState(false);
  const [approveWarning, setApproveWarning] = useState(false);
  const [renderPage, setRenderPage] = useState('negotiation');
  const { id: negotiationID } = useParams();
  const { isLoading, data: negotiationData } = GetNegotiation(negotiationID);
  const { mutateAsync: updateStatus } = UpdateNegotiationStatus();
  const contractsData = userProfile.isAdmin
    ? GetContracts(negotiationID)
    : null;
  const history = useHistory();

  async function SendUpdateStatus(status) {
    const data = {
      data: {
        status: status,
      },
      id: negotiationID,
    };
    try {
      await updateStatus(data);
      setRejectWarning(false);
      setApproveWarning(false);
      toast.success('Status atualizado com sucesso!');
    } catch (error) {
      setRejectWarning(false);
      setApproveWarning(false);
      toast.error('Erro ao atualizar status!');
    }
  }

  async function SendCreateRental() {
    try {
      const response = await createRentalRoute(negotiationID);
      toast.success('Aluguel criada com sucesso!');
      history.push(`/dashboard/alugueis/${response.id}/detalhes`);
    } catch (error) {
      toast.error('Erro ao criar locação!');
    }
  }

  const negotiatonPages = {
    negotiation: (
      <NegotiationData
        negotiationData={negotiationData}
        negotiationID={negotiationID}
      />
    ),
    contracts: <ContractData negotiationData={negotiationData} />,
    property: (
      <PropertyData
        negotiationData={negotiationData}
        negotiationID={negotiationID}
      />
    ),
    tenant: (
      <TenantData
        negotiationData={negotiationData}
        negotiationID={negotiationID}
      />
    ),
  };

  function cancelNegotiation() {
    SendUpdateStatus('canceled');
  }

  return (
    <Box>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Titles
            title="Detalhes da negociação"
            subtitle={`Imóvel ID/${negotiationData?.property?.id}`}
          />
          <NavigationContainer
            column={1}
            active={renderPage}
            setValue={setRenderPage}
            options={
              userProfile.isAdmin
                ? [
                    {
                      label: 'Dados Negociação',
                      value: 'negotiation',
                      filled: true,
                    },
                    {
                      label: 'Contratos',
                      value: 'contracts',
                      filled: contractsData?.data?.data?.length > 0,
                    },
                    {
                      label: 'Dados do Imóvel',
                      value: 'property',
                      filled: true,
                    },
                    {
                      label: 'Dados do Inquilino',
                      value: 'tenant',
                      filled: true,
                    },
                  ]
                : [
                    {
                      label: 'Dados Negociação',
                      value: 'negotiation',
                      filled: true,
                    },
                    {
                      label: 'Dados do Imóvel',
                      value: 'property',
                      filled: true,
                    },
                    {
                      label: 'Dados do Inquilino',
                      value: 'tenant',
                      filled: true,
                    },
                  ]
            }
          >
            <PropertyDetails
              property={negotiationData?.property}
              broker={negotiationData?.broker}
              owner={negotiationData?.owner}
              status={negotiationData?.status}
            />
          </NavigationContainer>
          <Buttons style={{ justifyContent: 'end', marginTop: 24 }}>
            <>
              {negotiationData?.status === 'analyzing' && userProfile.isAdmin && (
                <>
                  <Button
                    typeColor="success"
                    type="button"
                    onClick={() => setApproveWarning(true)}
                  >
                    <MdVerified />
                    Aprovar
                  </Button>
                  <Button
                    typeColor="cancel"
                    type="button"
                    onClick={() => setRejectWarning(true)}
                  >
                    <MdCancel />
                    Rejeitar
                  </Button>
                </>
              )}
              {negotiationData?.status === 'approved' && userProfile.isAdmin && (
                <>
                  <Button
                    typeColor="primary"
                    type="button"
                    onClick={() => SendCreateRental()}
                  >
                    <MdVpnKey />
                    Criar Aluguel
                  </Button>
                  <Button
                    typeColor="cancel"
                    type="button"
                    onClick={() => setRejectWarning(true)}
                  >
                    <MdCancel />
                    Cancelar
                  </Button>
                </>
              )}
            </>
          </Buttons>
          <ConfirmExecution
            open={rejectWarning}
            setOpen={setRejectWarning}
            fn={cancelNegotiation}
            validationkey={`Cancelar negociaçao ${negotiationID}`}
          />
          <ModalScreen open={approveWarning} setOpen={setApproveWarning}>
            <div>
              <h1>Deseja aprovar esta negociação?</h1>
              <p style={{ textAlign: 'center' }}>
                Você está preste a aprovar uma negociação.
              </p>
              <p style={{ textAlign: 'center' }}>
                <strong>
                  {' '}
                  Uma vez feita esta operação, não poderá reverte-la!
                </strong>
              </p>
              <Buttons>
                <Button
                  typeColor="secondary"
                  type="button"
                  onClick={() => setApproveWarning(false)}
                >
                  Cancelar
                </Button>
                <Button
                  typeColor="success"
                  type="button"
                  onClick={() => SendUpdateStatus('approved')}
                >
                  Confirmar
                </Button>
              </Buttons>
            </div>
          </ModalScreen>
          <BorderContainer style={{ marginTop: 16 }}>
            {negotiatonPages[renderPage]}
          </BorderContainer>
        </>
      )}
    </Box>
  );
}
