import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { Container, ErrorBallon } from './styles';

export function DropzoneFile({ name, setImageList, ...rest }) {
  const { fieldName, registerField, error, clearError } = useField(name);
  const fileInput = useRef();

  function handlePhoto(e) {
    if (e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      const renderImage = files.map(file => {
        var photo = URL.createObjectURL(file);
        return photo;
      });

      setImageList(renderImage[0]);
    }
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: fileInput.current,
      path: 'files',
      clearValue(ref) {
        ref.value = '';
      },
      getValue(ref) {
        if (ref.files.length > 0) {
          const files = Array.from(ref.files);
          const data = files.map(file => {
            const formData = new FormData();
            formData.append('file', file);
            return formData;
          });
          return data[0];
        } else {
          return null;
        }
      },
      setValue() {
        return null;
      },
    });
  }, [registerField, fieldName]);

  return (
    <Container>
      {error && (
        <ErrorBallon>
          <div>
            <p>{error}</p>
          </div>
        </ErrorBallon>
      )}
      <button
        className={error ? 'error' : ''}
        onClick={event => {
          event.preventDefault();
          fileInput.current.click();
        }}
        onFocus={() => clearError()}
      >
        <p>Clique aqui para enviar as fotos</p>
      </button>
      <input
        type="file"
        accept=".jpg, .jpeg, .png, application/pdf"
        onChange={event => {
          handlePhoto(event);
        }}
        ref={fileInput}
        {...rest}
      ></input>
    </Container>
  );
}
