import * as Yup from 'yup';

export const specificationsValidation = Yup.object().shape({
  specification: Yup.object().shape({
    bedroom: Yup.number()
      .typeError('Escolha a quantidade de quartos')
      .required('Escolha a quantidade de quartos'),
    bathroom: Yup.number()
      .typeError('Escolha a quantidade de banheiros')
      .required('Escolha a quantidade de banheiros'),
  }),
});
