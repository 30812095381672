import React from 'react'

import {
  Container
} from './styles'

export function Link ({ to, children }) {
  return (
    <Container to={to}>{children}</Container>
  )
}
