import React, { useContext } from 'react';

import { ThemeProvider } from 'styled-components';
import light from 'styles/theme/light';
import dark from 'styles/theme/dark';

import usePersistedState from 'hooks/usePersistedState';

const ThemeContext = React.createContext();
const ThemeUpdateContext = React.createContext();

export function useThemeUpdate() {
  return useContext(ThemeUpdateContext);
}

export function ContextTheme({ children }) {
  const [theme, setTheme] = usePersistedState('theme', 'light');

  function toggleTheme() {
    setTheme(theme === 'light' ? 'dark' : 'light');
  }

  return (
    <ThemeContext.Provider value={theme !== 'light' ? dark : light}>
      <ThemeUpdateContext.Provider value={toggleTheme}>
        <ThemeProvider theme={theme !== 'light' ? dark : light}>
          {children}
        </ThemeProvider>
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
}
