import React from 'react';
import { cpfMask, cnpjMask } from 'util/maskForms';

import {
  ContentVerificationData,
  ContentData,
  ContentGroup,
} from 'components/Shared';

import { bankList } from 'util/banks';

export function BankSummary({ bank, children }) {
  const { bankAccount } = bank;
  const hasBankName = bankAccount.bankCode
    ? bankList.find(item => item.value === bankAccount.bankCode)
    : null;

  const typeBank = {
    conta_corrente: 'Conta Corrente',
    poupanca: 'Poupança',
  };

  function handleDocumentType(document) {
    if (document.replace(/\D/g, '').length <= 11) {
      return cpfMask(document);
    } else {
      return cnpjMask(document);
    }
  }

  return (
    <ContentVerificationData>
      <h1>Informações bancarias</h1>
      <ContentGroup>
        <div>
          <h2>Informações da Conta</h2>
          <ContentData>
            <p>
              Name do Banco: <strong>{hasBankName.label}</strong>
            </p>
            <p>
              Tipo: <strong>{typeBank[bankAccount.type]}</strong>
            </p>
            <p>
              Agencia:{' '}
              <strong>
                {bankAccount.bankBranch}{' '}
                {bankAccount.bankBranchDv
                  ? `- ${bankAccount.bankBranchDv}`
                  : ''}
              </strong>
            </p>
            <p>
              Conta:{' '}
              <strong>
                {bankAccount.account} - {bankAccount.accountDv}
              </strong>
            </p>
          </ContentData>
        </div>
        <div>
          <h2>Infomações Vinculadas</h2>
          <ContentData>
            <p>{bankAccount.legalName}</p>
            <p>
              {bankAccount.documentType.toUpperCase()}:{' '}
              {handleDocumentType(bankAccount.documentNumber)}
            </p>
          </ContentData>
        </div>
      </ContentGroup>
      {children}
    </ContentVerificationData>
  );
}
