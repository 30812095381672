import React, { useEffect, useState } from 'react';

import { ContainerList, IconButton, Buttons } from './styles';
import { RoleType } from 'util/Translate/RoleType';
import { Button } from 'components/Shared';
import { RiPencilFill } from 'react-icons/ri';
import { FaTrash, FaAddressCard } from 'react-icons/fa';
import { MdLocationPin, MdPersonAdd } from 'react-icons/md';
import { FiPlus } from 'react-icons/fi';

export function AgentListType({
  dataAgent,
  setAgentInformation,
  setFormPage,
  setEdit,
  setDeleteAgent,
  setSubAgentInformation,
}) {
  const [agents, setAgents] = useState();

  useEffect(() => {
    if (dataAgent) {
      setAgents(dataAgent);
    }
  }, [dataAgent]);

  function findSpouceAgent(legalAgent) {
    if (legalAgent) {
      const findSpouse = legalAgent.find(agent => agent.type === 'spouse');

      return findSpouse;
    }
  }

  return (
    <ContainerList>
      <h2>Todos os responsaveis:</h2>
      <ul>
        {agents &&
          agents.map((agent, index) => {
            return (
              <li key={index}>
                <span>
                  <button
                    onClick={() => {
                      setDeleteAgent(true);
                      setAgentInformation(agent);
                    }}
                  >
                    <FaTrash />
                  </button>
                  <button
                    onClick={() => {
                      setFormPage('essencial');
                      setAgentInformation(agent);
                      setEdit(true);
                    }}
                  >
                    <RiPencilFill />
                  </button>
                </span>
                <div
                  onClick={() => {
                    setFormPage('essencial');
                    setAgentInformation(agent);
                  }}
                >
                  <p>
                    <strong>
                      {agent?.name} {agent?.lastName?.charAt(0)}
                      {'.'}
                    </strong>
                  </p>
                  <p>{RoleType(agent?.type)}</p>
                </div>
                <Buttons>
                  <IconButton
                    className={agent?.documents ? 'success' : ''}
                    onClick={() => {
                      setFormPage('documents');
                      setAgentInformation(agent);
                    }}
                  >
                    <FaAddressCard />
                  </IconButton>
                  <IconButton
                    className={agent?.address ? 'success' : ''}
                    onClick={() => {
                      setFormPage('address');
                      setAgentInformation(agent);
                    }}
                  >
                    <MdLocationPin />
                  </IconButton>
                  {agent?.type === 'surety' && (
                    <IconButton
                      className={
                        findSpouceAgent(agent?.legalAgents)
                          ? 'success'
                          : 'normal'
                      }
                      onClick={() => {
                        setFormPage('spouse');
                        setAgentInformation(agent);
                        setSubAgentInformation(agent.legalAgents || []);
                      }}
                    >
                      <MdPersonAdd />
                    </IconButton>
                  )}
                </Buttons>
              </li>
            );
          })}

        <li>
          <div>
            <div>
              <FiPlus />
            </div>
            <Button
              typeColor="secondary"
              onClick={() => {
                setAgentInformation();
                setFormPage('essencial');
              }}
              style={{ marginBottom: 16 }}
            >
              Adicionar Responsável
            </Button>
          </div>
        </li>
      </ul>
    </ContainerList>
  );
}
