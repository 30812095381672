import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;

  > img {
    margin-top: 89px;
    width: 100%;
    max-width: 471px;
  }

  > h1, > h2, > p, > div {
    max-width: 677px;
    text-align: center;
  }

  > h2 {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 900;
    font-size: 30.5455px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #055EFF;
  }

  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #353D40;
  }

  > div {
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > a {
      color: #fff;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      height: 54px;
      background: #055EFF;
      border-radius: 8px;
    }
  }

  @media screen and (max-width: 600px) {
    > div {
      flex-direction: column;

      > a {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }
`

export const Photos = styled.div`
  width: 100%;
  display: flex;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background: #055EFF;
  font-size: 16px;
  line-height: 140%;
  color: #fff;
  border: 1px solid #055EFF;
  box-sizing: border-box;
  border-radius: 4px;
`

export const Content = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 29px #5e5e5e, 0px 0px 9.23704px rgba(127, 128, 131, 0.0242963), 0px 0px 2.36296px rgba(127, 128, 131, 0.0157037);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 32px;
  z-index: 99999;
  background: #fff;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateY(-200%)'};

  > svg {
    top: 10px;
    right: 10px;
    position: absolute;
    font-size: 24px;
    color: #B0B3B5;
    cursor: pointer;
  }

  @media screen and (min-width: 600px) {
    width: 672px;
    height: 480px;
  }
`
