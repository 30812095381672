import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import { signInGuest } from '../store/modules/auth/actions';
import logo from '../assets/LogoWizzer.svg';

import {
  Container,
  Footer,
  GuestContainer,
  Loading,
} from '../components/unauth';
import { useParams } from 'react-router-dom';

export function GuestLogin() {
  const dispatch = useDispatch();
  const params = useParams();

  Cookies.remove('access_token');
  useEffect(() => {
    const { id } = params;
    const token = Cookies.get('access_token');
    console.log('token', token);

    if (id && !token) {
      dispatch(signInGuest(id));
    }
  }, [params]);

  return (
    <Container>
      <GuestContainer>
        <img src={logo} />

        <Loading>
          <div className="loading"></div>
          <span>Aguarde!</span>
        </Loading>
      </GuestContainer>
      <Footer>
        © {new Date().getFullYear()} Wizzer Imóveis - Brasil - Todos os direitos
        reservados
      </Footer>
    </Container>
  );
}
