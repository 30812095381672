import React from 'react';

import { PropertyList } from '../styles';
import { MdHouse } from 'react-icons/md';

export function PropertyListSelect({ property, setSelectedProperty }) {
  return (
    <>
      <PropertyList onClick={() => setSelectedProperty(property)}>
        <div>
          <div className="photo">
            <MdHouse />
          </div>
          <div className="info">
            <p className="name">
              <strong>
                {property.address.street}, {property.address.number} -{' '}
                {property.address.district}, {property.address.city}/
                {property.address.state}
              </strong>
            </p>
            <p>{property.address?.complement}</p>
          </div>
        </div>
      </PropertyList>
    </>
  );
}
