import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { NumberFormatBase } from 'react-number-format';

function InputCurrency({ name, title, required, ...rest }) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    error,
    clearError,
    defaultValue,
  } = useField(name);
  const [currency, setCurrency] = useState(defaultValue || '');

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);
    return `${amount}`;
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'props.value',
      getValue(ref) {
        if (ref.value) {
          const removeCurrency = ref.value.replace(/\D/g, '');
          const value = parseInt(removeCurrency);
          return value;
        } else {
          return '';
        }
      },
      setValue(_ref, value) {
        if (value) {
          setCurrency(value);
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <p style={{ fontWeight: 500 }}>{title}</p>
          {required && (
            <strong
              style={{ color: '#D8010E', fontWeight: 500, marginLeft: 8 }}
            >
              *
            </strong>
          )}
          {error && (
            <div style={{ marginLeft: 'auto' }}>
              <span role="img" aria-label="error" style={{ fontSize: 12 }}>
                ❌
              </span>
            </div>
          )}
        </div>
        <NumberFormatBase
          style={{
            marginTop: 8,
            border: error ? '1px solid #D8010E' : '',
          }}
          getInputRef={inputRef}
          className={error ? 'has-error' : ''}
          type="text"
          defaultValue={defaultValue}
          onFocus={() => clearError()}
          prefix={'R$ '}
          value={currency}
          format={currencyFormatter}
          {...rest}
        />
        <span
          className="error"
          style={{
            color: '#D8010E',
            fontSize: '14px',
            visibility: error ? 'visible' : 'hidden',
            marginTop: 8,
            height: 14,
          }}
        >
          {error}
        </span>
      </div>
    </>
  );
}

InputCurrency.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputCurrency;
