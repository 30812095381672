import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Buttons,
  NavigationContainer,
  SelectedFilter,
} from 'components/Shared';
import { Titles } from 'components/Titles';
import { PropertyDetails as Property } from './Components/PropertyDetails';
import { PropertyData } from './Components/Templates/PropertyData';
import { PhotoGalery } from './Components/PhotoGalery';

import { BorderContainer } from './styles';
import { GetProperty } from './Services';

import { shownStatus, availableStatuses } from './Options';
import { toast } from 'react-toastify';
import useFetch from 'services/ReactQuery';

export function PropertyDetails() {
  const { id } = useParams();
  const history = useHistory();
  const [renderPage, setRenderPage] = useState('property');
  const [propertyStatus, setPropertyStatus] = useState();
  const { role } = useSelector(state => state.user.profile);
  const { isLoading, data: propertyData } = GetProperty(id);
  const { mutateAsync: changeStatus } = useFetch.Put();

  useEffect(() => {
    if (propertyData) {
      const status = availableStatuses.find(
        ref => ref.value === propertyData.status
      );
      setPropertyStatus(status);
    }
  }, [propertyData]);

  function handleRedirectToCreateNegotiation(data) {
    history.push(`/dashboard/criar-negociacao`, {
      property: data,
    });
  }

  async function changePropertyStatus(status) {
    try {
      await changeStatus({
        url: `/property/status/${id}`,
        body: {
          status: status.value,
        },
        key: `/property/${id}`,
      });
      toast.success('Status do imóvel alterado com sucesso!');
    } catch (err) {
      toast.error('Erro ao alterar status do imóvel.');
    }
  }

  const propertyPages = {
    property: <PropertyData propertyData={propertyData} />,
    photos: <PhotoGalery propertyData={propertyData} />,
  };

  return (
    <Box>
      {isLoading || (
        <>
          <Titles
            title="Imóveis"
            subtitle={`Imóvel ID/${propertyData?.id} - ${
              shownStatus[propertyData?.status]
            }`}
          />
          <NavigationContainer
            style={{ marginBottom: 16 }}
            column={1}
            active={renderPage}
            setValue={setRenderPage}
            options={[
              {
                label: 'Dados do Imóvel',
                value: 'property',
                filled: true,
              },
              {
                label: 'Fotos',
                value: 'photos',
                filled: true,
              },
            ]}
          >
            <Property
              property={propertyData}
              broker={propertyData.broker}
              owner={propertyData.owner}
              status={propertyData.status}
            />
          </NavigationContainer>

          <Buttons>
            {role === 'admin' && (
              <SelectedFilter
                name="status"
                placeholder="Selecionar..."
                value={propertyStatus}
                required={false}
                options={availableStatuses}
                onChange={changePropertyStatus}
              />
            )}
            <Button
              typeColor="primary"
              onClick={() => handleRedirectToCreateNegotiation(propertyData)}
            >
              Nova Negociação
            </Button>
          </Buttons>

          <BorderContainer style={{ marginTop: 16 }}>
            {propertyPages[renderPage]}
          </BorderContainer>
        </>
      )}
    </Box>
  );
}
