import styled from 'styled-components';

export const Container = styled.button`
  position: fixed;
  top: 24px;
  left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0px;

  z-index: 10;
  &:focus {
    outline: none;
  }

  > svg {
    width: 32px;
    height: 32px;
    position: relative;
    display: ${({ open }) => (open ? 'block' : 'block')};
  }

  /* @media screen and (min-width: 600px) {
    display: none;
  } */
`;
