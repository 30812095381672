import React, { useRef } from 'react';

import {
  Box,
  LoadingScreen,
  Buttons,
  Button,
  BackButton,
} from 'components/Shared';
import { Titles } from 'components/Titles';
import { InnerList } from 'components/Tenancy/Components/InnerList';

import { ReadjustmentForm } from '../Components/ReadjustmentForm';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  GetReadjustmentDetails,
  ChangeReadjustmenteStatus,
  PutEditReadjustment,
} from '../Services';

export function EditReadjustment() {
  const formRef = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const { data, isLoading, refetch } = GetReadjustmentDetails({ id });

  async function updateStatus() {
    try {
      await ChangeReadjustmenteStatus(id);
      refetch();
    } catch (error) {
      console.log(error);
    }
  }

  async function ChangeReadjustment(body) {
    try {
      await PutEditReadjustment(body, id);
      toast.success('Reajuste atualizado com sucesso!');
      history.push('/dashboard/lista-de-reajuste');
    } catch (err) {
      toast.error('Erro ao editar reajuste!');
    }
  }

  return (
    <Box>
      <Titles
        title="Indice de Reajuste"
        subtitle="Criar novo indice de reajuste"
      />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <BackButton
            to="/dashboard/lista-de-reajuste"
            style={{ marginBottom: 16 }}
          />
          <InnerList
            type="readjustment"
            data={data}
            handleStatus={updateStatus}
          />
          <ReadjustmentForm
            type="edit"
            ChangeReadjustment={ChangeReadjustment}
            formRef={formRef}
            data={data}
          >
            <Buttons>
              <Button
                typeColor="primary"
                type="submit"
                style={{ marginLeft: 'auto' }}
              >
                Atualizar
              </Button>
            </Buttons>
          </ReadjustmentForm>
        </>
      )}
    </Box>
  );
}
