import React, { useState } from 'react';

import { CycleContainer } from './styles';
import { FaRecycle, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import FormatCurrency from 'util/FormatCurrency';
import { CycleTypeTranslate } from 'util/Translate/CyclesType';
import { Button, Buttons, ConfirmExecution } from 'components/Shared';
import useFetch from 'services/ReactQuery';
import { toast } from 'react-toastify';

export function CycleDetails({ cycle, setSelectedCycle }) {
  const {
    cycleType,
    cycleLength,
    cycleCurrent,
    amount,
    status,
    cycleDescription,
  } = cycle;
  const [showDetails, setShowDetails] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const { mutateAsync: deleteCycle } = useFetch.Delete();

  async function handleDeleteCycle() {
    try {
      await deleteCycle({
        url: `/cycle/${cycle.id}`,
        key: `/cycles/${cycle.rentId}/rental`,
      });
      setShowDelete(false);
      toast.success('Ciclo deletado com sucesso!');
    } catch (error) {
      console.log(error);
      toast.error('Erro ao deletar ciclo!');
    }
  }

  return (
    <CycleContainer className={status === 'active' ? 'status-active' : ''}>
      <div
        className="header-cycle"
        onClick={() => setShowDetails(!showDetails)}
      >
        <div className="title-cycle">
          <FaRecycle />
          <p>
            {CycleTypeTranslate(cycleType)}: {cycleCurrent} de {cycleLength}{' '}
            Ciclo(s) de {FormatCurrency(amount / 100)} -{' '}
            {status === 'active' ? 'Ativo' : 'Inativo'}
          </p>
          <span className="chevron">
            {showDetails ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </div>
      </div>
      <div
        className={`details-cycle ${
          showDetails ? 'show-details' : 'hide-details'
        }`}
      >
        <p>
          <strong>Detalhes do Ciclo:</strong>
        </p>
        <p>{cycleDescription}</p>
        <Buttons style={{ justifyContent: 'start' }}>
          <Button typeColor="secondary" onClick={() => setSelectedCycle(cycle)}>
            Editar
          </Button>
          <Button typeColor="cancel" onClick={() => setShowDelete(!showDelete)}>
            Excluir
          </Button>
        </Buttons>
        <ConfirmExecution
          setOpen={setShowDelete}
          open={showDelete}
          fn={handleDeleteCycle}
          validationkey={`Cancelar ciclo ${cycle.id}`}
        />
      </div>
    </CycleContainer>
  );
}
