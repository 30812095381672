import React from 'react';

import { Container } from './styles';
import littleSpace from 'assets/EmptyList/littleSpace.svg';

export function EmptyList() {
  return (
    <Container>
      <img src={littleSpace} alt="littleSpace" />
      <h2>Nenhum registro encontrado</h2>
    </Container>
  );
}
