import React from 'react';

import Route from '../../../routes/Route';
import { SuportOwner } from 'components/incorporadora/suporte';
import { UnitDetails } from 'components/incorporadora';

export function GuestRoutes() {
  return (
    <>
      <Route path="/dashboard/suporte" component={SuportOwner} isPrivate />
      <Route path="/dashboard/informacoes" component={UnitDetails} isPrivate />
    </>
  );
}
