export const StatusTranslate = props =>
  ({
    pending: 'Pendente',
    accepted: 'Aceito',
    rejected: 'Rejeitado',
    canceled: 'Cancelado',
    running: 'Andamento',
    finished: 'Finalizado',
    analyzing: 'Análise',
    waiting_payment: 'Aguardando pagamento',
    approved: 'Aprovado',
    denied: 'Negado',
  }[props] || 'Error');
