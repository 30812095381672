import React, { useState } from 'react';
import { Buttons, Button, LoadingScreen } from 'components/Shared';
import { TransactionList } from '../../components/Transaction/TransactionList';
import { TransactionForm } from '../../components/Transaction/Form';
import useFetch from 'services/ReactQuery';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export function TransactionsTemplate() {
  const { id } = useParams();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const {
    mutateAsync: createTransaction,
    isLoading: createLoading,
  } = useFetch.Post();

  async function handleCreateTransaction(data) {
    data = {
      rentId: id,
      ...data,
    };

    try {
      await createTransaction({
        url: '/transaction',
        body: data,
        key: `/transactions/${id}`,
      });
      setSelectedTransaction(null);
      toast.success('Transação criada com sucesso!');
    } catch (err) {
      toast.error('Erro ao criar transação!');
    }
  }

  const pageRender = props =>
    ({
      null: <TransactionList setSelectedTransaction={setSelectedTransaction} />,
      create: (
        <TransactionForm sendData={handleCreateTransaction}>
          <Buttons>
            <Button
              typeColor="secondary"
              type="button"
              onClick={() => setSelectedTransaction(null)}
            >
              Cancelar
            </Button>
            <Button typeColor="primary" type="submit">
              Criar
            </Button>
          </Buttons>
        </TransactionForm>
      ),
    }[props] || 'Ocorreu um erro');

  return (
    <>
      <div className="titleMixed" style={{ marginBottom: 16 }}>
        <h1>Lista de Transações:</h1>
        <Buttons style={{ justifyContent: 'end' }}>
          <Button
            typeColor="primary"
            onClick={() => setSelectedTransaction('create')}
          >
            Criar Transação
          </Button>
        </Buttons>
      </div>
      {createLoading ? <LoadingScreen /> : pageRender(selectedTransaction)}
    </>
  );
}
