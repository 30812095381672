import React from 'react';
import { useSelector } from 'react-redux';
import useFetch from 'services/ReactQuery';
import { ErrorScreen, LoadingScreen } from 'components/Shared';

export function InformationData() {
  const profile = useSelector(state => state.user.profile);
  const { data, isLoading, isError } = useFetch.Get({
    url: `/properties/${profile.id}`,
  });

  return {
    data: data,
    isLoading:
      (isLoading && <LoadingScreen />) || (isError && <ErrorScreen />) || null,
  };
}
