import jarvis from 'util/jarvis';
import { useQuery } from 'react-query';

export function GetContract(contractID) {
  const { data, isLoading, refetch } = useQuery(
    ['contract', contractID],
    async () => {
      const { data } = await jarvis.get(`/contract/${contractID}`);
      return data;
    }
  );
  return { data, isLoading, refetch };
}
