import styled from 'styled-components';

export const ContainerNegotiation = styled.div`
  position: relative;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border: ${props => props.theme.border};
  border-radius: 8px;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 8px;
    background: #ffde67;
    border-radius: 8px 0 0 8px;
  }
  &:hover {
    border: 1px solid #055eff;
  }

  > span {
    font-size: 14px;
  }
`;
