import styled from 'styled-components';

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
`;

export const BorderContainer = styled.div`
  border: ${props => props.theme.border};
  border-radius: 8px;
`;
