export const AdminFilters = [
  {
    label: 'Todos',
    value: null,
  },
  {
    label: 'Proprietários',
    value: 'owner',
  },
  {
    label: 'Inquilinos',
    value: 'tenant',
  },
  {
    label: 'Corretores',
    value: 'broker',
  },
];

export const BrokerFilters = [
  {
    label: 'Todos',
    value: null,
  },
  {
    label: 'Proprietários',
    value: 'owner',
  },
  {
    label: 'Inquilinos',
    value: 'tenant',
  },
];
