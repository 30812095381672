import React from 'react';

import { Box } from 'components/Shared';
import { Content } from './styles';
import ImageGallery from './gallery';
import { Titles } from 'components/Titles';

export function PhotosOwner() {
  return (
    <Box>
      <Titles title="Fotos" subtitle="Fotos da obra" />
      <Content>
        <h1>Fotos da Obra</h1>
        <ImageGallery />
      </Content>
    </Box>
  );
}
