import React, { useEffect, useState } from 'react';

import { ContainerList, IconButton, Buttons } from './styles';
import { Button } from 'components/Shared';
import { FaTrash, FaAddressCard } from 'react-icons/fa';
import { MdLocationPin, MdPersonAdd } from 'react-icons/md';

export function SubAgentsList({
  agentPrincipal,
  dataAgent,
  setAgentInformation,
  setFormPage,
  setDeleteAgent,
}) {
  const [agents, setAgents] = useState();

  useEffect(() => {
    if (dataAgent) {
      setAgents(dataAgent);
    }
  }, [dataAgent]);

  function handleAgentType(type) {
    switch (type) {
      case 'guarantor':
        return 'Fiador';
      case 'income-co-responsible':
        return 'Co-responsável';
      case 'realtor-property':
        return 'Agente Legal';
      case 'spouse':
        return 'Conjugue';
      case 'procurator':
        return 'Procurador';
      default:
        return 'Outro';
    }
  }

  return (
    <ContainerList>
      <div style={{ marginBottom: 16 }}>
        <h2>Todos os responsaveis: de {agentPrincipal.name}</h2>
      </div>
      <ul>
        {agents &&
          agents.map((agent, index) => {
            return (
              <li key={index}>
                <div
                  style={{ marginRight: 'auto' }}
                  onClick={() => {
                    setFormPage('essencial');
                    setAgentInformation(agent);
                  }}
                >
                  <p>
                    <strong>
                      {agent?.name} {agent?.lastName?.charAt(0)}
                      {'.'}
                    </strong>
                  </p>
                  <p>{handleAgentType(agent?.type)}</p>
                </div>
                <Buttons>
                  <IconButton
                    className={agent?.documents ? 'success' : ''}
                    onClick={() => {
                      setFormPage('documents');
                      setAgentInformation(agent);
                    }}
                  >
                    <FaAddressCard />
                  </IconButton>
                  <IconButton
                    className={agent?.address ? 'success' : ''}
                    onClick={() => {
                      setFormPage('address');
                      setAgentInformation(agent);
                    }}
                  >
                    <MdLocationPin />
                  </IconButton>
                  {agent?.type === 'guarantor' && (
                    <IconButton
                      className={agent?.spouse ? 'success' : ''}
                      onClick={() => {
                        setFormPage('spouse');
                        setAgentInformation(agent);
                      }}
                    >
                      <MdPersonAdd />
                    </IconButton>
                  )}
                </Buttons>
                <span style={{ marginLeft: 16 }}>
                  <button
                    onClick={() => {
                      setDeleteAgent(true);
                      setAgentInformation(agent);
                    }}
                  >
                    <FaTrash />
                  </button>
                </span>
              </li>
            );
          })}

        <li>
          <div>
            <Button
              typeColor="secondary"
              onClick={() => {
                setAgentInformation();
                setFormPage('essencial');
              }}
            >
              Adicionar Responsável
            </Button>
          </div>
        </li>
      </ul>
    </ContainerList>
  );
}
