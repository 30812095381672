import React, { useRef } from 'react';

import { Container, Buttons, Button, LinkButton } from 'components/Shared';

import { OwnerForm } from '../../Components/Forms/OwnerForm';

export function Proprietary({
  setCurrentProgress,
  currentProgress,
  setOwnerData,
  ownerData,
  role,
  id,
}) {
  const formRef = useRef(null);

  function sendData(data) {
    setOwnerData(data);
    setCurrentProgress(currentProgress + 1);
  }

  return (
    <Container>
      <OwnerForm
        formRef={formRef}
        sendData={sendData}
        ownerData={ownerData}
        role={role}
        id={id}
      >
        <Buttons>
          <LinkButton typeColor="disable" type="button" to="/dashboard">
            Cancelar
          </LinkButton>
          <Button type="submit" typeColor="primary">
            Confirmar e Avançar
          </Button>
        </Buttons>
      </OwnerForm>
    </Container>
  );
}
