import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';

const AsyncInputSelect = ({ ...rest }) => {
  const selectRef = useRef(null);

  return (
    <>
      <AsyncPaginate
        selectRef={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
    </>
  );
};

AsyncInputSelect.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AsyncInputSelect;
