import styled from 'styled-components';
import Select from '../../../Input/InputSelect';

export const Input = styled(Select)`
  width: 100%;
  max-height: 54px;

  .react-select-container {
    background: ${props => props.theme.body};
  }
  .react-select__control {
    background: ${props => props.theme.body};
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .react-select__menu {
    background: ${props => props.theme.body};
  }

  .react-select__menu-list {
    background: ${props => props.theme.body};
    border: ${props => props.theme.border};
    border-radius: 8px;
  }
  .react-select__single-value {
    color: ${props => props.theme.colors.primary};
  }

  .react-select__option {
    background: ${props => props.theme.body};
    color: ${props => props.theme.colors.primary};
  }

  .react-select__option--is-focused {
    background: ${props => props.theme.backgrounds.secondary};
  }

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;
