import React, { useState, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { AddresForm, Buttons, Button } from 'components/Shared';
import { AddressSummary } from '../../Summaries/AddressSummary';
import { ContentVerificationData } from 'components/Shared';
import {
  SendAddressForm,
  SendEditAddressForm,
} from 'components/User/CreateUser/Address/services';
import { toast } from 'react-toastify';

export function Address({ address, setAddress }) {
  const formRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const { id: userID } = useParams();

  async function sendData(data) {
    if (edit) {
      try {
        const addressResponse = await SendEditAddressForm(
          data,
          userID,
          address.id
        );
        setAddress(addressResponse.address);
        setEdit(false);
        toast.success('Endereço atualizado com sucesso!');
      } catch (err) {
        toast.error('Erro ao atualizar endereço!');
      }
    } else {
      try {
        const addressResponse = await SendAddressForm(data, userID);
        setAddress(addressResponse.address);
        setEdit(false);
        toast.success('Endereço adicionado com sucesso!');
      } catch (err) {
        toast.error('Erro ao adicionar endereço!');
      }
    }
  }

  return (
    <>
      {!edit && address ? (
        <AddressSummary address={address} title="Endereço">
          <Buttons>
            <Button typeColor="primary" onClick={() => setEdit(true)}>
              Editar
            </Button>
          </Buttons>
        </AddressSummary>
      ) : (
        <ContentVerificationData>
          <h1>Adicione o Endereço</h1>
          <AddresForm
            dataAddress={{ address }}
            formRef={formRef}
            sendData={sendData}
          >
            <Buttons>
              {edit && (
                <Button
                  typeColor="disable"
                  type="button"
                  onClick={() => setEdit(false)}
                >
                  Cancelar
                </Button>
              )}
              <Button
                style={{ marginLeft: 'auto' }}
                typeColor="primary"
                type="submit"
              >
                Enviar
              </Button>
            </Buttons>
          </AddresForm>
        </ContentVerificationData>
      )}
    </>
  );
}
