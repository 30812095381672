import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Buttons,
  Button,
  SpaceLine,
  ContentVerificationData,
} from 'components/Shared';
import { NegotiationForm } from '../Components/Forms/NegotiationForm';
import { NegotiationDataProperty } from '../Components/Forms/NegotiationDataProperty';
import { NegotiationDataTenant } from '../Components/Forms/NegotiationDataTenant';
import { NegotiationSummary } from '../Components/Forms/NegotiationSummary';
import { sendNegotiation } from './Services';

import { toast } from 'react-toastify';

export function VerificationData({
  negotiationData,
  selectedTenant,
  selectedProperty,
  setNegotiationData,
  setSelectedProperty,
  setSelectedTenant,
}) {
  const formRef = useRef();
  const history = useHistory();
  const [handleEdit, setHandleEdit] = useState(false);

  function handleGetFormData(data) {
    setNegotiationData(data);
    setHandleEdit(false);
  }

  const handleSendData = useCallback(async () => {
    const data = {
      ...negotiationData.negotiation,
      propertyId: selectedProperty.id,
      tenantId: selectedTenant.id,
      ownerId: selectedProperty.owner.id,
      brokerId: selectedProperty.broker.id,
    };

    try {
      const response = await sendNegotiation(data);
      toast.success('Negociação enviada com sucesso!');
      history.push(`/dashboard/negociacao-detalhes/${response.id}`);
    } catch (err) {
      if (err.response.data.name === 'InvalidPropertyError') {
        toast.error('Imóvel invalido para negociação');
      } else {
        toast.error('Erro ao enviar negociação!');
      }
    }
  }, [selectedTenant, selectedProperty, negotiationData, history]);

  return (
    <Container style={{ flexDirection: 'column' }}>
      {handleEdit ? (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <NegotiationDataProperty
              setSelectedProperty={setSelectedProperty}
              selectedProperty={selectedProperty}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <NegotiationDataTenant
              setSelectedTenant={setSelectedTenant}
              selectedTenant={selectedTenant}
            />
          </div>
          <SpaceLine style={{ marginBottom: 24 }} />
          <NegotiationForm
            formRef={formRef}
            getDataForm={handleGetFormData}
            negotiationData={negotiationData}
          >
            <Buttons>
              <Button
                typeColor="disable"
                type="button"
                onClick={() => {
                  setHandleEdit(false);
                }}
              >
                Cancelar
              </Button>
              <Button typeColor="primary" type="submit">
                Salvar
              </Button>
            </Buttons>
          </NegotiationForm>
        </>
      ) : (
        <ContentVerificationData>
          <NegotiationSummary
            negotiationData={negotiationData}
            selectedProperty={selectedProperty}
            selectedTenant={selectedTenant}
          />
          <Buttons>
            <Button
              onClick={() => {
                setHandleEdit(true);
              }}
              type="button"
              typeColor="secondary"
            >
              Editar
            </Button>
            <Button
              onClick={() => {
                handleSendData();
              }}
              type="button"
              typeColor="primary"
            >
              Enviar
            </Button>
          </Buttons>
        </ContentVerificationData>
      )}
    </Container>
  );
}
