import React, { useRef, useState } from 'react';

import { Container, Buttons, Button } from 'components/Shared';
import { AgentListType } from '../../Components/Forms/AgentForm/Components/ListType';

import { EssencialAgentForm } from 'components/User/Components/Forms/AgentForm/Components/EssencialAgantForm';
import { AddressAgentForm } from 'components/User/Components/Forms/AgentForm/Components/AddressAgentForm';
import { DocumentsAgentForm } from 'components/User/Components/Forms/AgentForm/Components/DocumentsAgentForm';

import { FinalizationModal } from '../../Components/Forms/Modal/FinalizationModal';
import {
  SendFormEssencial,
  SendFormAddress,
  sendAgentDocument,
  SendAgentFilesToDocuments,
} from './Services';
import { toast } from 'react-toastify';
import { SpouseAgentForm } from 'components/User/Components/Forms/AgentForm/Components/SpouseAgentForm';

export function Agent({ setDataAgent, dataAgent, dataEssencial, id }) {
  const formRef = useRef(null);
  const [formPage, setFormPage] = useState('essencial');
  const [showModal, setShowModal] = useState(false);
  const [agentInformation, setAgentInformation] = useState();
  const [subAgentInformation, setSubAgentInformation] = useState([]);

  function resetForm() {
    setAgentInformation(null);
    formRef.current.reset();
  }

  function handleNewData(data) {
    const newArray = dataAgent.map(item => {
      if (item === agentInformation) {
        const newData = {
          ...agentInformation,
          ...data,
        };
        return newData;
      }
      return item;
    });
    setDataAgent(newArray);
  }

  async function handleSendAddress(data) {
    try {
      await SendFormAddress(data, agentInformation.id);
      toast.success('Endereço atualizado com sucesso!');
      handleNewData(data);
      setFormPage(null);
      formRef.current.reset();
    } catch (err) {
      toast.error('Erro ao atualizar endereço!');
    }
  }

  async function handleSendDocuments(data) {
    try {
      const responseDocuments = await sendAgentDocument(
        data,
        agentInformation?.id
      );
      await SendAgentFilesToDocuments(data, responseDocuments.documents);
      resetForm();
      handleNewData(responseDocuments);
      setFormPage(null);
      toast.success('Documentos do responsável adicionado com sucesso!');
    } catch (error) {
      toast.error('Erro ao adicionar documentos do responsável!');
    }
  }

  async function handleSendEssencial(data) {
    await SendFormEssencial(data, id)
      .then(response => {
        data = {
          ...response,
        };
        setDataAgent([...dataAgent, data]);
        formRef.current.reset();
        setFormPage(null);
        toast.success('Responsável adicionado com sucesso!');
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao adicionar Responsável!');
      });
  }

  const handleFormPage = data => {
    return {
      essencial: (
        <EssencialAgentForm
          userRole={dataEssencial?.role}
          getEssencialAgent={agentInformation}
          sendDataForm={handleSendEssencial}
          formRef={formRef}
        >
          {data}
        </EssencialAgentForm>
      ),
      address: (
        <AddressAgentForm
          getAddressAgent={agentInformation}
          sendDataForm={handleSendAddress}
          formRef={formRef}
        >
          {data}
        </AddressAgentForm>
      ),
      documents: (
        <DocumentsAgentForm
          getDocuments={agentInformation}
          sendDataForm={handleSendDocuments}
          formRef={formRef}
        >
          {data}
        </DocumentsAgentForm>
      ),
      spouse: (
        <SpouseAgentForm
          userRole={dataEssencial?.role}
          agentInformation={agentInformation}
          userID={id}
          subAgentInformation={subAgentInformation}
          setSubAgentInformation={setSubAgentInformation}
        ></SpouseAgentForm>
      ),
    };
  };

  return (
    <>
      <Container>
        {!!dataAgent?.length && (
          <AgentListType
            dataAgent={dataAgent}
            setAgentInformation={setAgentInformation}
            setFormPage={setFormPage}
            setSubAgentInformation={setSubAgentInformation}
          />
        )}

        {
          handleFormPage(
            <>
              <Buttons>
                <Button
                  type="submit"
                  typeColor="secondary"
                  onClick={() => setShowModal(true)}
                >
                  Finalizar Cadastro
                </Button>
                <Button typeColor="primary" type="submit">
                  Enviar Dados
                </Button>
              </Buttons>
            </>
          )[formPage]
        }
        {!formPage && (
          <>
            <Button
              type="button"
              typeColor="secondary"
              onClick={() => setShowModal(true)}
            >
              Finalizar Cadastro
            </Button>
          </>
        )}
        <FinalizationModal
          open={showModal}
          setOpen={setShowModal}
          dataEssencial={dataEssencial}
          userId={id}
        />
      </Container>
    </>
  );
}
