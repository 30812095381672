import jarvis from 'util/jarvis';
import { useQuery } from 'react-query';

export function PropertyAdminQuery(currentParams) {
  const isNotStatus = ['analyzing', 'pending', 'rejected'];
  const { data, isLoading } = useQuery(
    [`/properties`, currentParams, isNotStatus],
    async () => {
      return jarvis
        .get(
          `/properties?filter[isNotStatus][0]=rented&filter[isNotStatus]=canceled`,
          {
            params: {
              search: currentParams.search,
            },
          }
        )
        .then(res => {
          return res.data;
        });
    },
    {
      enabled: currentParams.search !== null,
      refetchOnWindowFocus: false,
    }
  );
  return {
    propertyData: data,
    propertyIsLoading: isLoading,
  };
}

export function PropertyBrokerQuery(currentParams, brokerId) {
  const { data, isLoading } = useQuery(
    ['/properties', currentParams, brokerId],
    async () => {
      return jarvis
        .get(`/properties?filter[brokerId]=${brokerId}`, {
          params: {
            search: currentParams.search,
          },
        })
        .then(res => {
          return res.data;
        });
    },
    {
      enabled: currentParams.search !== null,
      refetchOnWindowFocus: false,
    }
  );

  return {
    propertyData: data,
    propertyIsLoading: isLoading,
  };
}
