import styled from 'styled-components';
import SelectInput from './InputSelect';
import AsyncInputSelect from './AsyncInputSelectPaginate';

export const AsyncFilter = styled(AsyncInputSelect)`
  display: flex;
  width: 50vh;
  max-width: 280px;
  z-index: 1;
  &::placeholder {
    color: #aaaaaa;
  }
  .field-select {
    flex: 1;
  }

  .react-select-container {
    background: ${props => props.theme.container.background};
  }
  .react-select__control {
    width: 100%;
    border-radius: 8px;
    background: ${props => props.theme.container.background};
    border: ${props => props.theme.border};
  }

  .react-select__single-value {
    color: ${props => props.theme.colors.primary};
  }

  .react-select__menu {
    background: ${props => props.theme.container.background};
  }

  .react-select__menu-list {
    background: ${props => props.theme.container.background};
    border: ${props => props.theme.border};
    border-radius: 8px;
  }

  .react-select__option {
    background: ${props => props.theme.container.background};
  }

  .react-select__option--is-focused {
    background: ${props => props.theme.backgrounds.secondary};
  }

  .react-select__input {
    color: ${props => props.theme.colors.primary};
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;

export const Filter = styled(SelectInput)`
  display: flex;
  width: 50vh;
  max-width: 280px;

  z-index: 1;
  &::placeholder {
    color: #aaaaaa;
  }
  .field-select {
    flex: 1;
  }

  .react-select-container {
    background: ${props => props.theme.container.background};
  }
  .react-select__control {
    width: 100%;
    border-radius: 8px;
    background: ${props => props.theme.container.background};
    border: ${props => props.theme.border};
  }

  .react-select__single-value {
    color: ${props => props.theme.colors.primary};
  }

  .react-select__menu {
    background: ${props => props.theme.container.background};
  }

  .react-select__menu-list {
    background: ${props => props.theme.container.background};
    border: ${props => props.theme.border};
    border-radius: 8px;
  }

  .react-select__option {
    background: ${props => props.theme.container.background};
  }

  .react-select__option--is-focused {
    background: ${props => props.theme.backgrounds.secondary};
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;
