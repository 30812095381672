import * as Yup from 'yup';

export const EssencialAgentValidation = Yup.object().shape({
  type: Yup.string().required('Selecione um tipo de perfil'),
  name: Yup.string().required('Digite o nome'),
  lastName: Yup.string().required('Digite o sobrenome'),
  email: Yup.string()
    .email('Digite um e-mail válido')
    .required('Digite um e-mail'),
  profile: Yup.object().shape({
    birthdayDate: Yup.string().required('Coloque a data de nascimento'),
    career: Yup.string().required('Coloque a profissão'),
    nationality: Yup.string().required('Digite a nacionalidade'),
  }),
  phones: Yup.array()
    .min(1, 'Insira pelo menos um numero de telefone')
    .of(
      Yup.object().shape({
        phone: Yup.string().test(
          'phone',
          'Digite um numero de telefone válido',
          function() {
            return (
              this.path === 'phones[1].phone' || this.parent.phone.length >= 10
            );
          }
        ),
      })
    ),
});
