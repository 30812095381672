import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { TenantSelect } from './TenantSelect';
import { PropertySelect } from './PropertySelect';
import { NegotiationData } from './NegotiationData';
import { VerificationData } from './VerificationData';

import { ProgressionBar } from 'components/Shared';
import { Container } from './Styles/styles';

export function CreateNegotiation() {
  const [currentProgress, setCurrentProgress] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedTenant, setSelectedTenant] = useState('');
  const [negotiationData, setNegotiationData] = useState('');
  const location = useLocation();

  const propertyLocation = location?.state?.property;
  const tenantLocation = location?.state?.tenant;

  useEffect(() => {
    if (propertyLocation && !tenantLocation) {
      setSelectedProperty(propertyLocation);
      setCurrentProgress(1);
    }
    if (propertyLocation && tenantLocation) {
      setSelectedTenant(tenantLocation);
      setSelectedProperty(propertyLocation);
      setCurrentProgress(2);
    }
  }, [propertyLocation, tenantLocation]);

  useEffect(() => {
    if (currentProgress > 0) {
      window.scrollTo(0, 0);
    }
  }, [currentProgress]);

  const Progress = {
    0: (
      <PropertySelect
        setSelectedProperty={setSelectedProperty}
        selectedProperty={selectedProperty}
        setCurrentProgress={setCurrentProgress}
      />
    ),
    1: (
      <TenantSelect
        setSelectedTenant={setSelectedTenant}
        selectedTenant={selectedTenant}
        selectedProperty={selectedProperty}
        setCurrentProgress={setCurrentProgress}
      />
    ),
    2: (
      <NegotiationData
        setNegotiationData={setNegotiationData}
        negotiationData={negotiationData}
        selectedTenant={selectedTenant}
        selectedProperty={selectedProperty}
        setSelectedProperty={setSelectedProperty}
        setSelectedTenant={setSelectedTenant}
        setCurrentProgress={setCurrentProgress}
      />
    ),
    3: (
      <VerificationData
        negotiationData={negotiationData}
        setNegotiationData={setNegotiationData}
        selectedTenant={selectedTenant}
        setSelectedProperty={setSelectedProperty}
        setSelectedTenant={setSelectedTenant}
        selectedProperty={selectedProperty}
      />
    ),
  };

  return (
    <Container>
      <ProgressionBar
        currentProgress={currentProgress}
        setCurrentProgress={setCurrentProgress}
        progress={[
          {
            title: 'Selecione um imóvel',
            subtitle: 'Selecione um imóvel para negociação',
            category: 'Imóvel Negociado',
            filled: selectedProperty,
          },
          {
            title: 'Selecione um inquilino',
            subtitle: 'Selecione um inquilino para negociação',
            category: 'Selecione um Inquilino',
            filled: selectedTenant,
          },
          {
            title: 'Dados da negociação',
            subtitle: 'Preencha as informações da negociação',
            category: 'Dados da Negociação',
            filled: negotiationData,
          },
          {
            title: 'Verificação',
            subtitle: 'Verifique os dados da negociação',
            category: 'Verificação',
            filled: negotiationData,
          },
        ]}
      ></ProgressionBar>
      {Progress[currentProgress]}
    </Container>
  );
}
