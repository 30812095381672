import React, { useState } from 'react';

import { Buttons, Button } from 'components/Shared';
import { SignatariusList } from './Components/SignatariusList';
import { SignatariusContainer } from './styles';
import { SignatariusSelect } from '../SignatariusSelect';
import { SelectSignatariusList } from '../SelectSignatariusList';

export function Signatarius({
  negotiationData,
  typeContract,
  selectedCoSignatarius,
  setSelectedCoSignatarius,
  children,
}) {
  const [openSignatariusSelect, setOpenSignatariusSelect] = useState(false);

  const signatarius = {
    tenant: negotiationData.tenant,
    owner: negotiationData.owner,
  };

  return (
    <SignatariusContainer>
      {typeContract === 'location' ? (
        <>
          <Buttons>
            <Button
              typeColor="secondary"
              onClick={() => setOpenSignatariusSelect(true)}
            >
              Adicionar signatário
            </Button>
          </Buttons>
          <h1>Locador e co-responsável</h1>
          <SignatariusList
            signatarius={signatarius.tenant}
            selectedCoSignatarius={selectedCoSignatarius.tenant}
            type="Locador"
          />
          <h1>Locatario e co-responsável</h1>
          <SignatariusList
            signatarius={signatarius.owner}
            selectedCoSignatarius={selectedCoSignatarius.owner}
            type="Locatario"
          />
          <SignatariusSelect
            open={openSignatariusSelect}
            setOpen={setOpenSignatariusSelect}
            getData={setSelectedCoSignatarius}
          >
            <h2>Co-signatarios do inquilino</h2>
            <SelectSignatariusList
              Signatarius={negotiationData.tenant}
              name={'tenant'}
            />
            <h2>Co-signatarios do proprietario</h2>
            <SelectSignatariusList
              Signatarius={negotiationData.owner}
              name={'owner'}
            />
            <Buttons>
              <Button typeColor="primary" type="submit">
                Selecionar
              </Button>
            </Buttons>
          </SignatariusSelect>
        </>
      ) : (
        <>
          <Buttons>
            <Button
              typeColor="secondary"
              onClick={() => setOpenSignatariusSelect(true)}
            >
              Adicionar signatário
            </Button>
          </Buttons>
          <h1>Locatario e co-responsável</h1>
          <SignatariusList
            signatarius={signatarius.owner}
            selectedCoSignatarius={selectedCoSignatarius.owner}
            type="Locatario"
          />
          <SignatariusSelect
            open={openSignatariusSelect}
            setOpen={setOpenSignatariusSelect}
            getData={setSelectedCoSignatarius}
          >
            <h2>Co-signatarios do proprietario</h2>
            <SelectSignatariusList
              Signatarius={negotiationData.owner}
              name={'owner'}
            />
            <Buttons>
              <Button typeColor="primary" type="submit">
                Selecionar
              </Button>
            </Buttons>
          </SignatariusSelect>
        </>
      )}
      {children}
    </SignatariusContainer>
  );
}
