import styled from 'styled-components'

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #001438;
  border-radius: 4px;
  border: 0;
  padding: 12px;
  width: 100%;
  color: #fff;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  > svg {
    margin-right: 12px;
  }

  &:hover {
    opacity: 0.9;
  }
`
