import React from 'react';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

import { Button } from './styles';

export function RecentlyButton({ order, ...rest }) {
  const handleOrder = order.toUpperCase();

  return (
    <Button {...rest} type="button">
      {handleOrder === 'DESC' ? 'Mais recentes' : 'Mais antigos'}
      {handleOrder === 'DESC' ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
    </Button>
  );
}
