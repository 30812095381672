import React from 'react';

import { ContainerDownload } from './styles';

export function DocIframe({ src, children }) {
  const url = src?.url;
  return (
    <ContainerDownload>
      <div>
        <a href={url} download>
          Download do contrato
        </a>
      </div>
      {children}
    </ContainerDownload>
  );
}
