import React, { useState } from 'react';
import {
  Button,
  Buttons,
  Content,
  DropzoneFile,
  ModalScreen,
} from 'components/Shared';
import useFetch from 'services/ReactQuery';
import { ContainerCities, ReelsContainer, CityCard, ImageForm } from './styles';
import { FaHeart } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useRef } from 'react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

export function CitiesCard({ citiesByState }) {
  const { cities } = citiesByState;
  const [modal, setModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState({});
  const [coverImage, setCoverImage] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);
  const formCityImages = useRef(null);
  const { mutateAsync: imageCoverSend } = useFetch.Post();
  const { mutateAsync: imageAvatarSend } = useFetch.Post();
  const { mutateAsync: favoriteCity } = useFetch.Post();
  const { mutateAsync: desativateSearchable } = useFetch.Post();

  const handleSubmit = useCallback(
    async data => {
      try {
        if (data.cover) {
          await imageCoverSend({
            url: `city/${selectedCity.id}/cover/file`,
            key: '/states',
          });
        }

        if (data.avatar) {
          await imageAvatarSend({
            url: `city/${selectedCity.id}/profile/file`,
            key: '/states',
          });
        }
        setModal(false);
        toast.success('Imagens enviadas com sucesso');
      } catch {
        toast.error('Erro ao enviar imagens');
      }
    },
    [formCityImages]
  );

  async function handleFavorite(city) {
    try {
      await favoriteCity({
        url: `/cities/favorite/${city.id}`,
        key: '/states',
      });
      toast.success('Cidade favoritada');
    } catch {
      toast.error('Erro ao favoritar');
    }
  }

  async function handleDesativateSearchable(city) {
    try {
      await desativateSearchable({
        url: `/cities/searchable/${city.id}`,
        key: '/states',
      });
      toast.success('Cidade desativada');
    } catch {
      toast.error('Erro ao desativar');
    }
  }

  return (
    <ContainerCities>
      <ReelsContainer>
        {cities?.length > 0 &&
          cities.map(city => (
            <CityCard key={city.id} urlImageCover={city.urlImageCover}>
              <div>
                <div className="svg_heart" onClick={() => handleFavorite(city)}>
                  <FaHeart
                    className={`${city.favorite ? 'active' : 'inative'}`}
                  />
                </div>
                <div
                  className="close_searchable"
                  onClick={() => handleDesativateSearchable(city)}
                >
                  <MdClose />
                </div>
                <img
                  className="image_avatar"
                  src={city.urlImageAvatar}
                  onClick={() => {
                    setModal(!modal);
                    setSelectedCity(city);
                  }}
                />
                <p className="city_name">{city.name}</p>
              </div>
            </CityCard>
          ))}
      </ReelsContainer>
      <ModalScreen open={modal && selectedCity} setOpen={setModal}>
        <div>
          <h1>Mudar Imagens de {selectedCity.name}</h1>
          <Content ref={formCityImages} onSubmit={handleSubmit}>
            <div>
              <span>
                <p>Fundo da foto:</p>
              </span>
            </div>
            <div>
              <span>
                <DropzoneFile name="cover" setImageList={setCoverImage} />
              </span>
            </div>
            {coverImage && (
              <div style={{ marginTop: 8 }}>
                <span>
                  <ImageForm src={coverImage} />
                </span>
              </div>
            )}
            <div>
              <span>
                <p>Fundo do Avatar:</p>
              </span>
            </div>
            <div>
              <span>
                <DropzoneFile name="avatar" setImageList={setAvatarImage} />
              </span>
            </div>
            {avatarImage && (
              <div style={{ marginTop: 8 }}>
                <span>
                  <ImageForm src={avatarImage} />
                </span>
              </div>
            )}
            <Buttons>
              <Button
                typeColor="secondary"
                type="button"
                onClick={() => setModal(!modal)}
              >
                Cancelar
              </Button>
              <Button typeColor="primary" type="submit">
                Salvar
              </Button>
            </Buttons>
          </Content>
        </div>
      </ModalScreen>
    </ContainerCities>
  );
}
