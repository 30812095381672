import React, { useEffect, useState } from 'react';
import { ModalOverlay, ModalContent } from './styles';

const ImageViewer = ({ image, onClose, images }) => {
  const [currentIndex, setCurrentIndex] = useState(images.indexOf(image));
  const [orientation, setOrientation] = useState('default');
  const [imageUrl, setImageUrl] = useState('');

  const nextImage = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevImage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  function overlayClick(e) {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }

  function verificarOrientacaoDaImagem(url) {
    const img = new Image();
    img.src = url;

    img.loading = 'lazy';

    img.onload = function() {
      const largura = img.width;
      const altura = img.height;

      if (largura > altura) {
        setOrientation('landscape');
      } else if (altura > largura) {
        setOrientation('portrait');
      } else {
        setOrientation('square');
      }
    };
    setImageUrl(img.src);
  }

  useEffect(() => {
    verificarOrientacaoDaImagem(images[currentIndex]);
  }, [currentIndex]);

  return (
    <ModalOverlay onClick={e => overlayClick(e)}>
      <ModalContent>
        <div>
          {orientation === 'landscape' && (
            <img className="landscape" src={imageUrl} />
          )}
          {orientation === 'portrait' && (
            <img className="portrait" src={imageUrl} />
          )}
          {orientation === 'square' && (
            <img className="square" src={imageUrl} />
          )}
          <span>
            <button onClick={prevImage}>Anterior</button>
            <button onClick={nextImage}>Próxima</button>
          </span>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ImageViewer;
