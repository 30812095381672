import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { validadeProperty } from './Validation';
import { toast } from 'react-toastify';

import { Button, Buttons, LoadingScreen } from 'components/Shared';
import { Container } from 'components/Shared';
import { ListContainer, Content, Search } from '../Styles/styles';
import { InputOption, MenuContainer, SelectStyle } from './styles';
import { PropertyListSelect } from '../Components/Property/PropertyListSelect';
import { PropertySelected } from '../Components/Property/PropertySelected';
import {
  loadOptionsUserOwner,
  loadOptionsUserByBroker,
  AdminQuery,
  BrokerQuery,
} from './Services';

export function PropertySelect({
  selectedProperty,
  setSelectedProperty,
  setCurrentProgress,
}) {
  const formRef = useRef();
  const { profile } = useSelector(state => state.user);
  const [addressSearch, setAddressSearch] = useState(true);
  const [ownerId, setOwnerId] = useState('');
  const [currentParams, setCurrentParams] = useState({
    search: null,
  });

  const { data, isLoading } = profile.isAdmin
    ? AdminQuery(currentParams, ownerId, addressSearch)
    : BrokerQuery(currentParams, ownerId, addressSearch, profile.id);

  const debounce = AwesomeDebouncePromise(updateQuery, 500);
  function updateQuery(event) {
    setCurrentParams({
      ...currentParams,
      search: event,
    });
  }

  const handleSubmit = useCallback(async () => {
    const data = {
      property: selectedProperty.id,
    };
    try {
      await validadeProperty.validate(data, {
        abortEarly: false,
      });
      toast.success('Imóvel selecionado com sucesso!');
      setCurrentProgress(1);
    } catch (error) {
      toast.error(error.message);
    }
  }, [selectedProperty, setCurrentProgress]);

  return (
    <Container>
      <Content style={{ width: '100%' }} ref={formRef} onSubmit={handleSubmit}>
        <MenuContainer>
          <p>
            <strong>Pesquisar um imóvel por:</strong>
          </p>
          <InputOption>
            <input
              name="query"
              id="address"
              type="radio"
              onChange={() => {
                setAddressSearch(true);
                setSelectedProperty('');
              }}
              checked={addressSearch}
              value="address"
            />
            <label htmlFor="address">Endereço</label>
            <input
              name="query"
              id="owner"
              type="radio"
              value="owner"
              checked={!addressSearch}
              onChange={() => {
                setAddressSearch(false);
                setSelectedProperty('');
              }}
            />
            <label htmlFor="owner">Proprietário</label>
          </InputOption>
        </MenuContainer>
        <Search>
          {addressSearch ? (
            <input
              id="search"
              type="text"
              placeholder="Pesquisar Imóvel"
              onChange={e => {
                debounce(e.target.value);
              }}
              onKeyUp={() => {
                setSelectedProperty('');
              }}
            />
          ) : (
            <AsyncPaginate
              name="owner_search"
              loadOptions={
                profile.isAdmin ? loadOptionsUserOwner : loadOptionsUserByBroker
              }
              styles={SelectStyle}
              placeholder="Pesquisar Proprietário"
              additional={{
                page: 1,
              }}
              onChange={e => {
                setOwnerId(e);
              }}
              onKeyUp={() => {
                setSelectedProperty('');
              }}
            />
          )}
        </Search>
        {data && !selectedProperty && (
          <ListContainer>
            {isLoading ? (
              <LoadingScreen />
            ) : (
              <>
                {data?.data.map(property => (
                  <PropertyListSelect
                    key={property.id}
                    property={property}
                    setSelectedProperty={setSelectedProperty}
                    selectedProperty={selectedProperty}
                  />
                ))}
              </>
            )}
          </ListContainer>
        )}
        {selectedProperty && (
          <PropertySelected property={selectedProperty} isSelected={true} />
        )}
        <Buttons style={{ marginTop: 24 }}>
          <Button type="button" typeColor="disable">
            Cancelar
          </Button>
          <Button type="submit" typeColor="primary">
            Avançar
          </Button>
        </Buttons>
      </Content>
    </Container>
  );
}
