import { useQuery } from 'react-query';
import jarvis from 'util/jarvis';
import { toast } from 'react-toastify';

export function GetGuaranteeDetails({ id }) {
  const { data, isLoading, refetch } = useQuery(
    ['getGuaranteeDetails', id],
    async () =>
      jarvis.get(`/guarantee/${id}`).then(res => {
        return res.data;
      })
  );
  return {
    data: data,
    isLoading: isLoading,
    refetch: refetch,
  };
}

export async function PutEditGuarantee(Body, id) {
  const { data } = await jarvis.put(`/guarantee/${id}`, Body);
  return data;
}

export function GetGuarantee({ currentParams }) {
  const { data, isLoading, refetch } = useQuery(
    ['getGuarantee', currentParams],
    async () =>
      jarvis
        .get(`/guarantees`, {
          params: currentParams,
        })
        .then(res => {
          return res.data;
        }),
    { refetchOnWindowFocus: false }
  );
  return {
    data: data,
    isLoading: isLoading,
    refetch: refetch,
  };
}

export async function ChangeGuaranteeStatus(id) {
  return jarvis
    .put(`/guarantee/status/${id}`)
    .then(res => {
      toast.success('Garantia atualizada com sucesso!');
      return res.data;
    })
    .catch(err => {
      if (err.response.data?.name === 'InvalidActivatedGuaranteeError') {
        toast.error('Garantia está sem contratos!');
      }
    });
}

export async function SendGuaranteeForm(Body) {
  const { data } = await jarvis.post('/guarantee', Body);
  return data;
}
