export const brokerFilterList = [
  {
    value: '',
    label: 'Todos',
  },
  { value: 'new_request', label: 'Análise' },
  { value: 'approved', label: 'Anunciados' },
  { value: 'rented', label: 'Alugados' },
];

export const adminFilterList = [
  {
    value: '',
    label: 'Todos',
  },
  { value: 'new_request', label: 'Novos' },
  { value: 'dealing', label: 'Negociando' },
  { value: 'pending_signature', label: 'Aguard. ass. contrato' },
  { value: 'approved', label: 'Aprovados' },
  { value: 'rented', label: 'Alugados' },
  { value: 'canceled', label: 'Cancelados' },
];
