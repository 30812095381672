import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Titles } from 'components/Titles';
import {
  Box,
  NavigationContainer,
  LoadingScreen,
  Buttons,
  Button,
} from 'components/Shared';

import { UserData } from 'components/User/Components/Details/UserData';
import { ClientSummary } from 'components/User/UserDetails/Components/Summaries/ClientSummary';
import { LegalAgents } from './Components/Templates/LegalAgents';
import { Property } from './Components/Templates/Property';
import { BankAccount } from './Components/Templates/BankAccount';

import { UserDataDetails } from './Services';

import { Essencial } from './Components/Templates/Essencial';
import { Address } from './Components/Templates/Address';
import { Documents } from './Components/Templates/Documents';
import { brokerOptions, tenantOptions, ownerOptions } from './Options';
import { ChangePasswordModal } from './Components/ChangePasswordModal';
import { RoleUser } from 'util/Translate/RoleUser';

export function UserDetails() {
  const [page, setPage] = useState('essencial');
  const { id: userID } = useParams();
  const [essencial, setEssencial] = useState(false);
  const [profile, setProfile] = useState(false);
  const [brokerResponsible, setBrokerResponsible] = useState(false);
  const [role, setRole] = useState('');
  const [address, setAddress] = useState(false);
  const [documents, setDocuments] = useState(false);
  const [bank, setBank] = useState(false);
  const [agent, setAgent] = useState([]);
  const [passwordModal, setPasswordModal] = useState(false);
  const { data, isLoading } = UserDataDetails(userID);

  useMemo(() => {
    if (data) {
      const dataTrataed = {
        initial: data?.initials,
        brokerResponsible: data?.brokerResponsible,
        essencial: {
          name: data?.name,
          lastName: data?.lastName,
          email: data?.email,
          phones: data?.phones,
          role: data?.role,
          responsibleCustomerId:
            data?.role !== 'broker' ? data?.brokerResponsible?.id : null,
          responsibleCustomerName: `${data?.brokerResponsible?.name} ${data?.brokerResponsible?.lastName}`,
          responsibleCustomerRole:
            data?.role !== 'broker' ? data?.brokerResponsible?.role : null,
        },
        profile: data?.profile,
        address: data?.address,
        bankData: data?.bankData,
        documents: data?.documents,
        legalAgents: data.legalAgents ? data.legalAgents : [],
      };
      setEssencial(dataTrataed.essencial);
      setProfile(dataTrataed.profile);
      setAddress(dataTrataed.address);
      setDocuments(dataTrataed.documents);
      setBank(dataTrataed.bankData);
      setAgent(dataTrataed.legalAgents);
      setBrokerResponsible(dataTrataed.brokerResponsible);
      setRole(dataTrataed.essencial.role);
    }
  }, [data]);

  const handlePage = {
    essencial: (
      <Essencial
        essencialData={essencial}
        setEssencialData={setEssencial}
        brokerResponsible={brokerResponsible}
        profile={profile}
        setProfile={setProfile}
        role={role}
      />
    ),
    address: <Address address={address} setAddress={setAddress} />,
    documents: <Documents documents={documents} setDocuments={setDocuments} />,
    bankAccount: <BankAccount bank={bank} setBank={setBank} />,
    property: <Property userID={userID} />,
    responsibles: (
      <LegalAgents
        dataAgent={agent}
        setAgent={setAgent}
        userRole={essencial.role}
      />
    ),
    client: <ClientSummary userID={userID} />,
  };

  const handleOptions = {
    owner: ownerOptions({ profile, address, documents, bank }),
    tenant: tenantOptions({ address, documents, profile }),
    broker: brokerOptions({ profile, address, documents, bank }),
  };

  return (
    <Box>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Titles
            title="Detalhes do usuário"
            subtitle={`Tipo: ${RoleUser(essencial.role)}`}
          />
          <NavigationContainer
            style={{ marginBottom: 16 }}
            column={2}
            active={page}
            setValue={setPage}
            options={handleOptions[role]}
          >
            <UserData user={data} />
          </NavigationContainer>

          <Buttons style={{ marginBottom: 16, justifyContent: 'flex-end' }}>
            <Button typeColor="primary" onClick={() => setPasswordModal(true)}>
              Mudar Senha
            </Button>
          </Buttons>

          {handlePage[page]}

          <ChangePasswordModal
            userID={userID}
            setModal={setPasswordModal}
            modal={passwordModal}
          />
        </>
      )}
    </Box>
  );
}
