import React, { useState } from 'react';
import { MdFilterList } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { MenuFilter, MenuButton, FilterTags, Tag } from './styles';
import {
  FilterContainer,
  FilterContainerNoBorder,
  Button,
  LinkButton,
  Filter,
  RecentlyButton,
  Buttons,
  SelectedFilter,
} from 'components/Shared';
import { useSelector } from 'react-redux';

export function FilterNegotation({
  FilterButtons,
  FilterMap,
  handleFilterNegotiation,
  handleFilterMenu,
  Tags,
  Order,
  handleOrder,
  options,
  handleRemoveTags,
  handleFilterButtons,
  handleActiveButtons,
  handleInputChange,
}) {
  const [active, setActive] = useState(false);
  const [MenuFilterName, setMenuFilterName] = useState('Filtros');
  const userProfile = useSelector(state => state.user.profile);

  function handleSelectFilter(event) {
    handleFilterNegotiation(event.value);
    setMenuFilterName('Filtros');
  }

  return (
    <>
      <FilterContainer>
        <Filter
          filter={FilterButtons}
          handleFilter={handleFilterButtons}
          active={handleActiveButtons}
        />
        <Buttons className="hasButton middleContent">
          <Button
            style={{ position: 'relative', marginLeft: 'auto' }}
            typeColor="secondary"
            onClick={() => {
              setActive(!active);
            }}
          >
            <MdFilterList />
            {MenuFilterName}
            <MenuFilter
              open={active}
              style={{ display: active ? 'flex' : 'none' }}
            >
              {FilterMap?.map((filter, index) => {
                return (
                  <MenuButton
                    key={index}
                    onClick={() => {
                      handleFilterMenu(filter);
                      setMenuFilterName(filter.label);
                    }}
                  >
                    {filter.label}
                  </MenuButton>
                );
              })}
            </MenuFilter>
          </Button>
          <SelectedFilter
            name="Filtros"
            value={''}
            className="field-select"
            placeholder="Selecione o filtro"
            options={options}
            onChange={e => {
              handleSelectFilter(e);
            }}
            onInputChange={e => handleInputChange(e)}
          />
        </Buttons>
      </FilterContainer>

      <FilterContainerNoBorder>
        <LinkButton typeColor="primary" to="/dashboard/criar-negociacao">
          Criar Negociação
        </LinkButton>
        <FilterTags>
          {Tags?.map((tag, index) => {
            const translate = {
              ownerId: 'Proprietário',
              tenantId: 'Inquilino',
              brokerId: 'Corretor',
              propertyId: 'Imóvel',
            };

            return (
              <Tag
                key={index}
                style={{
                  display: `${
                    userProfile.isBroker && tag === 'brokerId' ? 'none' : 'flex'
                  }`,
                }}
                onClick={() => handleRemoveTags(tag)}
              >
                <IoMdCloseCircle /> {translate[tag]}
              </Tag>
            );
          })}
        </FilterTags>
        <RecentlyButton order={Order} onClick={handleOrder} />
      </FilterContainerNoBorder>
    </>
  );
}
