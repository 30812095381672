import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

function InputNumber({ title, name, required, ...rest }) {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue = '',
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue(ref) {
        if (ref.value) {
          return parseInt(ref.value);
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <p style={{ fontWeight: 500 }}>{title}</p>
          {required && (
            <strong
              style={{ color: '#D8010E', fontWeight: 500, marginLeft: 8 }}
            >
              *
            </strong>
          )}
          {error && (
            <div style={{ marginLeft: 'auto' }}>
              <span role="img" aria-label="error" style={{ fontSize: 12 }}>
                ❌
              </span>
            </div>
          )}
        </div>
        <input
          style={{
            border: error ? '1px solid #D8010E' : '',
          }}
          ref={inputRef}
          defaultValue={defaultValue}
          className={error ? 'has-error' : ''}
          {...rest}
          onFocus={() => clearError()}
          type="number"
        />
        <span
          className="error"
          style={{
            color: '#D8010E',
            fontSize: '14px',
            visibility: error ? 'visible' : 'hidden',
            marginTop: 8,
            height: 14,
          }}
        >
          {error}
        </span>
      </div>
    </>
  );
}

InputNumber.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputNumber;
