import jarvis from 'util/jarvis';

export const handleTypes = async () => {
  const typesList = await jarvis.get('/types/search');
  const checkTypes = typesList.data.map(el => ({
    value: el.id,
    label: `${el.name.charAt(0).toUpperCase() + el.name.slice(1)}`,
  }));

  return checkTypes;
};
