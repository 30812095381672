import { parseBoolFromStringBoolean } from 'util/Context/Shared/parseBoolFromStringBoolean';
import { negotiationValidation } from '../Validation';
import { validationError } from 'validations/forms';

export async function handleSubmit(
  data,
  getDataForm,
  formRef,
  guaranteeLabel,
  admFeeLabel,
  readjustmentLabel
) {
  data = {
    negotiation: {
      ...data.negotiation,
      status: 'analyzing',
      insuranceFire: parseBoolFromStringBoolean(data.negotiation.insuranceFire),
      incomeTax: parseBoolFromStringBoolean(data.negotiation.incomeTax),
      dayPayment: data.negotiation.dayPayment
        ? parseInt(data.negotiation.dayPayment)
        : null,
    },
    labels: {
      guarantee: guaranteeLabel,
      fee: admFeeLabel,
      readjustment: readjustmentLabel,
    },
  };

  try {
    await negotiationValidation.validate(data, {
      abortEarly: false,
    });

    getDataForm(data);
  } catch (err) {
    const validationErrors = {};
    if (err instanceof validationError) {
      err.inner.forEach(error => {
        validationErrors[error.path] = error.message;
      });
      formRef.current.setErrors(validationErrors);
    }
  }
}
