export function signInRequest(email, password, type, code) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password, type, code },
  };
}

export function signInGuest(token) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { token },
  };
}

export function signInSuccess(token, refreshToken, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, refreshToken, user },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function refreshToken() {
  return {
    type: '@auth/REFRESH_TOKEN',
  };
}

export function signOut() {
  return {
    type: '@auth/LOGOUT',
  };
}
