import styled from 'styled-components';

export const ContentImage = styled.span`
  position: relative;
  display: flex;
  height: 320px;
  width: 500px;
  border: ${props => props.theme.border};
  border-radius: 8px;
  @media (max-width: 990px) {
    width: 100%;
  }

  img {
    border: ${props => props.theme.border};
    border-radius: 8px;
  }

  input {
    display: none;
  }

  .changeImage {
    position: absolute;
    cursor: pointer;
    background: ${props => props.theme.backgrounds.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 50%;
    top: 8px;
    left: 8px;
    svg {
      width: 24px;
      height: 24px;
      color: white;
    }
  }
`;
