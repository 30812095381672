import React from 'react';
import { FaHandsHelping } from 'react-icons/fa';
import { Container } from './styles';

export function ContractProfile({ contract }) {
  const { id, status, type = {}, downloads } = contract || {};

  const fontColor = {
    closed: '#fff',
    canceled: '#fff',
    running: '#353D40',
  };

  const backgroundColor = {
    closed: '#0B8E63',
    canceled: '#D8010E',
    running: '#FFDE67',
  };

  const translateContractType = {
    location: 'Locação',
    administration: 'Administração',
  };

  return (
    <Container
      fontColor={fontColor[status]}
      backgroundColor={backgroundColor[status]}
    >
      <span>
        <FaHandsHelping />
      </span>
      <div>
        <p className="title">
          Contrato tipo {translateContractType[type]}: #{id}
        </p>
        <a type="download" href={downloads.originalFileUrl}>
          Download do contrato
        </a>
      </div>
    </Container>
  );
}
