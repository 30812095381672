import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div``;

export const Content = styled(Form)`
  padding: 32px;
  margin: 32px;
  width: 100%;
  max-width: 738px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    padding: 12px;
    margin: 0;
  }
  flex-direction: column;

  > strong {
    border-left: 3px solid #055eff;
    margin: 32px 0 12px 0;
    text-indent: 16px;
    color: #055eff;
  }

  > span {
    margin: 6px 0 4px 0;
  }

  > button {
    margin-top: 32px;
  }

  > button,
  > input {
    max-width: 375px;
  }

  > input {
    color: #055eff;
  }

  > button {
    background: #055eff;
  }
`;

export const Avatar = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 54px;

  > img {
    width: 86px;
    height: 86px;
    border-radius: 100%;
    border: 1px solid #b4b4b5;
    margin-right: 6px;
  }

  > label {
    width: 86px;
    height: 86px;
    border-radius: 100%;
    background: #055eff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    line-height: 27px;
    color: #fff;
    margin-right: 6px;
  }

  > div {
    flex: 1;
    margin-left: 24px;

    > h2 {
      font-weight: 500;
      font-size: 30px;
      line-height: 34px;
      color: ${props => props.theme.colors.primary};
    }

    > p {
      margin-top: 8px;
      font-size: 16px;
      line-height: 20px;
      color: ${props => props.theme.colors.primary};
    }
  }
`;
