import { Button, Buttons } from 'components/Shared';
import React, { useState } from 'react';
import { ModalForm } from './components/ModalForm';

export function FormsCity({ citiesByState }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Buttons style={{ justifyContent: 'end' }}>
        <Button
          type="button"
          typeColor="primary"
          onClick={() => {
            setOpen(!open);
          }}
        >
          Adicionar Cidade
        </Button>
      </Buttons>
      {open && citiesByState && (
        <ModalForm
          open={open}
          setOpen={setOpen}
          citiesByState={citiesByState}
        />
      )}
    </>
  );
}
