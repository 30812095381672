import React from 'react';

import { LastNegotiations } from './Components/LastNegotiations';
import { LastUsers } from './Components/LastUsers';

import { Container } from './styles';
export function LastRegisters() {
  return (
    <Container>
      <h2>Últimos Registros:</h2>
      <div>
        <LastNegotiations />
        <LastUsers />
      </div>
    </Container>
  );
}
