import styled from 'styled-components';

export const EditorContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;

  .container_image_editor {
    position: absolute;
    padding: 1.5rem;
    background: ${props => props.theme.container.background};
    border: ${props => props.theme.border};
    border-radius: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 700px;
  }

  .button_rotate {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: #242424;
    border-radius: 1rem;
    opacity: 80%;
    svg {
      color: #f4f4f4;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .group_buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    div {
      display: flex;
      gap: 1rem;
    }
  }
  .canva {
    transform: scale(0.3);
  }

  .image-editor {
    display: flex;
    margin-top: 52px;
    align-items: center;
    justify-content: center;
    height: min-content;
    max-height: 450px;
  }
`;
