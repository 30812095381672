import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import useFetch from 'services/ReactQuery';

import { Button, Buttons, LoadingScreen } from 'components/Shared';
import { CycleForm } from '../../components/Cycles/Components/Form';
import { CycleList } from '../../components/Cycles/Components/CycleList';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

export function CyclesTemplate() {
  const { id } = useParams();
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    mutateAsync: createCycle,
    isLoading: createLoading,
  } = useFetch.Post();
  const { mutateAsync: updateCycle, isLoading: updateLoading } = useFetch.Put();

  useEffect(() => {
    if (createLoading || updateLoading) {
      setIsLoading(true);
    }
    if (!createLoading && !updateLoading) {
      setIsLoading(false);
    }
  }, [createLoading, updateLoading]);

  async function handleCreateCycle(data) {
    try {
      await createCycle({
        url: `/cycle`,
        body: data,
        key: `cycles/${id}/rental`,
      });
      setSelectedCycle(null);
      toast.success('Ciclo criado com sucesso!');
    } catch (err) {
      toast.error('Erro ao criar ciclo!');
    }
  }

  async function handleUpdateCycle(data) {
    try {
      await updateCycle({
        url: `/cycle/${selectedCycle.id}`,
        body: data,
        key: `cycles/${id}/rental`,
      });
      setSelectedCycle(null);
      toast.success('Ciclo atualizado com sucesso!');
    } catch (err) {
      toast.error('Erro ao atualizar ciclo!');
    }
  }

  const pageRender = props =>
    ({
      null: <CycleList setSelectedCycle={setSelectedCycle} />,
      create: (
        <CycleForm senData={handleCreateCycle}>
          <Buttons>
            <Button
              typeColor="secondary"
              type="button"
              onClick={() => setSelectedCycle(null)}
            >
              Cancelar
            </Button>
            <Button typeColor="primary" type="submit">
              Criar
            </Button>
          </Buttons>
        </CycleForm>
      ),
    }[props] || (
      <CycleForm selectedCycle={selectedCycle} senData={handleUpdateCycle}>
        <Buttons>
          <Button
            typeColor="secondary"
            type="button"
            onClick={() => setSelectedCycle(null)}
          >
            Cancelar
          </Button>
          <Button typeColor="primary" type="submit">
            Editar
          </Button>
        </Buttons>
      </CycleForm>
    ));

  return (
    <>
      <div className="titleMixed" style={{ marginBottom: 16 }}>
        <h1>Lista de Ciclos:</h1>
        <Buttons style={{ justifyContent: 'end' }}>
          <Button
            typeColor="primary"
            onClick={() => setSelectedCycle('create')}
          >
            Criar Ciclo
          </Button>
        </Buttons>
      </div>
      {isLoading ? <LoadingScreen /> : pageRender(selectedCycle)}
    </>
  );
}
