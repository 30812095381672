import React from 'react';

import { Container } from './styles';
import { MdMenu } from 'react-icons/md';
import { HiChevronDoubleLeft } from 'react-icons/hi';

export function MinimizeButton({ open, setOpen }) {
  return (
    <Container>
      <button onClick={() => setOpen(!open)}>
        {open ? <HiChevronDoubleLeft /> : <MdMenu />}
      </button>
    </Container>
  );
}
