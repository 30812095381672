import jarvis from 'util/jarvis';

export async function SendAddressForm(address, id) {
  const { data } = await jarvis.post(`/address/${id}`, address);
  return data;
}

export async function SendEditAddressForm(address, id, addressID) {
  address = {
    ...address.address,
    id: addressID,
  };
  delete address.type;

  const { data } = await jarvis.put(`/user/address/${id}`, address);
  return data;
}
