import styled from 'styled-components';

export const SignatariusContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: ${props => props.theme.border};
  border-radius: 8px;
  padding: 32px 24px;
`;

export const Signatariu = styled.div`
  display: flex;
  align-items: center;

  > span {
    display: flex;
    background: ${props => props.theme.backgrounds.primary};
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-right: 16px;
    width: 48px;
    height: 48px;
    > svg {
      width: 24px;
      height: 24px;
      color: white;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .name {
      font-size: 16px;
    }
    .assign {
      font-size: 12px;
      font-style: italic;
    }
  }
  &.disabled {
    > span {
      background: #dfe0e2;
      > svg {
        color: #989ba1;
      }
    }
    > div {
      > p {
        color: #989ba1;
      }
    }
  }
`;

export const CoSignatarius = styled.div`
  display: flex;
  position: relative;
  padding: 24px 17px 0;
  align-items: center;
  gap: 24px;

  &::before {
    content: '';
    position: absolute;
    left: 23px;
    top: -14px;
    width: 2px;
    height: 56px;
    background: ${props => props.theme.backgrounds.primary};
    z-index: -1;
  }

  > span {
    width: 14px;
    height: 14px;
    background: ${props => props.theme.backgrounds.primary};
    border-radius: 8px;
  }

  > div {
    display: flex;
    padding: 0 16px;
    align-items: center;

    > span {
      display: flex;
      background: ${props => props.theme.backgrounds.primary};
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin-right: 16px;
      width: 24px;
      height: 24px;
      > svg {
        width: 12px;
        height: 12px;
        color: white;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      .name {
        font-size: 14px;
      }
      .assign {
        font-size: 12px;
        font-style: italic;
      }
    }
  }
`;

export const SubSignatarius = styled.div`
  display: flex;
  position: relative;
  padding: 24px 17px 0;
  align-items: center;
  left: 58px;
  gap: 24px;

  &::before {
    content: '';
    position: absolute;
    left: 23px;
    top: -14px;
    width: 2px;
    height: 56px;
    background: ${props => props.theme.backgrounds.primary};
    z-index: -1;
  }

  > span {
    width: 14px;
    height: 14px;
    background: ${props => props.theme.backgrounds.primary};
    border-radius: 8px;
  }

  > div {
    display: flex;
    padding: 0 16px;
    align-items: center;

    > span {
      display: flex;
      background: ${props => props.theme.backgrounds.primary};
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin-right: 16px;
      width: 24px;
      height: 24px;
      > svg {
        width: 12px;
        height: 12px;
        color: white;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      .name {
        font-size: 14px;
      }
      .assign {
        font-size: 12px;
        font-style: italic;
      }
    }
  }
`;
