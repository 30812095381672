import styled from 'styled-components';

export const ContentScroll = styled.ul`
  display: flex;
  width: 100%;
  height: calc(100vh - 19rem);
  border: ${props => props.theme.border};
  border-radius: 8px;
  padding: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  list-style: none;
  background: ${props => props.theme.body};

  &.link-list {
    &:has(a:hover) a:not(a:hover) {
      opacity: 0.7;
      scale: 0.98;
    }
  }

  li {
    transition: all 0.2s ease-in-out;
  }
  &:has(li:hover) li:not(li:hover) {
    opacity: 0.7;
    scale: 0.98;
  }

  &::-webkit-scrollbar {
    padding: 0 15px;
    width: 4px;
    border-radius: 4px;
    background-color: ${props => props.theme.backgrounds.secondary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.backgrounds.primary};
    border-radius: 8px;
  }
`;
