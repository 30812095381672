import jarvis from 'util/jarvis';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

const negotiationUpdateRoute = data => {
  return jarvis.put(`/negotiation/${data.id}`, data.negotiation);
};

export function UpdateNegotiation() {
  const queryClient = useQueryClient();
  return useMutation(negotiationUpdateRoute, {
    onSuccess: data => {
      const newData = data.data;
      toast.success('Negotiation updated successfully');
      queryClient.invalidateQueries(['GetNegotiation', `${newData.id}`]);
      queryClient.setQueryData(['/GetNegotiation', `${newData.id}`], newData);
    },
  });
}

const negotiationUpdateRulesRoute = data => {
  const response = jarvis.put(
    `/negotiation/${data.id}/rules`,
    data.rules.negotiation
  );
  return response;
};

export function UpdateNegotiationRules() {
  const queryClient = useQueryClient();
  return useMutation(negotiationUpdateRulesRoute, {
    onSuccess: data => {
      const newData = data.data;
      toast.success('Negotiation rules updated successfully');
      queryClient.invalidateQueries(['GetNegotiation', `${newData.id}`]);
      queryClient.setQueryData(['/GetNegotiation', `${newData.id}`], newData);
    },
  });
}
