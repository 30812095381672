import jarvis from 'util/jarvis';
import { toast } from 'react-toastify';

export async function handleZipcode(data) {
  const zipcode = data.replace(/\D/g, '');

  if (zipcode.length === 8) {
    const response = await jarvis.get(`/cep/${zipcode}`);

    if (response.data.erro) {
      toast.error('CEP inválido, verifique.');
    }

    const address = response.data.address;

    if (response.data) {
      return {
        address: {
          zipcode: address.cep,
          district: address.district,
          street: address.street,
          city: address.city,
          state: address.state,
        },
      };
    }
  } else {
    return null;
  }
}
