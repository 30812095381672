import React, { useState } from 'react';

import { Box, Filter } from 'components/Shared';
import { Titles } from 'components/Titles';
import { TypesTemplate } from './Template/Types';
import { Cities } from './Template/Citites';

export function WebManager() {
  const [form, setForm] = useState('cities');
  const handlePages = props =>
    ({
      cities: <Cities />,
      types: <TypesTemplate />,
    }[props] || null);

  return (
    <Box>
      <Titles title="Portal de anúncios" subtitle={'Gerenciamento do site'} />
      <Filter
        filter={[
          { value: 'cities', label: 'Cidades' },
          { value: 'types', label: 'Tipos de Imóvel' },
        ]}
        active={form}
        handleFilter={filter => setForm(filter.value)}
      />

      {handlePages(form)}
    </Box>
  );
}
