import produce from 'immer';

const INITIAL_STATE = {
  files: [],
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@gallery/UPLOADED_FILES':
      return produce(state, draft => {
        draft.files = draft.files.concat(action.payload.files);
      });
    case '@gallery/UPDATE_FILES':
      return produce(state, draft => {
        draft.files = draft.files.map(file => {
          return action.payload.id === file.id
            ? { ...file, ...action.payload.data }
            : file;
        });
      });
    case '@gallery/MAIN_IMAGE':
      return produce(state, draft => {
        draft.files = action.payload.files;
      });
    case '@gallery/CLEAN_FILES':
      return produce(state, draft => {
        draft.files = [];
      });
    case '@gallery/REMOVE_FILES':
      return produce(state, draft => {
        draft.files = draft.files.filter(file => {
          return file.id !== action.payload.id;
        });
      });
    default:
      return state;
  }
}
