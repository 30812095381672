import React, { useState } from 'react';
import { Button } from 'components/Shared';
import { Content, Documents, ContentModal } from './styles';
import { ModalScreen } from 'components/Shared';
import { QRCodeCanvas } from 'qrcode.react';
import { toast } from 'react-toastify';

export function Unit({ data }) {
  const { address, blueprint } = data;
  const [modal, setModal] = useState(false);
  const location = window.location.origin;
  const qrcode = `${location}/guest/${data.qrcode}`;

  function copyToClipboard() {
    navigator.clipboard.writeText(qrcode);
    toast.success('Copiado com sucesso!');
  }

  const typeOfDocuments = type =>
    ({
      CLM: 'Climatização',
      ARQ: 'Arquitetônico',
      ELE: 'Elétrica',
      HIDR: 'Hidráulica',
    }[type] || type);

  function extrairTextoAposUnderscore(texto) {
    const partes = texto.split('_');
    if (partes.length > 1) {
      const parteFinal = partes[partes.length - 1].split('.')[0];
      return typeOfDocuments(parteFinal);
    } else {
      return texto;
    }
  }

  function DocumentsRender(blueprint, type) {
    const medias = blueprint.medias;
    const filtered = medias.filter(item => item.type === type);
    const title = type =>
      ({
        projects: 'Projetos executivos',
        plants: 'Plantas baixa',
        renderings: 'Renderizações',
      }[type]);

    if (filtered.length > 0) {
      return (
        <Documents>
          <h2>{title(type)}</h2>
          <div>
            {filtered.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.url_image_original}
                  target="_blank"
                  rel="noreferrer"
                >
                  {extrairTextoAposUnderscore(item.name)}
                </a>
              );
            })}
          </div>
        </Documents>
      );
    }
  }

  return (
    <Content>
      <ModalScreen open={modal} setOpen={setModal}>
        <ContentModal>
          <QRCodeCanvas value={qrcode} />
          <Button typeColor="primary" onClick={() => copyToClipboard()}>
            Copie URL
          </Button>
        </ContentModal>
      </ModalScreen>
      <div className="address">
        <div>
          <p>
            <strong>Unidade: {address.complement}</strong>
          </p>
          <p>
            {address.street}, {address.number} - {address.complement}
          </p>
          <p>
            {address.district} - {address.city} / {address.state}
          </p>
        </div>
        <Button typeColor="primary" onClick={() => setModal(true)}>
          Convite
        </Button>
      </div>
      <div className="blueprint">
        <div className="specification">
          <p>Especificações:</p>
          <p>Área: {blueprint.footage_m2}m²</p>
          <p>Garagens: {blueprint.parking_spaces}</p>
        </div>
        <div className="downloads">
          {DocumentsRender(blueprint, 'projects')}

          {DocumentsRender(blueprint, 'blueprint')}

          {DocumentsRender(blueprint, 'render')}
        </div>
      </div>
    </Content>
  );
}
