import React, { useState } from 'react';
import useFetch from 'services/ReactQuery';
import { useParams } from 'react-router-dom';

import { FaFileContract, FaTrash } from 'react-icons/fa';
import { MdFileDownload } from 'react-icons/md';
import { Button, ConfirmExecution } from 'components/Shared';
import { Container } from './styles';
import { toast } from 'react-toastify';

export function ContractList({ contract, handleContractDetails, ...rest }) {
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const { id: negotiationID } = useParams();
  const { mutateAsync: deleteContract } = useFetch.Patch();

  async function handleCancelContract() {
    try {
      await deleteContract({
        url: `/contract/${contract.id}`,
        key: `/contracts/${negotiationID}`,
      });
      setOpenModalDelete(false);
      toast.success('Contrato cancelado com sucesso');
    } catch (error) {
      toast.error('Erro ao cancelar o contrato');
    }
  }

  const backgroundStatus = {
    running: '#FFDE67',
    closed: '#0b8e63',
    canceled: '#d8010e',
  };

  const colorStatus = {
    running: '#353D40',
    closed: '#ffffff',
    canceled: '#ffffff',
  };

  const translateType = {
    location: 'Locação',
    administration: 'Administração',
  };

  return (
    <Container
      colorStatus={colorStatus[contract.status]}
      backgroundStatus={backgroundStatus[contract.status]}
      {...rest}
    >
      <div style={{ width: '100%' }} onClick={() => handleContractDetails()}>
        <span>
          <FaFileContract />
        </span>
        <p>
          Contrato de {translateType[contract.type]}{' '}
          <strong>#{contract.id}</strong>
        </p>
      </div>
      <div>
        <Button typeColor="secondary">
          <MdFileDownload />
          Download
        </Button>
        <Button
          style={{ padding: 16, marginLeft: 8 }}
          typeColor="cancel"
          onClick={() => setOpenModalDelete(true)}
        >
          <FaTrash style={{ marginRight: 0 }} />
        </Button>
      </div>
      <ConfirmExecution
        setOpen={setOpenModalDelete}
        open={openModalDelete}
        fn={handleCancelContract}
        validationkey={`Cancelar contrato ${contract.id}`}
      />
    </Container>
  );
}
