import styled from 'styled-components';

export const ContainerList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 32px 0;
  background: ${props => props.theme.backgrounds.secondary};
  padding: 16px 24px;
  border-radius: 8px;
  > h2 {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;
  }
  > ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    gap: 16px;
    > li {
      display: flex;
      flex-direction: column;
      min-width: 180px;
      align-items: center;
      background: ${props => props.theme.container.background};
      padding: 16px 24px;
      border-radius: 8px;
      border: 1px solid #055eff;
      flex: 0.2 1 0;
      > span {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-right: 0;
        > button {
          background: transparent;
          border-radius: 50%;
          border: 1px solid #055eff;
          padding: 8px;
          color: #055eff;
          cursor: pointer;
          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
      > div {
        margin: 24px 0;
        cursor: pointer;
        > p {
          font-size: 16px;
          margin-bottom: 8px;
          text-align: center;

          &:last-child {
            margin-bottom: 0;
          }
        }
        &:last-child {
          margin: 0;
        }
      }

      &:last-child {
        margin-right: 0;
        border: 2px dashed #055eff;
        justify-content: space-evenly;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          div {
            display: flex;
            justify-content: center;
            text-align: center;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background: ${props => props.theme.backgrounds.primary};
            margin-bottom: 16px;
            > svg {
              align-self: center;
              height: 32px;
              width: 32px;
              color: white;
            }
          }
        }
      }
    }
  }
`;

export const IconButton = styled.button`
  display: flex;
  position: relative;
  background: #d8010e;
  padding: 8px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  > svg {
    height: 24px;
    width: 24px;
    color: white;
  }

  &.normal {
    background: #055eff;
  }
  &.success {
    background: #0b8e63;
    > svg {
      color: #ffff;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
