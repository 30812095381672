import React, { useCallback } from 'react';

import { Content } from './styles';
import { addressSchema } from './Validation';
import { validationError } from '../../../validations/forms';

import { AddressInputs } from './Components/AddressInputs';

export function AddresForm({ children, formRef, sendData, dataAddress }) {
  const handleSubmit = useCallback(
    async data => {
      const Body = {
        address: {
          type: 'user',
          ...data.address,
        },
      };
      try {
        await addressSchema.validate(data, {
          abortEarly: false,
        });
        await sendData(Body);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [formRef, sendData]
  );

  return (
    <>
      <Content ref={formRef} onSubmit={handleSubmit}>
        <AddressInputs formRef={formRef} dataAddress={dataAddress} />
        {children}
      </Content>
    </>
  );
}
