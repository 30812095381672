import React from 'react';
import useFetch from 'services/ReactQuery';

import { BsShieldFillCheck } from 'react-icons/bs';

import { Container, InformationContent, StatusButtons } from './styles';
import { toast } from 'react-toastify';

export function TypesList({ type }) {
  const { mutateAsync: handleTypeStatus } = useFetch.Put();

  async function handleStatus() {
    try {
      await handleTypeStatus({
        url: `/types/${type.id}/update`,
        key: '/types/search',
      });
      toast.success('Status alterado com sucesso');
    } catch {
      toast.error('Erro ao alterar status');
    }
  }

  return (
    <Container>
      <InformationContent>
        <span className={type.showOnFilter === true ? 'active' : ''}>
          <BsShieldFillCheck />
        </span>
        <h2>{type.name}</h2>
      </InformationContent>
      <StatusButtons>
        <button
          type="button"
          className={type.showOnFilter === true ? 'active' : ''}
          onClick={() => handleStatus()}
        >
          Ativado
        </button>
        <button
          type="button"
          className={type.showOnFilter === false ? 'active' : ''}
          onClick={() => handleStatus()}
        >
          Desativado
        </button>
      </StatusButtons>
    </Container>
  );
}
