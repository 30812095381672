import React, { useState } from 'react';
import useFetch from 'services/ReactQuery';

import { Titles } from 'components/Titles';
import {
  Box,
  Filter,
  FilterContainer,
  FilterContainerNoBorder,
  RecentlyButton,
  ContentScroll,
  LinkButton,
} from 'components/Shared';
import { GetAdmFee } from '../Services';
import { InnerList } from 'components/Tenancy/Components/InnerList';
import { Pagination } from 'components/Pagination';
import { toast } from 'react-toastify';

export function AdminFeeList() {
  const { mutateAsync: updateAdmFeeStatus } = useFetch.Put();
  const [currentParams, setCurrentParams] = useState({
    filter: null,
    order: 'ASC',
    page: 1,
    perPage: 10,
    search: null,
  });

  const { data, isLoading } = GetAdmFee({ currentParams });

  async function updateStatus(id) {
    try {
      await updateAdmFeeStatus({
        url: `/fee/status/${id}`,
        key: '/fees',
        params: {
          id: Number(id),
        },
      });
      toast.success('Status atualizado com sucesso!');
    } catch (err) {
      toast.error('Erro ao atualizar status!');
    }
  }

  async function handlePageChange({ selected }) {
    const newParams = { ...currentParams, page: selected };
    setCurrentParams(newParams);
  }

  return (
    <Box>
      <Titles
        title="Taxa Administrativo"
        subtitle="Lista de Taxa Administrativa"
      />
      <FilterContainer>
        <Filter
          filter={[
            {
              label: 'Todos',
              value: null,
            },
            {
              label: 'Ativados',
              value: 'activated',
            },
            {
              label: 'Desativados',
              value: 'disabled',
            },
          ]}
          active={currentParams.filter}
          handleFilter={filter => {
            setCurrentParams({
              ...currentParams,
              filter: filter.value,
              page: 1,
            });
          }}
        />
        <LinkButton
          to="/dashboard/criar-de-taxa-de-administracao"
          typeColor="primary"
        >
          Criar Taxa
        </LinkButton>
      </FilterContainer>
      <FilterContainerNoBorder>
        <RecentlyButton
          style={{ marginLeft: 'auto' }}
          order={currentParams.order}
          onClick={() => {
            setCurrentParams({
              ...currentParams,
              page: 1,
              order: currentParams.order === 'DESC' ? 'ASC' : 'DESC',
            });
          }}
        />
      </FilterContainerNoBorder>
      {isLoading || (
        <>
          <ContentScroll>
            {data?.data.map(fee => {
              return (
                <InnerList
                  key={fee.id}
                  data={fee}
                  type="fee"
                  handleStatus={updateStatus}
                />
              );
            })}
          </ContentScroll>
          <Pagination
            currentPage={currentParams.page || 1}
            pagesSize={currentParams.perPage}
            totalCount={data?.total}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Box>
  );
}
