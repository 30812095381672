export function handleSubmit(
  ownerData,
  typeData,
  dataAddress,
  valuesData,
  specificationsData,
  additionalData
) {
  const propertyFormData = {
    show: true,
    type: typeData,
    owner: {
      id: ownerData?.user_id,
    },
    price: valuesData?.price,
    broker: {
      id: ownerData?.broker_id,
    },
    observation: additionalData?.observation,
    specification: {
      iptuPayByTenant:
        valuesData?.specification.iptuPayByTenant === true ? true : false,
      iptuPrice:
        valuesData?.specification.iptuPayByTenant === true
          ? valuesData?.specification.iptuPrice > 0
            ? valuesData?.specification.iptuPrice
            : 0
          : 0,
      iptuRegime:
        valuesData?.specification.iptuPayByTenant === true
          ? valuesData?.specification.iptuRegime
          : null,
      condo: valuesData?.specification.condo,
      bedroom: Number(specificationsData?.specification.bedroom),
      bathroom: Number(specificationsData?.specification.bathroom),
      hall: Number(specificationsData?.specification.hall),
      kitchen: Number(specificationsData?.specification.kitchen),
      parkingSpaces: Number(specificationsData?.specification.parkingSpaces),
      suite: Number(specificationsData?.specification.suite),
      isServiceArea:
        additionalData?.specification.isServiceArea === 'true' ? true : false,
      isBalcony:
        additionalData?.specification.isBalcony === 'true' ? true : false,
      isGourmetArea:
        additionalData?.specification.isGourmetArea === 'true' ? true : false,
      allowAnimals:
        additionalData?.specification.allowAnimals === 'true' ? true : false,
      isConcierge:
        additionalData?.specification.isConcierge === 'true' ? true : false,
      isElevator:
        additionalData?.specification.isElevator === 'true' ? true : false,
      isRecreationArea:
        additionalData?.specification.isRecreationArea === 'true'
          ? true
          : false,
      area: Number(additionalData?.specification.area) * 100,
      floor: additionalData?.specification.floor,
      keyOrigin: additionalData?.specification.keyOrigin,
      description: additionalData?.specification.description,
      powerInstallationNumber:
        additionalData?.specification.powerInstallationNumber,
      fire_insurance: false,
    },
    address: {
      ...dataAddress?.address,
      number: dataAddress?.address.number,
      city_id: dataAddress?.address.city_id,
    },
    nearbies: {
      school: additionalData?.nearbies.school === 'true' ? true : false,
      nursery: additionalData?.nearbies.nursery === 'true' ? true : false,
      drugstore: additionalData?.nearbies.drugstore === 'true' ? true : false,
      bakery: additionalData?.nearbies.bakery === 'true' ? true : false,
      cafeteria: additionalData?.nearbies.cafeteria === 'true' ? true : false,
      delegacy: additionalData?.nearbies.delegacy === 'true' ? true : false,
      supermarket:
        additionalData?.nearbies.supermarket === 'true' ? true : false,
      hospital: additionalData?.nearbies.hospital === 'true' ? true : false,
    },
  };
  return propertyFormData;
}
