import styled from 'styled-components';
import { Form } from '@unform/web';

export const BlackScreen = styled.div`
  display: ${props => (props.open ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Container = styled(Form)`
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  padding: 16px 24px;
  border-radius: 8px;
  width: calc(80% - 32px);
  height: calc(100% - 32px);
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.body};
  z-index: 10000;
  > h2 {
    font-size: 16px;
    margin-top: 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  > h1 {
    font-size: 24px;
  }
  > button {
    background: transparent;
    padding: 8px;
    > svg {
      width: 24px;
      height: 24px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
