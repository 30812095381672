import { isEmpty } from 'hooks/isEmpty';
import jarvis from 'util/jarvis';

export async function CreateContractTemplate(
  contractInformation,
  negotiationID
) {
  if (contractInformation.type === 'administration') {
    const { data } = await jarvis.post(
      `/adm/contract/${negotiationID}`,
      contractInformation
    );
    return data;
  } else {
    const { data } = await jarvis.post(
      `/location/contract/${negotiationID}`,
      contractInformation
    );
    return data;
  }
}

export async function SendSignatarius(contractID, signers) {
  const newOwnerSigners = {
    id: isEmpty(signers.owner.user) ? null : signers.owner.user[0].id,
    legalAgents: signers.owner.legalAgents
      ? signers.owner.legalAgents?.map(legalAgent => {
          return {
            id: legalAgent.id,
            legalAgents: legalAgent?.selected?.map(selected => {
              return {
                id: selected.id,
              };
            }),
          };
        })
      : null,
  };
  const newTenantSigners = {
    id: isEmpty(signers.tenant.user) ? null : signers.tenant.user[0].id,
    legalAgents: signers.tenant.legalAgents
      ? signers.tenant.legalAgents?.map(legalAgent => {
          return {
            id: legalAgent.id,

            legalAgents: legalAgent?.selected?.map(selected => {
              return {
                id: selected.id,
              };
            }),
          };
        })
      : null,
  };

  const Body = {
    contractId: contractID,
    owner: newOwnerSigners,
    tenant: newTenantSigners,
  };
  const { data } = await jarvis.post(`/signer`, Body);
  return data;
}

export async function CancelContractRoute(contractID) {
  const { data } = await jarvis.patch(`/contract/${contractID}`);
  return data;
}
