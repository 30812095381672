import React, { useState } from 'react';

import {
  Container,
  Back,
  Title,
  Subtitle,
  Progression,
  SelectMobile,
} from './styles';

import { MdArrowBack } from 'react-icons/md';

export function ProgressionBar({
  setCurrentProgress,
  currentProgress,
  progress,
  role,
}) {
  const [openMenu, setOpenMenu] = useState(false);
  let title = progress[currentProgress].title;
  let subtitle = progress[currentProgress].subtitle;
  return (
    <Container>
      <ul>
        {progress.map((index, stage) => {
          const isActive = currentProgress === stage;
          return (
            <Progression
              key={index.title}
              className={`${index.filled ? 'filled' : ''} ${
                isActive ? 'active' : ''
              }`}
            >
              <p>{index.category}</p>
              <span />
            </Progression>
          );
        })}
      </ul>
      <SelectMobile className="mobileSelect">
        <button>
          {title}

          {openMenu && (
            <>
              <ul>
                {progress.map((index, stage) => {
                  const isActive = currentProgress === stage;
                  return (
                    <li
                      key={index.title}
                      onClick={() => {
                        setCurrentProgress(stage);
                        setOpenMenu(false);
                      }}
                      className={`${isActive ? 'active' : ''} ${
                        index.roles[role] ? '' : 'disabled'
                      }`}
                    >
                      {index.category}
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </button>
      </SelectMobile>
      <div>
        <div>
          {currentProgress !== 0 && (
            <Back
              onClick={() => {
                setCurrentProgress(currentProgress - 1);
              }}
            >
              <MdArrowBack />
              Voltar
            </Back>
          )}
          <Title>{title}</Title>
        </div>
        <Subtitle>{subtitle}</Subtitle>
      </div>
    </Container>
  );
}
