import React from 'react';
import FormatCurrency from 'util/FormatCurrency';
import { useHistory } from 'react-router-dom';

import {
  ContentData,
  ContentGroup,
  LineSpace,
  TitleContainer,
} from 'components/Shared';
import { GoLinkExternal } from 'react-icons/go';

export function PropertySummary({ property, children, goSite }) {
  const { owner, broker } = property || {};
  const { address = {} } = property || {};
  const history = useHistory();

  function handleRedirect() {
    if (goSite) {
      window.open(`https://www.wizzer.com.br/anuncio/${property.id}`, '_blank');
    } else {
      history.push(`/dashboard/imoveis/detalhes/${property.id}`);
    }
  }

  function formatValues(values) {
    if (values) {
      const n = Number(values);
      return FormatCurrency(n / 100);
    }
    return FormatCurrency(0);
  }

  return (
    <>
      <TitleContainer>
        <h1>Dados do Imóvel</h1>
        <button onClick={() => handleRedirect()}>
          <GoLinkExternal />
        </button>
      </TitleContainer>
      <ContentGroup>
        <div>
          <h2>Endereço</h2>
          <ContentData>
            <p>
              CEP: <strong>{address?.zipcode}</strong>
            </p>
            <p>
              Logradouro:{' '}
              <strong>
                {address?.street}, {address?.number}
              </strong>
            </p>
            <p>
              Bairro: <strong>{address.district}</strong>
            </p>
            {address?.complement && (
              <p>
                Complemento: <strong>{address?.complement}</strong>
              </p>
            )}
            <p>
              Cidade:{' '}
              <strong>
                {address?.city} / {address?.state}
              </strong>
            </p>
          </ContentData>
        </div>
      </ContentGroup>
      <ContentGroup>
        <div>
          <h2>Corretor Responsável:</h2>
          <ContentData>
            <p>
              Nome:{' '}
              <strong>
                {broker?.name} {broker?.lastName}
              </strong>
            </p>
            <p>
              Email: <strong>{broker?.email}</strong>
            </p>
          </ContentData>
        </div>
        <div>
          <h2>Proprietario do imóvel:</h2>
          <ContentData>
            <p>
              Nome:{' '}
              <strong>
                {owner?.name} {owner?.lastName}
              </strong>
            </p>
            <p>
              Email: <strong>{owner?.email}</strong>
            </p>
          </ContentData>
        </div>
      </ContentGroup>
      <LineSpace />
      <TitleContainer>
        <h1>Detalhes do Imóvel</h1>
      </TitleContainer>
      <ContentGroup>
        <div>
          <h2>Valores:</h2>
          <ContentData>
            <p>
              Tipo: <strong>{property?.type.name}</strong>
            </p>
            <p>
              Valor do aluguel:{' '}
              <strong>{FormatCurrency(property?.price / 100)}</strong>
            </p>
            {property?.specification?.condo !== 0 &&
              property?.specification?.condo !== '0' &&
              property?.specification?.condo !== null && (
                <p>
                  Valor do condomínio:{' '}
                  <strong>{formatValues(property?.specification.condo)}</strong>
                </p>
              )}
            {property?.specification?.iptu_price !== 0 &&
              property?.specification?.iptu_price !== '0' &&
              property?.specification?.iptu_price !== null && (
                <p>
                  Valor do IPTU:{' '}
                  <strong>
                    {formatValues(property?.specification?.iptu_price)}
                  </strong>
                </p>
              )}
            {property?.specification?.fire_insurance_price !== 0 &&
              property?.specification?.fire_insurance_price !== '0' &&
              property?.specification?.fire_insurance_price !== null && (
                <p>
                  Valor do seguro incêndio:{' '}
                  <strong>
                    {formatValues(property?.specification?.fire_insurance_price)}
                  </strong>
                </p>
              )}
          </ContentData>
        </div>
        <div>
          <ContentData>
            {property?.observation && (
              <p>
                <br></br>
                <strong>Observações:</strong>
                <br></br>
                <br></br>
                {property?.observation}
              </p>
            )}
          </ContentData>
        </div>
      </ContentGroup>
      {children}
    </>
  );
}
