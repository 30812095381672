import styled from 'styled-components';
import { Form } from '@unform/web';

export const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  > p {
    font-size: 16px;
  }

  @media (max-width: 990px) {
    flex-direction: row;
    > a {
      padding: 0;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.theme.container.background};
  border: ${props => props.theme.border};
  border-radius: 8px;
  margin-bottom: 8px;

  > input {
    width: 100%;
    flex: 1;
    border: 0;
    background: none;
    padding: 8px;
    border-radius: 8px;
    height: 40px;
    font-size: 16px;
    line-height: 100%;
    color: ${props => props.theme.colors.primary};

    & ::placeholder {
      color: #989ba1;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

export const ListContainer = styled.div`
  display: flex;
  width: 100%;
  height: 220px;
  padding: 8px 16px;
  flex-direction: column;
  border: ${props => props.theme.border};
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 24px;
  &::-webkit-scrollbar {
    padding: 0 15px;
    width: 4px;
    border-radius: 4px;
    background-color: ${props => props.theme.backgrounds.secondary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }
`;

export const Container = styled.div`
  padding: 46px;
  > div {
    display: flex;
    margin-top: 32px;

    &:first-child {
      margin-top: 0;
    }

    span {
      width: 100%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .half {
    width: 50%;
    padding-right: 8px;
  }
  @media (max-width: 990px) {
    padding: 16px;
  }
`;

export const Content = styled(Form)`
  > div {
    display: flex;
    margin-bottom: 16px;

    &:first-child {
      margin-top: 0;
    }

    span {
      width: 100%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      > p {
        font-size: 16px;
      }
    }
  }
  .half {
    width: 50%;
    padding-right: 8px;
  }

  @media (max-width: 990px) {
    > div {
      flex-direction: column;
      span {
        margin-right: 0;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .half {
      width: 100%;
    }
  }
`;
