import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  position: relative;
  width: 260px;
  height: 280px;
  border-radius: 8px;
  > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: fill;
    border: ${props => props.theme.border};
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Favorite = styled.button`
  top: 8px;
  left: 8px;
  > svg {
    color: #a8abb2;
  }
  &.active {
    > svg {
      color: #d8010e;
    }
  }
`;
export const Delete = styled.button`
  right: 8px;
  bottom: 8px;
  background: #d8010e;
  border: ${props => props.theme.border};

  > svg {
    color: #f0f5ff;
  }
`;
export const Download = styled.button`
  left: 8px;
  bottom: 8px;
  background: #f0f5ff;
  border: ${props => props.theme.border};

  > svg {
    color: #055eff;
  }
`;
export const Rotate = styled.button`
  right: 8px;
  top: 8px;
  background: #f0f5ff;
  border: ${props => props.theme.border};

  > svg {
    color: #055eff;
  }
`;
