import * as Yup from 'yup';

export const informationValidationBroker = Yup.object().shape({
  profile: Yup.object().shape({
    birthdayDate: Yup.string().required('Digite sua data de nascimento'),
    career: Yup.string().required('Profissão é obrigatório'),
    maritualStatus: Yup.string().required('Estado civil é obrigatório'),
  }),
});

export const informationValidationOwner = Yup.object().shape({
  profile: Yup.object().shape({
    career: Yup.string().required('Profissão é obrigatório'),
    birthdayDate: Yup.string().required('Digite sua data de nascimento'),
    maritualStatus: Yup.string().required('Estado civil é obrigatório'),
  }),
});

export const informationValidationTenant = Yup.object().shape({
  profile: Yup.object().shape({
    career: Yup.string().required('Profissão é obrigatório'),
    birthdayDate: Yup.string().required('Digite sua data de nascimento'),
    maritualStatus: Yup.string().required('Estado civil é obrigatório'),
    animal: Yup.boolean().required('Animal é obrigatório'),
    animalDescription: Yup.string().when('animal', {
      is: true,
      then: Yup.string().required('Descrição do animal é obrigatório'),
      otherwise: Yup.string(),
    }),
    numberOfChildren: Yup.number().required(
      'Quantidade de filhos é obrigatório'
    ),
  }),
});
