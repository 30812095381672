import React, { useRef } from 'react';
import { Titles } from 'components/Titles';
import {
  Box,
  Buttons,
  Button,
  LinkButton,
  BackButton,
} from 'components/Shared';
import { GuaranteeForm } from '../Components/GuaranteeForm';
import { SendGuaranteeForm } from '../Services';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

export function CreateGuarantee() {
  const formRef = useRef(null);
  const history = useHistory();

  async function postGuarantee(data) {
    try {
      await SendGuaranteeForm(data);
      toast.success('Garantia criada com sucesso!');
      history.push('/dashboard/lista-de-garantias');
    } catch (err) {
      toast.error('Erro ao enviar garantia!');
    }
  }

  return (
    <Box>
      <Titles title="Garantias" subtitle="Criação de Garantia" />
      <BackButton
        to="/dashboard/lista-de-garantias"
        style={{ marginBottom: 16 }}
      />
      <GuaranteeForm
        formRef={formRef}
        postGuarantee={postGuarantee}
        type="create"
      >
        <Buttons>
          <LinkButton to="/dashboard/lista-de-garantias" typeColor="secondary">
            Cancelar
          </LinkButton>
          <Button typeColor="primary" type="submit">
            Criar Garantia
          </Button>
        </Buttons>
      </GuaranteeForm>
    </Box>
  );
}
