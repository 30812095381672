import React, { useCallback, useEffect } from 'react';

import {
  Content,
  InputText,
  TitleContainer,
  BorderContainer,
  InputPercent,
} from 'components/Shared';
import { AdminFeeValidation } from './Validation';
import { validationError } from 'validations/forms';

export function AdminFeeForm({ formRef, children, postAdminFee, type, data }) {
  useEffect(() => {
    if (data) {
      formRef.current.setData(data);
    }
  }, [data]);

  const handleSubmit = useCallback(
    async data => {
      try {
        await AdminFeeValidation.validate(data, {
          abortEarly: false,
        });
        postAdminFee(data);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [formRef, postAdminFee]
  );

  return (
    <BorderContainer>
      <Content ref={formRef} onSubmit={handleSubmit}>
        <div>
          <TitleContainer style={{ margin: 0 }}>
            <h1>
              {type === 'edit' ? 'Editar' : 'Criar'} Taxa de Administração
            </h1>
          </TitleContainer>
        </div>
        <div className="half">
          <span>
            <InputPercent
              name="percentage"
              title="Taxa (Porcentagem):"
              placeholder="Digite a taxa"
              required={true}
            />
          </span>
        </div>
        <div>
          <span>
            <InputText
              name="description"
              title="Descrição"
              placeholder="Digite a descrição da taxa de administração"
              required={true}
            />
          </span>
        </div>
        {children}
      </Content>
    </BorderContainer>
  );
}
