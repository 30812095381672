import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';

import {
  Container,
  Content,
  Logo,
  Form,
  Strong,
  Button,
  Link,
  Cover,
  Footer,
} from '../components/unauth';
import { InputForm } from 'components/Shared';

export function ForgotPassword() {
  const loading = useSelector(state => state.auth.loading);
  const [sended, setSended] = useState(false);
  const [charsEmail, setCharsEmail] = useState('');
  const [maskedEmail, setMaskedEmail] = useState('');

  async function handleSubmit(data) {
    try {
      await axios.post('/forgot', { email: data.email });

      setCharsEmail(data.email[0] + data.email[1] + data.email[2]);
      setMaskedEmail(data.email.split('@')[1]);
      setSended(true);
      toast.success('E-mail de recuperação de senha enviado com sucesso!');
    } catch (err) {
      console.log(err);
      toast.error(
        'Não foi possível enviar o e-mail de recuperação, tente novamente.'
      );
    }
  }

  return (
    <Container>
      <Content>
        <Form className="form" onSubmit={handleSubmit}>
          <Logo />
          {sended ? (
            <>
              <Strong>
                Enviamos um e-mail para{' '}
                {charsEmail + '***********@' + maskedEmail}. Acesse sua caixa de
                entrada e clique no link de recuperação de senha.
              </Strong>
              <Link to="/">Voltar</Link>
            </>
          ) : (
            <>
              <Strong>
                Digite o seu e-mail de acesso. Enviaremos um link para a
                recuperação da sua senha!
              </Strong>

              <InputForm
                name="email"
                id="email"
                title="E-mail"
                placeholder="Digite o E-mail"
                autocomplete="on"
                required={true}
              />
              <Button type="submit">
                {loading ? 'Carregando...' : 'ENVIAR'}
              </Button>
              <Link to="/">Voltar</Link>
            </>
          )}
        </Form>
        <Cover />
      </Content>
      <Footer>
        © {new Date().getFullYear()} Wizzer Imóveis - Brasil - Todos os direitos
        reservados
      </Footer>
    </Container>
  );
}
