import React from 'react';

import { TransactionContainerCard } from './styles';

export function TransactionInnerCard({ data }) {
  return (
    <TransactionContainerCard>
      {Array.isArray(data) &&
        data.map((item, index) => {
          return (
            <div className="card" key={index}>
              <p className="title-card">
                <strong>{item.title}:</strong>
              </p>
              <p className="description-card">{item.value}</p>
            </div>
          );
        })}
    </TransactionContainerCard>
  );
}
