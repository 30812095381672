import React from 'react';

import { ButtonStyles } from './styles';

export function Button({ children, typeColor, ...rest }) {
  const backgroundHex = {
    primary: '#055eff',
    secondary: '#ccdeff',
    success: '#0b8e63',
    cancel: '#d8010e',
    disable: '#dfe0e2',
    clean: 'transparent',
  };

  const colorHex = {
    primary: '#fff',
    secondary: '#055eff',
    success: '#fff',
    cancel: '#fff',
    disable: '#989BA1',
    clean: '#055eff',
  };

  return (
    <ButtonStyles
      background={backgroundHex[typeColor]}
      color={colorHex[typeColor]}
      {...rest}
    >
      {children}
    </ButtonStyles>
  );
}
