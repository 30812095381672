import { bankValidation } from '../Validation';
import { validationError } from 'validations/forms';
import { validateArray } from 'util/Context/Shared/validateArray';

export async function handleSubmit(data, sendData, formRef) {
  data.bankAccount = {
    ...data.bankAccount,
    documentType:
      data.bankAccount?.documentNumber?.replace(/\D/g, '').length <= 11
        ? 'cpf'
        : 'cnpj',
  };

  const pix = validateArray({
    data: data.pix,
    attributes: 'type',
  });

  data.pix = pix;

  try {
    await bankValidation.validate(data, {
      abortEarly: false,
    });
    await sendData(data);
  } catch (err) {
    const validationErrors = {};
    if (err instanceof validationError) {
      err.inner.forEach(error => {
        validationErrors[error.path] = error.message;
      });

      formRef.current.setErrors(validationErrors);
    }
  }
}
