import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  padding: 46px 0px;
  > div {
    display: flex;
    margin-top: 32px;

    &:first-child {
      margin-top: 0;
    }

    span {
      width: 100%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .half {
    width: 50%;
    padding-right: 8px;
  }
  @media (max-width: 990px) {
    padding: 0;
  }
`;

export const Content = styled(Form)`
  > div {
    display: flex;
    margin-top: 32px;
    gap: 16px;
    &:first-child {
      margin-top: 0;
    }

    span {
      display: flex;
      width: 100%;
      > p {
        font-size: 16px;
      }
    }
  }
  .half {
    width: 50%;
    padding-right: 8px;
  }

  @media (max-width: 990px) {
    > div {
      flex-direction: column;

      span {
        margin-right: 0;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .half {
      width: 100%;
    }
  }
`;

export const CenterButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 990px) {
    > button {
      margin-bottom: 16px;
      width: 100%;
      justify-content: center;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 16px;
      width: 100%;
      justify-content: center;

      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 24px 0;
  > h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
  }
  > h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
  }
  > button {
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-size: 16px;
    background: none;
    > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const SpaceLine = styled.div`
  width: 100%;
  height: 1px;
  background: #dfe0e3;
`;
