import React, { useState } from 'react';

import { Titles } from 'components/Titles';
import { Pagination } from 'components/Pagination';
import { InnerList } from 'components/Tenancy/Components/InnerList';
import {
  Box,
  Filter,
  FilterContainer,
  FilterContainerNoBorder,
  RecentlyButton,
  LinkButton,
  ContentScroll,
  LoadingScreen,
} from 'components/Shared';
import { GetReadjustment, ChangeReadjustmenteStatus } from '../Services';

export function ReadjustmentList() {
  const [currentParams, setCurrentParams] = useState({
    filter: null,
    order: 'ASC',
    page: 1,
    perPage: 10,
    search: null,
  });

  const { data, isLoading, refetch } = GetReadjustment({ currentParams });
  async function updateStatus(id) {
    try {
      await ChangeReadjustmenteStatus(id);
      refetch();
    } catch (error) {
      console.log(error);
    }
  }
  async function handlePageChange({ selected }) {
    const newParams = { ...currentParams, page: selected };
    setCurrentParams(newParams);
  }

  return (
    <Box>
      <Titles
        title="Índice de Reajuste"
        subtitle="Lista de Índice de Reajuste"
      />
      <FilterContainer>
        <Filter
          filter={[
            {
              label: 'Todos',
              value: null,
            },
            {
              label: 'Ativados',
              value: 'activated',
            },
            {
              label: 'Desativados',
              value: 'disabled',
            },
          ]}
          active={currentParams.filter}
          handleFilter={filter => {
            setCurrentParams({
              ...currentParams,
              filter: filter.value,
              page: 1,
            });
          }}
        />
        <LinkButton to="/dashboard/criar-reajuste" typeColor="primary">
          Criar Reajuste
        </LinkButton>
      </FilterContainer>
      <FilterContainerNoBorder>
        <RecentlyButton
          style={{ marginLeft: 'auto' }}
          order={currentParams.order}
          onClick={() => {
            setCurrentParams({
              ...currentParams,
              page: 1,
              order: currentParams.order === 'DESC' ? 'ASC' : 'DESC',
            });
          }}
        />
      </FilterContainerNoBorder>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ContentScroll>
            {data?.data.map(readjustment => {
              return (
                <InnerList
                  key={readjustment.id}
                  data={readjustment}
                  type="readjustment"
                  handleStatus={updateStatus}
                />
              );
            })}
          </ContentScroll>
          <Pagination
            currentPage={currentParams.page || 1}
            pagesSize={currentParams.perPage}
            totalCount={data?.total}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Box>
  );
}
