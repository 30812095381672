import React, { useState } from 'react';

import { Titles } from 'components/Titles';
import { Pagination } from 'components/Pagination';
import { Box, ContentScroll, EmptyList } from 'components/Shared';
import { Negotiation } from './Components/Negotiation';
import { FilterNegotation } from './Components/FilterNegotation';
import { GetUsers, GetNegotiation } from './Services';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import useDebounce from 'hooks/useDebounce';

export function NegotiationList() {
  const userProfile = useSelector(state => state.user.profile);
  const [search, setSearch] = useState('');
  const [currentParams, setCurrentParams] = useState({
    page: 1,
    perPage: 4,
    order: 'DESC',
    search: null,
    filter: null,
    status: 'analyzing',
  });

  const [usersFilterParams, setUsersFilterParams] = useState({
    page: 1,
    perPage: 10,
    search: null,
    filter: null,
  });

  async function handleFilterNegotiation(filter) {
    const roleToId = {
      owner: 'ownerId',
      tenant: 'tenantId',
      broker: 'brokerId',
      property: 'propertyId',
    };
    const typeFilter = { [roleToId[usersFilterParams.filter]]: filter };
    setCurrentParams({
      ...currentParams,
      filter: { ...currentParams.filter, ...typeFilter },
    });
  }

  useEffect(() => {
    if (userProfile.isBroker) {
      setCurrentParams({
        ...currentParams,
        filter: { ...currentParams.filter, brokerId: userProfile.id },
      });
    }
  }, []);

  function handleRemoveTags(tag) {
    const params = currentParams.filter;
    delete params[tag];
    setCurrentParams({
      ...currentParams,
      filter: params,
    });
  }

  async function handlePageChange({ selected }) {
    const newParams = { ...currentParams, page: selected };
    setCurrentParams(newParams);
  }

  function handleInputChange(e) {
    setSearch(e);
  }

  useDebounce(
    () =>
      setUsersFilterParams({
        ...usersFilterParams,
        search: search,
      }),
    500,
    [search]
  );

  const { data } = GetUsers({
    params: usersFilterParams,
    profile: userProfile,
  });

  const { negotiationData, total, isLoading } = GetNegotiation(currentParams);

  return (
    <Box>
      <Titles title="Negociações" subtitle="Lista de negociações" />
      <FilterNegotation
        FilterButtons={[
          { value: null, label: 'Todas' },
          { value: 'analyzing', label: 'Pendentes' },
          { value: 'approved', label: 'Aprovado' },
          { value: 'canceled', label: 'Canceladas' },
        ]}
        FilterMap={
          userProfile.isAdmin
            ? [
                { value: 'owner', label: 'Proprietario' },
                { value: 'tenant', label: 'Inquilino' },
                { value: 'broker', label: 'Corretor' },
                { value: 'property', label: 'Imóvel' },
              ]
            : [
                { value: 'property', label: 'Imóvel' },
                { value: 'owner', label: 'Proprietario' },
                { value: 'tenant', label: 'Inquilino' },
              ]
        }
        Order={currentParams?.order}
        handleOrder={() => {
          setCurrentParams({
            ...currentParams,
            order: currentParams.order === 'ASC' ? 'DESC' : 'ASC',
          });
        }}
        handleFilterButtons={filter => {
          setCurrentParams({ ...currentParams, page: 1, status: filter.value });
        }}
        handleActiveButtons={currentParams.status}
        handleFilterMenu={filter => {
          setUsersFilterParams({ ...usersFilterParams, filter: filter.value });
        }}
        Tags={currentParams.filter ? Object.keys(currentParams.filter) : []}
        handleFilterNegotiation={handleFilterNegotiation}
        handleRemoveTags={handleRemoveTags}
        options={data}
        handleInputChange={handleInputChange}
      />
      {isLoading || (
        <>
          {negotiationData.length > 0 ? (
            <>
              <ContentScroll className="link-list">
                {negotiationData?.map((negotiation, index) => {
                  return <Negotiation key={index} negotiation={negotiation} />;
                })}
              </ContentScroll>
              <Pagination
                currentPage={currentParams.page}
                pagesSize={currentParams.perPage}
                totalCount={total}
                onPageChange={handlePageChange}
              />
            </>
          ) : (
            <EmptyList />
          )}
        </>
      )}
    </Box>
  );
}
