import React from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import FormatCurrency from 'util/FormatCurrency';

import { ContentData, LineSpace, ContentGroup } from 'components/Shared';

export function NegotiationSummary({
  negotiationData,
  selectedTenant,
  selectedProperty,
}) {
  const { address, owner, broker } = selectedProperty;
  const { negotiation, labels } = negotiationData;

  function formatDateData(date) {
    const currentDate = new Date(date);
    return format(currentDate, "dd 'de' MMMM 'de' yyyy", { locale: pt });
  }

  return (
    <>
      <h1>Dados do Imóvel</h1>
      <ContentGroup>
        <div>
          <h2>Endereço</h2>
          <ContentData>
            <p>CEP: {address?.zipcode}</p>
            <p>
              {address?.street}, {address?.number} - {address?.district}
            </p>
            <p>
              {address?.city} / {address?.state}
            </p>
          </ContentData>
        </div>
      </ContentGroup>
      <ContentGroup>
        <div>
          <h2>Corretor Responsavel:</h2>
          <ContentData>
            <p>
              Nome: {broker?.name} {broker?.lastName}
            </p>
            <p>Email: {broker?.email}</p>
          </ContentData>
        </div>
        <div>
          <h2>Proprietario do imóvel:</h2>
          <ContentData>
            <p>
              Nome: {owner?.name} {owner?.lastName}
            </p>
            <p>Email: {owner?.email}</p>
          </ContentData>
        </div>
      </ContentGroup>
      <LineSpace />
      <h1>Dados do Inquilino:</h1>
      <ContentGroup>
        <div>
          <h2>Inquilino Selecionado:</h2>
          <ContentData>
            <p>
              Nome: {selectedTenant?.name} {selectedTenant?.lastName}
            </p>
            <p>Email: {selectedTenant?.email}</p>
          </ContentData>
        </div>
      </ContentGroup>
      <LineSpace />
      <h1>Dados da Negociação:</h1>
      <ContentGroup>
        <div>
          <h2>Detalhes do aluguel</h2>
          <ContentData>
            <p>
              Valor do aluguel:{' '}
              <strong>{FormatCurrency(negotiation?.amount / 100)}</strong>
            </p>
            <p>
              Périodo do aluguel:{' '}
              <strong>{negotiation?.periodMonths} meses</strong>
            </p>
            {negotiation?.startDate && (
              <p>
                Início do aluguel:{' '}
                <strong>{formatDateData(negotiation?.startDate)}</strong>
              </p>
            )}
          </ContentData>
        </div>
        <div>
          <h2>Dia do pagamento:</h2>
          <ContentData>
            <p>
              Dia do mês: <strong>{negotiation?.dayPayment}</strong>
            </p>
          </ContentData>
        </div>
        <div>
          <h2>Taxas e Seguro:</h2>
          <ContentData>
            <p>
              Taxa de administração: <strong>{labels?.fee.label}</strong>
            </p>
            <p>
              Inclui seguro incendio?{' '}
              <strong>{negotiation?.insuranceFire ? 'Sim' : 'Não'}</strong>
            </p>
            {negotiation?.insuranceFireAmount && (
              <p>
                Valor do seguro incendio:
                <strong>
                  {' '}
                  {FormatCurrency(negotiation?.insuranceFireAmount / 100)}
                </strong>
              </p>
            )}
            <p>
              Declara imposto de renda?{' '}
              <strong>{negotiation?.incomeTax ? 'Sim' : 'Não'}</strong>
            </p>
          </ContentData>
        </div>
        <div>
          <h2>Garantia e Índice de reajuste:</h2>
          <ContentData>
            <p>
              Garantia: <strong>{labels?.guarantee.label}</strong>
            </p>
            <p>
              Índice de reajuste: <strong>{labels?.readjustment.label}</strong>
            </p>
          </ContentData>
        </div>
        <div>
          <h2>Informações contratuais:</h2>
          <ContentData>
            <p>
              Multa referente à:{' '}
              <strong>{negotiation?.periodMinChargeFine} meses</strong>
            </p>
            <p>
              Período mínimo para não cobrar a multa:{' '}
              <strong>{negotiation?.periodContractualFine} meses</strong>
            </p>
          </ContentData>
        </div>
        {negotiation?.description && (
          <div>
            <h2>Observações:</h2>
            <ContentData>
              <p>
                Observações da negociação:{' '}
                <strong>{negotiation?.description}</strong>
              </p>
            </ContentData>
          </div>
        )}
      </ContentGroup>
    </>
  );
}
