import React from 'react';

import Route from '../../../routes/Route';

import { Settings } from '../../../components/Settings';

import { UsersList } from '../../../components/User/UsersList';
import { PropertyDetails } from 'components/Property/PropertyDetails';
import { PropertyForm } from 'components/Property/CreateProperty';
import { CongratsLocation } from '../../../components/broker/success/Location';
import CongratsBroker from '../../../components/broker/Congrats';
import { PropertyList } from '../../../components/Property/PropertyList';
import { CreateNegotiation } from 'components/Negotiations/CreateNegotiation';
import { NegotiationDetails } from 'components/Negotiations/NegotationDetails';
import { NegotiationList } from 'components/Negotiations/NegotiationList';
import { UserDetails } from 'components/User/UserDetails';
import { WebManager } from 'components/WebManage';
import { GuanranteeList } from 'components/Tenancy/Guarantees/GuaranteeList';
import { CreateGuarantee } from 'components/Tenancy/Guarantees/CreateGuarantee';
import { EditGuarantee } from 'components/Tenancy/Guarantees/EditGuarantee';
import { UserForm } from '../../../components/User/CreateUser';
import { ReadjustmentList } from 'components/Tenancy/Readjustment/ReadjustmentList';
import { CreateReadjustment } from 'components/Tenancy/Readjustment/CreateReadjustment';
import { EditReadjustment } from 'components/Tenancy/Readjustment/EditReadjustment';
import { AdminFeeList } from 'components/Tenancy/AdminFee/AdminFeeList';
import { CreateAdminFee } from 'components/Tenancy/AdminFee/CreateAdminFee';
import { EditAdminFee } from 'components/Tenancy/AdminFee/EditAdminFee';
import { ContractDetails } from 'components/Contract/ContractDetails';
import { RentalList } from 'components/Renting/RentalList';
import { RentalDetails } from 'components/Renting/RentalDetails';

export const AdminRoutes = () => {
  return (
    <>
      <Route path="/dashboard/configuracoes" component={Settings} isPrivate />
      <Route path="/dashboard/clientes" component={UsersList} isPrivate exact />
      <Route
        path="/dashboard/clientes/criar/:create?"
        component={UserForm}
        isPrivate
        exact
      />
      <Route
        path="/dashboard/clientes/detalhes/:id?"
        component={UserDetails}
        isPrivate
        exact
      />
      <Route
        path="/dashboard/registrar-imovel"
        component={PropertyForm}
        isPrivate
      />
      <Route
        path="/dashboard/sucesso/nova-locacao/:idNegotiation"
        component={CongratsLocation}
        isPrivate
        exact
      />
      <Route
        path="/dashboard/congrats/broker/:id"
        component={CongratsBroker}
        isPrivate
      />
      <Route
        path="/dashboard/imoveis"
        component={PropertyList}
        isPrivate
        exact
      />
      <Route
        path="/dashboard/imoveis/detalhes/:id"
        component={PropertyDetails}
        isPrivate
      />
      <Route
        path="/dashboard/proprietarios/:user_id/userinfo"
        component={() => <UsersList defaultSection="userinfo" />}
        isPrivate
      />
      <Route
        path="/dashboard/negociacoes"
        component={NegotiationList}
        isPrivate
        exact
      />
      <Route path="/dashboard/portal" component={WebManager} isPrivate />
      <Route
        path="/dashboard/alugueis/:id/detalhes"
        component={RentalDetails}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/alugueis"
        component={RentalList}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/criar-negociacao/:propertyid?/:userid?"
        component={CreateNegotiation}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/negociacao-detalhes/:id?"
        component={NegotiationDetails}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/negociacao-detalhes/:negotiationID?/contrato/:contractID?"
        component={ContractDetails}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/lista-de-garantias"
        component={GuanranteeList}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/criar-garantia"
        component={CreateGuarantee}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/editar-garantia/:id"
        component={EditGuarantee}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/lista-de-reajuste"
        component={ReadjustmentList}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/criar-reajuste"
        component={CreateReadjustment}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/editar-reajuste/:id"
        component={EditReadjustment}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/lista-de-taxa-de-administracao"
        component={AdminFeeList}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/criar-de-taxa-de-administracao"
        component={CreateAdminFee}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/editar-de-taxa-de-administracao/:id"
        component={EditAdminFee}
        exact
        isPrivate
      />
    </>
  );
};
