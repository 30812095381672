import React, { useState, useCallback, useEffect } from 'react';

import { SpaceLine, TitleContainer } from 'components/Shared';

import { Details } from './Components/Details';
import { Tax } from './Components/Tax';
import { ContractInformation } from './Components/ContractInformation';

import { Content } from '../../../Styles/styles';

import { handleSubmit } from './Context';

export function NegotiationForm({
  negotiationData,
  getDataForm,
  formRef,
  children,
}) {
  const [guaranteeLabel, setGuaranteeLabel] = useState('');
  const [admFeeLabel, setAdmFeeLabel] = useState('');
  const [readjustmentLabel, setReadjustmentLabel] = useState('');

  useEffect(() => {
    if (negotiationData) {
      formRef.current.setData(negotiationData);
    } else {
      formRef.current.setFieldValue('negotiation.periodMinChargeFine', 4);
      formRef.current.setFieldValue('negotiation.periodContractualFine', 12);
      formRef.current.setFieldValue('negotiation.periodMonths', 30);
      formRef.current.setFieldValue('negotiation.insuranceFire', true);
    }
  }, [negotiationData, formRef]);

  useEffect(() => {
    if (negotiationData) {
      formRef.current.setFieldValue(
        'negotiation.guaranteeId',
        negotiationData.labels.guarantee
      );
      formRef.current.setFieldValue(
        'negotiation.admFeeId',
        negotiationData.labels.fee
      );
      formRef.current.setFieldValue(
        'negotiation.readjustmentId',
        negotiationData.labels.readjustment
      );
    }
  }, [negotiationData, formRef]);

  const submit = useCallback(
    async data => {
      await handleSubmit(
        data,
        getDataForm,
        formRef,
        guaranteeLabel,
        admFeeLabel,
        readjustmentLabel
      );
    },
    [guaranteeLabel, admFeeLabel, readjustmentLabel, getDataForm, formRef]
  );

  return (
    <Content style={{ width: '100%' }} ref={formRef} onSubmit={submit}>
      <TitleContainer>
        <h2>Detalhes da negociação</h2>
      </TitleContainer>
      <Details />
      <SpaceLine />
      <TitleContainer>
        <h2>Garantia e Índice de reajuste:</h2>
      </TitleContainer>
      <Tax
        setReadjustmentLabel={setReadjustmentLabel}
        setGuaranteeLabel={setGuaranteeLabel}
        setAdmFeeLabel={setAdmFeeLabel}
      />
      <SpaceLine />
      <TitleContainer>
        <h2>Informações contratuais:</h2>
      </TitleContainer>
      <ContractInformation />
      {children}
    </Content>
  );
}
