import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CenterButtons } from '../../../../../Shared/Containers/Forms/styles';
import { Button } from 'components/Shared';
import { BlackScreen, Modal } from '../styles';

export function FinalizationModal({ userId, dataEssencial, setOpen, open }) {
  const history = useHistory();
  const location = useLocation();
  const { create } = useParams();

  function handleRedirect(data) {
    if (create === 'tenant') {
      history.push(`/dashboard/criar-negociacao/${userId}`, {
        tenant: data,
        property: location.state.property,
      });
    } else {
      history.push(`/dashboard/clientes/detalhes/${userId}`);
    }
  }

  return (
    <>
      <BlackScreen onClick={() => setOpen(false)} open={open} />
      <Modal open={open}>
        <div>
          <h1>Cadastro Finalizado</h1>
          <p style={{ textAlign: 'center' }}>
            O cliente {dataEssencial.name} foi adicionado com sucesso a lista de
            clientes!
          </p>
          <CenterButtons>
            <Button
              onClick={() => handleRedirect(dataEssencial)}
              typeColor={'primary'}
            >
              Finalizar
            </Button>
          </CenterButtons>
        </div>
      </Modal>
    </>
  );
}
