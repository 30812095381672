import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  > p {
    font-size: 1.2rem;
    text-align: center;
  }
`;

export const GoogleForm = styled.div`
  > iframe {
    max-width: 100%;
  }
`;

export const ButtonWhats = styled.button`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  background-color: #25d366;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
`;
