export const typeAccount = [
  { value: 'conta_corrente', label: 'Conta Corrente' },
  { value: 'conta_poupanca', label: 'Conta Poupança' },
];

export const pixOptions = [
  { value: null, label: 'Nenhum' },
  { value: 'document', label: 'CPF/CNPJ' },
  { value: 'email', label: 'E-mail' },
  { value: 'phone', label: 'Telefone' },
  { value: 'key', label: 'Chave aleatória' },
];
