import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';

export default createGlobalStyle`

  * {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
    outline: 0;
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: ${props => props.theme.body};
  }

  body, html, input, p, a, button {
    color: ${props => props.theme.colors.primary};
    font-size: 14px;
  }
  input {
    background: ${props => props.theme.body};
  }

  button {
    outline: 0;
    border: 0;
    cursor: pointer;
  }

  .react-datepicker-wrapper {
    width: 100%
  }

`;
