import * as Yup from 'yup';

export const bankValidation = Yup.object().shape({
  bankAccount: Yup.object().shape({
    bankCode: Yup.string().required('O banco é obrigatório'),
    bankBranch: Yup.string().required('A agência é obrigatória'),
    bankBranchDv: Yup.string(),
    account: Yup.string().required('A conta bancária é obrigatória'),
    accountDv: Yup.string().required('O codigo verificador é obrigatório'),
    type: Yup.string().required('O tipo da conta bancária é obrigatório'),
    legalName: Yup.string().required('O nome do titular é obrigatório'),
    documentNumber: Yup.string().required('O documento é obrigatório'),
    documentType: Yup.string(),
  }),
  pix: Yup.array().of(
    Yup.object().shape({
      type: Yup.string(),
      key: Yup.string().when('type', {
        is: '',
        then: Yup.string(),
        otherwise: Yup.string().required('O tipo do documento é obrigatório'),
      }),
    })
  ),
});
