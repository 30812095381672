import React, { useState } from 'react';

import { TenantSummary } from '../../Summary/TenantSummary';
import { NegotiationDataTenant } from 'components/Negotiations/CreateNegotiation/Components/Forms/NegotiationDataTenant';
import { Buttons, Button, Box } from 'components/Shared';
import { UpdateNegotiationTenant } from './Services';
import { toast } from 'react-toastify';

export function TenantData({ negotiationData, negotiationID }) {
  const { tenant, status } = negotiationData;
  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const [edit, setEdit] = useState(false);

  const { mutateAsync: updateTenant } = UpdateNegotiationTenant();

  async function sendUpdateTenant() {
    const data = {
      tenant: {
        tenantId: selectedTenant.id,
      },
      id: negotiationID,
    };
    try {
      await updateTenant(data);
      setEdit(false);
      toast.success('Alterações salvas com sucesso!');
    } catch (error) {
      toast.error('Erro ao salvar alterações!');
    }
  }

  return (
    <Box>
      {!edit ? (
        <TenantSummary tenant={tenant}>
          {status === 'analyzing' && (
            <Buttons style={{ marginTop: 24 }}>
              <Button typeColor={'primary'} onClick={() => setEdit(true)}>
                {' '}
                Editar
              </Button>
            </Buttons>
          )}
        </TenantSummary>
      ) : (
        <div style={{ padding: 24 }}>
          <NegotiationDataTenant
            selectedTenant={selectedTenant}
            setSelectedTenant={setSelectedTenant}
          />
          <Buttons style={{ marginTop: 24 }}>
            <Button typeColor={'disable'} onClick={() => setEdit(false)}>
              Voltar
            </Button>
            <Button typeColor={'primary'} onClick={() => sendUpdateTenant()}>
              Salvar
            </Button>
          </Buttons>
        </div>
      )}
    </Box>
  );
}
