import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Box,
  NavigationContainer,
  Buttons,
  Button,
  BackButton,
  BorderContainer,
  LoadingScreen,
} from 'components/Shared';
import { Titles } from 'components/Titles';
import { GetContract } from './Services';
import { VerificationContract } from 'components/Negotiations/NegotationDetails/Components/CreateContract/Components/VerificationContract';
import { Signatarius } from 'components/Negotiations/NegotationDetails/Components/CreateContract/Components/Signatarius';
import { ContractProfile } from './Components/ContractProfile';
import {
  CancelContractRoute,
  SendSignatarius,
} from 'components/Negotiations/NegotationDetails/Components/CreateContract/Components/FinishContract/Services';
import { SignersList } from './Components/SignersList';
import { FaTrash } from 'react-icons/fa';

export function ContractDetails() {
  const [renderPage, setRenderPage] = useState('details');
  const { contractID, negotiationID } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { data, isLoading, refetch } = GetContract(contractID);
  const { negotiationData } = location.state;
  const [selectedCoSignatarius, setSelectedCoSignatarius] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);

  useEffect(() => {
    if (data?.signers?.length > 0) {
      setSelectedCoSignatarius(data.signers);
    }
  }, [data]);

  async function cancelContract() {
    try {
      await CancelContractRoute(contractID);
      toast.success('Contrato cancelado com sucesso!');
      history.push(`/dashboard/negociacao-detalhes/${negotiationID}`);
    } catch (error) {
      toast.error('Erro ao cancelar contrato');
    }
  }

  async function SendSelectedSignatarius() {
    setLoadingScreen(true);
    try {
      await SendSignatarius(contractID, selectedCoSignatarius);
      toast.success('Assinantes adicionados com sucesso!');
      refetch();
      setLoadingScreen(false);
    } catch (error) {
      toast.error('Erro ao adicionar assinantes');
      setLoadingScreen(false);
    }
  }

  const handlePage = {
    details: !isLoading && (
      <VerificationContract
        negotiationData={negotiationData}
        selectedCoSignatarius={selectedCoSignatarius}
        getContractsInformation={data}
      />
    ),
    signers: !isLoading && (
      <BorderContainer>
        <SignersList SignersData={data.signers} />
        <Signatarius
          negotiationData={negotiationData}
          typeContract={data.type}
          setSelectedCoSignatarius={setSelectedCoSignatarius}
          selectedCoSignatarius={selectedCoSignatarius}
        >
          <Buttons>
            <Button type="button" typeColor="secondary">
              Voltar
            </Button>
            <Button
              typeColor="primary"
              type="button"
              onClick={() => SendSelectedSignatarius()}
            >
              Enviar
            </Button>
          </Buttons>
        </Signatarius>
      </BorderContainer>
    ),
  };

  return (
    <>
      {!isLoading && (
        <Box>
          <Buttons style={{ marginBottom: 24 }}>
            <BackButton
              to={`/dashboard/negociacao-detalhes/${negotiationID}`}
            />
          </Buttons>
          <Titles title="Detalhes do Contrato" />
          <NavigationContainer
            style={{ marginBottom: 24 }}
            column={1}
            active={renderPage}
            setValue={setRenderPage}
            options={[
              {
                label: 'Detalhes',
                value: 'details',
                filled: true,
              },
              {
                label: 'Assinantes',
                value: 'signers',
                filled: true,
              },
            ]}
          >
            <ContractProfile contract={data} />
          </NavigationContainer>
          <Buttons style={{ marginBottom: 24 }}>
            <Button
              style={{ marginLeft: 'auto' }}
              typeColor="cancel"
              onClick={() => cancelContract()}
            >
              <FaTrash />
              Cancelar
            </Button>
          </Buttons>
          {loadingScreen ? <LoadingScreen /> : handlePage[renderPage]}
        </Box>
      )}
    </>
  );
}
