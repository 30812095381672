import styled from 'styled-components';

export const InputOption = styled.div`
  display: flex;
  padding: 16px 0px;
  align-items: center;
  > input {
    margin-right: 8px;
  }
  > label {
    font-size: 16px;
    margin-right: 16px;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 8px;
  > p {
    font-size: 16px;
  }
`;

export const SelectStyle = {
  input: () => ({
    maxHeight: 40,
    // width: window.innerWidth <= 600 ? 90 : 308,
    flex: 1,
    border: 'border: 1px solid rgba(0,0,0,0.3)',
  }),
  control: () => ({
    backgroundColor: '#f2f5f8',
    border: '1px solid #dfe0e3',
    borderRadius: 8,
    transition: 'all 100ms',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    cursor: 'default',
    alignItems: 'center',
  }),
  valueContainer: () => ({
    paddingTop: 0.5,
    paddingBottom: 0.3,
    paddingLeft: 10,
    paddingRight: 10,
  }),
  container: () => ({
    position: 'relative',
    width: '100%',
  }),
};
