export const CoRepresentativeTenant = [
  { value: 'guarantor', label: 'Avalista' },
  { value: 'surety', label: 'Fiador' },
  { value: 'co_responsible', label: 'Co-Responsável' },
  { value: 'legal_representative', label: 'Representante Legal' },
  { value: 'spouse', label: 'Conjugue' },
  { value: 'procurator', label: 'Procurador' },
  { value: 'other', label: 'Outro' },
];

export const CoRepresentativeOwner = [
  { value: 'legal_representative', label: 'Representante Legal' },
  { value: 'realtor_property', label: 'Corretor de imóveis' },
  { value: 'co_responsible', label: 'Co-Responsável' },
  { value: 'spouse', label: 'Conjugue' },
  { value: 'procurator', label: 'Procurador' },
  { value: 'other', label: 'Outro' },
];
