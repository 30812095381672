import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { signInRequest } from '../store/modules/auth/actions';

import {
  Container,
  Content,
  Logo,
  Form,
  Link,
  Footer,
} from '../components/unauth';
import { InputForm, Button } from 'components/Shared';

export function Main() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Logo />
          <InputForm
            name="email"
            id="email"
            title="E-mail"
            placeholder="Digite o E-mail"
            autoComplete="on"
            required={true}
          />
          <InputForm
            name="password"
            title="Senha"
            type="password"
            placeholder="Senha"
            required={true}
          />
          <Button style={{ width: '100%' }} typeColor="primary" type="submit">
            {loading ? 'Carregando...' : 'ENTRAR'}
          </Button>
          <Link to="/forgot-password">Esqueceu sua senha?</Link>
        </Form>
      </Content>
      <Footer>
        © {new Date().getFullYear()} Wizzer Imóveis - Brasil - Todos os direitos
        reservados
      </Footer>
    </Container>
  );
}
