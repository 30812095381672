import React, { useState, useEffect } from 'react';

import { cpfMask, cnpjMask } from 'util/maskForms';
import { DocumentsTranlate } from 'util/Translate/documents';
import { MdPublishedWithChanges } from 'react-icons/md';
import { CardSendDocument } from './Components/CardSendDocument';
import { SendDeleteDocumentsPhoto } from 'components/User/CreateUser/UserDocuments/Services';
import { AddDocumentFile } from './Services';
import {
  ContentVerificationData,
  ContentGroup,
  ContentData,
  Buttons,
} from 'components/Shared';
import { ContentImage } from './styles';
import { toast } from 'react-toastify';

export function DocumentsSummary({ setDocuments, documents, title, children }) {
  const [file, setFile] = useState([]);
  const hasCpfOrCnpj = documents.find(
    document => document.type === 'cpf' || document.type === 'cnpj'
  );

  const hasWeddingCertificate = documents.find(
    document => document.type === 'wedding_certificate'
  );

  function findPhotoDocuments(documents) {
    let allFiles = [];
    documents.map(document => {
      if (document.file) {
        const findFiles = document.file.filter(file => file);
        return allFiles.push(findFiles);
      }
    });
    return allFiles.flat();
  }

  useEffect(() => {
    if (documents) {
      setFile(findPhotoDocuments(documents));
    }
  }, [documents]);

  async function changeFiles(files, fileId, order) {
    const fileMap = documents.map(document => {
      if (document.file) {
        const file = document.file?.filter(file => file.id === fileId);
        return {
          ...document,
          file,
        };
      }
    });
    const newArrayOfFiles = fileMap.filter(file => file);

    try {
      await SendDeleteDocumentsPhoto(fileId);
      await AddDocumentFile(files, order, newArrayOfFiles[0].id);

      toast.success('Arquivo trocado com sucesso');
    } catch (err) {
      toast.error('Erro ao trocar arquivo');
    }
  }

  return (
    <ContentVerificationData>
      <h1>{title}</h1>
      <ContentGroup>
        <div>
          <ContentData>
            {documents?.map(document => {
              function handleDocumentType(document) {
                if (document.type === 'cpf' || document.type === 'cnpj') {
                  if (document.document.replace(/\D/g, '').length <= 11) {
                    return cpfMask(document.document);
                  } else {
                    return cnpjMask(document.document);
                  }
                } else {
                  return document.document;
                }
              }

              return (
                <p key={document.type}>
                  {DocumentsTranlate[document.type]}:{' '}
                  {handleDocumentType(document)}
                </p>
              );
            })}
          </ContentData>
        </div>
      </ContentGroup>
      <ContentGroup>
        <div>
          <Buttons style={{ justifyContent: 'start', gap: 16 }}>
            {hasCpfOrCnpj && (
              <CardSendDocument
                setDocuments={setDocuments}
                documents={documents}
                document={hasCpfOrCnpj}
              />
            )}
            {hasWeddingCertificate && (
              <CardSendDocument
                setDocuments={setDocuments}
                documents={documents}
                document={hasWeddingCertificate}
              />
            )}
          </Buttons>
        </div>
      </ContentGroup>
      <ContentGroup>
        <div style={{ display: 'grid' }}>
          {file.length > 0 && (
            <ContentData className="fileContent">
              {file.map((file, index) => {
                function handleFileOrUrl(file) {
                  if (typeof file === 'string') {
                    return file;
                  }
                  if (file instanceof File) {
                    return URL.createObjectURL(file);
                  }
                }
                return (
                  <ContentImage key={index}>
                    <img src={handleFileOrUrl(file.file)} />
                    <label htmlFor="changeFile" className="changeImage">
                      <MdPublishedWithChanges />
                    </label>
                    <input
                      id="changeFile"
                      type="file"
                      accept=".jpg, .jpeg, .png, application/pdf"
                      onChange={e =>
                        changeFiles(e.target.files, file.id, file.order)
                      }
                    />
                  </ContentImage>
                );
              })}
            </ContentData>
          )}
        </div>
      </ContentGroup>
      {children}
    </ContentVerificationData>
  );
}
