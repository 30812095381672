import React, { useRef } from 'react';

import {
  Box,
  LoadingScreen,
  Buttons,
  Button,
  BackButton,
} from 'components/Shared';
import { Titles } from 'components/Titles';
import { InnerList } from 'components/Tenancy/Components/InnerList';

import { AdminFeeForm } from '../Components/AdminFeeForm';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ChangeFeeStatus, GetAdmFeeDetails, PutEditAdmFee } from '../Services';

export function EditAdminFee() {
  const formRef = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const { data, isLoading, refetch } = GetAdmFeeDetails({ id });

  async function updateStatus() {
    try {
      await ChangeFeeStatus(id);
      refetch();
    } catch (error) {
      console.log(error);
    }
  }

  async function postAdminFee(body) {
    try {
      await PutEditAdmFee(body, id);
      toast.success('Taxa atualizada com sucesso!');
      history.push('/dashboard/lista-de-taxa-de-administracao');
    } catch (err) {
      toast.error('Erro ao editar taxa!');
    }
  }

  return (
    <Box>
      <Titles title="Editar Garantia" subtitle="Editar Garantia" />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <BackButton
            to="/dashboard/lista-de-taxa-de-administracao"
            style={{ marginBottom: 16 }}
          />
          <InnerList type="fee" data={data} handleStatus={updateStatus} />
          <AdminFeeForm
            type="edit"
            postAdminFee={postAdminFee}
            formRef={formRef}
            data={data}
          >
            <Buttons>
              <Button
                typeColor="primary"
                type="submit"
                style={{ marginLeft: 'auto' }}
              >
                Atualizar
              </Button>
            </Buttons>
          </AdminFeeForm>
        </>
      )}
    </Box>
  );
}
