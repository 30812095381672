import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px;
  background: ${props => props.theme.backgrounds.secondary};

  @media screen and (max-width: 990px) {
    padding: 24px;
  }
`;
