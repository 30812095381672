export const termOfCommunion = [
  {
    value: 'Comunhão Universal de Bens',
    label: 'Comunhão Universal de Bens',
  },
  {
    value: 'Comunhão Parcial de Bens',
    label: 'Comunhão Parcial de Bens',
  },
  {
    value: 'Separação Convencional',
    label: 'Separação Convencional',
  },
  {
    value: 'Separação Obrigatória',
    label: 'Separação Obrigatória',
  },
  {
    value: 'Participação Final nos Aquestos',
    label: 'Participação Final nos Aquestos',
  },
  {
    value: 'Pacto Antenupcial',
    label: 'Pacto Antenupcial',
  },
];
