import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  display: flex;
  position: relative;
  justify-content: center;
  > img {
    max-width: 600px;
  }
  > h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 24px;
    letter-spacing: 2px;
  }
`;
