import React, { useRef } from 'react';

import { Content } from 'components/Shared';

import { AdditionalInputs } from '../Components/AdditionalInputs';

export function AdditionalForm({ sendData, additionalData, children }) {
  const formRef = useRef(null);

  async function handleSubmit(data) {
    sendData(data);
  }

  return (
    <Content ref={formRef} onSubmit={handleSubmit}>
      <AdditionalInputs formRef={formRef} additionalData={additionalData} />
      {children}
    </Content>
  );
}
