import styled from 'styled-components';
import { Form } from '@unform/web';

export const ContainerUploadList = styled(Form)`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: fixed;
  flex-direction: column;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.backgrounds.secondary};
  padding: 16px 24px;
  border: ${({ theme }) => theme.border};
  border-radius: 16px;
  gap: 16px;
  z-index: 1000;

  .container_zone {
  }
  .container_list {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    min-width: 260px;
    max-width: 360px;
    height: 260px;
    width: 100%;
    overflow-y: auto;
    list-style: none;
    justify-content: start;

    li {
      display: flex;
      flex: 0 1 auto;
      img {
        width: 72px;
        height: 72px;
        object-fit: fill;
        border-radius: 8px;
      }
    }
  }
`;
