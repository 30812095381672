import React, { useRef } from 'react';

import { Content } from 'components/Shared';
import { SpecificationsInputs } from '../Components/SpecificationsInputs';

import { specificationsValidation } from './validation';
import { validationError } from 'validations/forms';

export function SpecificationsForm({ children, sendData, specificationsData }) {
  const formRef = useRef(null);

  async function handleSubmit(data) {
    try {
      await specificationsValidation.validate(data, {
        abortEarly: false,
      });
      await sendData(data);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof validationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Content ref={formRef} onSubmit={handleSubmit}>
      <SpecificationsInputs
        formRef={formRef}
        specificationsData={specificationsData}
      />
      {children}
    </Content>
  );
}
