import React, { useState } from 'react';
import ImageThumbnail from '../thumb/index';
import ImageViewer from '../imageview/index';
import { ImagesThumbs } from './styles';

const images = [
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212884&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212886&authkey=%21AHRsVkLqsdubDC8&height=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212887&authkey=%21AHRsVkLqsdubDC8&height=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212888&authkey=%21AHRsVkLqsdubDC8&height=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212889&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212891&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212890&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212892&authkey=%21AHRsVkLqsdubDC8&height=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212893&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212894&authkey=%21AHRsVkLqsdubDC8&height=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212895&authkey=%21AHRsVkLqsdubDC8&height=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212896&authkey=%21AHRsVkLqsdubDC8&height=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212897&authkey=%21AHRsVkLqsdubDC8&height=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212898&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212900&authkey=%21AHRsVkLqsdubDC8&width=768&height=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212899&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212903&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212901&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212902&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212905&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212904&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212906&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212907&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212908&authkey=%21AHRsVkLqsdubDC8&width=1024',
  'https://onedrive.live.com/embed?resid=D8A4CF97B06C2185%212883&authkey=%21AHRsVkLqsdubDC8&width=1024',
];

const ImageGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImageViewer = image => {
    setSelectedImage(image);
  };

  const closeImageViewer = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <ImagesThumbs>
        {images.map((image, index) => (
          <ImageThumbnail
            key={index}
            image={image}
            onClick={() => openImageViewer(image)}
          />
        ))}
      </ImagesThumbs>
      {selectedImage && (
        <ImageViewer
          image={selectedImage}
          onClose={closeImageViewer}
          images={images}
        />
      )}
    </div>
  );
};

export default ImageGallery;
