import styled from 'styled-components';

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  > h1 {
    text-align: center;
  }
  > p {
    font-size: 16px;
  }
`;
