export function brokerOptions(data) {
  const { profile, address, documents, bank } = data;
  const options = [
    {
      value: 'essencial',
      label: 'Dados do Usuário',
      filled: profile,
    },
    { value: 'address', label: 'Endereço', filled: address },
    { value: 'documents', label: 'Documentos', filled: documents },
    { value: 'bankAccount', label: 'Conta Bancária', filled: bank },
    { value: 'property', label: 'Imóveis', filled: true },
    { value: 'client', label: 'Clientes', filled: true },
  ];

  return options;
}

export function tenantOptions(data) {
  const { address, documents, profile } = data;

  function profileAlert(documents) {
    if (documents) {
      const document = documents.find(doc => doc.type === 'cpf');
      if (document) {
        return profile;
      } else {
        return true;
      }
    } else {
      return profile;
    }
  }

  const options = [
    {
      value: 'essencial',
      label: 'Dados do Usuário',
      filled: profileAlert(documents),
    },
    { value: 'address', label: 'Endereço', filled: address },
    { value: 'documents', label: 'Documentos', filled: documents },
    { value: 'responsibles', label: 'Responsáveis', filled: true },
  ];
  return options;
}

export function ownerOptions(data) {
  const { profile, address, documents, bank } = data;

  function profileAlert(documents) {
    if (documents) {
      const document = documents.find(doc => doc.type === 'cpf');
      if (document) {
        return profile;
      } else {
        return true;
      }
    } else {
      return profile;
    }
  }

  const options = [
    {
      value: 'essencial',
      label: 'Dados do Usuário',
      filled: profileAlert(documents),
    },
    { value: 'address', label: 'Endereço', filled: address },
    { value: 'documents', label: 'Documentos', filled: documents },
    { value: 'bankAccount', label: 'Conta Bancária', filled: bank },
    { value: 'property', label: 'Imóveis', filled: true },
    { value: 'responsibles', label: 'Responsáveis', filled: true },
  ];
  return options;
}
