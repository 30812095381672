import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import { MenuContainer, ListContainer } from '../../../Styles/styles';
import { Button, LoadingScreen, Search } from 'components/Shared';
import { UserListSelect } from '../../User/UserListSelect';
import { UserSelected } from '../../User/UserSelected';

import { TenantAdminQuery, TenantBrokerQuery } from './Services';

export function NegotiationDataTenant({ selectedTenant, setSelectedTenant }) {
  const { profile } = useSelector(state => state.user);
  const [tenantCurrentParams, setTenantCurrentParams] = useState({
    search: null,
    filter: 'tenant',
  });

  const { tenantData, tenantIsLoading } = profile.isAdmin
    ? TenantAdminQuery(tenantCurrentParams)
    : TenantBrokerQuery(tenantCurrentParams, profile.id);

  const debounceTenant = AwesomeDebouncePromise(updateQueryTenant, 500);
  function updateQueryTenant(event) {
    setTenantCurrentParams({
      ...tenantCurrentParams,
      search: event,
    });
  }

  return (
    <>
      {!selectedTenant && (
        <>
          <MenuContainer>
            <p>
              <strong>Pesquisar um Inquilino</strong>
            </p>
          </MenuContainer>
          <Search
            style={{
              marginLeft: 0,
              marginBottom: 8,
              margintop: 16,
              maxWidth: 'none',
            }}
            id="search"
            type="text"
            placeholder="Pesquisar Inquilino"
            onChange={e => {
              debounceTenant(e.target.value);
            }}
          />

          {tenantData && !selectedTenant && (
            <ListContainer>
              {tenantIsLoading ? (
                <LoadingScreen />
              ) : (
                <>
                  {tenantData?.data.map(user => (
                    <UserListSelect
                      key={user.id}
                      user={user}
                      setSelectedTenant={setSelectedTenant}
                      selectedTenant={selectedTenant}
                    />
                  ))}
                </>
              )}
            </ListContainer>
          )}
        </>
      )}
      {selectedTenant && (
        <>
          <MenuContainer>
            <p>
              <strong>Quem vai alugar:</strong>
            </p>
            <Button
              typeColor="clean"
              onClick={() => {
                setSelectedTenant('');
              }}
            >
              Trocar
            </Button>
          </MenuContainer>
          <UserSelected user={selectedTenant} />
        </>
      )}
    </>
  );
}
