import React from 'react';
import useFetch from 'services/ReactQuery';
import { LoadingScreen, ErrorScreen } from 'components/Shared';

export function GetUsers({ params, profile }) {
  const { isAdmin } = profile;

  const queries = () => {
    if (params.filter !== 'property') {
      const { data } = useFetch.Get({
        url: isAdmin ? '/users' : '/users/broker',
        params: {
          ...params,
        },
        enabled: params.filter !== null,
      });

      return data;
    } else {
      const { data } = useFetch.Get({
        url: '/properties',
        params: {
          ...params,
        },
        enabled: params.filter !== null,
      });

      return data;
    }
  };

  const checkValueSelect = queries()?.data.map(data => {
    if (params.filter !== 'property') {
      return {
        value: data.id,
        label: `${data.name + ' ' + data.lastName} - ${data.email}`,
      };
    } else {
      return {
        value: data.id,
        label: `${data.address.street}, ${data.address.number} - ${data.address?.complement}/ ${data.address?.city}`,
      };
    }
  });

  return {
    data: checkValueSelect || [],
  };
}

export function GetNegotiation(params) {
  const { data, isLoading, isError } = useFetch.Get({
    url: '/negotiations',
    params: {
      page: params.page,
      perPage: params.perPage,
      order: params.order,
      status: params?.status,
      ...params.filter,
    },
    refetchOnWindowFocus: false,
  });

  return {
    negotiationData: data?.data,
    total: data?.total,
    isLoading:
      (isLoading && <LoadingScreen />) || (isError && <ErrorScreen />) || null,
  };
}
