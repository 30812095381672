import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { validationError } from '../../validations/forms';

import Button from '../Button';

import { Container, Content, Avatar } from './styles';
import { InputForm } from 'components/Shared';

export function Settings() {
  const formRef = useRef(null);
  const { id, fullname, role, avatar, initials } = useSelector(
    state => state.user.profile
  );

  function handleRole(role) {
    const roles = {
      owner: 'Proprietário',
      tenant: 'Inquilino',
      broker: 'Corretor',
      admin: 'Administrador',
    };
    return Object.prototype.hasOwnProperty.call(roles, role) ? roles[role] : '';
  }

  async function handleSubmit(data, { reset }) {
    formRef.current.setErrors({});

    try {
      const schema = Yup.object().shape({
        password: Yup.string()
          .min(6, 'Mínimo 6 caracteres')
          .required('A senha é obrigatória'),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'As senhas são diferentes')
          .required('O confirmar senha é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await axios.put(`/user/broker/${id}`, {
        password: data.password,
      });

      reset();

      toast.success(`Senha atualizada com sucesso!`);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof validationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else {
        toast.error('Houve um erro ao processar sua solicitação.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }

  return (
    <Container>
      <Content ref={formRef} onSubmit={handleSubmit}>
        <Avatar>
          {avatar ? (
            <img src={avatar} alt="Avatar" />
          ) : (
            <label>{initials}</label>
          )}
          <div>
            <h2>{fullname}</h2>
            <p>{handleRole(role)}</p>
          </div>
        </Avatar>
        <strong>Altere sua senha</strong>
        <InputForm
          title="Nova senha"
          type="password"
          placeholder="Senha"
          id="password"
          name="password"
        />
        <InputForm
          title="Confirmar a nova senha"
          type="password"
          placeholder="Confirmação de Senha"
          id="password_confirmation"
          name="password_confirmation"
        />
        <Button type="submit">Salvar Alterações</Button>
      </Content>
    </Container>
  );
}
