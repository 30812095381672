import React from 'react';

import { Container } from './styles';
export function DarkScreen({ close, setClose }) {
  return (
    <Container
      className={`${close ? 'open' : ''}`}
      onClick={() => setClose(!close)}
    />
  );
}
