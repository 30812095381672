import React from 'react';
import useFetch from 'services/ReactQuery';
import { LoadingScreen, ErrorScreen } from 'components/Shared';

export function GetProperty(propertyID) {
  const { data, isLoading, isError } = useFetch.Get({
    url: `/property/${propertyID}`,
  });

  return {
    data: data,
    isLoading:
      (isLoading && <LoadingScreen />) || (isError && <ErrorScreen />) || null,
  };
}

export async function updateStatus(propertyId, newStatus) {
  const { mutateAsync } = useFetch.Put();

  mutateAsync({
    url: `/property/status/${propertyId}`,
    body: {
      status: newStatus,
    },
    key: `/property/details/${propertyId}`,
  });
}
