import React, { useCallback, useState, useEffect } from 'react';

import { validationError } from 'validations/forms';
import {
  Content,
  InputForm,
  InputCalendar,
  InputSelect,
  InputText,
} from 'components/Shared';

import {
  informationValidationBroker,
  informationValidationOwner,
  informationValidationTenant,
} from './validation';

import { maritualStatus, hasPets, sons } from './Options';

export function UserProfileForm({
  userRole,
  dataProfile,
  formRef,
  children,
  sendData,
}) {
  const [showAnimal, setShowAnimal] = useState(false);
  const filedData = dataProfile;

  const ValidationPerRole = {
    broker: informationValidationBroker,
    owner: informationValidationOwner,
    tenant: informationValidationTenant,
  };

  useEffect(() => {
    if (userRole === 'broker') {
      formRef.current.setFieldValue('profile.career', 'Corretor');
    }
    if (filedData) {
      formRef.current.setData(filedData);
    }
  }, [userRole, formRef, filedData]);

  const handleSubmit = useCallback(
    async data => {
      try {
        await ValidationPerRole[userRole].validate(data, {
          abortEarly: false,
        });
        await sendData(data);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [sendData, formRef, userRole, ValidationPerRole]
  );

  return (
    <>
      <Content ref={formRef} onSubmit={handleSubmit}>
        <div>
          <span>
            <InputForm
              name="profile.career"
              title="Profissão"
              placeholder="Digite a profissão"
              required={true}
            />
          </span>
          <span>
            <InputCalendar
              name="profile.birthdayDate"
              title="Data de Nascimento"
              placeholder="Coloque a data de nascimento"
              required={true}
            />
          </span>
        </div>
        <div>
          <span>
            <InputText
              name="profile.description"
              title="Descrição"
              placeholder="Adicione uma descrição a esse cliente"
              required={false}
              maxLength={240}
            />
          </span>
        </div>
        <div className={userRole === 'broker' ? 'half' : ''}>
          <span>
            <InputSelect
              name="profile.maritualStatus"
              placeholder="Selecione o Estado Civil"
              title="Estado Civil"
              required={true}
              options={maritualStatus}
            />
          </span>
          <span
            style={{ display: `${userRole === 'broker' ? 'none' : 'block'}` }}
          >
            <InputForm
              name="profile.nationality"
              title="Nacionalidade"
              placeholder="Digite a nacionalidade"
              required={false}
            />
          </span>
        </div>
        <div style={{ display: `${userRole === 'tenant' ? 'flex' : 'none'}` }}>
          <span>
            <InputSelect
              name="profile.numberOfChildren"
              defaultValue={{ value: 0, label: 'Nenhum' }}
              title="Quantidade de Filhos"
              placeholder="Selecione a quantidade de filhos"
              required={true}
              options={sons}
            />
          </span>
          <span>
            <InputSelect
              name="profile.animal"
              title="Tem animais"
              defaultValue={{ value: false, label: 'Não' }}
              options={hasPets}
              onChange={e => setShowAnimal(e)}
              required={true}
            />
          </span>
        </div>
        <div
          style={{
            display: `${
              showAnimal.value && userRole === 'tenant' ? 'block' : 'none'
            }`,
          }}
        >
          <span>
            <InputText
              name="profile.animalDescription"
              title="Descrição dos animais"
              placeholder="Adicione uma descrição dos animais"
              required={false}
              maxLength={240}
            />
          </span>
        </div>
        {children}
      </Content>
    </>
  );
}
