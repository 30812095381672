import React from 'react';

import { MdAddToPhotos } from 'react-icons/md';
import { CardFile } from './styles';
import { toast } from 'react-toastify';
import { AddDocumentFile } from '../../Services';
import { DocumentsTranlate } from 'util/Translate/documents';

export function CardSendDocument({ document, setDocuments, documents }) {
  async function handleSendFile(e) {
    const files = e.target.files[0];
    const fileLength = document.file ? document.file.length : 0;
    try {
      const response = await AddDocumentFile(files, fileLength, document.id);
      setDocuments(
        documents.map(doc =>
          doc.id === document.id
            ? {
                ...doc,
                file: (doc.file ? doc.file : []).concat(response.data),
              }
            : doc
        )
      );
      toast.success('Arquivo enviado com sucesso');
    } catch (err) {
      console.log(err);
      toast.error('Erro ao enviar arquivo');
    }
  }

  return (
    <CardFile htmlFor="sendFile">
      <span>
        <MdAddToPhotos />
        <p>{DocumentsTranlate[document.type]}</p>
      </span>
      <input
        id="sendFile"
        type="file"
        accept=".jpg, .jpeg, .png, application/pdf"
        onChange={e => handleSendFile(e)}
      />
    </CardFile>
  );
}
