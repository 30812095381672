import styled from 'styled-components';

export const FilterContainer = styled.nav`
  display: flex;
  align-items: flex-start;
  padding: 4px;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.backgrounds.secondary};
  border-radius: 8px;

  @media screen and (max-width: 990px) {
    display: ${({ open }) => (open ? 'none' : 'flex')};
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: ${props => props.theme.border};
    border-radius: 8px;
  }

  .active {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    color: rgb(5, 94, 255);
    background: ${props => props.theme.container.background};
  }
`;

export const FilterButton = styled.button`
  padding: 8px;
  color: #aaaaaa;
  min-width: 140px;
  max-width: 180px;
  padding: 12px;
  background: ${props => props.theme.backgrounds.secondary};
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  @media screen and (max-width: 990px) {
    display: none;
    width: 100%;
    box-shadow: none;
    margin: 2px 0;
  }
`;

export const SelectedFilter = styled.div`
  display: none;
  align-self: center;
  width: 50vh;
  margin: 0 8px;
  max-width: 280px;
  .field-select {
    flex: 1;
  }
  @media screen and (max-width: 990px) {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;
