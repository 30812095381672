import {
  Button,
  Buttons,
  Content,
  InputSelect,
  LoadingScreen,
  ModalScreen,
} from 'components/Shared';
import React, { useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import useFetch from 'services/ReactQuery';
import { getAllStateCityByIBGE } from '../../services';

export function ModalForm({ open, setOpen, citiesByState }) {
  const formAddCity = useRef(null);
  const { mutateAsync: createCity } = useFetch.Post();
  const { data: options, isLoading } = getAllStateCityByIBGE(citiesByState.id);

  const handleSubmit = useCallback(async data => {
    try {
      await createCity({
        url: `/cities/searchable/${data.city}`,
        key: '/states',
      });
      setOpen(false);
      toast.success('Cidade adicionada com sucesso!');
    } catch (error) {
      toast.error('Erro ao adicionar a cidade!');
    }
  }, []);

  return (
    <ModalScreen open={open} setOpen={setOpen}>
      {isLoading ? (
        <div>
          <LoadingScreen />
        </div>
      ) : (
        <div>
          <h1>Adicionar uma Cidade em {citiesByState.sigla}</h1>
          <Content ref={formAddCity} onSubmit={handleSubmit}>
            <div>
              <span>
                <InputSelect
                  name="city"
                  options={options}
                  placeholder="Selecione uma cidade"
                />
              </span>
            </div>
            <Buttons>
              <Button
                typeColor="secondary"
                type="button"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </Button>
              <Button type="submit" typeColor="primary">
                Salvar
              </Button>
            </Buttons>
          </Content>
        </div>
      )}
    </ModalScreen>
  );
}
