import React from 'react';
import ReactDOM from 'react-dom/client';
import ApiService from './services/service.api';
import TokenService from './services/service.token';
import App from './App';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <App />
  </QueryClientProvider>
);

if (TokenService.getAccessToken()) {
  ApiService.setHeaderAuthorization();
  ApiService.mount401Interceptor();
}
