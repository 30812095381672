import styled from 'styled-components';

export const ContainerSignersList = styled.div`
  display: flex;
  flex-direction: column;
  > h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  > ul {
    display: flex;
    gap: 16px;
    list-style: none;
  }
`;

export const Signer = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 0.2 1 auto;
  > p {
    font-size: 16px;
  }
`;
