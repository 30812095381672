import styled from 'styled-components';

export const ButtonContainer = styled.button`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 8px;
  background: #055eff;
  color: #f0f0f0;
  bottom: 25px;
  right: 4px;
`;
