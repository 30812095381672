import React, { useRef } from 'react';
import { Titles } from 'components/Titles';
import {
  Box,
  BackButton,
  Buttons,
  Button,
  LinkButton,
} from 'components/Shared';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { AdminFeeForm } from '../Components/AdminFeeForm';
import { SendAdmFeeForm } from '../Services';

export function CreateAdminFee() {
  const formRef = useRef(null);
  const history = useHistory();

  async function postAdminFee(data) {
    try {
      await SendAdmFeeForm(data);
      toast.success('Taxa criada com sucesso!');
      history.push('/dashboard/lista-de-taxa-de-administracao');
    } catch (err) {
      toast.error('Erro ao enviar taxa!');
    }
  }

  return (
    <Box>
      <Titles
        title="Taxa Administrativo"
        subtitle="Lista de Taxa Administrativa"
      />
      <BackButton
        to="/dashboard/lista-de-taxa-de-administracao"
        style={{ marginBottom: 16 }}
      />
      <AdminFeeForm formRef={formRef} postAdminFee={postAdminFee} type="create">
        <Buttons>
          <LinkButton
            to="/dashboard/lista-de-taxa-de-administracao"
            typeColor="secondary"
          >
            Cancelar
          </LinkButton>
          <Button typeColor="primary" type="submit">
            Criar Taxa
          </Button>
        </Buttons>
      </AdminFeeForm>
    </Box>
  );
}
