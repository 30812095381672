import jarvis from 'util/jarvis';
import { useQuery, useQueryClient, useMutation } from 'react-query';

export function GetNegotiation(negotiationID) {
  const { data, isLoading } = useQuery(
    ['/GetNegotiation', negotiationID],
    async () => {
      const response = await jarvis.get(`/negotiation/${negotiationID}`);
      return response.data;
    }
  );

  return { isLoading, data };
}

const UpdateNegotiationStatusRoute = data => {
  return jarvis.put(`/negotiation/${data.id}/status`, data.data);
};

export function UpdateNegotiationStatus() {
  const clientQuery = useQueryClient();
  return useMutation(UpdateNegotiationStatusRoute, {
    onSuccess: data => {
      const newData = data.data;
      clientQuery.invalidateQueries(['GetNegotiation', `${newData.id}`]);
      clientQuery.setQueryData(['/GetNegotiation', `${newData.id}`], newData);
    },
  });
}

export const createRentalRoute = async id => {
  const { data } = await jarvis.post('/rental', {
    negotiationId: +id,
  });

  return data;
};
