import React from 'react';

import {
  ContentVerificationData,
  FilterContainer,
  Filter,
  Search,
} from 'components/Shared';
import { UserInformation } from 'components/User/Components/UserList/UserInformation';

export function ClientSummary() {
  const user = {
    id: 1,
    name: 'Alexandre',
    lastName: 'O Grande',
    initials: 'AG',
    role: 'owner',
  };

  return (
    <ContentVerificationData>
      <h1>Clientes atrelados</h1>
      <FilterContainer>
        <Filter
          filter={[
            { value: '', label: 'Todos' },
            { value: 'tenant', label: 'Inquilino' },
            { value: 'owner', label: 'Proprietário' },
          ]}
          active=""
        />
        <Search placeholder="Buscar" />
      </FilterContainer>
      <div>
        <UserInformation user={user} />
        <UserInformation user={user} />
        <UserInformation user={user} />
      </div>
    </ContentVerificationData>
  );
}
