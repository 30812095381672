import axios from 'axios'
import { toast } from 'react-toastify'

export async function resetPasswordRoute(data) {
    try {
        await axios.put('/user/reset/password', data)
        toast.success('Senha alterada com sucesso')
    } catch (err) {
        toast.error('Erro ao alterar a senha')
    }
}