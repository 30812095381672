import jarvis from 'util/jarvis';

export async function loadOptionsUserBroker(search, loadedOptions, { page }) {
  const response = await jarvis.get(
    `/users?page=${page}&filter=broker&search=${search}`
  );

  const checkUsers = response.data.data.map(broker => ({
    value: broker.id,
    label: `${broker.name + ' ' + broker.lastName} - ${broker.email}`,
  }));

  const hasMore = page < response.data.lastPage;

  return {
    options: checkUsers,
    hasMore: hasMore,
    additional: {
      page: page + 1,
    },
  };
}
