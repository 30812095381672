import styled from 'styled-components';

export const ContentVerificationData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  border: ${props => props.theme.border};
  gap: 24px;
  > h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  > h2 {
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
`;

export const ContentData = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    font-size: 16px;
    margin-bottom: 8px;
    word-break: break-all;
  }
  > img {
    width: 50%;
    border: ${props => props.theme.border};
    border-radius: 8px;
    @media (max-width: 990px) {
      width: 100%;
    }
  }

  &.fileContent {
    display: flex;
    flex-direction: row;
    gap: 16px;
    overflow: auto;
    border: ${props => props.theme.border};
    border-radius: 8px;
    padding: 8px;
    &::-webkit-scrollbar {
      padding: 0 15px;
      width: 2px;
      border-radius: 4px;
      background-color: #f2f2f2;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
    }
  }
`;

export const ContentList = styled.li`
  display: flex;
  flex-direction: column;
  > p {
    font-size: 16px;
    margin-bottom: 8px;
    word-break: break-all;
  }
`;

export const ContentGroup = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  > div,
  ul {
    flex: 1 1 30rem;
    > h2 {
      font-size: 16px;
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }
`;

export const LineSpace = styled.div`
  width: 100%;
  height: 1px;
  background: #dfe0e3;
  margin: 24px 0;
`;
