import jarvis from 'util/jarvis';

// const crypto = require('crypto');
// const ENC_KEY = '6fa979f20126cb08aa645a8f495f6d85';
// const IV = '8955aw14aw14aw14';

// function encrypt(value) {
//   let cipher = crypto.createCipheriv('aes-256-cbc', ENC_KEY, IV);
//   let encrypted = cipher.update(value, 'utf8', 'base64');
//   encrypted += cipher.final('base64');
//   return encrypted;
// }

export async function SendBankData(Body, id) {
  // const newEncripytedBody = Object.keys(Body).reduce((acc, key) => {
  //   if (Body[key] !== undefined) {
  //     return { ...acc, [key]: encrypt(Body[key]) };
  //   }
  //   return acc;
  // }, {});

  const { data } = await jarvis.post(`/user/recipient/${id}`, Body);
  return data;
}
