import React, { useState } from 'react';

import { Buttons, Button, Box } from 'components/Shared';
import { PropertySummary } from 'components/Negotiations/NegotationDetails/Components/Summary/PropertySummary';

import { SpecificationsProperty } from '../../SpecificationsProperty';
import { EditPropertyForm } from 'components/Property/EditProperty';
import { LoadingScreen } from 'components/Shared';

export function PropertyData({ propertyData }) {
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const propertyId = propertyData.id;

  return (
    <Box>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {edit ? (
            <EditPropertyForm
              propertyData={propertyData}
              propertyId={propertyId}
              setEdit={setEdit}
              setIsLoading={setIsLoading}
            >
              {' '}
              <Buttons>
                <Button
                  typeColor="disable"
                  type="button"
                  onClick={() => setEdit(!edit)}
                >
                  Cancelar
                </Button>
                <Button type="submit" typeColor="primary">
                  Confirmar e Finalizar
                </Button>
              </Buttons>
            </EditPropertyForm>
          ) : (
            <PropertySummary property={propertyData} goSite={true}>
              {propertyData.specification && (
                <SpecificationsProperty
                  specification={propertyData.specification}
                />
              )}
              <Buttons style={{ marginTop: 24 }}>
                <Button
                  type="button"
                  typeColor="primary"
                  onClick={() => setEdit(!edit)}
                >
                  Editar
                </Button>
              </Buttons>
            </PropertySummary>
          )}
        </>
      )}
    </Box>
  );
}
