import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: ${props => props.theme.backgrounds.secondary};
  color: #055eff;
  margin: 0 6px;

  ${({ disabled }) => {
    if (disabled) {
      return `
        pointer-events: none;
        background:  ${props => props.theme.container.background};
        color: #aaaaaa;
      `;
    }
  }}
`;

export const MoreRecord = styled.div`
  display: flex;
  padding: 4px;
  align-self: flex-end;

  > div {
    background: #999;
    width: 4px;
    height: 4px;
    border-radius: 8px;

    & + div {
      margin-left: 4px;
    }
  }

  ${({ has }) => {
    if (has) {
      return `
        display: flex;
      `;
    }
  }}
`;

export const TripleDot = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  > div {
    width: 6px;
    height: 6px;
    margin-bottom: 2px;
    border-radius: 50%;
    align-self: flex-end;
    background-color: #aaaaaa;

    & + div {
      margin-left: 4px;
    }
  }
`;

export const PageButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: ${props => props.theme.backgrounds.secondary};

  color: #aaaaaa;
  margin: 0 6px;

  ${({ active }) => {
    if (active) {
      return `
        pointer-events: none;
        color: #055eff;
        background: ${props => props.theme.backgrounds.primary};
      `;
    }
  }}
`;
