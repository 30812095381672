import { userCreation } from '../Validation';
import { validationError } from 'validations/forms';

export async function handleSubmit(data, profile, sendData, formRef) {
  const phones = data.phones?.filter(item => {
    return item.phone !== undefined;
  });

  const documents = data.documents
    ?.filter(item => {
      return item.document !== undefined;
    })
    .map(item => {
      return { document: item.document, type: 'creci' };
    });
  data = {
    ...data,
    documents: documents,
    phones: phones,
  };
  if (profile.isBroker) {
    data = {
      ...data,
      responsibleCustomerId: profile.id,
      responsibleCustomerRole: 'broker',
    };
  }
  if (data?.responsibleCustomerId && profile.isAdmin) {
    data = {
      ...data,
      responsibleCustomerRole: 'broker',
    };
  }
  try {
    await userCreation.validate(data, {
      abortEarly: false,
    });

    await sendData(data);
  } catch (err) {
    const validationErrors = {};
    if (err instanceof validationError) {
      err.inner.forEach(error => {
        if (error.path === 'phones') {
          validationErrors['phones[0].phone'] = error.message;
        } else {
          validationErrors[error.path] = error.message;
        }
      });
      formRef.current.setErrors(validationErrors);
    }
  }
}
