import React, { useCallback, useEffect, useState } from 'react';

import {
  DropzoneDocument,
  InputDocument,
  InputForm,
  Content,
} from 'components/Shared';
import { documentsValidation } from './Validation';
import { validationError } from 'validations/forms';
import { handleDocumentsData } from 'util/Context/User/Form/Document';

export function UserDocumentsForm({
  dataDocuments,
  children,
  formRef,
  sendData,
}) {
  const filedData = dataDocuments;
  const [typeDocRGorRC, setTypeDocRGorRC] = useState();
  const [moreFile, setMoreFile] = useState(false);

  useEffect(() => {
    if (filedData) {
      formRef.current.setData(filedData);
      const findDocument = filedData.documents.find(doc => {
        return doc?.type === 'cpf' || doc?.type === 'cnpj';
      });
      if (findDocument) {
        setTypeDocRGorRC(findDocument.document);
      }
    }
  }, [filedData, formRef]);

  const handleSubmit = useCallback(
    async data => {
      const { documents } = data;
      const newData = handleDocumentsData(documents);
      try {
        await documentsValidation.validate(data, {
          abortEarly: false,
        });
        await sendData(newData);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [formRef, sendData]
  );

  function documentTypeOf(documentValue) {
    if (documentValue)
      return documentValue.replace(/\D/g, '').length <= 11 ? 'cpf' : 'cnpj';
    else return 'cpf';
  }

  return (
    <>
      <Content ref={formRef} onSubmit={handleSubmit}>
        <div>
          <span>
            <InputDocument
              name="documents[0].document"
              title="Documento (CPF/CNPJ)"
              placeholder="Digite o documento"
              onInput={e => setTypeDocRGorRC(e.target.value)}
              required={true}
            />
          </span>
          <span>
            <InputForm
              name="documents[1].document"
              title={
                documentTypeOf(typeDocRGorRC) === 'cpf'
                  ? 'Documento RG'
                  : 'Documento Inscrição Comercial'
              }
              placeholder={
                documentTypeOf(typeDocRGorRC) === 'cpf'
                  ? 'Digite o RG'
                  : 'Digite o Inscrição Comercial'
              }
              required={true}
            />
          </span>
        </div>
        <div>
          <span>
            <p>
              Para certificar os seus dados pessoais envie as fotos do{' '}
              <strong>
                {`${
                  documentTypeOf(typeDocRGorRC) === 'cpf'
                    ? 'RG ou CNH'
                    : 'Contrato Social'
                }`}
              </strong>
              :
            </p>
          </span>
        </div>
        <div>
          <span>
            <DropzoneDocument
              photoType="front"
              name="documents[0].file[0].file"
            />
          </span>
          {documentTypeOf(typeDocRGorRC) !== 'cnpj' && (
            <span>
              <DropzoneDocument
                photoType="back"
                name="documents[0].file[1].file"
              />
            </span>
          )}
        </div>
        <div>
          <span>
            <input
              id="moreFiles"
              type="checkbox"
              onChange={() => setMoreFile(!moreFile)}
            />
            <label style={{ marginLeft: 8, fontSize: 16 }} htmlFor="moreFiles">
              Mais fotos
            </label>
          </span>
        </div>
        {moreFile && (
          <div>
            <span>
              <DropzoneDocument
                photoType="front"
                name="documents[0].file[2].file"
              />
            </span>
            <span>
              <DropzoneDocument
                photoType="back"
                name="documents[0].file[3].file"
              />
            </span>
          </div>
        )}
        {children}
      </Content>
    </>
  );
}
