import React, { useRef, useCallback } from 'react';
import { Button, Buttons, DropzoneFiles } from 'components/Shared';
import { ContainerUploadList } from './styles';
import { BlackScreen } from 'components/Shared/Modal/styles';
import { useState } from 'react';

export function UploadList({ serviceFn }) {
  const [open, setOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const form = useRef(null);

  const handleSubmit = useCallback(async data => {
    if (data.photos.length > 0) {
      for (const photo of data.photos) {
        await serviceFn(photo);
      }
    }
  }, []);

  return (
    <>
      <Button typeColor="primary" onClick={() => setOpen(!open)}>
        Enviar Fotos
      </Button>
      <BlackScreen open={open} onClick={() => setOpen(!open)} />
      <ContainerUploadList open={open} ref={form} onSubmit={handleSubmit}>
        <div className="container_zone">
          <DropzoneFiles name="photos" setImageList={setImageList} />
        </div>
        <ul className="container_list">
          {imageList.map(image => (
            <li key={image}>
              <img src={image} alt="Imagem" />
            </li>
          ))}
        </ul>
        <Buttons>
          <Button
            typeColor="secondary"
            type="button"
            onClick={() => setOpen(!open)}
          >
            Cancelar
          </Button>
          <Button typeColor="primary" type="submit">
            Enviar
          </Button>
        </Buttons>
      </ContainerUploadList>
    </>
  );
}
