import styled from 'styled-components';

export const ContainerBrokerCongrats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
  justify-content: center;
  align-items: center;

  img {
    max-width: 360px;
  }

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }
`;
