import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { LoadingScreen, ProgressBar } from 'components/Shared';
import { Titles } from 'components/Titles';

import {
  Proprietary,
  Type,
  Address,
  Values,
  Specifications,
  Additional,
} from './Template';

import { Container } from './styles';

import { handleSubmit } from './Context';
import { sendData } from './Services';

export function PropertyForm() {
  const [currentProgress, setCurrentProgress] = useState(0);
  const [ownerData, setOwnerData] = useState();
  const [typeData, setTypeData] = useState();
  const [dataAddress, setDataAddress] = useState();
  const [valuesData, setValuesData] = useState();
  const [specificationsData, setSpecificationsData] = useState();
  const [additionalData, setAdditionalData] = useState();
  const [loading, setLoading] = useState(false);
  const { role, id } = useSelector(state => state.user.profile);
  const history = useHistory();

  async function submit(data) {
    setLoading(true);
    try {
      console.log(data);
      setAdditionalData(data);
      const propertyFormData = handleSubmit(
        ownerData,
        typeData,
        dataAddress,
        valuesData,
        specificationsData,
        additionalData,
        role
      );

      const propertyOrFail = await sendData(propertyFormData);

      if (role === 'admin') {
        history.push(`/dashboard/imoveis/detalhes/${propertyOrFail.id}`);
      } else {
        history.push(`/dashboard/congrats/broker/${propertyOrFail.id}`);
      }
    } catch (err) {
      setLoading(false);
      toast.error('Houve um erro ao processar sua solicitação.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const progress = props =>
    ({
      0: (
        <Proprietary
          setCurrentProgress={setCurrentProgress}
          currentProgress={currentProgress}
          setOwnerData={setOwnerData}
          ownerData={ownerData}
          role={role}
          id={id}
        />
      ),
      1: (
        <Type
          setCurrentProgress={setCurrentProgress}
          currentProgress={currentProgress}
          setTypeData={setTypeData}
          typeData={typeData}
        />
      ),
      2: (
        <Address
          setCurrentProgress={setCurrentProgress}
          currentProgress={currentProgress}
          setDataAddress={setDataAddress}
          dataAddress={dataAddress}
        />
      ),
      3: (
        <Values
          setCurrentProgress={setCurrentProgress}
          currentProgress={currentProgress}
          setValuesData={setValuesData}
          valuesData={valuesData}
        />
      ),
      4: (
        <Specifications
          setCurrentProgress={setCurrentProgress}
          currentProgress={currentProgress}
          setSpecificationsData={setSpecificationsData}
          specificationsData={specificationsData}
        />
      ),
      5: (
        <Additional
          setCurrentProgress={setCurrentProgress}
          currentProgress={currentProgress}
          setAdditionalData={setAdditionalData}
          additionalData={additionalData}
          submitValues={submit}
        />
      ),
    }[props] || null);

  return (
    <>
      <Container>
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <Titles title={'Imóveis'} subtitle={'Adicionar Imóvel'} />
            <ProgressBar
              role={{
                value: 'owner',
                label: 'Proprietario',
              }}
              currentProgress={currentProgress}
              setCurrentProgress={setCurrentProgress}
              unlock={true}
              progress={[
                {
                  title: 'Vamos começar!',
                  subtitle: 'Quem é o proprietário do imóvel?',
                  category: 'Proprietário',
                  roles: {
                    broker: true,
                    owner: true,
                    tenant: false,
                  },
                  filled: ownerData,
                },
                {
                  title: 'Informações do Imóvel',
                  subtitle: 'Qual o tipo do imóvel?',
                  category: 'Tipo',
                  roles: {
                    broker: true,
                    owner: true,
                    tenant: false,
                  },
                  filled: typeData,
                },
                {
                  title: 'Endereço',
                  subtitle: 'Onde fica este imóvel?',
                  category: 'Endereço',
                  roles: {
                    broker: true,
                    owner: true,
                    tenant: false,
                  },
                  filled: dataAddress,
                },
                {
                  title: 'Valores',
                  subtitle: 'Quais valores compõem o aluguel?',
                  category: 'Valores',
                  roles: {
                    broker: true,
                    owner: true,
                    tenant: false,
                  },
                  filled: valuesData,
                },
                {
                  title: 'Especificações',
                  subtitle: 'Quais são as especificações do imóvel?',
                  category: 'Especificações',
                  roles: {
                    broker: true,
                    owner: true,
                    tenant: false,
                  },
                  filled: specificationsData,
                },
                {
                  title: 'Informações Adicionais',
                  subtitle: 'Quais são as especificações do imóvel?',
                  category: 'Adicionais',
                  roles: {
                    broker: true,
                    owner: true,
                    tenant: false,
                  },
                  filled: additionalData,
                },
              ]}
            />
            {progress(currentProgress)}
          </>
        )}
      </Container>
    </>
  );
}
