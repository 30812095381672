import React from 'react';

import { InputNumberSuffix, InputText } from 'components/Shared';

export function ContractInformation() {
  return (
    <>
      <div>
        <span>
          <InputNumberSuffix
            name="negotiation.periodMinChargeFine"
            placeholder="Multa referente á meses"
            title="Multa referente à:"
            suffix=" alugueis"
          />
        </span>
        <span>
          <InputNumberSuffix
            name="negotiation.periodContractualFine"
            placeholder="Período mínimo de meses"
            title="Período mínimo para não cobrar a multa:"
            suffix=" meses"
          />
        </span>
      </div>
      <div>
        <span>
          <InputText name="negotiation.description" title="Observação:" />
        </span>
      </div>
    </>
  );
}
