import React, { useCallback, useEffect, useState } from 'react';

import {
  Content,
  TitleContainer,
  InputForm,
  InputNumber,
  InputSelect,
} from 'components/Shared';

import { handleZipcode } from 'services/service.address';
import { statesOption } from './Option';

import { validationError } from 'validations/forms';
import { AddressAgentValidation } from './Validation';

export function AddressAgentForm({
  getAddressAgent,
  sendDataForm,
  formRef,
  children,
}) {
  const [selectedState, setSelectedState] = useState();
  const [disabledState, setDisabledState] = useState(false);

  const getAddress = useCallback(
    async data => {
      const response = await handleZipcode(data);
      if (response) {
        const data = {
          ...response,
        };
        formRef.current.setData(data);
        setDisabledState(true);
      }
    },
    [formRef]
  );

  useEffect(() => {
    if (getAddressAgent) {
      formRef.current.setData(getAddressAgent);
    }
  }, [getAddressAgent, formRef]);

  const handleSubmit = useCallback(
    async data => {
      try {
        await AddressAgentValidation.validate(data, {
          abortEarly: false,
        });

        await sendDataForm(data);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
        }
        formRef.current.setErrors(validationErrors);
      }
    },
    [sendDataForm, formRef]
  );

  return (
    <Content ref={formRef} onSubmit={handleSubmit}>
      <TitleContainer>
        <h2>Endereço do Responsável</h2>
      </TitleContainer>
      <div>
        <span>
          <InputForm
            name="address.zipcode"
            maxLength="9"
            title="CEP"
            placeholder="Digite o CEP"
            required={true}
            onKeyUp={zipcodeInput => getAddress(zipcodeInput.target.value)}
          />
        </span>
        <span>
          <InputForm
            name="address.street"
            title="Rua"
            placeholder="Digite a rua"
            required={true}
          />
        </span>
      </div>
      <div>
        <span>
          <InputForm
            name="address.district"
            title="Bairro"
            placeholder="Digite o bairro"
            required={true}
          />
        </span>
        <span>
          <InputNumber
            name="address.number"
            title="Número"
            placeholder="Digite o número"
            required={true}
          />
        </span>
      </div>
      <div>
        <span>
          <InputForm
            name="address.city"
            title="Cidade"
            placeholder="Digite a cidade"
            required={true}
            disabled={disabledState}
          />
        </span>
        <span>
          <InputSelect
            name="address.state"
            value={selectedState}
            onChange={e => setSelectedState(e)}
            title="Estado"
            placeholder="Coloque o estado"
            required={true}
            options={statesOption}
            isDisabled={disabledState}
          />
        </span>
      </div>
      <div className="half">
        <span>
          <InputForm
            name={`address.complement`}
            title="Complemento"
            placeholder="Digite o complemento"
            required={false}
          />
        </span>
      </div>
      {children}
    </Content>
  );
}
