import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 29px #5e5e5e, 0px 0px 9.23704px rgba(127, 128, 131, 0.0242963), 0px 0px 2.36296px rgba(127, 128, 131, 0.0157037);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 32px;
  z-index: 99999;
  background: #fff;

  > div {
    width: 100%;
    display: flex;
    margin-top: auto;
    box-shadow: 0px 0px 2px #5e5e5e;
    border-radius: 4px;

    > button {
      margin: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      background: #001438;
      border-radius: 4px;
      border: 0;
      padding: 12px;
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      margin-top: 16px;
      transition: background-color 0.2s;

      &:hover {
        opacity: 0.9;
      }
    }

    > button + button {
      margin-left: 0;
    }
  }

  @media screen and (min-width: 600px) {
    width: 672px;
    height: 480px;
  }
`

export const Image = styled.img`
  width: 320px;
  height: 320px;
  border-radius: 160px;
  box-shadow: 0px 0px 29px #5e5e5e, 0px 0px 9.23704px rgba(127, 128, 131, 0.0242963), 0px 0px 2.36296px rgba(127, 128, 131, 0.0157037);
  transition: all 300ms ease-out;

  ${({ rotate }) => {
      return `
          transform: rotate(${rotate}deg);
        `
    }
  }
`
