import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-end;
  padding-bottom: 36px;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      > button {
        padding: 16px;
        background: #055eff;
        border: none;
        border-radius: 8px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .landscape {
    max-width: 580px;
    height: auto;
    object-fit: scale-down;
  }
  .portrait {
    max-width: 300px;
    object-fit: scale-down;
  }
  .square {
    max-width: 400px;
    object-fit: scale-down;
  }

  @meida (max-width: 990px) {
    .landscape,
    .portrait,
    .square {
      max-width: 300px;
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;
