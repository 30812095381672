import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;

  .error {
    border: 2px dashed #d8010e;
    background: #f9c1c4;
  }

  button {
    display: flex;
    width: 100%;
    height: -webkit-fill-available;
    padding: 36px 24px;
    flex-direction: column;
    border: 2px dashed #055eff;
    border-radius: 8px;
    background: ${props => props.theme.backgrounds.secondary};
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    align-items: center;

    h2 {
      font-size: 24px;
      font-weight: 500;
    }

    p {
      font-size: 16px;
      font-weight: 400;
    }

    img {
      width: 68px;
      height: auto;
      margin: 8px 0;
    }
  }

  input {
    display: none;
  }

  @media (max-width: 990px) {
    > button {
      padding: 16px;
    }
  }
`;

export const PhotoName = styled.div`
  display: flex;
  padding: 8px 16px;
  background: #fff;
  border-radius: 8px;
  border: ${props => props.theme.border};
  margin: 14px 0;
  align-items: left;
  justify-content: center;
`;

export const ErrorBallon = styled.div`
  display: flex;
  position: absolute;
  top: -24px;
  right: -16px;
  div {
    position: relative;
    padding: 24px 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    background: #d8010e;

    p {
      color: #fff;
      font-size: 16px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;

      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid #d8010e;
    }
  }

  @media (max-width: 990px) {
    right: -24px;
    > div {
      padding: 16px;

      &:after {
        border-right: 8px solid #d8010e;
        border-left: 0;
      }

      > p {
        font-size: 14px;
      }
    }
  }
`;
