import { cpfMask, cnpjMask } from 'util/maskForms';

export default function UseDocument(document) {
  if (document.type === 'cpf' || document.type === 'cnpj') {
    if (document.document.replace(/\D/g, '').length <= 11) {
      return cpfMask(document.document);
    } else {
      return cnpjMask(document.document);
    }
  } else {
    return document.document;
  }
}
