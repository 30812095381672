import React from 'react';

import { GetUsers } from 'components/User/UsersList/Services';
import { useSelector } from 'react-redux';
import { UserList } from './Components/UserList';
import { LoadingScreen, EmptyList } from 'components/Shared';

import { Container } from './styles';

export function LastUsers() {
  const { profile } = useSelector(state => state.user);
  const currentParams = {
    page: 1,
    perPage: 10,
    order: 'DESC',
    search: null,
    filter: null,
  };
  const { data, isLoading } = GetUsers({ currentParams, profile });

  return (
    <Container>
      <h2>Usuários:</h2>
      <div>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            {data.data.length > 0 ? (
              <>
                {data.data.map(user => {
                  return <UserList key={user.id} user={user} />;
                })}
              </>
            ) : (
              <EmptyList />
            )}
          </>
        )}
      </div>
    </Container>
  );
}
