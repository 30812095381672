import React, { useEffect, useState } from 'react';

import { NegotiationList } from './Components/NegotiationList';
import { LoadingScreen, EmptyList } from 'components/Shared';
import { useSelector } from 'react-redux';

import { Container } from './styles';
import { GetNegotiation } from 'components/Negotiations/NegotiationList/Services';

export function LastNegotiations() {
  const { profile } = useSelector(state => state.user);
  const [params, setParams] = useState({
    page: 1,
    perPage: 10,
    order: 'DESC',
    search: null,
    filter: null,
    status: 'analyzing',
  });

  useEffect(() => {
    if (profile.isBroker) {
      setParams({
        ...params,
        filter: { ...params.filter, brokerId: profile.id },
      });
    }
  }, []);

  const { negotiationData, isLoading } = GetNegotiation(params);
  return (
    <Container>
      <h2>Negociações:</h2>
      <div>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            {negotiationData.length > 0 ? (
              <>
                {negotiationData.map(negotiation => {
                  return (
                    <NegotiationList
                      key={negotiation.id}
                      negotiation={negotiation}
                    />
                  );
                })}
              </>
            ) : (
              <EmptyList>Não há registros</EmptyList>
            )}
          </>
        )}
      </div>
    </Container>
  );
}
