import {
  MdPersonAdd,
  MdPersonSearch,
  MdHome,
  MdHomeWork,
  MdSettings,
} from 'react-icons/md';
import { FaHandsHelping, FaKey } from 'react-icons/fa';

const addClients = {
  path: '/dashboard/clientes/criar',
  name: 'Adcionar Cliente',
  icon: MdPersonAdd,
};

const listClients = {
  path: '/dashboard/clientes',
  name: 'Clientes',
  icon: MdPersonSearch,
};

const addProperty = {
  path: '/dashboard/registrar-imovel',
  name: 'Adicionar Imóvel',
  icon: MdHome,
};

const listProperties = {
  path: '/dashboard/imoveis',
  name: 'Imóveis',
  icon: MdHomeWork,
};

const negotiationList = {
  path: '/dashboard/negociacoes',
  name: 'Negociações',
  icon: FaHandsHelping,
};

const rentals = {
  path: '/dashboard/alugueis',
  name: 'Aluguéis',
  icon: FaKey,
};

const settings = {
  path: '/dashboard/configuracoes',
  name: 'Configurações',
  icon: MdSettings,
};

export const painelByRole = role =>
  ({
    admin: [
      addClients,
      listClients,
      addProperty,
      listProperties,
      negotiationList,
      rentals,
      settings,
    ],
    broker: [
      addClients,
      listClients,
      addProperty,
      listProperties,
      negotiationList,
      settings,
    ],
  }[role] || [settings]);
