import React from 'react'

import {
  Container,
  Title,
  Subtitle
} from './styles'

export function Titles ({ title, subtitle }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  )
}
