import useFetch from 'services/ReactQuery';

export function getAllStateCityByIBGE(id) {
  const { data, isLoading, isError } = useFetch.Get({
    url: `/cities/${id}`,
    enabled: id === undefined ? false : true,
  });

  const optionsValue = data?.map(item => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  return {
    data: optionsValue,
    isLoading,
    isError,
  };
}
