import produce from 'immer';

const INITIAL_STATE = {
  user: {
    profile: {
      id: 0,
      name: '',
      lastName: '',
      email: '',
      role: '',
      isAdmin: false,
      isBroker: false,
      isOwner: false,
      isGuest: false,
    },
  },
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@auth/SIGN_IN_SUCCESS':
      return produce(state, draft => {
        draft.profile = action.payload.user;
      });
    case '@auth/LOGOUT':
      return produce(state, draft => {
        draft.profile = state;
      });
    default:
      return state;
  }
}
