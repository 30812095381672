import React, { useRef } from 'react';

import {
  Box,
  LoadingScreen,
  Buttons,
  Button,
  BackButton,
} from 'components/Shared';
import { Titles } from 'components/Titles';
import { InnerList } from 'components/Tenancy/Components/InnerList';
import {
  GetGuaranteeDetails,
  ChangeGuaranteeStatus,
  PutEditGuarantee,
} from '../Services';
import { GuaranteeForm } from '../Components/GuaranteeForm';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export function EditGuarantee() {
  const formRef = useRef(null);
  const { id } = useParams();
  const { data, isLoading, refetch } = GetGuaranteeDetails({ id });
  const history = useHistory();

  async function updateStatus() {
    try {
      await ChangeGuaranteeStatus(id);
      refetch();
    } catch (error) {
      console.log(error);
    }
  }

  async function putGuarantee(body) {
    try {
      await PutEditGuarantee(body, id);
      toast.success('Garantia atualizada com sucesso!');
      history.push('/dashboard/lista-de-garantias');
    } catch (err) {
      toast.error('Erro ao editar garantia!');
    }
  }

  return (
    <Box>
      <Titles title="Editar Garantia" subtitle="Editar Garantia" />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <BackButton
            to="/dashboard/lista-de-garantias"
            style={{ marginBottom: 16 }}
          />
          <InnerList type="guarantee" data={data} handleStatus={updateStatus} />
          <GuaranteeForm
            type="edit"
            postGuarantee={putGuarantee}
            formRef={formRef}
            data={data}
          >
            <Buttons>
              <Button
                typeColor="primary"
                type="submit"
                style={{ marginLeft: 'auto' }}
              >
                Atualizar
              </Button>
            </Buttons>
          </GuaranteeForm>
        </>
      )}
    </Box>
  );
}
