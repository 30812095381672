import styled from 'styled-components';

export const FilterContainerNoBorder = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: space-between;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    justify-content: auto;
  }
`;
