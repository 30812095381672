import React, { useState } from 'react';
import {
  BorderContainer,
  Box,
  LoadingScreen,
  NavigationContainer,
  Buttons,
  Button,
  ConfirmExecution,
} from 'components/Shared';
import { Titles } from 'components/Titles';
import { RentalProperty } from './components/RentalProperty';
import { useParams } from 'react-router-dom';
import useFetch from 'services/ReactQuery';
import { StatusTranslate } from 'util/Translate/Status';
import { RentalDetails as RentalInformation } from './template/RentalDetails';
import { Payments } from './template/Payments';
import { FaTrash } from 'react-icons/fa';
import { CyclesTemplate } from './template/CyclesTemplate';
import { toast } from 'react-toastify';
import { TransactionsTemplate } from './template/TransactionsTemplate';

export function RentalDetails() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [renderPage, setRenderPage] = useState('negotiation');
  const { mutateAsync: cancelRental } = useFetch.Put();

  const { data, isLoading } = useFetch.Get({
    url: `/rental`,
    params: {
      id: Number(id),
    },
  });

  const SelectedPage = props =>
    ({
      negotiation: <RentalInformation rental={data.rental} />,
      payments: <Payments rental={data.rental} />,
      cycles: <CyclesTemplate />,
      transactions: <TransactionsTemplate />,
    }[props] || null);

  async function handleCancelRental() {
    setOpen(!open);
    try {
      await cancelRental({
        url: `/rental/cancel/${id}`,
        body: {
          advertiseProperty: true,
        },
        key: '/rental',
        params: {
          id: Number(id),
        },
      });

      toast.success('Aluguel cancelado com sucesso!');
    } catch (error) {
      toast.error('Erro ao cancelar aluguel!');
    }
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Titles
            title={`Aluguel ID: ${id}`}
            subtitle={`Status: ${StatusTranslate(data.rental.statusRental)}`}
          />
          <NavigationContainer
            column={1}
            active={renderPage}
            setValue={setRenderPage}
            options={[
              {
                label: 'Dados do Aluguel',
                value: 'negotiation',
                filled: true,
              },
              {
                label: 'Pagamentos',
                value: 'payments',
                filled: true,
              },
              {
                label: 'Ciclos',
                value: 'cycles',
                filled: true,
              },
              {
                label: 'Transações',
                value: 'transactions',
                filled: true,
              },
            ]}
          >
            <RentalProperty
              status={data.rental.statusRental}
              negotiation={data.rental.negotiation}
            />
          </NavigationContainer>
          <Buttons style={{ justifyContent: 'end' }}>
            {data.rental.statusRental !== 'canceled' && (
              <Button typeColor="cancel" onClick={() => setOpen(!open)}>
                <FaTrash />
                Cancelar
              </Button>
            )}
          </Buttons>
          <ConfirmExecution
            open={open}
            setOpen={setOpen}
            fn={handleCancelRental}
            validationkey={`Cancelar aluguel ${id}`}
          />
          <BorderContainer>{SelectedPage(renderPage)}</BorderContainer>
        </>
      )}
    </Box>
  );
}
