import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import { Titles } from 'components/Titles';
import {
  Filter,
  RecentlyButton,
  FilterContainer,
  Search,
  EmptyList,
} from 'components/Shared';

import { GetUsers } from './Services';

import { UserInformation } from '../Components/UserList/UserInformation';

import { Pagination } from 'components/Pagination';
import { AdminFilters, BrokerFilters } from './Options';

import { Container, InfoLabels, List } from './styles';

export function UsersList() {
  const { profile } = useSelector(state => state.user);

  const [currentParams, setCurrentParams] = useState({
    page: 1,
    perPage: 6,
    order: 'DESC',
    search: null,
    filter: null,
  });

  const { data, isLoading } = GetUsers({ currentParams, profile });

  async function handlePageChange({ selected }) {
    const newParams = { ...currentParams, page: selected };
    setCurrentParams(newParams);
  }

  function handleSearch(search) {
    const newParams = { ...currentParams, page: 1, search: search };
    setCurrentParams(newParams);
  }

  const handleSearchDebounced = AwesomeDebouncePromise(handleSearch, 400);

  function handleSearchChange(e) {
    handleSearchDebounced(e.target.value);
  }

  function handleFilter(filter) {
    if (profile.isAdmin) {
      const newParams = { ...currentParams, page: 1, filter: filter };
      setCurrentParams(newParams);
    } else {
      const newParams = {
        ...currentParams,
        page: 1,
        filter: { role: filter },
      };
      setCurrentParams(newParams);
    }
  }

  return (
    <Container>
      <Titles title="Clientes" subtitle="Lista de clientes" />

      <FilterContainer>
        <Filter
          filter={profile.isAdmin ? AdminFilters : BrokerFilters}
          active={
            profile.isAdmin
              ? currentParams.filter
              : currentParams.filter?.role || null
          }
          handleFilter={filter => {
            handleFilter(filter.value);
          }}
        ></Filter>
        <Search
          id="search"
          type="text"
          placeholder="Pesquisar cliente..."
          onChange={handleSearchChange}
        />
      </FilterContainer>

      <InfoLabels>
        <span>
          Total de contatos -{' '}
          <strong>
            {data?.total} contato{data?.total > 1 ? 's' : ''}
          </strong>
        </span>
        <RecentlyButton
          order={currentParams.order}
          onClick={() => {
            setCurrentParams({
              ...currentParams,
              page: 1,
              order: currentParams.order === 'DESC' ? 'ASC' : 'DESC',
            });
          }}
        />
      </InfoLabels>
      {isLoading || (
        <>
          <List>
            {data?.data.length > 0 ? (
              <>
                {data?.data.map(user => {
                  return <UserInformation key={user.id} user={user} />;
                })}
              </>
            ) : (
              <EmptyList />
            )}
          </List>

          <Pagination
            currentPage={currentParams.page || 1}
            pagesSize={currentParams.perPage}
            totalCount={data.total}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Container>
  );
}
