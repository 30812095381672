import React, { useCallback } from 'react';

import { Container } from './styles';
import { MdMenu } from 'react-icons/md';

export const Hamburger = ({ close, setClose }) => {
  const handleOpen = useCallback(() => {
    window.scroll(0, 0);
    if (window.innerWidth <= 990) {
      setClose(!close);
    }
  }, [close]);

  return (
    <Container close={true} onClick={() => handleOpen()}>
      <MdMenu />
    </Container>
  );
};
