import React from 'react';
import { Box } from 'components/Shared';
import { Titles } from 'components/Titles';
import { Unit } from './components/unit';
import { Container } from './styles';
import { useSelector } from 'react-redux';

export function InformationOwner({ data }) {
  const { isOwner } = useSelector(state => state.user.profile);
  return (
    <Box>
      <Titles title="Informações" subtitle="Documentos e plantas" />
      {isOwner && (
        <Container>
          <h2>Manual de Proprietário Wizzer Tower</h2>
          <a
            href="https://drive.google.com/uc?export=download&id=1lXhgXfIhgsvu04PYeI_VPVbKXwC5edPt"
            target="_blank"
            rel="noreferrer"
          >
            Manual do Proprietário
          </a>
        </Container>
      )}
      {
        <>
          {data.map((item, index) => {
            return <Unit key={index} data={item} />;
          })}
        </>
      }
    </Box>
  );
}
