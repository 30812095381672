import styled from 'styled-components';

export const InfoLabels = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  align-items: center;
  > span {
    margin-right: auto;
  }
`;

export const ContainerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 10px;

  a {
    transition: all 0.2s ease-in-out;
  }
  &:has(a:hover) a:not(a:hover) {
    opacity: 0.7;
    scale: 0.98;
  }
`;

export const SelectedFilter = styled.div`
  display: flex;
  align-self: center;
  width: 50vh;
  max-width: 280px;
  .field-select {
    flex: 1;
  }
  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;
