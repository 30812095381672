import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Content, InputCurrency } from 'components/Shared';
import { ButtonSubmitRental } from '../ButtonSubmitRental';

export function RentalAmount({ amount, sendRentalAmount }) {
  const [rentalAmount, setRentalAmount] = useState(amount);
  const rentalPrice = useRef(null);

  useEffect(() => {
    rentalPrice.current.setData({ rental: { amount: amount } });
  }, [rentalPrice, amount]);

  function handleAmountChange(event) {
    const amountString = event.replace(/\D/g, '');
    const amountNumber = Number(amountString);
    setRentalAmount(amountNumber);
  }

  const handleSubmit = useCallback(
    data => {
      sendRentalAmount(data);
    },
    [rentalPrice]
  );

  return (
    <Content ref={rentalPrice} onSubmit={handleSubmit}>
      <div style={{ position: 'relative' }}>
        <InputCurrency
          name="rental.amount"
          title="Valor do Aluguel"
          onInput={e => handleAmountChange(e.target.value)}
        />
        {rentalAmount !== amount && (
          <ButtonSubmitRental type="submit">Salvar</ButtonSubmitRental>
        )}
      </div>
    </Content>
  );
}
