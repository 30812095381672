import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 8px 16px;
  z-index: 11;
  background: ${props => props.theme.dashboard.background};
  width: 100%;
  max-width: 240px;
  overflow: auto;
  transition: all 0.3s ease-in-out;

  &.close {
    transform: unset;
  }

  &.minimize {
    max-width: 88px;
    h3 {
      display: none;
    }
    ul {
      gap: 8px;
      li {
        label {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    position: fixed;
    transform: translateX(-100%);
  }
`;
export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.dashboard.background};
  width: 260px;
  height: 100vh;
  position: fixed;
  z-index: 1;
  overflow: auto;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  border: 1px solid #055eff;
  border-radius: 8px;
  margin-bottom: 16px;

  > a {
    > img {
      height: 40px;
    }
  }
`;

export const MenuFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 24px;

  > img {
    width: 120px;
  }
`;

export const Line = styled.hr`
  display: block;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.14);
`;

export const List = styled.ul`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const ListTitle = styled.h3`
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.dashboard.color};
`;

export const Li = styled.li`
  width: 100%;
  position: relative;
  list-style: none;

  > .selected {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
`;

export const Navigation = styled(NavLink)`
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.dashboard.color};
  gap: 8px;
  padding: 16px;
  > label {
    cursor: pointer;
  }
  > svg {
    height: 24px;
    width: 24px;
    color: ${props => props.theme.dashboard.color};
  }
`;
