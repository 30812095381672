import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { IoIosLogIn, IoMdSettings } from 'react-icons/io';
import { MdDarkMode } from 'react-icons/md';
import { FiChevronDown } from 'react-icons/fi';
import { FaUserAlt } from 'react-icons/fa';

import { signOut } from 'store/modules/auth/actions';
import { useSettingsOnClickOutside } from 'hooks';

import { Container, Content } from './styles';

import { useThemeUpdate } from 'context/ContextTheme';

export function Profile() {
  const { name, avatar } = useSelector(state => state.user.profile);
  const dispath = useDispatch();
  const menu = useRef();
  const [isOpen, setIsOpen] = useState(false);
  useSettingsOnClickOutside(menu, () => setIsOpen(false));
  const changeTheme = useThemeUpdate();

  return (
    <Container ref={menu}>
      <button
        className="profile-container"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {avatar ? (
          <img className="profile-img" src={avatar} alt="Avatar" />
        ) : (
          <span className="alt-img">
            <FaUserAlt />
          </span>
        )}
        <p className="profile-name">{name}</p>
        <FiChevronDown className="profile-chevron" />
      </button>
      <Content className={isOpen ? 'show-modal' : 'off-modal'}>
        <button onClick={changeTheme}>
          <MdDarkMode />
          Mudar Tema
        </button>
        <NavLink
          onClick={() => setIsOpen(false)}
          to={'/dashboard/configuracoes'}
        >
          <IoMdSettings />
          Configurações
        </NavLink>
        <button onClick={() => dispath(signOut())}>
          <IoIosLogIn />
          Sair
        </button>
      </Content>
    </Container>
  );
}
