import { EssencialAgentValidation } from '../Validation';
import { validationError } from 'validations/forms';

export async function handleSubmit(data, sendDataForm, formRef) {
  const phones = data?.phones?.filter(item => {
    return item.phone !== undefined;
  });

  data = {
    ...data,
    phones: phones,
  };

  try {
    await EssencialAgentValidation.validate(data, {
      abortEarly: false,
    });

    await sendDataForm(data);
  } catch (err) {
    const validationErrors = {};
    if (err instanceof validationError) {
      err.inner.forEach(error => {
        if (error.path === 'phones') {
          validationErrors['phones[0].phone'] = error.message;
        } else {
          validationErrors[error.path] = error.message;
        }
      });
    }
    formRef.current.setErrors(validationErrors);
  }
}
