import styled from 'styled-components';

export const MenuFilter = styled.div`
  position: absolute;
  flex-direction: column;
  top: 104%;
  left: 0;
  border-radius: 8px;
  background: ${props => props.theme.backgrounds.secondary};
  border: ${props => props.theme.border};
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const MenuButton = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.primary};
  border: none;
  padding: 16px 24px;
  font-size: 16px;
  align-self: flex-start;
  &:hover {
    background: ${props => props.theme.container.background};
    color: ${props => props.theme.colors.primary};

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

export const FilterTags = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  background: ${props => props.theme.backgrounds.secondary};
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  transition: 0.5s ease;

  > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: #fe4853;
  }

  &:hover {
    background: #fe4853;
    color: #fff;

    > svg {
      color: #fff;
    }
  }
`;
