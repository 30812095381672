import React, { useCallback, useEffect, useState } from 'react';

import {
  Content,
  TitleContainer,
  InputForm,
  InputSelect,
  InputDocument,
  DropzoneDocument,
} from 'components/Shared';

import { termOfCommunion } from './Options';

import { SpouseAgentValidation, DocumentsAgentValidation } from './Validation';
import { validationError } from 'validations/forms';
import { handleDocumentsData } from 'util/Context/User/Form/Document';

export function DocumentsAgentForm({
  getDocuments,
  sendDataForm,
  formRef,
  children,
}) {
  const [moreFiles, setMoreFiles] = useState(false);
  useEffect(() => {
    if (getDocuments?.documents) {
      let documentsData = getDocuments.documents;
      let cpf = documentsData.find(item => item.type === 'cpf');
      let rg = documentsData.find(item => item.type === 'rg');
      let wedding_certificate = documentsData.find(
        item => item.type === 'wedding_certificate'
      );

      let documentsArray = {
        documents: [
          {
            ...cpf,
          },
          {
            ...rg,
          },
          {
            ...wedding_certificate,
          },
        ],
      };
      formRef.current.setData(documentsArray);
    }
  }, [getDocuments, formRef]);

  const handleSubmit = useCallback(
    async data => {
      const { documents } = data;
      const newData = handleDocumentsData(documents);
      try {
        if (getDocuments?.type === 'spouse') {
          await SpouseAgentValidation.validate(data, {
            abortEarly: false,
          });
        } else {
          await DocumentsAgentValidation.validate(data, {
            abortEarly: false,
          });
        }
        await sendDataForm(newData);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
        }
        formRef.current.setErrors(validationErrors);
      }
    },
    [sendDataForm, getDocuments]
  );

  return (
    <Content ref={formRef} onSubmit={handleSubmit}>
      <TitleContainer>
        <h2>Documentação do Responsável</h2>
      </TitleContainer>
      <div>
        <span>
          <InputDocument
            name="documents[0].document"
            title="Documento (CPF)"
            maxLength={14}
            placeholder="Digite o documento"
            required={true}
          />
        </span>
        <span>
          <InputForm
            name="documents[1].document"
            title="Documento (RG)"
            placeholder="Digite o documento"
            required={true}
          />
        </span>
      </div>
      <div>
        <span>
          <DropzoneDocument
            photoType="front"
            name="documents[0].file[0].file"
          />
        </span>
        <span style={{ marginLeft: 16 }}>
          <DropzoneDocument photoType="back" name="documents[0].file[1].file" />
        </span>
      </div>
      <div>
        <span>
          <input
            type="checkbox"
            id="moreFiles"
            onClick={() => setMoreFiles(!moreFiles)}
          />
          <label style={{ marginLeft: 8, fontSize: 16 }} htmlFor="moreFiles">
            Adicionar mais fotos
          </label>
        </span>
      </div>
      {moreFiles && (
        <div>
          <span>
            <DropzoneDocument
              photoType="front"
              name="documents[0].file[2].file"
            />
          </span>
          <span>
            <DropzoneDocument
              photoType="back"
              name="documents[0].file[3].file"
            />
          </span>
        </div>
      )}
      {getDocuments?.type === 'spouse' && (
        <>
          <TitleContainer>
            <h2>Contrato de Comunhão</h2>
          </TitleContainer>
          <span>
            <InputSelect
              name="documents[2].document"
              title="Regime de comunhão"
              placeholder="Selecione o regime de comunhão"
              required={true}
              options={termOfCommunion}
            />
          </span>
          <div>
            <span>
              <DropzoneDocument
                photoType="spouse"
                name="documents[2].file[0].file"
              />
            </span>
          </div>
        </>
      )}
      {children}
    </Content>
  );
}
