export default {
  title: 'dark',
  colors: {
    primary: '#F0F5FF',
    secondary: '#353d40',
  },
  backgrounds: {
    primary: '#055eff',
    secondary: '#5b626f',
    third: '#F0F5FF',
  },
  border: '1px solid #6a6c70',
  dashboard: {
    background: '#090f1b',
    color: '#F0F5FF',
  },
  body: '#242424',
  header: {
    background: 'linear-gradient(145deg, #0a101d, #080e18)',
    boxShadow: '5px 5px 6px #04060c,-5px -5px 6px #0e182a',
  },
  container: {
    background: '#2d3436',
    border: 'none',
  },
  label: {
    color: '#B7B9BD',
  },
  hover: {
    color: '#80ADFF',
  },
};
