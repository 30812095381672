import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { InputRadioStyled } from './styles';

function InputCheckBox({ title, options, name, required }) {
  const inputRef = useRef([]);
  const [selected, setSelected] = useState('');
  const { fieldName, registerField, error, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue(refs) {
        if (refs) {
          return refs.filter(ref => ref.checked).map(ref => ref.value);
        } else {
          return [];
        }
      },
      setValue(refs, value) {
        const item = refs.find(ref => ref.value === `${value}`);

        if (item) {
          item.checked = true;
          setSelected(item.value);
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <p style={{ fontWeight: 500 }}>{title}</p>
          {required && (
            <strong
              style={{ color: '#D8010E', fontWeight: 500, marginLeft: 8 }}
            >
              *
            </strong>
          )}
          {error && (
            <div style={{ marginLeft: 'auto' }}>
              <span role="img" aria-label="error" style={{ fontSize: 12 }}>
                ❌
              </span>
            </div>
          )}
        </div>
        <div
          style={{ width: '100%', display: 'flex', gap: 8, flexWrap: 'wrap' }}
        >
          {options.map((option, index) => {
            return (
              <InputRadioStyled key={option.value}>
                <input
                  ref={elRef => (inputRef.current[index] = elRef)}
                  type="checkbox"
                  name={fieldName}
                  value={option.value}
                  defaultChecked={defaultValue === option.value}
                  onChange={() => setSelected(`${option.value}`)}
                />
                <span
                  className={`${
                    selected === `${option.value}` ? 'isActive' : ''
                  }`}
                >
                  {option.label}
                </span>
              </InputRadioStyled>
            );
          })}
        </div>
        <span
          className="error"
          style={{
            color: '#D8010E',
            fontSize: '14px',
            visibility: error ? 'visible' : 'hidden',
            marginTop: 8,
            height: 14,
          }}
        >
          {error}
        </span>
      </div>
    </>
  );
}

InputCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputCheckBox;
