import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  background: #055eff;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  text-align: center;

  > strong {
    font-weight: 900;
    font-size: 16px;
  }
`;
