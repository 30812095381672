import React from 'react';
import { Box } from 'components/Shared';
import { Content } from './styles';
import { toast } from 'react-toastify';
import { Titles } from 'components/Titles';

export function InviteGuest() {
  function text() {
    navigator.clipboard.writeText('copyText.value');
    toast.success('Link copiado com sucesso!');
  }
  return (
    <Box>
      <Titles title="Convites" subtitle="Envie link ou mostre o QR Code" />
      <h1>Envie convites:</h1>
      <Content>
        <div>
          <p>Mostre o QR Code ou envie o link:</p>
          <img src="https://qrcg-free-editor.qr-code-generator.com/main/assets/images/websiteQRCode_noFrame.png" />
          <button onClick={text}>Gerar Link</button>
        </div>
      </Content>
    </Box>
  );
}
