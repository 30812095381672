export const maritualStatus = [
  {
    value: 'single',
    label: 'Solteiro',
  },
  {
    value: 'married',
    label: 'Casado',
  },
  {
    value: 'divorced',
    label: 'Divorciado',
  },
  {
    value: 'widowed',
    label: 'Viúvo',
  },
  {
    value: 'union',
    label: 'União Estável',
  },
];

export const sons = [
  {
    value: 0,
    label: 'Nenhum',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: 'Mais de 5',
  },
];

export const hasPets = [
  {
    value: true,
    label: 'Sim',
  },
  {
    value: false,
    label: 'Não',
  },
];
