import React from 'react';
import { MdArrowBack } from 'react-icons/md';

import { Button } from './styles';

export function BackButton({ ...rest }) {
  return (
    <Button type="button" className="back-button" {...rest}>
      <MdArrowBack />
      Voltar
    </Button>
  );
}
