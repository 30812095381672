import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  > a {
    padding: 16px 8px;
    background: #055eff;
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    color: #fff;
  }
`;
