import styled from 'styled-components';
import { Form } from '@unform/web';

export const BlackScreen = styled.span`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background: black;
  opacity: 60%;
  z-index: 999;
`;

export const FormContent = styled(Form)`
  width: 100%;
`;

export const Modal = styled.span`
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1000;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${props => props.theme.container.background};
  transform: translate(-50%, -50%);
  box-shadow: 0px 8px 16px 2px rgb(0 0 0 / 16%);

  > div {
    width: 60vh;
    padding: 36px;
    justify-content: center;
    align-items: center;
    text-align: center;
    > h1 {
      font-size: 24px;
      line-height: 140%;
      margin-bottom: 16px;
      font-weight: 700;
    }
    > p {
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 16px;
      text-align: left;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
    }
  }
  > img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 990px) {
    left: 0;
    top: 25%;
    width: 100%;
    transform: none;
  }
`;
