import styled from 'styled-components';

export const ImagesThumbs = styled.div`
  display: grid;
  gap: 16px;
  border: ${props => props.theme.border};
  border-radius: 8px;
  padding: 16px 24px;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-rows: 280px;
`;
