import React from 'react';

import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { phoneMask, telMask } from 'util/maskForms';
import {
  ContentVerificationData,
  ContentGroup,
  ContentData,
} from 'components/Negotiations/CreateNegotiation/VerificationData/styles';

export function EssencialAgentSummary({ agentInformation, children }) {
  const agentTypeTranslate = {
    guarantor: 'Fiador',
    'income-co-responsible': 'Co-responsável',
    spouse: 'Conjugue',
    procurator: 'Procurador',
    'realtor-property': 'Corretor de imóveis',
    other: 'Outro',
  };

  function formatDateData(date) {
    if (date) {
      const currentDate = new Date(date);
      return format(currentDate, "dd 'de' MMMM 'de' yyyy", { locale: pt });
    }
  }

  return (
    <ContentVerificationData>
      <h1>Dados Principais</h1>
      <ContentGroup>
        <div>
          <ContentData>
            <p>
              Nome: {agentInformation.name} {agentInformation.lastName}
            </p>
            <p>E-mail: {agentInformation.email}</p>
            {agentInformation.phones.map(phone => {
              function handlePhone(number) {
                if (number.replace(/\D/g, '').length < 11) {
                  return telMask(number);
                } else {
                  return phoneMask(number);
                }
              }
              return (
                <p key={phone.phone}>Telefone: {handlePhone(phone.phone)}</p>
              );
            })}

            <p>Tipo: {agentTypeTranslate[agentInformation.type]}</p>
          </ContentData>
        </div>
      </ContentGroup>
      <ContentGroup style={{ marginBottom: 16 }}>
        <div>
          <h2>Informações Adicionais:</h2>
          <ContentData>
            <p>
              Data de Nascimento:{' '}
              {formatDateData(agentInformation?.profile.birthdayDate)}
            </p>
            <p>Profissão: {agentInformation.profile.career}</p>
          </ContentData>
        </div>
      </ContentGroup>
      {children}
    </ContentVerificationData>
  );
}
