export function handleFormDTO(propertyData, propertyFormData) {
  propertyFormData = {
    ...propertyFormData,
    show: true,
    types: [propertyFormData.types],
    user_id: propertyData.owner.id,
    broker_id: propertyData.broker.id,
    address: {
      ...propertyFormData.address,
      number: String(propertyFormData.address.number),
    },
    specification: {
      ...propertyFormData.specification,
      area: Number(propertyFormData.specification.area) * 100,
      floor: propertyFormData.specification.floor,
      iptu_pay_by_tenant: propertyFormData.specification.iptu_pay_by_tenant,
      iptu_price:
        propertyFormData.specification.iptu_pay_by_tenant === true
          ? propertyFormData.specification.iptu_price > 0
            ? propertyFormData.specification.iptu_price
            : 0
          : 0,
      iptu_regime:
        propertyFormData.specification.iptu_pay_by_tenant === true
          ? propertyFormData.specification.iptu_regime
          : null,
      isServiceArea:
        propertyFormData.specification.isServiceArea === 'true' ? true : false,
      isBalcony:
        propertyFormData.specification.isBalcony === 'true' ? true : false,
      isGourmetArea:
        propertyFormData.specification.isGourmetArea === 'true' ? true : false,
      allow_animals:
        propertyFormData.specification.allow_animals === 'true' ? true : false,
      isConcierge:
        propertyFormData.specification.isConcierge === 'true' ? true : false,
      isElevator:
        propertyFormData.specification.isElevator === 'true' ? true : false,
      isRecreationArea:
        propertyFormData.specification.isRecreationArea === 'true'
          ? true
          : false,
      bedroom: Number(propertyFormData.specification.bedroom),
      bathroom: Number(propertyFormData.specification.bathroom),
      suite: Number(propertyFormData.specification.suite),
      kitchen: Number(propertyFormData.specification.kitchen),
      hall: Number(propertyFormData.specification.hall),
      parking_spaces: Number(propertyFormData.specification.parking_spaces),
      fire_insurance: false,
    },
    nearbies: {
      ...propertyFormData.nearbies,
      school: propertyFormData.nearbies.school === 'true' ? true : false,
      nursery: propertyFormData.nearbies.nursery === 'true' ? true : false,
      drugstore: propertyFormData.nearbies.drugstore === 'true' ? true : false,
      bakery: propertyFormData.nearbies.bakery === 'true' ? true : false,
      cafeteria: propertyFormData.nearbies.cafeteria === 'true' ? true : false,
      delegacy: propertyFormData.nearbies.delegacy === 'true' ? true : false,
      supermarket:
        propertyFormData.nearbies.supermarket === 'true' ? true : false,
      hospital: propertyFormData.nearbies.hospital === 'true' ? true : false,
    },
  };

  return propertyFormData;
}
