import React from 'react';

import { Box } from 'components/Shared';
import { Container, ButtonWhats, GoogleForm } from './styles';
import { Titles } from 'components/Titles';

export function SuportOwner() {
  function Whatsapp() {
    const link = 'https://wa.me//5535995545561?text=Estou%20com%20dúvida';
    window.open(link, '_blank');
  }

  return (
    <Box>
      <Titles title="Suporte" subtitle="Contatos e formulário" />
      <Container>
        <h1>Contatos</h1>
        <p>E-mail: contato@wizzer.com.br</p>
        <p>Telefone: (31) 9 9554-5561</p>
        <p>Endereço: Rua Alvarina Frota, 55 - Santa Luiza / Varginha - MG</p>
        <ButtonWhats onClick={() => Whatsapp()}>Whatsapp</ButtonWhats>

        <br></br>
        <p>Acesse nosso formulario</p>
        <GoogleForm>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdbiQHl7FVks3b_9ncKRXYmAz7aXXa_sIp4fEfJp80QaqacXA/viewform?embedded=true"
            width="640"
            height="868"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            scrolling="no"
          >
            Carregando…
          </iframe>
        </GoogleForm>
      </Container>
    </Box>
  );
}
