import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  border: ${props => props.theme.border};
  border-radius: 8px;

  > div {
    display: flex;
    align-items: center;

    > span {
      display: flex;
      width: 96px;
      height: 48px;
      background-color: ${props => props.backgroundStatus};
      border-radius: 8px;
      margin-right: 16px;
      justify-content: center;
      align-items: center;
      > svg {
        width: 24px;
        height: 24px;
        color: ${props => props.colorStatus};
      }
    }
    > p {
      font-size: 16px;
    }
  }
`;
