import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { SearchContainer } from './styles';

export function Search({ style, ...rest }) {
  return (
    <SearchContainer style={style}>
      <input id="search" {...rest} />
      <FaSearch />
    </SearchContainer>
  );
}
