import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { signInRequest } from '../store/modules/auth/actions';
import logo from '../assets/LogoWizzer.svg';

import { Container, Content, Form, Link, Footer } from '../components/unauth';
import { InputForm, Button } from 'components/Shared';
import { Image, Typography } from './styles';
import useFetch from 'services/ReactQuery';
import { toast } from 'react-toastify';

export function Client() {
  const [login, setLogin] = useState('validate');
  const [email, setEmail] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);
  const { auth } = useSelector(state => state);
  const { mutateAsync: validadeEmail } = useFetch.Post();

  useEffect(() => {
    if (auth.failure) {
      setLogin('signin');
    }
  }, [auth]);

  async function handleSubmit({ email, password }) {
    if(email) email = email.toLowerCase().trim().replace(/^\s+|\s+$/g, '');
    
    if (login === 'validate') {
      try {
        await validadeEmail({
          url: `/validate-account`,
          body: { email },
        });
        toast.success('Código enviado com sucesso!');
        setEmail(email);
      } catch (err) {
        if (
          err.response.status === 400 &&
          err.response.data.message === 'Account is ready'
        ) {
          toast.error('Conta já ativada, por favor faça login!');
          setLogin('signin');
        }
        if (
          err.response.status === 400 &&
          err.response.data.message === 'Account not found'
        ) {
          toast.error('Conta não cadastrada, contate o suporte!');
        }
      }
    }
    if (login === 'signin') {
      dispatch(signInRequest(email, password, login));
    }
  }
  function handleSubmitValidateCode({ code }) {
    dispatch(signInRequest(email, null, 'validate', code));
  }

  const handleLogin = props =>
    ({
      signin: (
        <Form onSubmit={handleSubmit}>
          <Image src={logo} />
          <InputForm
            name="email"
            id="email"
            title="E-mail"
            placeholder="Digite o E-mail"
            autoComplete="on"
            required={true}
          />
          <InputForm
            name="password"
            title="Senha"
            type="password"
            placeholder="Senha"
            required={true}
          />
          <Button style={{ width: '100%' }} typeColor="primary" type="submit">
            {loading ? 'Carregando...' : 'ENTRAR'}
          </Button>
          <Typography onClick={() => setLogin('validate')}>
            Não tenho login
          </Typography>
          <Link to="/forgot-password">Esqueceu sua senha?</Link>
        </Form>
      ),
      validate: (
        <Form onSubmit={handleSubmit}>
          <Image src={logo} />
          <InputForm
            name="email"
            id="email"
            title="E-mail"
            placeholder="Digite o E-mail"
            autoComplete="on"
            required={true}
          />
          <Button style={{ width: '100%' }} typeColor="primary" type="submit">
            {loading ? 'Carregando...' : 'Enviar'}
          </Button>
          <Typography onClick={() => setLogin('signin')}>
            Já tenho Login
          </Typography>
        </Form>
      ),
    }[props]);

  function CodeValidation() {
    return (
      <Form onSubmit={handleSubmitValidateCode}>
        <Image src={logo} />
        <p style={{ fontSize: 16, marginBottom: 16 }}>
          Foi enviado um código ao seu e-mail, digite-o abaixo para validar:
        </p>
        <InputForm
          name="code"
          id="code"
          title="Codigo:"
          placeholder="Digite o codigo recebido por e-mail"
          required={true}
        />
        <Button style={{ width: '100%' }} typeColor="primary" type="submit">
          {loading ? 'Carregando...' : 'Enviar'}
        </Button>
        <Button
          type="button"
          typeColor="secondary"
          style={{ width: '100%', marginTop: 16 }}
          onClick={() => handleSubmit({ email })}
        >
          Re-enviar
        </Button>
      </Form>
    );
  }
  return (
    <Container>
      <Content>
        {!email && handleLogin(login)}
        {email && CodeValidation()}
      </Content>
      <Footer>
        © {new Date().getFullYear()} Wizzer Imóveis - Brasil - Todos os direitos
        reservados
      </Footer>
    </Container>
  );
}
