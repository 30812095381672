import React, { useRef, useCallback, useState, useEffect } from 'react';

import { InputText, Content } from 'components/Shared';
import { MdAdminPanelSettings, MdHouse } from 'react-icons/md';
import { ContractButton, ContractContainer, ContractButtons } from './styles';
import { toast } from 'react-toastify';
import { contractTypeValidation } from './Validation';
import { validationError } from 'validations/forms';

export function SelectContractType({ sendData, setCurrentProgress, children }) {
  const formRef = useRef(null);
  const [typeContract, setTypeContract] = useState();

  useEffect(() => {
    formRef.current.setFieldValue(
      'foro',
      'Elegem as partes o foro da situação do imóvel, com renúncia de qualquer outro, por mais privilegiado que seja, para dirimir qualquer pendência relativa a este contrato.\n\nAssim justas e livremente contratadas, as partes firmam o presente em 02 (duas) vias de igual teor e para um só efeito.'
    );
    formRef.current.setFieldValue(
      'entryInsception',
      'O laudo de vistoria será feito posteriormente e será parte integrante desse contrato'
    );
  }, [formRef]);

  const handleSubmit = useCallback(
    async data => {
      data = { ...data, type: typeContract };
      if (typeContract !== 'location') {
        delete data.entryInsception;
      }
      try {
        await contractTypeValidation.validate(data, {
          abortEarly: false,
        });
        sendData(data);
        setCurrentProgress(1);
      } catch (err) {
        toast.error('Selecione um tipo de contrato');
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [formRef, typeContract]
  );

  return (
    <ContractContainer>
      <ContractButtons>
        <ContractButton
          className={typeContract === 'administration' ? 'active' : ''}
          onClick={() => setTypeContract('administration')}
        >
          <MdAdminPanelSettings />
          Administração
        </ContractButton>
        <ContractButton
          className={typeContract === 'location' ? 'active' : ''}
          onClick={() => setTypeContract('location')}
        >
          <MdHouse />
          Locação
        </ContractButton>
      </ContractButtons>
      <Content ref={formRef} onSubmit={e => handleSubmit(e)}>
        <InputText name="otherInformation" title="Observação de contrato:" />
        <InputText name="foro" title="Foro Comarca:" />

        <InputText
          style={{ display: typeContract === 'location' ? 'block' : 'none' }}
          name="entryInsception"
          title="Vistoria de entrada:"
        />
        {children}
      </Content>
    </ContractContainer>
  );
}
