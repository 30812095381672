import React, { useState } from 'react';

import { Box } from 'components/Shared';
import { ContractsNegotiaton } from '../../NegotiationContracts';
import { CreateContract } from '../../CreateContract';
import { GetContracts } from './Services';

export function ContractData({ negotiationData }) {
  const [createContracts, setCreateContracts] = useState(false);
  const [currentParams, setCurrentParams] = useState({
    type: 'location',
    order: 'DESC',
    status: 'running',
    page: 1,
    perPage: 10,
  });

  const { data, isLoading } = GetContracts(negotiationData.id, currentParams);

  return (
    <Box>
      {isLoading || (
        <>
          {data && !createContracts ? (
            <ContractsNegotiaton
              ContractData={data}
              setCurrentParams={setCurrentParams}
              currentParams={currentParams}
              setCreateContracts={setCreateContracts}
              negotiationData={negotiationData}
            />
          ) : (
            <CreateContract negotiationData={negotiationData} />
          )}
        </>
      )}
    </Box>
  );
}
