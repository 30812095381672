import axios from 'axios';
import React from 'react';
import { ErrorScreen, LoadingScreen } from 'components/Shared';
import useFetch from 'services/ReactQuery';

export async function getTypes() {
  try {
    const responseTypes = await axios.get('/type');
    return responseTypes;
  } catch (err) {
    alert(
      'Houve um erro ao processar sua solicitação, tente novamente mais tarde.'
    );
    console.error(err);
  }
}

export async function putType(type) {
  try {
    await axios.put(`/type/${type.id}`, {
      show_on_filter: !type.show_on_filter,
    });
  } catch (err) {
    alert(
      'Houve um erro ao processar sua solicitação, tente novamente mais tarde.'
    );
    console.error(err);
  }
}

export function getAllTypes() {
  const { data, isLoading, isError } = useFetch.Get({
    url: '/types/search',
  });

  return {
    data: data,
    isLoading:
      (isLoading && <LoadingScreen />) || (isError && <ErrorScreen />) || null,
  };
}
