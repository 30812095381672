import React from 'react'
import { useParams, Link } from 'react-router-dom'
import gratsBroker from '../../../../assets/success.svg'

import {
  Container
} from './styles'

export function CongratsLocation () {
  const { idNegotiation } = useParams()

  return (
    <Container>
      <img src={gratsBroker} alt='gratsBroker' />
      <h2>NEGOCIAÇÃO CRIADA!</h2>
      <p>Os dados o inquilino foram enviados para análise, agora aguarde a ativação de aluguel.</p>
      <div>
        <Link to={`/dashboard/negociacoes/${idNegotiation}/detalhes`} >Ver Negociação</Link>
      </div>
    </Container>
  )
}
