import React from 'react';

import {
  InputCurrency,
  InputNumberSuffix,
  InputRadio,
  InputCalendar,
} from 'components/Shared';

export function Details() {
  return (
    <>
      <div>
        <span>
          <InputCurrency
            name="negotiation.amount"
            title="Valor do aluguel"
            placeholder="Coloque o valor"
            required={true}
          />
        </span>
        <span>
          <InputNumberSuffix
            name="negotiation.periodMonths"
            suffix=" meses"
            title="Período do aluguel"
            placeholder="Coloque o período"
            required={true}
          />
        </span>
      </div>
      <div className="half">
        <span>
          <InputCalendar
            name="negotiation.startDate"
            title="Início do aluguel"
            placeholderText="Selecione uma data"
            required={true}
          />
        </span>
      </div>
      <div>
        <span>
          <InputRadio
            name="negotiation.insuranceFire"
            title="Inclui seguro incendio?"
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="negotiation.dayPayment"
            title="Dia do pagamento"
            required={true}
            options={[
              {
                label: '05',
                value: 5,
              },
              {
                label: '10',
                value: 10,
              },
              {
                label: '15',
                value: 15,
              },
              {
                label: '20',
                value: 20,
              },
              {
                label: '25',
                value: 25,
              },
            ]}
          />
        </span>
      </div>
      <div className="half">
        <span>
          <InputRadio
            name="negotiation.incomeTax"
            title="Declara o imposto de renda?"
            required={true}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
      </div>
    </>
  );
}
