import styled from 'styled-components';

export const ContainerCities = styled.div``;

export const ReelsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  gap: 8px;
  border: ${props => props.theme.border};
  border-radius: 8px;
  overflow-x: scroll;
  background: ${props => props.theme.container.background};

  &::-webkit-scrollbar {
    padding: 0 15px;
    height: 8px;
    border-radius: 4px;
    background-color: ${props => props.theme.backgrounds.secondary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 8px;
  }
`;

export const ImagePreview = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: fill;
`;

export const CityCard = styled.div`
  border-radius: 16px;
  min-width: 280px;
  height: 280px;
  background: #b7b9bd;
  display: flex;

  div {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.urlImageCover});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 16px;
  }

  .city_name {
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .img_cover {
    position: absolute;
    left: 0;
  }
  .image_avatar {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 1px solid #055eff;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .close_searchable {
    position: absolute;
    display: flex;
    border-radius: 100%;
    background: #f4f4f4;
    width: 32px;
    height: 32px;
    right: 8px;
    top: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      fill: red;
    }
  }

  .svg_heart {
    position: absolute;
    display: flex;
    border-radius: 100%;
    background: #f4f4f4;
    width: 32px;
    height: 32px;
    left: 8px;
    top: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .inative {
      fill: #b7b9bd;
    }
    .active {
      fill: red;
    }
  }
`;

export const ImageForm = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 8px;
`;
