import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 28px;

  @media screen and (max-width: 990px) {
    padding: 28px 24px;
  }
`;
