import React, { useEffect } from 'react';

import { InputRadio } from 'components/Shared';

export function SpecificationsInputs({ formRef, specificationsData }) {
  const filedData = specificationsData;

  useEffect(() => {
    if (filedData) {
      formRef.current.setData(filedData);
    }
  }, [filedData, formRef]);

  return (
    <>
      <div>
        <span>
          <InputRadio
            name="specification.bedroom"
            title="Quantidade de quartos:"
            required={true}
            options={[
              {
                label: '0',
                value: 0,
              },
              {
                label: '1',
                value: 1,
              },
              {
                label: '2',
                value: 2,
              },
              {
                label: '3',
                value: 3,
              },
              {
                label: '4',
                value: 4,
              },
              {
                label: '5',
                value: 5,
              },
              {
                label: '6',
                value: 6,
              },
              {
                label: '7',
                value: 7,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="specification.bathroom"
            title="Quantidade de banheiros:"
            required={true}
            options={[
              {
                label: '0',
                value: 0,
              },
              {
                label: '1',
                value: 1,
              },
              {
                label: '2',
                value: 2,
              },
              {
                label: '3',
                value: 3,
              },
              {
                label: '4',
                value: 4,
              },
              {
                label: '5',
                value: 5,
              },
              {
                label: '6',
                value: 6,
              },
              {
                label: '7',
                value: 7,
              },
            ]}
          />
        </span>
      </div>
      <div>
        <span>
          <InputRadio
            name="specification.suite"
            title="Quantidades de suítes:"
            options={[
              {
                label: '0',
                value: 0,
              },
              {
                label: '1',
                value: 1,
              },
              {
                label: '2',
                value: 2,
              },
              {
                label: '3',
                value: 3,
              },
              {
                label: '4',
                value: 4,
              },
              {
                label: '5',
                value: 5,
              },
              {
                label: '6',
                value: 6,
              },
              {
                label: '7',
                value: 7,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="specification.kitchen"
            title="Quantidade de cozinhas:"
            options={[
              {
                label: '0',
                value: 0,
              },
              {
                label: '1',
                value: 1,
              },
              {
                label: '2',
                value: 2,
              },
              {
                label: '3',
                value: 3,
              },
              {
                label: '4',
                value: 4,
              },
              {
                label: '5',
                value: 5,
              },
              {
                label: '6',
                value: 6,
              },
              {
                label: '7',
                value: 7,
              },
            ]}
          />
        </span>
      </div>
      <div>
        <span>
          <InputRadio
            name="specification.hall"
            title="Quantidade de salas"
            options={[
              {
                label: '0',
                value: 0,
              },
              {
                label: '1',
                value: 1,
              },
              {
                label: '2',
                value: 2,
              },
              {
                label: '3',
                value: 3,
              },
              {
                label: '4',
                value: 4,
              },
              {
                label: '5',
                value: 5,
              },
              {
                label: '6',
                value: 6,
              },
              {
                label: '7',
                value: 7,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="specification.parkingSpaces"
            title="Quantidade de vagas de garagem:"
            options={[
              {
                label: '0',
                value: 0,
              },
              {
                label: '1',
                value: 1,
              },
              {
                label: '2',
                value: 2,
              },
              {
                label: '3',
                value: 3,
              },
              {
                label: '4',
                value: 4,
              },
              {
                label: '5',
                value: 5,
              },
              {
                label: '6',
                value: 6,
              },
              {
                label: '7',
                value: 7,
              },
            ]}
          />
        </span>
      </div>
    </>
  );
}
