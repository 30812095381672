import React, { useState } from 'react';

import { Titles } from '../../Titles';
import { ProgressBar } from '../../Shared/ProgressBar';

import { Container } from './styles';
import { EssencialData } from './EssencialData';
import { Address } from './Address';
import { UserProfile } from './UserProfile';
import { Agent } from './Agent';
import { UserDocuments } from './UserDocuments';
import { BankAccount } from './BankAccount';

export function UserForm() {
  const [currentProgress, setCurrentProgress] = useState(0);
  const [role, setRole] = useState({ value: 'owner', label: 'Proprietario' });
  const [unlock, setUnlock] = useState(false);
  const [id, setId] = useState('');
  const [dataEssencial, setDataEssencial] = useState();
  const [dataAddress, setDataAddress] = useState();
  const [dataDocuments, setDataDocuments] = useState();
  const [dataProfile, setDataProfile] = useState();
  const [dataBank, setDataBank] = useState();
  const [dataAgent, setDataAgent] = useState([]);

  function handleDocumentsType(data) {
    if (data) {
      const type = data?.documents?.find(doc => doc.type === 'cpf');
      return type?.type;
    } else {
      return 'cpf';
    }
  }

  const Progress = {
    0: (
      <EssencialData
        setCurrentProgress={setCurrentProgress}
        currentProgress={currentProgress}
        setDataEssencial={setDataEssencial}
        dataEssencial={dataEssencial}
        setUnlock={setUnlock}
        setRole={setRole}
        role={role}
        setId={setId}
        id={id}
      />
    ),
    1: (
      <UserDocuments
        setCurrentProgress={setCurrentProgress}
        currentProgress={currentProgress}
        setDataDocuments={setDataDocuments}
        dataDocuments={dataDocuments}
        dataEssencial={dataEssencial}
        id={id}
      />
    ),
    2: (
      <UserProfile
        setCurrentProgress={setCurrentProgress}
        currentProgress={currentProgress}
        setDataProfile={setDataProfile}
        dataEssencial={dataEssencial}
        dataProfile={dataProfile}
        id={id}
      />
    ),
    3: (
      <Address
        setCurrentProgress={setCurrentProgress}
        currentProgress={currentProgress}
        setDataAddress={setDataAddress}
        role={role?.value}
        dataAddress={dataAddress}
        dataEssencial={dataEssencial}
        id={id}
      />
    ),
    4: (
      <BankAccount
        setCurrentProgress={setCurrentProgress}
        currentProgress={currentProgress}
        setDataBank={setDataBank}
        dataEssencial={dataEssencial}
        dataBank={dataBank}
        id={id}
      />
    ),
    5: (
      <Agent
        setCurrentProgress={setCurrentProgress}
        currentProgress={currentProgress}
        setDataAgent={setDataAgent}
        dataEssencial={dataEssencial}
        dataAgent={dataAgent}
        id={id}
      />
    ),
  };

  return (
    <>
      <Container>
        <Titles title={'Clientes'} subtitle={'Adicionar Cliente'} />
        <ProgressBar
          role={role}
          currentProgress={currentProgress}
          setCurrentProgress={setCurrentProgress}
          unlock={unlock}
          progress={[
            {
              title: 'Adicionando um Novo Cliente',
              subtitle: 'Preencha as informações do cliente',
              category: 'Dados Essenciais',
              roles: {
                broker: true,
                owner: true,
                tenant: true,
              },
              filled: dataEssencial,
            },
            {
              title: 'Documentos do Cliente',
              subtitle: 'Anexe os documentos do cliente',
              category: 'Documentos',
              roles: {
                broker: true,
                owner: true,
                tenant: true,
              },
              filled: dataDocuments,
            },
            {
              title: 'Informações do Perfil',
              subtitle:
                'Preencha as informações adicionais do perfil do cliente',
              category: 'Perfil do Cliente',
              roles: {
                broker: true,
                owner: handleDocumentsType(dataDocuments) === 'cpf',
                tenant: true,
              },
              filled: dataProfile,
            },
            {
              title: 'Adicione um novo endereço',
              subtitle: 'Preencha as informações do endereço',
              category: 'Endereço',
              roles: {
                broker: true,
                owner: true,
                tenant: true,
              },
              filled: dataAddress,
            },
            {
              title: 'Conta Bancária',
              subtitle: 'Preencha as informações da conta bancária do cliente',
              category: 'Conta Bancária',
              roles: {
                broker: true,
                owner: true,
                tenant: false,
              },
              filled: dataBank,
            },
            {
              title: 'Outros Responsáveis',
              subtitle:
                'Preencha as informações sobre outros atrelados aos dados do cliente',
              category: 'Outros Responsáveis',
              roles: {
                broker: false,
                owner: true,
                tenant: true,
              },
              filled: dataAgent.length,
            },
          ]}
        />
        {Progress[currentProgress]}
      </Container>
    </>
  );
}
