import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Button = styled(NavLink)`
  display: flex;
  align-items: center;
  background: transparent;
  font-size: 16px;
  text-decoration: none;
  > svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
`;
