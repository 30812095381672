import React from 'react';

import { ContentGroup, ContentData } from 'components/Shared';

export function SpecificationsProperty(specification) {
  const { specification: specificationData } = specification;
  return (
    <>
      {specificationData?.bedroom !== 0 &&
        specificationData?.bedroom !== '0' &&
        specificationData?.bedroom !== null &&
        specificationData?.bedroom !== '' &&
        specificationData?.bedroom !== false && (
          <>
            <ContentGroup>
              <div>
                <h2>Especificações do imóvel:</h2>
                <ContentData>
                  <p>
                    Quartos: <strong>{specificationData?.bedroom}</strong>
                  </p>

                  <p>
                    Banheiros: <strong>{specificationData?.bathroom}</strong>
                  </p>

                  <p>
                    Vagas na Garagem:{' '}
                    <strong>{specificationData?.parking_spaces}</strong>
                  </p>

                  <p>
                    Cozinhas: <strong>{specificationData?.kitchen}</strong>
                  </p>

                  <p>
                    Salas: <strong>{specificationData.hall}</strong>
                  </p>

                  <p>
                    Suítes: <strong>{specificationData.suite}</strong>
                  </p>
                  <br></br>
                  <br></br>
                  {specificationData?.description !== 0 &&
                    specificationData?.description !== '0' &&
                    specificationData?.description !== null &&
                    specificationData?.description !== '' &&
                    specificationData?.description !== false && (
                      <p>
                        <strong>
                          Descrição: <br></br>
                        </strong>
                        <br></br>
                        {specificationData.description}
                      </p>
                    )}
                </ContentData>
              </div>

              <div>
                <h2>Outros:</h2>
                <ContentData>
                  <p>
                    Tem Sacada:{' '}
                    <strong>
                      {specificationData.isBalcony ? 'Tem' : 'Não'}
                    </strong>
                  </p>

                  <p>
                    Tem Portaria:{' '}
                    <strong>
                      {specificationData.isConcierge ? 'Tem' : 'Não'}
                    </strong>
                  </p>

                  <p>
                    Tem Elevador:{' '}
                    <strong>
                      {specificationData.isElevator ? 'Tem' : 'Não'}
                    </strong>
                  </p>

                  <p>
                    Tem Área Gourmet:{' '}
                    <strong>
                      {specificationData.isGourmetArea ? 'Tem' : 'Não'}
                    </strong>
                  </p>

                  <p>
                    Tem Área de Lazer:{' '}
                    <strong>
                      {specificationData.isRecreationArea ? 'Tem' : 'Não'}
                    </strong>
                  </p>

                  <p>
                    Tem Área de Serviço:{' '}
                    <strong>
                      {specificationData.isServiceArea ? 'Tem' : 'Não'}
                    </strong>
                  </p>

                  <p>
                    Aceita Animais:{' '}
                    <strong>
                      {specificationData.allow_animals ? 'Aceita' : 'Não'}
                    </strong>
                  </p>

                  {specificationData?.details !== 0 &&
                    specificationData?.details !== '0' &&
                    specificationData?.details !== null &&
                    specificationData?.details !== '' &&
                    specificationData?.details !== false && (
                      <p>
                        Detalhes: <strong>{specificationData.details}</strong>
                      </p>
                    )}
                </ContentData>
              </div>
            </ContentGroup>
          </>
        )}
    </>
  );
}
