import React from 'react';
import { BlackScreen, Modal } from '../styles';

export function AttentionModal({ children, open, setOpen }) {
  return (
    <>
      <BlackScreen onClick={() => setOpen(false)} open={open} />
      <Modal open={open}>
        <div>
          <h1>Atenção!</h1>
          <p>
            Os dados essenciais do seu cliente já foram adicionados. Você deseja
            continuar o preenchimento dos dados ou finalizar o processo aqui?
          </p>
          {children}
        </div>
      </Modal>
    </>
  );
}
