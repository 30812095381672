import styled from 'styled-components';

export const ContainerInput = styled.div`
  display: flex;
  position: relative;
  > svg {
    width: 18px;
    height: 18px;
    color: #055eff;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
`;
