import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jarvis from 'util/jarvis';
import { toast } from 'react-toastify';
import { signInRequest } from '../store/modules/auth/actions';

import {
  Container,
  Content,
  Logo,
  Form,
  Strong,
  Button,
  Link,
  Cover,
  Footer,
} from '../components/unauth';
import { InputForm } from 'components/Shared';

export function SetPassword() {
  const loading = useSelector(state => state.auth.loading);
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();

  function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  function handleLogin({ email, password }) {
    dispatch(signInRequest(email, password, 'signin'));
  }

  async function handleSubmit(data) {
    try {
      await jarvis.post('/set-password', {
        token: getCookie('access_token'),
        newPassword: data.password,
      });
      toast.success('Senha atualizada com sucesso!');
      handleLogin({ email: profile.email, password: data.password });
    } catch (err) {
      toast.error('Não foi possível resetar sua senha, tente novamente.');
    }
  }

  return (
    <Container>
      <Content>
        <Form className="form" onSubmit={handleSubmit}>
          <Logo />
          <Strong>Nova Senha</Strong>
          <InputForm
            name="password"
            title="Sua Nova Senha"
            type="password"
            placeholder="Senha"
            required={true}
          />
          <InputForm
            name="password"
            title="Confirmar Nova Senha"
            type="password"
            placeholder="Senha"
            required={true}
          />
          <Button type="submit">
            {loading ? 'Carregando...' : 'Criar SENHA'}
          </Button>
          <Link to="/">Voltar</Link>
        </Form>
        <Cover />
      </Content>
      <Footer>
        © {new Date().getFullYear()} Wizzer Imóveis - Brasil - Todos os direitos
        reservados
      </Footer>
    </Container>
  );
}
