import React from 'react';

import { ContainerSignersList, Signer } from './styles';

export function SignersList({ SignersData }) {
  const translateSignersRoles = {
    administrator: 'Administrador',
    lessor: 'Locador',
    lessee: 'Locatário',
    guarantor: 'Avalista',
    approve: 'Aprovador',
    surety: 'Fiador',
    legal_representative: 'Representante legal',
    contractor: 'Contratante',
    contractee: 'Contratado',
  };
  return (
    <ContainerSignersList>
      <h1>Assinantes do Documentos:</h1>
      <ul>
        {SignersData.map(signer => {
          return (
            <Signer key={signer.name}>
              <p>
                Nome: <strong>{signer.name}</strong>
              </p>
              <p>
                Documento: <strong>{signer.documentation}</strong>
              </p>
              <p>
                Assinado como:{' '}
                <strong>{translateSignersRoles[signer.signAs]}</strong>
              </p>
            </Signer>
          );
        })}
      </ul>
    </ContainerSignersList>
  );
}
