import styled from 'styled-components';

export const Container = styled.footer`
  padding: 0 24px;
  margin-top: 32px;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
`;
