import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { AdminQuery, BrokerQuery } from './Services';
import { validadeTenant } from './Validation';
import { toast } from 'react-toastify';

import { Button, Buttons, LoadingScreen } from 'components/Shared';
import { Container } from 'components/Shared';
import {
  MenuContainer,
  ListContainer,
  Content,
  Search,
} from '../Styles/styles';
import { UserListSelect } from '../Components/User/UserListSelect';
import { UserSelected } from '../Components/User/UserSelected';

export function TenantSelect({
  selectedTenant,
  setSelectedTenant,
  setCurrentProgress,
  selectedProperty,
}) {
  const formRef = useRef();
  const history = useHistory();
  const { profile } = useSelector(state => state.user);
  const [currentParams, setCurrentParams] = useState({
    search: null,
    filter: 'tenant',
  });
  const { data, isLoading } = profile.isAdmin
    ? AdminQuery(currentParams)
    : BrokerQuery(currentParams, profile.id);

  const debounce = AwesomeDebouncePromise(updateQuery, 500);
  function updateQuery(event) {
    setCurrentParams({
      ...currentParams,
      search: event,
    });
  }

  const handleSubmit = useCallback(async () => {
    const data = {
      tenant: selectedTenant.id,
    };
    try {
      await validadeTenant.validate(data, {
        abortEarly: false,
      });
      toast.success('Imóvel selecionado com sucesso!');
      setCurrentProgress(2);
    } catch (error) {
      toast.error(error.message);
    }
  }, [selectedTenant, setCurrentProgress]);

  return (
    <Container>
      <Content style={{ width: '100%' }} ref={formRef} onSubmit={handleSubmit}>
        <MenuContainer>
          <p>
            <strong>Pesquisar um Inquilino</strong>
          </p>
          <Button
            onClick={() =>
              history.push('/dashboard/clientes/criar/tenant', {
                property: selectedProperty,
              })
            }
            typeColor="clean"
          >
            Adicionar um inquilino
          </Button>
        </MenuContainer>
        <Search>
          <input
            id="search"
            type="text"
            placeholder="Pesquisar Inquilino"
            onChange={e => {
              debounce(e.target.value);
            }}
            onKeyUp={() => setSelectedTenant('')}
          />
        </Search>
        {data && !selectedTenant && (
          <ListContainer>
            {isLoading ? (
              <LoadingScreen />
            ) : (
              <>
                {data?.data.map(user => (
                  <UserListSelect
                    key={user.id}
                    user={user}
                    setSelectedTenant={setSelectedTenant}
                    selectedTenant={selectedTenant}
                  />
                ))}
              </>
            )}
          </ListContainer>
        )}
        {selectedTenant && <UserSelected user={selectedTenant} />}
        <Buttons style={{ marginTop: 24 }}>
          <Button type="button" typeColor="disable">
            Cancelar
          </Button>
          <Button type="submit" typeColor="primary">
            Avançar
          </Button>
        </Buttons>
      </Content>
    </Container>
  );
}
