import React from 'react';
import { useSelector } from 'react-redux';

import { Content, InputSelectPaginate } from 'components/Shared';

import { ownerValidation } from './Validation';
import { validationError } from 'validations/forms';

import { loadOptionsUserOwner, loadOptionsUserBroker } from './Services';

export function OwnerForm({ children, formRef, sendData }) {
  const { role, id } = useSelector(state => state.user.profile);

  async function handleSubmit(data) {
    if (role === 'broker') {
      data = {
        ...data,
        broker_id: id,
      };
    }
    try {
      await ownerValidation.validate(data, {
        abortEarly: false,
      });
      sendData(data);
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      if (err instanceof validationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }
  return (
    <Content ref={formRef} onSubmit={handleSubmit}>
      <div>
        <span>
          <InputSelectPaginate
            name="user_id"
            title="Quem é o proprietário?"
            placeholder="Selecionar..."
            required={true}
            loadOptions={loadOptionsUserOwner}
            additional={{
              page: 1,
            }}
          />
        </span>
        {role !== 'broker' && (
          <span>
            <InputSelectPaginate
              name="broker_id"
              title="Quem é o corretor?"
              placeholder="Selecionar..."
              required={true}
              loadOptions={loadOptionsUserBroker}
              additional={{
                page: 1,
              }}
            />
          </span>
        )}
      </div>
      {children}
    </Content>
  );
}
