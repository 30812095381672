import React from 'react'

import {
  Container
} from './styles'

export function Strong ({ children }) {
  return (
    <Container>{children}</Container>
  )
}
