import React from 'react'
import {
  Container as DefaultContainer
} from './styles'

export function Container ({ children }) {
  return (
    <DefaultContainer>{children}</DefaultContainer>
  )
}
