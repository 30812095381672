import styled from "styled-components";

export const ContainerList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

export const SelectedFilter = styled.div`
  display: flex;
  align-self: center;
  width: 50vh;
  max-width: 280px;
  .field-select {
    flex: 1;
  }
  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;