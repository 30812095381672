import React from 'react';
import { FilterSelect } from './FilterSelect';

import { FilterContainer, FilterButton, SelectedFilter } from './styles';

export function Filter(props) {
  return (
    <FilterContainer style={props.style}>
      {props?.filter.map((filter, index) => {
        return (
          <FilterButton
            key={index}
            className={props.active === filter.value ? 'active' : ''}
            onClick={() => props.handleFilter(filter)}
          >
            {filter.label}
          </FilterButton>
        );
      })}
      <SelectedFilter>
        <FilterSelect
          className="field-select"
          options={props.filter}
          onChange={filter => props.handleFilter(filter)}
          defaultValue={props.filter[0]}
        />
      </SelectedFilter>
    </FilterContainer>
  );
}
