import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  width: 100%;

  @media screen and (max-width: 600px) {
    padding: 12px;
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
`;

export const FilterButton = styled.button`
  height: 100%;
  padding: 8px;
  border-bottom: 3px solid #fff;
  box-sizing: border-box;
  color: ${props => props.theme.colors.primary};
  background: none;
  font-size: 14px;
  line-height: 16px;
  margin-left: 20px;
  transition: all 0.3s ease-in-out;
  ${props => {
    switch (props.active) {
      case true:
        return `
          margin-left: 18px;
          font-weight: bold;
          border-bottom: 3px solid #055EFF;
        `;
      default:
        return '';
    }
  }}

  @media screen and (max-width: 600px) {
    padding: 12px;
  }
`;

export const InfoLabels = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  align-items: center;
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  max-width: 300px;
  background: #f2f5f8;
  border-radius: 8px;
  margin-left: auto;
  > input {
    border: 0;
    background: none;
    font-size: 16px;
    line-height: 100%;
    color: #989ba1;

    & ::placeholder {
      color: #989ba1;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
    }
  }
  @media screen and (max-width: 990px) {
    max-width: none;
    margin-left: none;
    margin-top: 8px;
    width: 100%;
    > input {
      width: 100%;
    }
  }
`;

export const List = styled.ul`
  position: relative;
  list-style-type: none;
  width: 100%;

  a {
    transition: all 0.2s ease-in-out;
  }
  &:has(a:hover) a:not(a:hover) {
    opacity: 0.7;
    scale: 0.98;
  }
`;
