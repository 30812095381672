import jarvis from 'util/jarvis';

export const FilterOptions = [
  {
    label: 'Todos',
    value: 'all',
  },
  {
    label: 'Aguardando',
    value: 'waiting_payment',
  },
  {
    label: 'Concluidas',
    value: 'running',
  },
  {
    label: 'Ativar Cobrança',
    value: 'analyzing',
  },
  {
    label: 'Canceladas',
    value: 'canceled',
  },
];

export async function UserOptions(search, loadedOptions, { page }) {
  const response = await jarvis.get(`/users?page=${page}&search=${search}`);

  const chackUser = response.data.data.map(user => ({
    value: user.id,
    role: user.role,
    label: `${user.name} - ${user.lastName}`,
  }));

  const hasMore = page < response.data.lastPage;

  return {
    options: chackUser,
    hasMore: hasMore,
    additional: {
      page: page + 1,
    },
  };
}
