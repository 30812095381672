import React from 'react';

import { useHistory } from 'react-router-dom';
import {
  Box,
  FilterContainer,
  Filter,
  Button,
  SelectedFilter,
} from 'components/Shared';
import { Pagination } from 'components/Pagination';
import { ContractList } from './Components/ContractList';

export function ContractsNegotiaton({
  ContractData,
  setCurrentParams,
  currentParams,
  setCreateContracts,
  negotiationData,
}) {
  const history = useHistory();
  async function handlePageChange({ selected }) {
    const newParams = { ...currentParams, page: selected };
    setCurrentParams(newParams);
  }

  function handleFilterChange(filter) {
    const newParams = { ...currentParams, status: filter };
    setCurrentParams(newParams);
  }

  return (
    <Box>
      <FilterContainer>
        <Filter
          filter={[
            {
              label: 'Administração',
              value: 'administration',
            },
            {
              label: 'Locação',
              value: 'location',
            },
          ]}
          active={currentParams.type}
          handleFilter={filter =>
            setCurrentParams({ ...currentParams, page: 1, type: filter.value })
          }
        />
        <Button
          className="hasButton"
          typeColor="primary"
          onClick={() => setCreateContracts(true)}
        >
          Criar Contrato
        </Button>
      </FilterContainer>
      <FilterContainer style={{ marginTop: 16 }}>
        <SelectedFilter
          name="filterContract"
          options={[
            {
              label: 'Em andamento',
              value: 'running',
            },
            {
              label: 'Finalizados',
              value: 'closed',
            },
          ]}
          defaultValue={{ label: 'Em andamento', value: 'running' }}
          onChange={filter => handleFilterChange(filter.value)}
        />
      </FilterContainer>
      {ContractData.data?.map(contract => {
        function handleContractDetails() {
          history.push(
            `/dashboard/negociacao-detalhes/${negotiationData.id}/contrato/${contract.id}`,
            {
              negotiationData: negotiationData,
            }
          );
        }
        return (
          <div style={{ marginTop: 16, cursor: 'pointer' }} key={contract.id}>
            <ContractList
              contract={contract}
              handleContractDetails={handleContractDetails}
            />
          </div>
        );
      })}
      <Pagination
        currentPage={currentParams.page}
        pagesSize={currentParams.perPage}
        totalCount={ContractData.total}
        onPageChange={handlePageChange}
      />
    </Box>
  );
}
