import * as Yup from 'yup';

export const transactionValidation = Yup.object().shape({
  typeTransaction: Yup.string().required('O tipo da transação é requerido.'),
  expirationDate: Yup.string().required('A data de vencimento é requerida.'),
  intervalDays: Yup.string().when('typeTransaction', typeTransaction => {
    if (typeTransaction === 'proportional') {
      return Yup.string().required('Intervalo é requerido.');
    }
  }),
});
