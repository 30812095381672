import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 24px;
`;

export const Photo = styled.div`
  display: flex;
  width: 56px;
  height: 56px;
  background-color: #055eff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  > span {
    font-size: 16px;
    color: white;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > h2 {
    font-size: 24px;
  }
  > p {
    display: flex;
    font-size: 14px;
    align-items: center;
    > strong {
      margin-left: 8px;
    }
    > svg {
      margin-right: 8px;
      height: 16px;
      width: 16px;
    }
  }

  .hovered {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    gap: 16px;
    p {
      font-size: 16px;

      > svg {
        height: 24px;
        width: 24px;
      }
    }
  }
`;
