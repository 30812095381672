import Cookies from 'js-cookie';

class TokenService {
  constructor() {
    this.accessToken = 'access_token';
    this.refreshToken = 'refresh_token';
  }

  getAccessToken() {
    const token = Cookies.get(this.accessToken);
    if (token === '') {
      return false;
    }
    return token;
  }

  getRefreshToken() {
    return Cookies.get(this.refreshToken);
  }

  setAccessToken(accessToken) {
    Cookies.set(this.accessToken, accessToken, {
      expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });
  }

  setRefreshToken(refreshToken) {
    Cookies.set(this.refreshToken, refreshToken);
  }

  deleteAccessToken() {
    Cookies.remove(this.accessToken);
  }

  deleteRefreshToken() {
    Cookies.remove(this.refreshToken);
  }
}

export default new TokenService();
