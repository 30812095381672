import React from 'react';

import FormatCurrency from 'util/FormatCurrency';

import { Container } from './styles';

export function WithIPTU({ value }) {
  return (
    <Container>
      <span style={{ marginBottom: 8 }}>Valor total com IPTU</span>
      <strong>{FormatCurrency(value)}</strong>
    </Container>
  );
}
