import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 24px;
  > span {
    display: flex;
    width: 48px;
    height: 48px;
    background: ${props => props.backgroundColor};
    border-radius: 50%;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
    > svg {
      width: 24px;
      height: 24px;
      color: ${props => props.fontColor};
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    > address {
      font-style: normal;
      font-size: 16px;
    }
    > p {
      font-size: 14px;
    }
  }

  .title-address {
    font-size: 24px;
    font-weight: bold;
  }
`;
