import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px 24px;
  border: 1px solid #055eff;
  border-radius: 8px;
  background: ${props => props.theme.container.background};
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  > h2 {
    font-size: 16px;
    margin-bottom: 16px;
  }
`;
