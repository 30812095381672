import React, { useRef } from 'react';

import { handleZipcode } from 'services/service.address';
import { handleGeoCoder } from './service';

import {
  Container,
  AddresForm,
  Buttons,
  Button,
  LinkButton,
} from 'components/Shared';

export function Address({
  setCurrentProgress,
  currentProgress,
  dataAddress,
  setDataAddress,
}) {
  const formRef = useRef(null);

  async function sendData(data) {
    try {
      const {
        address: { city_id },
      } = await handleZipcode(data.address.zipcode);

      const lacalization = await handleGeoCoder(data.address);
      data = {
        address: {
          ...data.address,
          city_id: city_id,
          lat: lacalization.lat,
          lng: lacalization.lng,
        },
      };
      delete data.address.type;
      setDataAddress(data);
      setCurrentProgress(currentProgress + 1);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Container>
      <AddresForm
        formRef={formRef}
        sendData={sendData}
        dataAddress={dataAddress}
      >
        {' '}
        <Buttons>
          <LinkButton typeColor="disable" type="button" to="/dashboard">
            Cancelar
          </LinkButton>
          <Button type="submit" typeColor="primary">
            Confirmar e Avançar
          </Button>
        </Buttons>
      </AddresForm>
    </Container>
  );
}
