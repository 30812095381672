export async function handleGeoCoder(address) {
  const { street, number, district, city, zipcode } = address;

  const geocoder = new window.google.maps.Geocoder();

  const GEO = await geocoder
    .geocode({
      address: `
          ${street}, ${number} - ${district}, ${city.name} - ${zipcode}
        `,
    })
    .then(response => {
      return {
        lat: response.results[0].geometry.location.lat(),
        lng: response.results[0].geometry.location.lng(),
      };
    });

  return GEO;
}
