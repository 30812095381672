import React from 'react';

import { Box } from 'components/Shared';
import { ReelsContainer, ButtonReel } from './styles';
import { Titles } from 'components/Titles';
import { painelByRole } from './Components/HotRoutesByRoles';
import { useSelector } from 'react-redux';
import { LastRegisters } from './Components/LastRegisters';
import { ClientDashboard } from './Components/clientDashboard';
import { GuestDashboard } from './Components/guestDashboard';

export function Home() {
  const { role } = useSelector(state => state.user.profile);
  const painelReels = painelByRole(role);

  return (
    <Box>
      <Titles title="Painel" subtitle="Resumo Informativo" />
      {(role === 'admin' || role === 'broker') && (
        <>
          <ReelsContainer>
            {painelReels.map(item => {
              return (
                <ButtonReel key={item.name} to={item.path}>
                  {item.icon()}
                  {item.name}
                </ButtonReel>
              );
            })}
          </ReelsContainer>
          <LastRegisters />
        </>
      )}
      {role === 'owner' && (
        <>
          <ClientDashboard />
        </>
      )}
      {role === 'guest' && (
        <>
          <GuestDashboard />
        </>
      )}
    </Box>
  );
}
