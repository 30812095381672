import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AllContent = styled.div`
  transition: all 0.3s ease-in-out;
  &.max {
    width: calc(100% - 240px);
  }
  &.min {
    width: calc(100% - 80px);
  }

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

export const Space = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;
`;
