import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { UserDocumentsForm } from 'components/User/Components/Forms/UserDocumentsForm';
import {
  SendDocumentsData,
  SendEditDocumentsData,
  SendDeleteDocumentsPhoto,
  SendDocumentFile,
} from 'components/User/CreateUser/UserDocuments/Services';
import { DocumentsSummary } from '../../Summaries/DocumentsSummary';
import { Buttons, Button, ContentVerificationData } from 'components/Shared';
import { toast } from 'react-toastify';

export function Documents({ documents, setDocuments }) {
  const formRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const { id: userID } = useParams();

  function documentsType(document) {
    const documents = [
      document?.find(doc => doc.type !== 'rg'),
      document?.find(doc => doc.type === 'rg'),
    ];

    return {
      documents,
    };
  }

  async function sendData(data) {
    const findFiles = data.documents.find(doc => doc.file).file;
    const documentsWithId = ({ documents, response }) =>
      documents.map((document, index) => {
        return {
          ...document,
          id: response[index].id,
        };
      });

    if (edit) {
      try {
        const documentResponse = await SendEditDocumentsData(
          data,
          userID,
          documents
        );

        const photoFile = documents => {
          const photo = documents.find(item => item.file);
          return photo || null;
        };

        const newDocuments = documentsWithId({
          documents: data.documents,
          response: documentResponse,
        });

        if (findFiles.length > 0) {
          if (photoFile(documents)) {
            for (const file of photoFile(documents).file) {
              await SendDeleteDocumentsPhoto(file.id);
            }
          }

          await SendDocumentFile(newDocuments);
        }
        setDocuments(newDocuments);
        toast.success('Documentos adicionados com sucesso!');
        setEdit(false);
      } catch (err) {
        console.log(err);
        toast.error('Erro ao adicionar documentos!');
      }
    } else {
      try {
        const documentResponse = await SendDocumentsData(data, userID);
        const newDocuments = documentsWithId({
          documents: data.documents,
          response: documentResponse,
        });
        if (findFiles.length > 0) {
          await SendDocumentFile(newDocuments);
        }
        setDocuments(newDocuments);
        toast.success('Documentos adicionados com sucesso!');
        setEdit(false);
      } catch (error) {
        toast.error('Erro ao adicionar documentos!');
      }
    }
  }

  return (
    <>
      {!edit && documents ? (
        <DocumentsSummary
          setDocuments={setDocuments}
          documents={documents}
          title="Documentos"
        >
          <Buttons>
            <Button typeColor="primary" onClick={() => setEdit(true)}>
              Editar
            </Button>
          </Buttons>
        </DocumentsSummary>
      ) : (
        <ContentVerificationData>
          <h1>Adicione os Documentos</h1>
          <UserDocumentsForm
            formRef={formRef}
            dataDocuments={documentsType(documents)}
            sendData={sendData}
          >
            <Buttons>
              {edit && (
                <Button
                  typeColor="disable"
                  type="button"
                  onClick={() => setEdit(false)}
                >
                  Cancelar
                </Button>
              )}
              <Button
                style={{ marginLeft: 'auto' }}
                typeColor="primary"
                type="submit"
              >
                Enviar
              </Button>
            </Buttons>
          </UserDocumentsForm>
        </ContentVerificationData>
      )}
    </>
  );
}
