import styled from 'styled-components';

export const ContainerList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 32px 0;
  background: ${props => props.theme.backgrounds.secondary};
  padding: 16px 24px;
  border-radius: 8px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > h2 {
      font-size: 16px;
      font-weight: 500;
    }
    > button {
      background: transparent;
      padding: 8px;
      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 16px;
    > li {
      display: flex;
      min-width: 180px;
      align-items: center;
      background: ${props => props.theme.container.background};
      padding: 16px 24px;
      border-radius: 8px;
      border: 1px solid #055eff;
      flex: 0.2 1 0;
      > span {
        display: flex;
        width: auto;
        justify-content: space-between;
        margin-right: 0;
        > button {
          display: flex;
          align-items: center;
          background: #d8010e;
          border-radius: 4px;
          padding: 12px;
          color: #ffff;
          cursor: pointer;
          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
      > div {
        display: flex;
        flex-direction: row-reverse;
        gap: 16px;
        cursor: pointer;
        > p {
          font-size: 16px;
          text-align: center;

          &:last-child {
            margin-bottom: 0;
          }
        }
        &:last-child {
          margin: 0;
        }
      }

      &:last-child {
        margin-right: 0;
        border: 2px dashed #055eff;
        justify-content: space-evenly;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          div {
            display: flex;
            justify-content: center;
            text-align: center;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background: ${props => props.theme.backgrounds.primary};
            margin-bottom: 16px;
            > svg {
              align-self: center;
              height: 32px;
              width: 32px;
              color: white;
            }
          }
        }
      }
    }
  }
`;

export const IconButton = styled.button`
  display: flex;
  position: relative;
  background: #055eff;
  padding: 8px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  > svg {
    height: 24px;
    width: 24px;
    color: white;
  }

  &.success {
    background: #0b8e63;
    > svg {
      color: #ffff;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
