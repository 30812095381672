import React, { useState } from 'react';

import { States } from 'components/WebManage/Components/States';
import { CitiesCard } from 'components/WebManage/Components/CitiesCards';
import { FormsCity } from 'components/WebManage/Components/FormsCity';
import { Container } from 'components/Shared';

export function Cities() {
  const [citiesByState, setCitiesByState] = useState([]);

  return (
    <Container>
      <States setCitiesByState={setCitiesByState} />
      <FormsCity citiesByState={citiesByState} />
      <CitiesCard citiesByState={citiesByState} />
    </Container>
  );
}
