import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { Container, PhotoName, ErrorBallon } from './styles';
import front from '../../../../assets/icons/frontDocument.png';
import back from '../../../../assets/icons/behaviorDocument.png';

export function DropzoneDocument({ name, photoType, ...rest }) {
  const { fieldName, registerField, error, clearError } = useField(name);
  const [photo, setPhoto] = useState(null);
  const fileInput = useRef();

  const typeDocument = {
    front: {
      name: 'Frente',
      subtitle: 'da frente',
      icon: front,
    },
    back: {
      name: 'Verso',
      subtitle: 'do verso',
      icon: back,
    },
    spouse: {
      name: 'Contrato de Comunhão',
      subtitle: 'da frente',
      icon: front,
    },
  };

  function handlePhoto(e) {
    const file = e.target.files[0];
    if (
      (file && file.type.match('image.*')) ||
      file.type.match('application/pdf')
    ) {
      setPhoto(file);
    } else {
      setPhoto(null);
    }
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: fileInput.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
        setPhoto(null);
      },
      getValue(ref) {
        if (ref.files[0]) {
          return ref.files[0];
        } else {
          return null;
        }
      },
      setValue() {
        return null;
      },
    });
  }, [registerField, fieldName, photo]);

  return (
    <Container>
      {error && (
        <ErrorBallon>
          <div>
            <p>{error}</p>
          </div>
        </ErrorBallon>
      )}
      <button
        className={error ? 'error' : ''}
        onClick={event => {
          event.preventDefault();
          fileInput.current.click();
        }}
        onFocus={() => clearError()}
      >
        <h2>{typeDocument[photoType].name}</h2>
        {photo ? (
          <PhotoName>
            <p>{photo?.name}</p>
          </PhotoName>
        ) : (
          <img alt="dropzone" src={typeDocument[photoType].icon} />
        )}
        <p>
          Clique aqui para enviar a foto do documento de{' '}
          {typeDocument[photoType].name}
        </p>
      </button>
      <input
        type="file"
        accept=".jpg, .jpeg, .png, application/pdf"
        onChange={event => {
          handlePhoto(event);
        }}
        ref={fileInput}
        {...rest}
      ></input>
    </Container>
  );
}
