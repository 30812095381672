import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Container = styled(NavLink)`
  text-decoration: none;
  font-size: 16px;
  line-height: 140%;
  color: #055eff;
  margin-top: 24px;
`
