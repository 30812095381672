import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import ApiService from '../../../services/service.api';
import TokenService from '../../../services/service.token';
import Role from '../../../services/service.role';

import history from '../../../services/service.history';

import { signInSuccess, signFailure } from './actions';
import jarvis from 'util/jarvis';

export function* signIn({ payload }) {
  if (payload.token) {
    try {
      const response = yield call(
        jarvis.post,
        `/auth/property/${payload.token}`,
        {
          headers: {
            'X-Api-key': process.env.REACT_APP_KEY,
            'X-Client-Id': process.env.REACT_APP_CLIENT_ID,
          },
        }
      );

      const { token } = response.data;

      const user = {
        email: '',
        role: 'guest',
        name: 'Convidado',
        lastName: '',
        id: 0,
        property: response.data.property,
      };

      const UserRole = new Role(user.role);

      const { isOwner } = UserRole.getRole();

      TokenService.setAccessToken(token);
      TokenService.setRefreshToken(refreshToken);
      ApiService.setHeaderAuthorization();

      ApiService.mount401Interceptor();

      yield put(
        signInSuccess(token, refreshToken, {
          ...user,
          isOwner: isOwner(),
          isGuest: true,
        })
      );
      history.push('/dashboard');
    } catch (err) {
      toast.error('Falha na autenticação, verifique seus dados');
      yield put(signFailure());
    }
  } else {
    try {
      const { email, password, type = 'signin', code } = payload;
      console.log(payload);
      console.log(code);
      if (type === 'signin') {
        const response = yield call(
          jarvis.post,
          'signin',
          {
            email,
            password,
          },
          {
            headers: {
              'X-Api-key': process.env.REACT_APP_KEY,
              'X-Client-Id': process.env.REACT_APP_CLIENT_ID,
            },
          }
        );

        const {
          access_token: token,
          refresh_token: refreshToken,
        } = response.data;

        const user = {
          email: response.data.email,
          role: response.data.role,
          name: response.data.name,
          lastName: response.data.lastName,
          id: response.data.id,
        };

        const UserRole = new Role(user.role);

        const { isOwner, isAdmin, isTenant, isBroker } = UserRole.getRole();

        TokenService.setAccessToken(token);
        TokenService.setRefreshToken(refreshToken);
        ApiService.setHeaderAuthorization();

        ApiService.mount401Interceptor();

        yield put(
          signInSuccess(token, refreshToken, {
            ...user,
            isOwner: isOwner(),
            isAdmin: isAdmin(),
            isBroker: isBroker(),
            isTenant: isTenant(),
          })
        );
        history.push('/dashboard');
      }

      if (type === 'validate') {
        console.log(email, code);
        const response = yield call(
          jarvis.post,
          'validate-account',
          {
            email,
            code,
          },
          {
            headers: {
              'X-Api-key': process.env.REACT_APP_KEY,
              'X-Client-Id': process.env.REACT_APP_CLIENT_ID,
            },
          }
        );
        toast.success('Código validado com sucesso!');
        if (!response.data.access_token) {
          toast.warn('Talvez você já tenha uma conta cadastrada.');

          yield put(signFailure());
          return;
        }

        const {
          access_token: token,
          refresh_token: refreshToken,
        } = response.data;

        const user = {
          email: response.data.email,
          role: response.data.role,
          name: response.data.name,
          lastName: response.data.lastName,
          id: response.data.id,
        };

        const UserRole = new Role(user.role);

        const { isOwner, isAdmin, isTenant, isBroker } = UserRole.getRole();

        TokenService.setAccessToken(token);
        TokenService.setRefreshToken(refreshToken);
        ApiService.setHeaderAuthorization();

        ApiService.mount401Interceptor();

        yield put(
          signInSuccess(token, refreshToken, {
            ...user,
            nopass: true,
            isOwner: isOwner(),
            isAdmin: isAdmin(),
            isBroker: isBroker(),
            isTenant: isTenant(),
          })
        );
      }
    } catch (err) {
      toast.error('Falha na autenticação, verifique seus dados');
      yield put(signFailure());
    }
  }
}
export function* refreshToken() {}

export function logout() {
  TokenService.deleteAccessToken();
  TokenService.deleteRefreshToken();
  ApiService.deleteHeaderAuthorization();

  ApiService.unmount401Interceptor();
  history.push('/');
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/LOGOUT', logout),
  takeLatest('@auth/REFRESH_TOKEN', refreshToken),
]);

// toast.error('Permissão negada')
// yield put(signFailure())
