import React, { useState } from 'react';
import {
  Box,
  ContentScroll,
  EmptyList,
  Filter,
  FilterContainer,
  SelectedAsyncFunction,
} from 'components/Shared';
import { Titles } from 'components/Titles';
import { RentalSelect } from './components/RentalSelect';
import { Pagination } from 'components/Pagination';
import { FilterOptions, UserOptions } from './Options';

import { getRentals } from './service';

export function RentalList() {
  const [currentParams, setCurrentParams] = useState({
    status: 'all',
    page: 1,
    perPage: 10,
    order: 'desc',
  });

  const [selectValue, setSelectValue] = useState('');

  const { data, isLoading } = getRentals(currentParams);

  async function handlePageChange({ selected }) {
    const newParams = { ...currentParams, page: selected };
    if (newParams.userId) {
      delete newParams.userId;
      delete newParams.role;
    }
    setSelectValue('');
    setCurrentParams(newParams);
  }

  async function handleFilterChange(value) {
    const newParams = { ...currentParams, status: value };
    if (newParams.userId) {
      delete newParams.userId;
      delete newParams.role;
    }
    setSelectValue('');
    setCurrentParams(newParams);
  }

  async function handleFilterUserChange(user) {
    const newParams = { ...currentParams, userId: user.value, role: user.role };
    setCurrentParams(newParams);
  }

  return (
    <Box>
      <Titles title="Aluguéis" subtitle="Lista de aluguéis" />
      <FilterContainer style={{ gap: 8 }}>
        <Filter
          filter={FilterOptions}
          handleFilter={filter => handleFilterChange(filter.value)}
          active={currentParams.status}
        />
        <SelectedAsyncFunction
          name="Filtros"
          className="field-select"
          placeholder="Selecione o cliente"
          loadOptions={UserOptions}
          noOptionsMessage={() => 'Sem opções'}
          value={selectValue}
          additional={{
            page: 1,
          }}
          onChange={user => {
            handleFilterUserChange(user);
            setSelectValue(user);
          }}
        />
      </FilterContainer>
      {isLoading || (
        <>
          {data.total > 0 ? (
            <>
              <ContentScroll style={{ marginTop: 24 }}>
                {data.rentals.map(rental => (
                  <RentalSelect key={rental.id} rental={rental} />
                ))}
              </ContentScroll>
              <Pagination
                currentPage={currentParams.page}
                pagesSize={currentParams.perPage}
                totalCount={data.total}
                onPageChange={handlePageChange}
              />
            </>
          ) : (
            <EmptyList />
          )}
        </>
      )}
    </Box>
  );
}
