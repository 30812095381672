import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { MdRotateLeft, MdRotateRight } from 'react-icons/md';

import { Container, Image } from './styles';

export function FocusedImage({ image, setFocusedMediaId }) {
  const [rotate, setRotate] = useState(image.rotation);

  const handleLeftRotate = useCallback(() => {
    if (rotate === 0) {
      setRotate(270);
    }
    if (rotate === 90) {
      setRotate(0);
    }
    if (rotate === 180) {
      setRotate(90);
    }
    if (rotate === 270) {
      setRotate(180);
    }
  }, [rotate, setRotate]);

  const handleRightRotate = useCallback(() => {
    if (rotate === 0) {
      setRotate(90);
    }
    if (rotate === 90) {
      setRotate(180);
    }
    if (rotate === 180) {
      setRotate(270);
    }
    if (rotate === 270) {
      setRotate(0);
    }
  }, [rotate, setRotate]);

  const handleRotation = useCallback(async () => {
    await axios.put(`/media/${image.id}/rotation/${rotate}`);

    setFocusedMediaId(undefined);
    toast.success(`Rotação atualizada com sucesso!`);
  }, [rotate, setFocusedMediaId, image.id]);

  return (
    <Container>
      <Image rotate={rotate} src={image.url_image_770x650} />
      <div>
        <button
          style={{ background: '#D8010E' }}
          onClick={() => setFocusedMediaId(undefined)}
        >
          Voltar
        </button>
        <button onClick={handleLeftRotate}>
          <MdRotateLeft />
        </button>
        <button onClick={handleRightRotate}>
          <MdRotateRight />
        </button>
        <button style={{ background: '#0DAF7A' }} onClick={handleRotation}>
          Salvar
        </button>
      </div>
    </Container>
  );
}
