import styled from 'styled-components';

export const Container = styled.div`
  z-index: 9;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  padding: 6px 12px;

  @media screen and (max-width: 990px) {
    left: 48px;
    top: 4px;
  }
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  align-items: center;
  color: ${props => props.theme.dashboard.color};

  margin: 12px 12px 0 12px;
  @media screen and (max-width: 990px) {
    font-size: 16px;
  }
`;

export const Subtitle = styled.span`
  margin-top: 10px;
  font-size: 16px;
  line-height: 100%;
  align-items: center;
  color: ${props => props.theme.dashboard.color};

  margin: 10px 12px 12px 12px;
  @media screen and (max-width: 990px) {
    font-size: 14px;
  }
`;
