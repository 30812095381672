import React from 'react';

import { TitleContainer } from 'components/Shared';
import { CondoAmount } from '../../components/Forms/CondoAmount';
import { DayPayment } from '../../components/Forms/DayPayment';
import { RentalAmount } from '../../components/Forms/RentalAmount';
import useFetch from 'services/ReactQuery';
import { toast } from 'react-toastify';

export function Payments({ rental }) {
  const { amount, dayPayment, amountTownhouse, id } = rental;
  const { mutateAsync: updateRental } = useFetch.Put();
  const { mutateAsync: updateTownhouse } = useFetch.Put();
  const { mutateAsync: updateDayPayment } = useFetch.Put();

  async function sendRentalAmount(data) {
    try {
      await updateRental({
        url: `/rental/update/amount/${id}`,
        body: {
          amount: data.rental.amount,
        },
        params: {
          id: id,
        },
        key: `/rental`,
      });
      toast.success('Valor do aluguel atualizado com sucesso!');
    } catch (error) {
      console.warn(error);
      toast.error('Erro ao atualizar valor do aluguel!');
    }
  }

  async function sendCondoAmount(data) {
    try {
      await updateTownhouse({
        url: `rental/update/amount-townhouse/${id}`,
        body: {
          amountTownhouse: data.rental.amountTownhouse,
        },
        params: {
          id: id,
        },
        key: `/rental`,
      });
      toast.success('Valor do condomínio atualizado com sucesso!');
    } catch (error) {
      console.warn(error);
      toast.error('Erro ao atualizar valor do condomínio!');
    }
  }

  async function sendDayPayment(data) {
    try {
      await updateDayPayment({
        url: `rental/daypayment/${id}`,
        body: {
          dayPayment: Number(data.dayPayment),
        },
        params: {
          id: id,
        },
        key: `/rental`,
      });
      toast.success('Dia de pagamento atualizado com sucesso!');
    } catch (error) {
      console.warn(error);
      toast.error('Erro ao atualizar dia de pagamento!');
    }
  }

  return (
    <>
      <TitleContainer>
        <h1>Pagamentos do Aluguel:</h1>
      </TitleContainer>
      <RentalAmount amount={amount} sendRentalAmount={sendRentalAmount} />
      <CondoAmount
        townhouseAmount={amountTownhouse}
        sendCondoAmount={sendCondoAmount}
      />
      <DayPayment dayPayment={dayPayment} sendDayPayment={sendDayPayment} />
    </>
  );
}
