import React, { useState, useEffect, useRef } from 'react';

import { formValidation } from './Validation';
import { validationError } from 'validations/forms';
import { handleFormDTO } from './Context';

import {
  Content,
  LineSpace,
  TitleContainer,
  InputSelect,
} from 'components/Shared';

import { AddressInputs } from 'components/Shared/AddresForm/Components/AddressInputs';
import { ValuesInputs } from '../CreateProperty/Components/Forms/Components/ValuesInputs';
import { SpecificationsInputs } from '../CreateProperty/Components/Forms/Components/SpecificationsInputs';
import { AdditionalInputs } from '../CreateProperty/Components/Forms/Components/AdditionalInputs';
import useFetch from 'services/ReactQuery';

import { handleTypes } from '../CreateProperty/Components/Forms/TypeForm/service';

export function EditPropertyForm({
  children,
  propertyData,
  propertyId,
  setEdit,
  setIsLoading,
}) {
  const formRef = useRef(null);
  const [data, setData] = useState();
  const { mutateAsync: handleData } = useFetch.Put();

  useEffect(() => {
    async function getData() {
      const filedData = await handleTypes();
      setData(filedData);
    }
    getData();
  }, []);

  async function submit(data) {
    const propertyFormData = handleFormDTO(propertyData, data);
    setIsLoading(true);
    try {
      await formValidation.validate(propertyFormData, {
        abortEarly: false,
      });
      await handleData({
        url: `properties/${propertyId}`,
        body: propertyFormData,
        key: `/property/${propertyId}`,
      });
      setEdit(false);
      setIsLoading(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof validationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (propertyData) {
      formRef.current.setData(propertyData);
      formRef.current.setFieldValue('types', propertyData.type.id);
    }
  }, [propertyData, formRef, data]);

  return (
    <Content ref={formRef} onSubmit={submit}>
      <TitleContainer>
        <h2>TIPO</h2>
      </TitleContainer>
      <InputSelect
        className="half"
        name="types"
        title="Casa ou Apartamento?"
        required={false}
        options={data}
      />
      <LineSpace />
      <TitleContainer>
        <h2>ENDEREÇO</h2>
      </TitleContainer>
      <AddressInputs formRef={formRef} />
      <LineSpace />
      <TitleContainer>
        <h2>VALORES</h2>
      </TitleContainer>
      <ValuesInputs formRef={formRef} />
      <LineSpace />
      <TitleContainer>
        <h2>ESPECIFICAÇÕES</h2>
      </TitleContainer>
      <SpecificationsInputs formRef={formRef} />
      <LineSpace />
      <TitleContainer>
        <h2>INFORMAÇÕES ADICIONAIS</h2>
      </TitleContainer>
      <AdditionalInputs formRef={formRef} />
      {children}
    </Content>
  );
}
