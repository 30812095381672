import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import useFetch from 'services/ReactQuery';

import { Carousel } from './Components/Carousel';
import { CardPhoto } from './Components/CardPhoto';
import { Box, Button, Buttons } from 'components/Shared';
import { toast } from 'react-toastify';
import { ImageEditor } from 'util/ImageCrop';
import { UploadList } from './Components/UploadList';

export function PhotoGalery({ propertyData }) {
  const [photos, setPhotos] = useState(propertyData.images || []);
  const [ImageToEdit, setImageToEdit] = useState(false);
  const { id } = propertyData;
  const { mutateAsync: sendPropertyImages } = useFetch.Post();

  useEffect(() => {
    const sortedPhotos = photos.sort((a, b) => {
      return b.main - a.main;
    });
    setPhotos(sortedPhotos);
  }, [photos]);

  async function sendImages(formData) {
    try {
      await sendPropertyImages({
        url: `/property/${id}/file`,
        body: formData,
        key: `property/${id}`,
      });
      toast.success('Imagens enviadas com sucesso');
    } catch (err) {
      toast.error('Erro ao enviar as imagens');
    }
  }

  async function downlaodList() {
    toast.warn('Aguarde!');
    try {
      const zip = new JSZip();
      var img = zip.folder('fotos');
      for (const [index, item] of photos.entries()) {
        const image = await fetch(
          item.url_image_original || item.url_image_1140x550,
          {
            method: 'GET',
            credentials: 'omit',
            headers: {
              Accept:
                'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
              'Accept-Encoding': ' gzip, deflate, br',
              'Accept-Language': 'pt-BR,pt',
              'Cache-Control': 'no-cache',
              Connection: 'keep-alive',
              Host: 'wz-thanos.s3-sa-east-1.amazonaws.com',
            },
          }
        );
        const imageBlob = await image.blob();

        img.file(`foto${index}.jpg`, imageBlob, { base64: true });
      }

      zip.generateAsync({ type: 'blob' }).then(function(content) {
        saveAs(content, `fotos - imovel:${id} `);
      });
    } catch (err) {
      toast.error('Erro ao baixar as imagens');
    }
  }

  return (
    <Box style={{ display: 'inline-grid' }}>
      {ImageToEdit && (
        <ImageEditor setImageEdit={setImageToEdit} imageEdit={ImageToEdit} />
      )}
      {photos?.length || !photos ? (
        <>
          <Buttons style={{ marginBottom: 16 }}>
            <Button typeColor={'primary'} onClick={() => downlaodList()}>
              Download das Fotos
            </Button>
            <UploadList serviceFn={sendImages} />
          </Buttons>
          <Carousel>
            {photos?.map(photo => {
              return (
                <CardPhoto
                  key={photo.id}
                  propertyData={propertyData}
                  photo={photo}
                  setPhotos={setPhotos}
                  setImageToEdit={setImageToEdit}
                />
              );
            })}
          </Carousel>
        </>
      ) : (
        <Buttons style={{ marginBottom: 16 }}>
          <UploadList serviceFn={sendImages} />
        </Buttons>
      )}
    </Box>
  );
}
