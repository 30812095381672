import React, { useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Buttons, Button, ModalScreen } from 'components/Shared';
import { ContentVerificationData } from 'components/Shared';
import { AgentListType } from 'components/User/Components/Forms/AgentForm/Components/ListType';
import { EssencialAgentForm } from 'components/User/Components/Forms/AgentForm/Components/EssencialAgantForm';
import { DocumentsAgentForm } from 'components/User/Components/Forms/AgentForm/Components/DocumentsAgentForm';
import { AddresForm } from 'components/Shared';
import { EssencialAgentSummary } from '../../Summaries/AgentsSummary/EssencialAgentSummary';
import { DocumentsSummary } from '../../Summaries/DocumentsSummary';
import { AddressSummary } from '../../Summaries/AddressSummary';
import { SpouseAgentForm } from 'components/User/Components/Forms/AgentForm/Components/SpouseAgentForm';
import { toast } from 'react-toastify';

import {
  SendFormAddress,
  SendFormEditAddress,
  SendFormEssencial,
  SendFormEditEssencial,
  SendDeleteAgent,
  sendAgentDocument,
  SendAgentFilesToDocuments,
  sendToDeleteFiles,
  sendEditAgentDocuments,
} from 'components/User/CreateUser/Agent/Services';

export function LegalAgents({ dataAgent, setAgent, userRole }) {
  const formRef = useRef(null);

  const [editEssencial, setEditEssencial] = useState(false);
  const [editDocuments, setEditDocuments] = useState(false);
  const [deleteAgent, setDeleteAgent] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [agentInformation, setAgentInformation] = useState();
  const [subAgentInformation, setSubAgentInformation] = useState(null);
  const [formPage, setFormPage] = useState();
  const { id: userID } = useParams();

  const [agentDocuments, setAgentDocuments] = useState();
  const [agentAddress, setAgentAddress] = useState();

  useMemo(() => {
    if (agentInformation) {
      const data = agentInformation;
      setAgentDocuments(data.documents);
      setAgentAddress(data.address);
    }
  }, [agentInformation]);

  function handleNewData(data) {
    const newArray = dataAgent.map(item => {
      if (item === agentInformation) {
        const newData = {
          ...agentInformation,
          ...data,
        };
        return newData;
      }
      return item;
    });
    setAgent(newArray);
  }

  async function handleSendEssencial(data) {
    if (editEssencial && agentInformation) {
      try {
        const response = await SendFormEditEssencial(
          data,
          agentInformation.id,
          agentInformation.profile.id
        );
        data = {
          ...response,
        };

        const editAgentData = dataAgent
          .filter(item => item !== agentInformation)
          .concat(data);
        setAgent(editAgentData);
        setEditEssencial(false);
        setFormPage(null);
        toast.success('Responsável adicionado com sucesso!');
      } catch (err) {
        toast.error('Erro ao adicionar responsável!');
      }
    } else {
      try {
        const response = await SendFormEssencial(data, userID);
        console.log(response);
        data = {
          ...response,
        };
        setAgent([...dataAgent, data]);
        setFormPage(null);
        toast.success('Responsável adicionado com sucesso!');
      } catch (err) {
        toast.error('Erro ao adicionar Responsável!');
      }
    }
  }
  async function handleSendAddress(data) {
    if (editAddress) {
      try {
        await SendFormEditAddress(
          data,
          agentInformation.id,
          agentInformation.profile.id
        );
        toast.success('Endereço do responsável adicionado com sucesso!');
        setFormPage(null);
        handleNewData(data);
      } catch (err) {
        toast.error('Erro ao adicionar endereço do responsável!');
      }
    } else {
      try {
        await SendFormAddress(data, agentInformation.id);
        toast.success('Endereço do responsável adicionado com sucesso!');
        setFormPage(null);
        handleNewData(data);
      } catch (err) {
        toast.error('Erro ao adicionar endereço do responsável!');
      }
    }
  }

  async function handleSendDocuments(data) {
    if (editDocuments) {
      try {
        const responseDocuments = await sendEditAgentDocuments(
          data,
          agentInformation.documents,
          agentInformation.id
        );
        const hasFileForm = data.documents.find(item => item.file);
        let allFiles = [];
        if (hasFileForm.file.length > 0) {
          agentInformation.documents.map(item => {
            if (item.file) {
              const findFile = item.file.filter(file => file);
              return allFiles.push(findFile);
            }
          });
          const unionFiles = allFiles.flat();
          if (unionFiles.length > 0) {
            await sendToDeleteFiles(unionFiles);
          }
          await SendAgentFilesToDocuments(data, responseDocuments.documents);
        }
        handleNewData(responseDocuments);
        setFormPage(null);
        setEditDocuments(false);
        toast.success('Documentos do responsável adicionado com sucesso!');
      } catch (error) {
        console.log(error);
        toast.error('Erro ao adicionar documentos do responsável!');
      }
    } else {
      try {
        const responseDocuments = await sendAgentDocument(
          data,
          agentInformation?.id
        );
        await SendAgentFilesToDocuments(data, responseDocuments.documents);
        handleNewData(responseDocuments);
        setFormPage(null);
        setEditDocuments(false);
        toast.success('Documentos do responsável adicionado com sucesso!');
      } catch (err) {
        toast.error('Erro ao adicionar documentos do responsável!');
      }
    }
  }

  async function handleDeleteAgent() {
    try {
      await SendDeleteAgent(agentInformation.id);
      const newArray = dataAgent.filter(item => item !== agentInformation);
      setAgent(newArray);
      setDeleteAgent(false);
      setFormPage(null);
      toast.success('Responsável removido com sucesso!');
    } catch (err) {
      toast.error('Erro ao remover responsável!');
    }
  }

  const handlePage = {
    essencial:
      !editEssencial && agentInformation ? (
        <EssencialAgentSummary agentInformation={agentInformation}>
          <Buttons>
            <Button typeColor="primary" onClick={() => setEditEssencial(true)}>
              Editar
            </Button>
          </Buttons>
        </EssencialAgentSummary>
      ) : (
        <ContentVerificationData>
          <EssencialAgentForm
            formRef={formRef}
            sendDataForm={handleSendEssencial}
            getEssencialAgent={agentInformation}
            userRole={userRole}
          >
            <Buttons>
              <Button
                typeColor="disable"
                type="button"
                onClick={() => setEditEssencial(false)}
              >
                Cancelar
              </Button>
              <Button typeColor="primary" type="submit">
                Salvar
              </Button>
            </Buttons>
          </EssencialAgentForm>
        </ContentVerificationData>
      ),
    documents:
      !editDocuments && agentDocuments ? (
        <DocumentsSummary
          documents={agentDocuments}
          setDocuments={setAgentDocuments}
          title={'Documentos do responável'}
        >
          <Buttons>
            <Button typeColor="primary" onClick={() => setEditDocuments(true)}>
              Editar
            </Button>
          </Buttons>
        </DocumentsSummary>
      ) : (
        <ContentVerificationData>
          <DocumentsAgentForm
            getDocuments={agentInformation}
            formRef={formRef}
            sendDataForm={handleSendDocuments}
          >
            <Buttons>
              {editDocuments && (
                <Button
                  typeColor="disable"
                  type="button"
                  onClick={() => setEditDocuments(false)}
                >
                  Cancelar
                </Button>
              )}
              <Button
                style={{ marginLeft: 'auto' }}
                typeColor="primary"
                type="submit"
              >
                Enviar
              </Button>
            </Buttons>
          </DocumentsAgentForm>
        </ContentVerificationData>
      ),
    address:
      !editAddress && agentAddress ? (
        <AddressSummary
          address={agentAddress}
          title={'Endereço do responsável'}
        >
          <Buttons>
            <Button typeColor="primary" onClick={() => setEditAddress(true)}>
              Editar
            </Button>
          </Buttons>
        </AddressSummary>
      ) : (
        <ContentVerificationData>
          <h2>Endereço do Responsável</h2>
          <AddresForm
            dataAddress={agentInformation}
            sendData={handleSendAddress}
            formRef={formRef}
          >
            <Buttons>
              {editAddress && (
                <Button
                  typeColor="disable"
                  type="button"
                  onClick={() => setEditAddress(false)}
                >
                  Cancelar
                </Button>
              )}
              <Button
                style={{ marginLeft: 'auto' }}
                typeColor="primary"
                type="submit"
              >
                Enviar
              </Button>
            </Buttons>
          </AddresForm>
        </ContentVerificationData>
      ),
    spouse: (
      <SpouseAgentForm
        userRole={userRole}
        agentInformation={agentInformation}
        userID={userID}
        subAgentInformation={subAgentInformation}
        setSubAgentInformation={setSubAgentInformation}
      />
    ),
  };

  return (
    <>
      <AgentListType
        dataAgent={dataAgent}
        setAgentInformation={setAgentInformation}
        setFormPage={setFormPage}
        setDeleteAgent={setDeleteAgent}
        setEdit={setEditEssencial}
        setSubAgentInformation={setSubAgentInformation}
      />
      {handlePage[formPage]}
      <ModalScreen open={deleteAgent}>
        <div>
          <h1>Excluir Responsável</h1>
          <p style={{ textAlignLast: 'center' }}>
            <strong>
              {agentInformation?.name} {agentInformation?.lastName}
            </strong>
          </p>
          <p style={{ textAlignLast: 'center' }}>
            Será excluido permanentemente do sistema. Deseja continuar?
          </p>
          <Buttons>
            <Button typeColor="secondary" onClick={() => setDeleteAgent(false)}>
              Cancelar
            </Button>
            <Button
              typeColor="cancel"
              type="button"
              onClick={() => handleDeleteAgent()}
            >
              Excluir
            </Button>
          </Buttons>
        </div>
      </ModalScreen>
    </>
  );
}
