import styled from 'styled-components';

export const Container = styled.label`
  width: 100%;
  margin: 8px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: ${props => props.theme.colors.primary};
`;
