import { useQuery } from 'react-query';
import jarvis from 'util/jarvis';

export function AdminQuery(currentParams) {
  const { data, isLoading } = useQuery(
    ['/users', currentParams],
    async () => {
      return jarvis.get(`/users`, { params: currentParams }).then(res => {
        return res.data;
      });
    },
    { refetchOnWindowFocus: false, enabled: currentParams.search !== null }
  );

  return {
    data: data,
    isLoading: isLoading,
  };
}

export function BrokerQuery(currentParams) {
  const { data, isLoading } = useQuery(
    ['/users/broker', currentParams],
    async () => {
      return jarvis
        .get(`/users/broker`, {
          params: {
            search: currentParams.search,
            filter: {
              role: 'tenant',
            },
          },
        })
        .then(res => {
          return res.data;
        });
    },
    { refetchOnWindowFocus: false, enabled: currentParams.search !== null }
  );

  return {
    data: data,
    isLoading: isLoading,
  };
}
