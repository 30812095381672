import React, { useRef } from 'react';
import InputSelect from 'react-select';

const SelectInput = ({ options, ...rest }) => {
  const selectRef = useRef(null);

  return (
    <>
      <InputSelect
        ref={selectRef}
        options={options}
        classNamePrefix="react-select"
        {...rest}
      />
    </>
  );
};

export default SelectInput;
