import styled from 'styled-components';

export const InputRadioStyled = styled.label`
  display: flex;
  margin-top: 16px;

  > input {
    display: none;
  }

  > span {
    background: #dfe0e2;
    padding: 16px;
    border-radius: 8px;
    font-size: 16px;
    color: #989ba1;
  }
  .isActive {
    background: #055eff;
    color: white;
  }
`;
