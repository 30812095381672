import React from 'react';
import { useRef, useCallback } from 'react';

import {
  Content,
  InputCalendar,
  InputSelect,
  InputNumber,
} from 'components/Shared';
import { transactionValidation } from './Validation';
import { validationError } from 'validations/forms';

export function TransactionForm({ sendData, children }) {
  const formRef = useRef(null);

  const handleSubmit = useCallback(
    async data => {
      try {
        await transactionValidation.validate(data, {
          abortEarly: false,
        });

        await sendData(data);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [formRef]
  );

  return (
    <Content ref={formRef} onSubmit={handleSubmit}>
      <div>
        <span>
          <InputSelect
            title="Selecione o tipo da transação"
            required={true}
            name="typeTransaction"
            options={[
              { value: 'proportional', label: 'Proporcional' },
              { value: 'recurrence', label: 'Recorrente' },
            ]}
          />
        </span>
      </div>
      <div>
        <span>
          <InputCalendar
            title="Data de Vencimento:"
            name="expirationDate"
            required={true}
          />
        </span>
        <span>
          <InputNumber
            title="Intervalo de dias"
            name="intervalDays"
            required={true}
          />
        </span>
      </div>
      {children}
    </Content>
  );
}
