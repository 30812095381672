import styled from 'styled-components';

export const Thumbnail = styled.img`
  display: flex;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;
