import React, { useState } from 'react';
import { Button, Buttons, Box } from 'components/Shared';

import { PropertySummary } from '../../Summary/PropertySummary';
import { NegotiationDataProperty } from 'components/Negotiations/CreateNegotiation/Components/Forms/NegotiationDataProperty';

import { UpdateNegotiationProperty } from './Services';
import { toast } from 'react-toastify';

export function PropertyData({ negotiationID, negotiationData }) {
  const { owner, property, broker, status } = negotiationData;
  const propertyData = {
    ...property,
    owner: {
      ...owner,
    },
    broker: {
      ...broker,
    },
  };

  const [selectedProperty, setSelectedProperty] = useState(propertyData);
  const [edit, setEdit] = useState(false);
  const { mutateAsync: updateProperty } = UpdateNegotiationProperty();

  async function sendUpdateNegotiationProperty() {
    const data = {
      property: {
        propertyId: selectedProperty.id,
      },
      id: negotiationID,
    };
    try {
      await updateProperty(data);
      setEdit(false);
      toast.success('Imóvel atualizado com sucesso!');
    } catch (_err) {
      toast.error('Erro ao atualizar imóvel!');
    }
  }

  return (
    <Box>
      {!edit ? (
        <PropertySummary
          property={property}
          owner={owner}
          broker={broker}
          goSite={false}
        >
          {status === 'analyzing' && (
            <Buttons style={{ marginTop: 24 }}>
              <Button typeColor={'primary'} onClick={() => setEdit(true)}>
                Editar
              </Button>
            </Buttons>
          )}
        </PropertySummary>
      ) : (
        <div style={{ padding: 24 }}>
          <NegotiationDataProperty
            selectedProperty={selectedProperty}
            setSelectedProperty={setSelectedProperty}
          />
          <Buttons style={{ marginTop: 24 }}>
            <Button typeColor={'disable'} onClick={() => setEdit(false)}>
              Cancelar
            </Button>
            <Button
              typeColor={'primary'}
              onClick={() => sendUpdateNegotiationProperty()}
            >
              Salvar
            </Button>
          </Buttons>
        </div>
      )}
    </Box>
  );
}
