import styled from 'styled-components';

export const TransactionContainerCard = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  .card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 16px;
    border: 1px solid #055eff;
    border-radius: 8px;
  }
`;
