import jarvis from 'util/jarvis';
import { useMutation, useQueryClient } from 'react-query';

const UpdateNegotiationPropertyRoute = data => {
  return jarvis.put(`/negotiation/${data.id}/property`, data.property);
};

export function UpdateNegotiationProperty() {
  const queryClient = useQueryClient();
  return useMutation(UpdateNegotiationPropertyRoute, {
    onSuccess: data => {
      const newData = data.data;
      queryClient.invalidateQueries(['GetNegotiation', `${newData.id}`]);
      queryClient.setQueryData(['/GetNegotiation', `${newData.id}`], newData);
    },
  });
}
