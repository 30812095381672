import React from 'react';

import { UserList } from '../styles';

export function UserListSelect({ user, setSelectedTenant }) {
  return (
    <>
      <UserList onClick={() => setSelectedTenant(user)}>
        <div>
          <div className="photo">
            <p>
              {user.name.charAt(0)}
              {user.lastName.charAt(0)}
            </p>
          </div>
          <div className="info">
            <p className="name">
              <strong>
                {user.name} {user.lastName}
              </strong>
            </p>
            <p>{user.email}</p>
          </div>
        </div>
      </UserList>
    </>
  );
}
