import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import DatePicker, { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import './Calendar.css';
import { ContainerInput } from './style';
import { FaCalendar } from 'react-icons/fa';

import 'react-datepicker/dist/react-datepicker.css';

const Calendar = ({ name, required, title, ...rest }) => {
  registerLocale('pt', pt);

  const datepickerRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  const [date, setDate] = useState(defaultValue || '');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',

      clearValue: ref => {
        ref.clear();
      },
      setValue: (_, value) => {
        if (value) {
          setDate(new Date(value));
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 8,
          }}
        >
          <p style={{ fontWeight: 500 }}>{title}</p>
          {required && (
            <strong
              style={{ color: '#D8010E', fontWeight: 500, marginLeft: 8 }}
            >
              *
            </strong>
          )}
          {error && (
            <div style={{ marginLeft: 'auto' }}>
              <span role="img" aria-label="error" style={{ fontSize: 12 }}>
                ❌
              </span>
            </div>
          )}
        </div>
        <style>
          {`.react-datepicker__input-container input  {
           ${error ? 'border: 1px solid #D8010E' : ''}
      }`}
        </style>
        <ContainerInput>
          <DatePicker
            className={error ? 'inputCalendar has-error' : 'inputCalendar'}
            locale="pt"
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            showMonthDropdown
            utcOffset={2}
            dropdownMode="select"
            autoComplete="off"
            selected={date}
            onChange={setDate}
            ref={datepickerRef}
            onFocus={() => clearError()}
            {...rest}
          />
          <FaCalendar />
        </ContainerInput>
        <span
          className="error"
          style={{
            color: '#D8010E',
            fontSize: '14px',
            visibility: error ? 'visible' : 'hidden',
            marginTop: 8,
            height: 14,
          }}
        >
          {error}
        </span>
      </div>
    </>
  );
};

Calendar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Calendar;
