import React from 'react';
import { ContainerUser } from './styles';
import { RoleUser } from 'util/Translate/RoleUser';
import { useHistory } from 'react-router-dom';

export function UserList({ user }) {
  const history = useHistory();
  return (
    <ContainerUser
      onClick={() => history.push(`/dashboard/clientes/detalhes/${user.id}`)}
    >
      <span>
        <strong>Nome:</strong> {user.name} {user.lastname}
      </span>
      <span style={{ marginLeft: 'auto' }}>
        <strong>{RoleUser(user.role)}</strong>
      </span>
    </ContainerUser>
  );
}
