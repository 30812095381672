import React, { useRef} from 'react';

import {
  Container,
  Button,
  SpaceLine,
  Buttons
} from 'components/Shared';

import { NegotiationForm } from '../Components/Forms/NegotiationForm';
import { NegotiationDataProperty } from '../Components/Forms/NegotiationDataProperty';
import { NegotiationDataTenant } from '../Components/Forms/NegotiationDataTenant';

export function NegotiationData({
  setNegotiationData,
  negotiationData,
  selectedTenant,
  selectedProperty,
  setSelectedTenant,
  setSelectedProperty,
  setCurrentProgress,
}) {
  const formRef = useRef();

  function handleGetFormData(data) {
    setNegotiationData(data);
    setCurrentProgress(3);
  }

  return (
    <Container style={{flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
          <NegotiationDataProperty
            setSelectedProperty={setSelectedProperty}
            selectedProperty={selectedProperty}
          />
      </div>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <NegotiationDataTenant
          setSelectedTenant={setSelectedTenant}
          selectedTenant={selectedTenant}
        />
      </div>
        <SpaceLine style={{marginBottom: 24}} />
        <NegotiationForm
          formRef={formRef}
          getDataForm={handleGetFormData}
          negotiationData={negotiationData}
        >
        <Buttons>
          <Button type="button" typeColor="secondary">
            Cancelar
          </Button>
          <Button type="submit" typeColor="primary">
            Salvar
          </Button>
        </Buttons>
        </NegotiationForm>
    </Container>
  );
}
