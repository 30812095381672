import styled from 'styled-components';

export const CarouselContainer = styled.div`
  display: -webkit-box;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    ${props => props.theme.backgrounds.secondary} 0%,
    ${props => props.theme.backgrounds.primary} 100%
  );
  overflow-x: scroll;

  &::-webkit-scrollbar {
    padding: 0 15px;
    width: 4px;
    height: 8px;
    border-radius: 4px;
    background-color: ${props => props.theme.backgrounds.secondary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }
`;
