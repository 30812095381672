import React, { useRef, useCallback } from 'react';

import { MdClose } from 'react-icons/md';
import { BlackScreen, Container, Header } from './styles';

export function SignatariusSelect({ open, setOpen, children, getData }) {
  const formRef = useRef();

  const handleSubmit = useCallback(data => {
    const owner = data?.owner?.user.filter(item => item.checked);
    const tenant = data?.tenant?.user?.filter(item => item.checked);
    const coOwner = data?.owner?.legalAgents?.filter(item => item.checked);
    const coTenant = data?.tenant?.legalAgents?.filter(item => item.checked);

    if (coOwner) {
      coOwner.forEach(item => {
        item.selected = item.selected?.filter(e => e.checked);
      });
    }

    if (coTenant) {
      coTenant.forEach(item => {
        item.selected = item.selected?.filter(e => e.checked);
      });
    }

    data = {
      owner: {
        user: {
          ...owner,
        },
        legalAgents: coOwner,
      },
      tenant: {
        user: {
          ...tenant,
        },
        legalAgents: coTenant,
      },
    };

    getData(data);
    setOpen(false);
  }, []);

  return (
    <>
      <BlackScreen open={open} onClick={() => setOpen(!open)} />
      <Container ref={formRef} open={open} onSubmit={handleSubmit}>
        <Header>
          <h1>Novos Signatarios</h1>
          <button type="button" onClick={() => setOpen(!open)}>
            <MdClose />
          </button>
        </Header>
        {children}
      </Container>
    </>
  );
}
