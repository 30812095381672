import React from 'react';

import Route from '../../../routes/Route';

import CongratsBroker from '../../../components/broker/Congrats';
import { CongratsLocation } from '../../../components/broker/success/Location';
import { PropertyForm } from 'components/Property/CreateProperty';
import { CreateNegotiation } from 'components/Negotiations/CreateNegotiation';
import { PropertyList } from '../../../components/Property/PropertyList';
import { Settings } from '../../../components/Settings';
import { UsersList } from '../../../components/User/UsersList';
import { PropertyDetails } from 'components/Property/PropertyDetails';
import { NegotiationList } from 'components/Negotiations/NegotiationList';
import { UserDetails } from 'components/User/UserDetails';
import { UserForm } from '../../../components/User/CreateUser';
import { NegotiationDetails } from 'components/Negotiations/NegotationDetails';

export const BrokerRoutes = () => {
  return (
    <>
      <Route path="/dashboard/configuracoes" component={Settings} isPrivate />
      <Route path="/dashboard/clientes" component={UsersList} isPrivate exact />
      <Route
        path="/dashboard/clientes/criar"
        component={UserForm}
        isPrivate
        exact
      />
      <Route
        path="/dashboard/clientes/detalhes/:id?"
        component={UserDetails}
        isPrivate
        exact
      />
      <Route
        path="/dashboard/registrar-imovel"
        component={PropertyForm}
        isPrivate
      />
      <Route
        path="/dashboard/sucesso/nova-locacao/:idNegotiation"
        component={CongratsLocation}
        isPrivate
        exact
      />
      <Route
        path="/dashboard/congrats/broker/:id"
        component={CongratsBroker}
        isPrivate
      />
      <Route
        path="/dashboard/imoveis/detalhes/:id"
        component={PropertyDetails}
        isPrivate
      />
      <Route
        path="/dashboard/negociacoes"
        component={NegotiationList}
        isPrivate
        exact
      />

      <Route
        path="/dashboard/criar-negociacao/:propertyid?/:userid?"
        component={CreateNegotiation}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/negociacao-detalhes/:id?"
        component={NegotiationDetails}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/create-negotiation/:propertyid?/:userid?"
        component={CreateNegotiation}
        exact
        isPrivate
      />
      <Route
        path="/dashboard/imoveis"
        component={PropertyList}
        isPrivate
        exact
      />
    </>
  );
};
