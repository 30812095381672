import * as Yup from 'yup';

export const AddressAgentValidation = Yup.object().shape({
      address: Yup.object().shape({
        street: Yup.string().required('Digite o endereço'),
        number: Yup.string().required('Digite o número'),
        district: Yup.string().required('Digite o bairro'),
        city: Yup.string().required('Digite a cidade'),
        state: Yup.string().required('Digite o estado'),
        zipcode: Yup.string().required('Digite o CEP'),
      }),
  });