import React from 'react';

import { Container, Buttons, Button, LinkButton } from 'components/Shared';
import { SpecificationsForm } from '../../Components/Forms/SpecificationsForm';

export function Specifications({
  setCurrentProgress,
  currentProgress,
  specificationsData,
  setSpecificationsData,
}) {
  function sendData(data) {
    setSpecificationsData(data);
    setCurrentProgress(currentProgress + 1);
  }
  return (
    <Container>
      <SpecificationsForm
        sendData={sendData}
        specificationsData={specificationsData}
      >
        <Buttons>
          <LinkButton typeColor="disable" type="button" to="/dashboard">
            Cancelar
          </LinkButton>
          <Button type="submit" typeColor="primary">
            Confirmar e Avançar
          </Button>
        </Buttons>
      </SpecificationsForm>
    </Container>
  );
}
