import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Content, InputRadio } from 'components/Shared';
import { ButtonSubmitRental } from '../ButtonSubmitRental';

export function DayPayment({ dayPayment, sendDayPayment }) {
  const dateForm = useRef(null);
  const [day, setDay] = useState(dayPayment);

  useEffect(() => {
    dateForm.current.setData({ dayPayment: Number(dayPayment) });
    setDay(dayPayment);
  }, [dateForm, dayPayment]);

  function handleDayChange(event) {
    setDay(Number(event));
  }

  const handleSubmit = useCallback(
    data => {
      sendDayPayment(data);
    },
    [dateForm]
  );

  return (
    <Content ref={dateForm} onSubmit={handleSubmit}>
      <div style={{ position: 'relative' }}>
        <InputRadio
          name="dayPayment"
          title="Dia do pagamento"
          required={true}
          onInput={e => handleDayChange(e.target.value)}
          options={[
            {
              label: '05',
              value: 5,
            },
            {
              label: '10',
              value: 10,
            },
            {
              label: '15',
              value: 15,
            },
            {
              label: '20',
              value: 20,
            },
            {
              label: '25',
              value: 25,
            },
          ]}
        />
        {day !== dayPayment && (
          <ButtonSubmitRental type="submit">Salvar</ButtonSubmitRental>
        )}
      </div>
    </Content>
  );
}
