import { Button } from 'components/Shared';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { EditorContainer } from './styles';
import { MdRotateLeft, MdRotateRight } from 'react-icons/md';
import useFetch from 'services/ReactQuery';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export function ImageEditor({ imageEdit, setImageEdit }) {
  const canvasRef = useRef(null);
  const params = useParams();
  const [image, setImage] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [rotate, setRotate] = useState(0);
  const [download, setDownload] = useState(false);
  const { mutateAsync: imageDownload } = useFetch.Post();
  const imageUrlEdit = imageEdit.urlImage || imageEdit.urlImage1140x550;

  useEffect(() => {
    if (imageUrlEdit) {
      downloadImage();
    }
  }, [imageUrlEdit]);

  function handleRotate(rotation) {
    if (rotate === 270 || rotate === -270) {
      setRotate(0);
    } else {
      setRotate(rotation + rotate);
    }
  }

  useEffect(() => {
    if (image) {
      handleRotateClick(rotate);
    }
  }, [rotate]);

  async function downloadImage() {
    try {
      if (!image) {
        const image = await fetch(imageUrlEdit, {
          method: 'GET',
          credentials: 'omit',
          headers: {
            Accept:
              'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
            'Accept-Encoding': ' gzip, deflate, br',
            'Accept-Language': 'pt-BR,pt',
            'Cache-Control': 'no-cache',
            Connection: 'keep-alive',
            Host: 'wz-thanos.s3-sa-east-1.amazonaws.com',
          },
        });
        const imageBlob = await image.blob();
        const imgURL = URL.createObjectURL(imageBlob);
        setDownload(imgURL);
      }
    } catch (err) {
      alert('Erro! Tente novamente');
    }
  }

  const handleImageLoad = e => {
    setImage(e.target);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(e.target, 0, 0);
  };

  const handleRotateClick = rotate => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const angleInRadians = (rotate * Math.PI) / 180;
    const newWidth =
      image.width * Math.abs(Math.cos(angleInRadians)) +
      image.height * Math.abs(Math.sin(angleInRadians));
    const newHeight =
      image.width * Math.abs(Math.sin(angleInRadians)) +
      image.height * Math.abs(Math.cos(angleInRadians));

    canvas.width = newWidth;
    canvas.height = newHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.translate(newWidth / 2, newHeight / 2);
    ctx.rotate(angleInRadians);
    ctx.drawImage(image, -image.width / 2, -image.height / 2);
    ctx.restore();

    const dataUrl = canvas.toDataURL();
    setDownloadUrl(dataUrl);
  };

  async function uploadImageToDb() {
    const newFormData = new FormData();
    newFormData.append('file', downloadUrl);

    try {
      imageDownload({
        url: `/property/${params.id}/file/update/${imageEdit.id}`,
        body: {
          file: newFormData,
        },
        key: `property/${params.id}`,
      });

      setImageEdit(null);
      toast.success('Imagem editada com sucesso!');
    } catch (err) {
      toast.error('Erro ao editar imagem!');
    }
  }

  return (
    <EditorContainer>
      <div className="container_image_editor">
        <div className="group_buttons">
          <div>
            <button className="button_rotate" onClick={() => handleRotate(-90)}>
              <MdRotateLeft />
            </button>
            <button className="button_rotate" onClick={() => handleRotate(90)}>
              <MdRotateRight />
            </button>
          </div>
          <div>
            <Button typeColor="secondary" onClick={() => setImageEdit(null)}>
              Cancelar
            </Button>
            <Button typeColor="primary" onClick={() => uploadImageToDb()}>
              Salvar
            </Button>
          </div>
        </div>
        <div className="image-editor">
          <canvas className="canva" ref={canvasRef} width={800} height={600} />
        </div>
        {download && (
          <img
            src={download}
            onLoad={handleImageLoad}
            style={{ display: 'none' }}
          />
        )}
        <div className="group_buttons"></div>
      </div>
    </EditorContainer>
  );
}
