import styled from 'styled-components';

export const ContractContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`;

export const ContractButtons = styled.div`
  display: flex;
  margin: 24px 0;
  justify-content: center;
  gap: 24px;

  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

export const ContractButton = styled.button`
  display: flex;
  background: #ccdeff;
  color: #055eff;
  padding: 24px 16px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 16px;
  flex: 0.3 1 0;
  transition: 0.2s ease-in-out;

  &.active {
    background: #055eff;
    color: white;
  }

  > svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: #055eff;
    color: white;
  }
`;
