import React from 'react'

import logo from '../../../assets/wizzerImoveis.svg'

import {
  Container
} from './styles'

export function Logo () {
  return (
    <Container src={logo} alt='Wizzer' />
  )
}
