import React from 'react';
import { useSelector } from 'react-redux';

import FormatCurrency from '../../../../util/FormatCurrency';

import {
  getStatuses,
  getStatusColors,
  renderMainImage,
  speficificationValues,
} from './Context';

import { FaBed, FaShower } from 'react-icons/fa';

import { Container, Thumb, Info, IconSpecification } from './styles';

export function CardProperty({ property }) {
  const { role } = useSelector(state => state.user.profile);
  const statuses = getStatuses(role);
  const statusColors = getStatusColors(role);

  const totalValue = speficificationValues(property);

  return (
    <Container to={`/dashboard/imoveis/detalhes/${property.id}`}>
      <Thumb key={property.id}>
        {renderMainImage(property?.images)}
        <strong>{property.id}</strong>
        <label>{FormatCurrency((property.price / 100) * 0.6)}</label>
        <span style={{ background: statusColors[property.status] }}>
          {statuses[property.status]}
        </span>
      </Thumb>
      <Info>
        <strong>
          {property.address.street}, {property.address.number}{' '}
          {property.address.complement
            ? `- ${property.address.complement}`
            : ''}
        </strong>
        <span className="address">{property.address.district}</span>
        <span>
          {property.address.city} - {property.address.state}
        </span>
        <IconSpecification>
          <FaBed /> <strong>{property?.specification?.bedroom}</strong>
          <FaShower /> <strong>{property?.specification?.bathroom}</strong>
        </IconSpecification>
        <strong>{FormatCurrency((property?.price + totalValue) / 100)}</strong>
        <span>{FormatCurrency(property?.price / 100)}</span>
      </Info>
    </Container>
  );
}
