import React from 'react';

import {
  ContentVerificationData,
  ContentGroup,
  ContentData,
} from 'components/Shared';

export function AddressSummary({ address, title, children }) {
  return (
    <>
      {address && (
        <ContentVerificationData>
          <h1>{title}</h1>
          <ContentGroup>
            <div>
              <ContentData>
                <p>Logradouro: {address.street}</p>
                <p>Número: {address.number}</p>
                {address.complement && <p>Complemento: {address.complement}</p>}
                <p>Bairro: {address.district}</p>
                <p>
                  Cidade: {address.city}/{address.state}
                </p>
                <p>CEP: {address.zipcode}</p>
              </ContentData>
            </div>
          </ContentGroup>
          {children}
        </ContentVerificationData>
      )}
    </>
  );
}
