import jarvis from 'util/jarvis';

export async function AddDocumentFile(file, order, documentID) {
  if (file) {
    const formData = new FormData();
    formData.append(`file`, file);
    formData.append(`order`, order);
    const data = await jarvis.post(`/document/${documentID}/file`, formData);
    return data;
  }
}
