import React, { useState, useEffect, useCallback } from 'react';

import { InputCurrency, InputRadio } from 'components/Shared';
import { InputSelect } from 'components/Shared';

import { WithIPTU } from '../../TopContent/WithIPTU';
import { WithoutIPTU } from '../../TopContent/WithoutIPTU';

export function ValuesInputs({ formRef, valuesData }) {
  const [totalValueWithIPTU, setTotalValueWithIPTU] = useState();
  const [totalValueWithoutIPTU, setTotalValueWithoutIPTU] = useState();
  const [priceValue, setPriceValue] = useState(0);
  const [condoValue, setCondoValue] = useState(0);
  const [fireInsurancePriceValue, SetFireInsurancePriceValue] = useState(0);
  const [showIPTUValue, setShowIPTUValue] = useState(false);
  const [IPTUPriceValue, SetIPTUPriceValue] = useState(0);
  const filedData = valuesData;

  useEffect(() => {
    if (filedData) {
      formRef.current.setData(filedData);
    }
  }, [filedData, formRef]);

  // ATUALIZAR O VALOR DO ALUGUEL COM E SEM IPTU NO CONTEUDO DO TOPO DA TELA
  // ***********************************************************************
  const handleTopContent = useCallback(async () => {
    const { price } = await formRef.current.getData();
    const { specification } = await formRef.current.getData();
    const { condo } = specification;
    const { fire_insurance_price } = specification;
    const { iptu_price } = specification;

    // VERIFICAR SE OS VALORES NAO SAO NaN PARA NAO DAR ERRO NA SOMA

    if (price > 0) {
      setPriceValue(price);
    }
    if (condo > 0) {
      setCondoValue(condo);
    }
    if (fire_insurance_price > 0) {
      SetFireInsurancePriceValue(fire_insurance_price);
    }
    if (iptu_price > 0) {
      SetIPTUPriceValue(iptu_price);
    }
  }, [showIPTUValue]);

  // ATUALIZAR A SOMA QUANDO OS VALORES MUDAREM
  useEffect(() => {
    setTotalValueWithIPTU(
      (priceValue + condoValue + fireInsurancePriceValue + IPTUPriceValue) / 100
    );
    setTotalValueWithoutIPTU(
      (priceValue + condoValue + fireInsurancePriceValue) / 100
    );
  }, [priceValue, condoValue, fireInsurancePriceValue, IPTUPriceValue]);
  // ***********************************************************************

  return (
    <>
      {' '}
      {showIPTUValue ? (
        <div>
          <span>
            <WithIPTU value={totalValueWithIPTU} />
          </span>
          <span>
            {' '}
            <WithoutIPTU value={totalValueWithoutIPTU} />
          </span>
        </div>
      ) : (
        <></>
      )}
      <div>
        <span>
          <InputCurrency
            name="price"
            title="Valor do aluguel"
            placeholder="R$"
            required={true}
            onKeyUp={handleTopContent}
          />
        </span>
        <span>
          <InputCurrency
            name="specification.condo"
            title="Valor do condomínio"
            placeholder="R$"
            required={false}
            onKeyUp={handleTopContent}
          />
        </span>
      </div>
      {/* <div className="half">
        <span>
          <InputCurrency
            name="specification.fireInsurancePriceValue"
            title="Seguro incêndio"
            placeholder="R$"
            required={false}
            onKeyUp={handleTopContent}
          />
        </span>
      </div> */}
      <div>
        <h3>Informações de IPTU</h3>
      </div>
      <div className="half">
        <span>
          <InputSelect
            onChange={e => setShowIPTUValue(e.value)}
            name="specification.iptuPayByTenant"
            title="O inquilino vai pagar?"
            placeholder="Selecionar..."
            required={false}
            defaultValue={{ value: false, label: 'Não' }}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
      </div>
      <div style={{ display: `${showIPTUValue ? 'flex' : 'none'}` }}>
        <span>
          <InputCurrency
            name="specification.iptuPrice"
            title="Valor do IPTU"
            placeholder="R$"
            required={false}
            onKeyUp={handleTopContent}
          />
        </span>
        <span>
          <InputRadio
            name="specification.iptuRegime"
            title="Valor mensal ou anual?"
            options={[
              {
                label: 'Mensal',
                value: 'monthly',
              },
              {
                label: 'Anual',
                value: 'yearly',
              },
            ]}
          />
        </span>
      </div>
    </>
  );
}
