import { cpfMask, cnpjMask } from 'util/maskForms';

export function formatedDocument(document, type) {
  if (type === 'cpf' || type === 'cnpj') {
    if (document.replace(/\D/g, '').length <= 11) {
      return cpfMask(document);
    } else {
      return cnpjMask(document);
    }
  } else {
    return document;
  }
}
