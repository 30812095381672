import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Content, InputCurrency } from 'components/Shared';
import { ButtonSubmitRental } from '../ButtonSubmitRental';

export function CondoAmount({ townhouseAmount, sendCondoAmount }) {
  const condominuinPrice = useRef(null);
  const [condominuinAmount, setCondominuinAmount] = useState(townhouseAmount);

  useEffect(() => {
    condominuinPrice.current.setData({
      rental: { amountTownhouse: townhouseAmount },
    });
  }, [condominuinPrice, townhouseAmount]);

  function handleAmountChange(event) {
    const amountString = event.replace(/\D/g, '');
    const amountNumber = Number(amountString);
    setCondominuinAmount(amountNumber);
  }

  const handleSubmit = useCallback(
    data => {
      sendCondoAmount(data);
    },
    [condominuinPrice]
  );

  return (
    <Content ref={condominuinPrice} onSubmit={handleSubmit}>
      <div style={{ position: 'relative' }}>
        <InputCurrency
          name="rental.amountTownhouse"
          title="Valor do Condomínio"
          onInput={e => handleAmountChange(e.target.value)}
        />
        {condominuinAmount !== townhouseAmount && (
          <ButtonSubmitRental type="submit">Salvar</ButtonSubmitRental>
        )}
      </div>
    </Content>
  );
}
