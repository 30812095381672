import jarvis from 'util/jarvis';
import { useQuery } from 'react-query';

export function GetPropertyList(currentParams, userID) {
  const { data, isLoading } = useQuery(
    ['/properties', currentParams, userID],
    async () => {
      return jarvis
        .get(`/properties`, {
          params: {
            page: currentParams.page,
            perPage: currentParams.perPage,
            search: currentParams.search,
            filter: {
              status: currentParams.filter.status,
              ownerId: userID,
            },
          },
        })
        .then(res => {
          return res.data;
        });
    }
  );

  return {
    data: data?.data,
    isLoading: isLoading,
  };
}
