import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'Campo é obrigatorio',
  },
});

export const addressSchema = Yup.object().shape({
  address: Yup.object().shape({
    zipcode: Yup.string().required('Digite o seu CEP'),
    district: Yup.string().required('Digite o seu Bairro'),
    number: Yup.string().required('Digite o seu Número'),
    street: Yup.string().required('Digite o seu Endereço'),
    complement: Yup.string(),
    city: Yup.string().required('Digite a sua Cidade'),
    state: Yup.string().required('Digite o seu Estado'),
  }),
});
