import React from 'react';
import { InformationOwner } from './informativo';
import { InformationData } from './informativo/service';
import { useSelector } from 'react-redux';

export function UnitDetails() {
  const user = useSelector(state => state.user.profile);

  if (user.role === 'owner') {
    const { data, isLoading } = InformationData();
    return <>{isLoading || <InformationOwner data={data} />}</>;
  }

  if (user.role === 'guest') {
    const property = [user.property];
    return <InformationOwner data={property} />;
  }
}
