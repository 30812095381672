import React, { useRef, useState } from 'react';

import { Container, Buttons, Button } from '../../../Shared';
import { BankAccountForm } from '../../Components/Forms/BankAccountForm';
import { FinalizationModal } from '../../Components/Forms/Modal/FinalizationModal';
import { SendBankData } from './Services';
import { toast } from 'react-toastify';

export function BankAccount({
  setDataBank,
  setCurrentProgress,
  currentProgress,
  dataEssencial,
  dataBank,
  id,
}) {
  const formRef = useRef(null);
  const [finalize, setFinalize] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function handleActionsButtons() {
    if (finalize === true) {
      setShowModal(true);
    } else {
      setCurrentProgress(currentProgress + 1);
    }
  }

  async function sendData(data) {
    if (dataBank) {
      try {
        await SendBankData(data, id);
        setDataBank(data);
        handleActionsButtons();
        toast.success('Dados bancários adicionados com sucesso!');
      } catch (error) {
        toast.error('Erro ao adicionar dados bancários!');
      }
    } else {
      try {
        await SendBankData(data, id);
        setDataBank(data);
        handleActionsButtons();
        toast.success('Dados bancários adicionados com sucesso!');
      } catch (error) {
        toast.error('Erro ao adicionar dados bancários!');
      }
    }
  }
  // function cripeteiCaralho() {
  //   const crypto = require('crypto');
  //   const ENC_KEY = '6fa979f20126cb08aa645a8f495f6d85';
  //   const IV = '7a8f9e8f8f9e8f9e';

  //   const phrase = 'pão de queijo';

  //   var encrypt = val => {
  //     let cipher = crypto.createCipheriv('aes-256-cbc', ENC_KEY, IV);
  //     let encrypted = cipher.update(val, 'utf8', 'base64');
  //     encrypted += cipher.final('base64');
  //     return encrypted;
  //   };

  //   var decrypt = encrypted => {
  //     let decipher = crypto.createDecipheriv('aes-256-cbc', ENC_KEY, IV);
  //     let decrypted = decipher.update(encrypted, 'base64', 'utf8');
  //     return decrypted + decipher.final('utf8');
  //   };

  //   var encrypted_key = encrypt(phrase);
  //   var original_phrase = decrypt(encrypted_key);
  //   console.log(encrypted_key);
  //   console.log(original_phrase);
  // }

  return (
    <>
      <Container>
        <BankAccountForm
          formRef={formRef}
          dataBank={dataBank}
          sendData={sendData}
        >
          <Buttons>
            <Button typeColor="secondary" onClick={() => setFinalize(true)}>
              Finalizar Cadastro
            </Button>
            {dataEssencial?.role !== 'broker' && (
              <Button type="submit" typeColor="primary">
                Confimar e Avançar
              </Button>
            )}
          </Buttons>
          <FinalizationModal
            open={showModal}
            setOpen={setShowModal}
            dataEssencial={dataEssencial}
            userId={id}
          />
        </BankAccountForm>
      </Container>
    </>
  );
}
