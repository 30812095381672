import React from 'react';

import { MdKeyboardArrowRight } from 'react-icons/md';
import { GoAlert } from 'react-icons/go';

import { FilterSelect } from 'components/Shared/Filter/FilterSelect';

import { Container, NavButtons, SelectedFilter } from './styles';
export function NavigationContainer({
  children,
  column,
  setValue,
  active,
  options,
  style,
}) {
  const amount = options?.length;
  const halfOptions = Math.ceil(amount / column);
  const result = [];

  for (let i = 0; i < column; i++) {
    const start = i * halfOptions;
    const end = start + halfOptions;
    const slicedOption = options?.slice(start, end);
    result.push(slicedOption);
  }

  return (
    <Container style={style}>
      <div>{children}</div>
      <NavButtons>
        {result?.map((item, index) => {
          return (
            <div key={index}>
              {item.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={active === item.value ? 'active' : ''}
                    onClick={() => setValue(item.value)}
                  >
                    <MdKeyboardArrowRight />
                    {item.label}{' '}
                    {item.filled ? '' : <GoAlert className="alert" />}
                  </div>
                );
              })}
            </div>
          );
        })}
      </NavButtons>
      <SelectedFilter>
        <FilterSelect
          className="field-select"
          options={options}
          onChange={options => setValue(options.value)}
          defaultValue={options[0].label}
          placeholder="Selecione um filtro"
        />
      </SelectedFilter>
    </Container>
  );
}
