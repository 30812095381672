import React from 'react';
import useFetch from 'services/ReactQuery';

import { useQuery } from 'react-query';
import jarvis from 'util/jarvis';
import { toast } from 'react-toastify';
import { ErrorScreen, LoadingScreen } from 'components/Shared';

export function GetAdmFee({ currentParams }) {
  const { data, isLoading, isError } = useFetch.Get({
    url: '/fees',
    params: currentParams,
  });

  return {
    data: data,
    isLoading:
      (isLoading && <LoadingScreen />) || (isError && <ErrorScreen />) || false,
  };
}

export function UpdateAdmFeeStatus() {
  const { mutateAsync: updateAdmFeeStatus } = useFetch.Put();

  async function handleUpdateAdmFeeStatus(id) {
    try {
      await updateAdmFeeStatus({
        url: `/fee/status/${id}`,
        key: '/fees',
        params: {
          id: Number(id),
        },
      });
      toast.success('Status atualizado com sucesso!');
    } catch (err) {
      toast.error('Erro ao atualizar status!');
    }
  }

  return {handleUpdateAdmFeeStatus};
}

// export function GetAdmFee({ currentParams }) {
//   const { data, isLoading, refetch } = useQuery(
//     ['getFees', currentParams],
//     async () =>
//       jarvis
//         .get(`/fees`, {
//           params: currentParams,
//         })
//         .then(res => {
//           return res.data;
//         }),
//     { refetchOnWindowFocus: false }
//   );
//   return {
//     data: data,
//     isLoading: isLoading,
//     refetch: refetch,
//   };
// }

export function GetAdmFeeDetails({ id }) {
  const { data, isLoading, refetch } = useQuery(
    ['getFeeDetails', id],
    async () =>
      jarvis.get(`/fee/${id}`).then(res => {
        return res.data;
      })
  );
  return {
    data: data,
    isLoading: isLoading,
    refetch: refetch,
  };
}

export async function PutEditAdmFee(Body, id) {
  const { data } = await jarvis.put(`/fee/${id}`, Body);
  return data;
}

export async function ChangeFeeStatus(id) {
  try {
    const { data } = await jarvis.put(`/fee/status/${id}`);
    toast.success('Status alterado com sucesso!');
    return data;
  } catch (err) {
    toast.error('Erro ao alterar status!');
  }
}

export async function SendAdmFeeForm(Body) {
  const { data } = await jarvis.post('/fee', Body);
  return data;
}
