import React from 'react';
import { Thumbnail } from './styles';

const ImageThumbnail = ({ image, onClick }) => {
  return (
    <Thumbnail
      width="300"
      height="300"
      src={image}
      loading="lazy"
      alt="Thumbnail"
      onClick={onClick}
    />
  );
};

export default ImageThumbnail;
