import React, { useCallback, useEffect } from 'react';

import {
  Content,
  InputForm,
  InputText,
  TitleContainer,
  BorderContainer,
} from 'components/Shared';
import { GuaranteeValidation } from './Validation';
import { validationError } from 'validations/forms';

export function GuaranteeForm({
  formRef,
  children,
  postGuarantee,
  type,
  data,
}) {
  useEffect(() => {
    if (data) {
      formRef.current.setData(data);
    }
  }, [data]);

  const handleSubmit = useCallback(
    async data => {
      try {
        await GuaranteeValidation.validate(data, {
          abortEarly: false,
        });
        postGuarantee(data);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [formRef, postGuarantee]
  );

  return (
    <BorderContainer>
      <Content ref={formRef} onSubmit={handleSubmit}>
        <div>
          <TitleContainer style={{ margin: 0 }}>
            <h1>{type === 'edit' ? 'Editar' : 'Criar'} Garantia:</h1>
          </TitleContainer>
        </div>
        <div className="half">
          <span>
            <InputForm
              name="name"
              title="Nome"
              placeholder="Digite o nome da garantia"
              required={true}
            />
          </span>
        </div>
        <div>
          <span>
            <InputText
              name="description"
              title="Descrição"
              placeholder="Digite a descrição da garantia"
              required={true}
            />
          </span>
        </div>
        <div>
          <span>
            <InputForm
              name="contractIdWithoutIr"
              title="Indentificação do contrato de LOCAÇÃO SEM Imposto de Renda:"
              placeholder="Digite o número do contrato"
            />
          </span>
          <span>
            <InputForm
              name="contractIdIr"
              title="Indentificação do contrato de LOCAÇÃO COM Imposto de Renda:"
              placeholder="Digite o número do contrato"
            />
          </span>
        </div>
        <div>
          <span>
            <InputForm
              name="admContractIdWithoutIr"
              title="Indentificação do contrato de ADMINISTRAÇÃO SEM Imposto de Renda:"
              placeholder="Digite o número do contrato"
            />
          </span>
          <span>
            <InputForm
              name="admContractIdIr"
              title="Indentificação do contrato de ADMINISTRAÇÃO COM Imposto de Renda:"
              placeholder="Digite o número do contrato"
            />
          </span>
        </div>
        {children}
      </Content>
    </BorderContainer>
  );
}
