import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { usePagination } from 'hooks/usePagination';
import { Container, Button, TripleDot, PageButton } from './styles';

export function Pagination({
  currentPage,
  onPageChange,
  pagesSize,
  totalCount,
}) {
  const siblingCount = 1;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pagesSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Container>
      <Button
        disabled={currentPage === 1}
        onClick={() => onPageChange({ selected: currentPage - 1 })}
      >
        <FaChevronLeft />
      </Button>
      {paginationRange.map((page, index) => {
        return (
          <div key={index}>
            {page !== 'DOTS' ? (
              <PageButton
                active={currentPage === page}
                onClick={() => onPageChange({ selected: page })}
              >
                {page}
              </PageButton>
            ) : (
              <TripleDot>
                <div />
                <div />
                <div />
              </TripleDot>
            )}
          </div>
        );
      })}
      <Button
        disabled={currentPage === lastPage}
        onClick={() => onPageChange({ selected: currentPage + 1 })}
      >
        <FaChevronRight />
      </Button>
    </Container>
  );
}
