import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 0px;
  top: 26px;
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #ccdeff;
    border: 1px solid #055eff;
    border-radius: 50%;
    > svg {
      width: 20px;
      height: 20px;
      color: #000;
    }
  }

  @media screen and (max-width: 990px) {
    display: none;
  }
`;
