import * as Yup from 'yup';

export const CicleValidation = Yup.object().shape({
  amount: Yup.number()
    .typeError('O valor deve ser um número')
    .required('O valor é obrigatório'),
  cycleLength: Yup.number()
    .typeError('A quantidade de ciclos deve ser um número')
    .required('A quantidade de ciclos é obrigatória'),
  cycleType: Yup.string().required('O tipo do ciclo é obrigatório'),
  cycleDescription: Yup.string().required('A descrição é obrigatória'),
});
