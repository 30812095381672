import React from 'react';

import { ContainerError } from './styles';
import ErrorSvg from 'assets/ErrorImage/ErrorSvg.svg';

export function ErrorScreen() {
  return (
    <ContainerError>
      <img src={ErrorSvg} />
      <div className="message">Ocorreu um Erro</div>
    </ContainerError>
  );
}
