import React from 'react';
import { NegotiationContainer } from './styles';

import { FaHandsHelping } from 'react-icons/fa';

export function Negotiation({ negotiation }) {
  const { property, owner, broker, tenant, status } = negotiation;
  const statusTranslate = {
    approved: 'Aprovada',
    canceled: 'Cancelada',
    analyzing: 'Analisando',
  };

  const fontColor = {
    approved: '#fff',
    canceled: '#fff',
    analyzing: '#353D40',
  };

  const backgroundColor = {
    approved: '#0B8E63',
    canceled: '#D8010E',
    analyzing: '#FFDE67',
  };

  return (
    <NegotiationContainer
      to={`/dashboard/negociacao-detalhes/${negotiation.id}`}
      backgroundcolor={backgroundColor[status]}
      fontcolor={fontColor[status]}
    >
      <div className="data-negotiation-property">
        <div className="photo">
          <FaHandsHelping />
        </div>
        <div className="data-negotiation-property-information">
          <p className="data-negotiation-property-address">ID: {property.id}</p>
          <address className="data-negotiation-property-address">
            {property.address.street}, {property.address.number} -{' '}
            {property.address.district}
          </address>
          <address className="data-negotiation-property-address-complement">
            {property.address.complement
              ? `${property.address.complement} - `
              : ''}
            {property.address.city}/ {property.address.state}
          </address>
          <p className="data-negotiation-property-responsible">
            Proprietario:{owner.name} {owner.lastName}
          </p>
          <p className="data-negotiation-property-responsible">
            Corretor: {broker.name} {broker.lastName}
          </p>
        </div>
      </div>
      <div className="data-negotiation-others">
        <div className="data-negotiation-tenant">
          <p className="data-negotiation-tenant-title">Inquilino:</p>
          <div className="data-negotiation-tenant-information">
            <p className="data-negotiation-tenant-photo">{tenant.initials}</p>
            <div className="data-negotiation-tenant-data">
              <p>{tenant.name}</p>
              <p>{tenant.email}</p>
            </div>
          </div>
        </div>
        <div className="data-negotiation-status">
          <span className="data-negotiation-status-value">
            <p>{statusTranslate[status]}</p>
          </span>
        </div>
      </div>
    </NegotiationContainer>
  );
}
