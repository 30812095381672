import React, { useRef, useState } from 'react';
import { Container, Buttons, Button } from '../../../Shared';
import { UserProfileForm } from '../../Components/Forms/UserProfileForm';
import { FinalizationModal } from '../../Components/Forms/Modal/FinalizationModal';
import { toast } from 'react-toastify';

import { SendProfileData, SendProfilePhoto } from './Services';

export function UserProfile({
  setCurrentProgress,
  dataEssencial,
  currentProgress,
  setDataProfile,
  dataProfile,
  id,
}) {
  const formRef = useRef(null);
  const [finalize, setFinalize] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function handleActionsButtons() {
    if (finalize === true) {
      setShowModal(true);
    } else {
      setCurrentProgress(currentProgress + 1);
    }
  }

  async function sendData(data) {
    if (dataProfile) {
      // edit
    } else {
      try {
        await SendProfileData(data.profile, id);
        setDataProfile(data);
        handleActionsButtons();
        toast.success('Perfil criado com sucesso!');
      } catch (err) {
        toast.error('Erro ao criar perfil!');
      }
    }
    if (data.profilePhoto) {
      SendProfilePhoto(data.profilePhoto, id);
    }
  }

  return (
    <>
      <Container>
        <UserProfileForm
          dataProfile={dataProfile}
          formRef={formRef}
          sendData={sendData}
          userName={dataEssencial?.name}
          userRole={dataEssencial?.role}
        >
          <Buttons>
            <Button typeColor="secondary" onClick={() => setFinalize(true)}>
              Finalizar Cadastro
            </Button>
            <Button type="submit" typeColor="primary">
              Confimar e Avançar
            </Button>
          </Buttons>
          <FinalizationModal
            open={showModal}
            setOpen={setShowModal}
            dataEssencial={dataEssencial}
            userId={id}
          />
        </UserProfileForm>
      </Container>
    </>
  );
}
