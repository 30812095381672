import React, { useRef, useCallback } from 'react';

import {
  Buttons,
  Button,
  InputForm,
  ModalScreen,
  Content,
} from 'components/Shared';

import { resetPasswordValidation } from './Validation';
import { validationError } from 'validations/forms';

import { resetPasswordRoute } from './Services';

export function ChangePasswordModal({ userID, setModal, modal }) {
  const formRef = useRef();

  const handleSubmit = useCallback(
    async data => {
      data = {
        ...data,
        userId: userID,
      };

      try {
        await resetPasswordValidation.validate(data, {
          abortEarly: false,
        });
        await resetPasswordRoute(data);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [formRef, userID]
  );

  return (
    <ModalScreen setOpen={setModal} open={modal}>
      <div>
        <h1>Alterar Senha</h1>
        <p style={{ textAlign: 'center' }}>
          Cuidado, você está alterando a senha de um usuário!
        </p>

        <Content ref={formRef} onSubmit={handleSubmit}>
          <div>
            <span>
              <InputForm
                name="password"
                title="Nova Senha"
                placeholder="Digite a nova senha"
                required={true}
              />
            </span>
          </div>
          <div style={{ marginTop: 8 }}>
            <span>
              <InputForm
                name="password_confirmation"
                title="Confirme a nova Senha"
                placeholder="Confirme a nova senha"
                required={true}
              />
            </span>
          </div>

          <Buttons>
            <Button
              typeColor="disable"
              type="button"
              onClick={() => setModal(false)}
            >
              Cancelar
            </Button>
            <Button typeColor="primary" type="submit">
              Trocar
            </Button>
          </Buttons>
        </Content>
      </div>
    </ModalScreen>
  );
}
