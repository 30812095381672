import React from 'react';
import { useHistory } from 'react-router-dom';
import { RentalContainer } from './styles';
import { StatusTranslate } from 'util/Translate/Status';
import { FaKey } from 'react-icons/fa';

export function RentalSelect({ rental }) {
  const { id, negotiation, statusRental } = rental;
  const history = useHistory();
  const { property } = negotiation;

  const statusColor = props =>
    ({
      canceled: 'canceled',
      running: 'running',
      analyzing: 'analyzing',
      waiting_payment: 'waiting_payment',
    }[props] || 'default');

  function redirectToDetails() {
    history.push(`/dashboard/alugueis/${id}/detalhes`);
  }

  return (
    <RentalContainer
      onClick={() => redirectToDetails()}
      className={`${statusColor(statusRental)}-left`}
    >
      <div>
        <span className="svg-container">
          <FaKey />
        </span>
        <span className="address-container">
          <p>
            {property.address.street}, {property.address.number},{' '}
            {property.address.complement && `, ${property.address.complement}`}{' '}
            - {property.address.district}, {property.address.city} -{' '}
            {property.address.state} / ID {id}
          </p>
        </span>
      </div>
      <span className={`status-container ${statusColor(statusRental)}`}>
        <p>{StatusTranslate(statusRental)}</p>
      </span>
    </RentalContainer>
  );
}
