import React, { useState } from 'react';

import {
  ProgressionBar,
  Button,
  Buttons,
  LoadingScreen,
} from 'components/Shared';
import { SelectContractType } from './Components/SelectContractType';
import { Signatarius } from './Components/Signatarius';
import { FinishContract } from './Components/FinishContract';
import { VerificationContract } from './Components/VerificationContract';
import { isEmpty } from 'hooks/isEmpty';
import { toast } from 'react-toastify';
import { DocIframe } from './Components/DocIframe';
import {
  CreateContractTemplate,
  CancelContractRoute,
} from './Components/FinishContract/Services';

export function CreateContract({ negotiationData }) {
  const [currentProgress, setCurrentProgress] = useState(0);
  const [getContractsInformation, setGetContractsInformation] = useState();
  const [selectedCoSignatarius, setSelectedCoSignatarius] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [verification, setVerification] = useState(false);

  function handleVerificationSignatariusSelected() {
    if (selectedCoSignatarius) {
      if (
        selectedCoSignatarius?.tenant?.legalAgents?.length > 0 ||
        !isEmpty(selectedCoSignatarius?.tenant?.user) ||
        selectedCoSignatarius?.owner?.legalAgents?.length > 0 ||
        !isEmpty(selectedCoSignatarius?.owner?.user)
      ) {
        setCurrentProgress(currentProgress + 1);
      } else {
        toast.error('Selecione pelo menos um signatário');
      }
    } else {
      toast.error('Selecione pelo menos um signatário');
    }
  }

  async function createContractType() {
    setLoadingSubmit(true);
    try {
      const responseCreateContract = await CreateContractTemplate(
        getContractsInformation,
        negotiationData.id
      );
      const newData = {
        ...getContractsInformation,
        id: responseCreateContract.contract.id,
        url: responseCreateContract.contract.url,
      };
      setGetContractsInformation(newData);
      toast.success('Contrato criado com sucesso');
      setCurrentProgress(currentProgress + 1);
      setLoadingSubmit(false);
    } catch (error) {
      const { message } = error.response.data;
      const ErrorMessage = message =>
        ({
          'must bank account is required':
            'É necessário cadastrar uma conta bancária',
          'must cpf, and rg are required': 'É necessário cadastrar um CPF e RG',
          'The surety is invalid': 'O fiador não é válido',
        }[message] || 'Erro ao criar contrato');
      toast.error(ErrorMessage(message));

      setLoadingSubmit(false);
    }
  }

  async function cancelContract() {
    setLoadingSubmit(true);
    try {
      await CancelContractRoute(getContractsInformation.id);
      setCurrentProgress(0);
      setLoadingSubmit(false);
    } catch (error) {
      setLoadingSubmit(false);
      toast.error('Erro ao cancelar contrato');
    }
  }

  const progress = {
    0: (
      <SelectContractType
        setCurrentProgress={setCurrentProgress}
        sendData={setGetContractsInformation}
      >
        <Buttons>
          <Button
            style={{ marginLeft: 'auto' }}
            typeColor="primary"
            type="submit"
          >
            Continuar
          </Button>
        </Buttons>
      </SelectContractType>
    ),
    1: (
      <VerificationContract
        negotiationData={negotiationData}
        getContractsInformation={getContractsInformation}
      >
        <Buttons>
          <Button
            typeColor="secondary"
            type="button"
            onClick={() => {
              setCurrentProgress(currentProgress - 1);
              setVerification(false);
            }}
          >
            Voltar
          </Button>
          <Button
            typeColor="primary"
            type="button"
            onClick={() => {
              createContractType();
              setVerification(true);
            }}
          >
            Continuar
          </Button>
        </Buttons>
      </VerificationContract>
    ),
    2: (
      <DocIframe src={getContractsInformation}>
        <Buttons>
          <Button
            typeColor="secondary"
            type="button"
            onClick={() => cancelContract()}
          >
            Voltar e Cancelar Contrato
          </Button>
          <Button
            typeColor="primary"
            type="button"
            onClick={() => setCurrentProgress(currentProgress + 1)}
          >
            Continuar
          </Button>
        </Buttons>
      </DocIframe>
    ),
    3: (
      <Signatarius
        typeContract={getContractsInformation?.type}
        negotiationData={negotiationData}
        setSelectedCoSignatarius={setSelectedCoSignatarius}
        selectedCoSignatarius={selectedCoSignatarius}
      >
        <Buttons>
          <Button
            typeColor="secondary"
            type="button"
            onClick={() => setCurrentProgress(1)}
          >
            Voltar
          </Button>
          <Button
            typeColor="primary"
            type="button"
            onClick={() => handleVerificationSignatariusSelected()}
          >
            Continuar
          </Button>
        </Buttons>
      </Signatarius>
    ),
    4: (
      <FinishContract
        selectedCoSignatarius={selectedCoSignatarius}
        getContractsInformation={getContractsInformation}
        negotiationData={negotiationData}
        setLoadingSubmit={setLoadingSubmit}
      />
    ),
  };

  const translateContract = {
    administration: 'Administração',
    location: 'Locação',
  };

  return (
    <>
      <ProgressionBar
        setCurrentProgress={setCurrentProgress}
        currentProgress={currentProgress}
        progress={[
          {
            title: 'Tipos de contratos',
            subtitle: 'Selecione o tipo de contrato',
            category: 'Contratos',
            filled: getContractsInformation,
          },
          {
            title: 'Pre-vizualização do contrato',
            subtitle: 'Vizualize a preview do contrato',
            category: 'Vizualização',
            filled: getContractsInformation,
          },
          {
            title: 'Download do contrato',
            subtitle: 'Verifique os signatarios que você inseriu',
            category: 'Download',
            filled: verification,
          },
          {
            title: 'Adicionar assinantes',
            subtitle: `Contrato de ${
              translateContract[getContractsInformation?.type]
            }`,
            category: 'Signatarios',
            filled: selectedCoSignatarius?.tenant,
          },
          {
            title: 'Envio do contrato',
            subtitle: 'Envie o contrato',
            category: 'Finalizar',
          },
        ]}
      />
      {!loadingSubmit ? <>{progress[currentProgress]}</> : <LoadingScreen />}
    </>
  );
}
