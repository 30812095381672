import { useQuery } from 'react-query';
import jarvis from 'util/jarvis';
import { toast } from 'react-toastify';

export function GetReadjustment({ currentParams }) {
  const { data, isLoading, refetch } = useQuery(
    ['getReadjustments', currentParams],
    async () =>
      jarvis
        .get(`/readjustments`, {
          params: currentParams,
        })
        .then(res => {
          return res.data;
        }),
    { refetchOnWindowFocus: false }
  );
  return {
    data: data,
    isLoading: isLoading,
    refetch: refetch,
  };
}

export function GetReadjustmentDetails({ id }) {
  const { data, isLoading, refetch } = useQuery(
    ['getReadjustmentDetails', id],
    async () =>
      jarvis.get(`/readjustment/${id}`).then(res => {
        return res.data;
      })
  );
  return {
    data: data,
    isLoading: isLoading,
    refetch: refetch,
  };
}

export async function PutEditReadjustment(Body, id) {
  const { data } = await jarvis.put(`/readjustment/${id}`, Body);
  return data;
}

export async function ChangeReadjustmenteStatus(id) {
  try {
    const { data } = await jarvis.put(`/readjustment/status/${id}`);
    toast.success('Status alterado com sucesso!');
    return data;
  } catch (err) {
    toast.error('Erro ao alterar status!');
  }
}

export async function SendReadjustmentForm(Body) {
  const { data } = await jarvis.post('/readjustment', Body);
  return data;
}
