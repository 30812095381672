import jarvis from 'util/jarvis';

export async function SendFormEssencial(Body, id) {
  const EssencialData = {
    legalAgent: {
      ...Body,
      userId: id,
    },
  };
  const { data } = await jarvis.post('/legal-agent', EssencialData);
  return data;
}

export async function SendFormEditEssencial(Body, agentID, profileID) {
  const profile = {
    ...Body.profile,
  };

  const EssencialData = {
    ...Body,
    profile: {
      id: +profileID,
      ...profile,
    },
  };

  const { data } = await jarvis.put(`/legal-agent/${agentID}`, EssencialData);
  return data;
}

export async function SendFormAddress(Body, AgentId) {
  const Address = {
    address: {
      type: 'user',
      ...Body.address,
    },
  };

  const { data } = await jarvis.post(
    `/legal-agent/address/${AgentId}`,
    Address
  );
  return data;
}

export async function SendFormEditAddress(Body, AgentId, addressId) {
  const Address = {
    type: 'user',
    id: addressId,
    ...Body.address,
  };

  const { data } = await jarvis.put(`/legal-agent/address/${AgentId}`, Address);
  return data;
}

export async function sendAgentDocument(documents, agentId) {
  const { data } = await jarvis.post(
    `/legal-agent/documents/${agentId}`,
    documents
  );
  return data;
}

export async function SendAgentFilesToDocuments(
  documentsFile,
  documentsResponse
) {
  const cpfOrFail = documentsResponse.find(item => item.type === 'cpf');
  const weddingCertificateOrFail = documentsResponse.find(
    item => item.type === 'wedding_certificate'
  );

  for (const [index, item] of documentsFile.documents.entries()) {
    if (item.file) {
      if (index === 0 && cpfOrFail) {
        for (const [key, file] of item.file.entries()) {
          const formData = new FormData();
          formData.append(`file`, file.file);
          formData.append(`order`, key);
          await jarvis.post(`/document/${cpfOrFail.id}/file`, formData);
        }
      }
    }

    if (index === 2 && weddingCertificateOrFail) {
      if (item.file.length > 0) {
        for (const [key, file] of item.file.entries()) {
          const formData = new FormData();
          formData.append(`file`, file.file);
          formData.append(`order`, key);
          await jarvis.post(
            `/document/${weddingCertificateOrFail.id}/file`,
            formData
          );
        }
      }
    }
  }
}

export async function sendToDeleteFiles(documentFiles) {
  for (const file of documentFiles) {
    await jarvis.delete(`/document/file/${file.id}`);
  }
}

export async function sendEditAgentDocuments(body, documentsData, agentId) {
  const findCPF = documentsData.find(doc => doc.type === 'cpf');
  const findRG = documentsData.find(doc => doc.type === 'rg');
  const findWeddingCertificate = documentsData.find(
    doc => doc.type === 'wedding_certificate'
  );

  let documents = [];

  if (findWeddingCertificate) {
    documents = [
      {
        id: findCPF.id,
        ...body.documents[0],
      },
      {
        id: findRG.id,
        ...body.documents[1],
      },
      findWeddingCertificate
        ? {
            id: findWeddingCertificate.id,
            ...body.documents[2],
          }
        : { ...body.documents[2] },
    ];
  } else {
    documents = [
      {
        id: findCPF.id,
        ...body.documents[0],
      },
      {
        id: findRG.id,
        ...body.documents[1],
      },
    ];
  }
  const { data } = await jarvis.put(`/legal-agent/documents/${agentId}`, {
    documents,
  });
  return data;
}

export async function SendDeleteAgent(id) {
  const { data } = await jarvis.delete(`/legal-agent/${id}`);
  return data;
}

export async function createSubLegal(Body, id, legalAgentID) {
  const EssencialData = {
    legalAgent: {
      ...Body,
      userId: id,
    },
  };

  const { data } = await jarvis.post(
    `/legal-agent/${legalAgentID}`,
    EssencialData
  );
  return data;
}
