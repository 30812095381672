import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled(NavLink)`
  display: flex;
  width: 260px;
  height: 360px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: ${props => props.theme.border};
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.5s;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const Thumb = styled.div`
  height: 45%;
  width: 100%;
  position: relative;
  border-radius: 8px 8px 0 0;

  > strong {
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 8px;
    background: ${props => props.theme.backgrounds.secondary};
    color: ${props => props.theme.colors.primary};
    height: 32px;
    text-align: center;
    padding: 8px;
  }

  > label {
    display: flex;
    align-items: center;
    position: absolute;
    background: ${props => props.theme.backgrounds.secondary};
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    padding: 8px;
    right: 8px;
    top: 8px;
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
    line-height: 100%;

    > img {
      margin-right: auto;
    }
    > svg {
      height: 16px;
      width: 16px;
    }
  }

  > span {
    position: absolute;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.13);
    width: 120px;
    padding: 4px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    right: 8px;
    bottom: 0px;
    color: #fff;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
  }

  > img {
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 65%;
  width: 100%;
  padding: 16px;
  background: ${props => props.theme.container.background};
  border-radius: 8px;
  gap: 8px;

  > label {
    font-size: 16px;
    line-height: 100%;
    color: #055eff;
  }

  > strong {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: ${props => props.theme.colors.primary};
  }

  > span {
    font-size: 16px;
    line-height: 100%;
    color: ${props => props.theme.label.color};
  }
`;

export const IconSpecification = styled.span`
  display: flex;
  align-items: center;
  > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  > strong {
    margin-right: 8px;
  }
`;
