import React from 'react';
import { useRef, useCallback, useEffect } from 'react';
import {
  InputCurrency,
  InputSelect,
  InputText,
  Content,
  InputNumber,
} from 'components/Shared';
import { CicleValidation } from './Validation';
import { validationError } from 'validations/forms';
import { useParams } from 'react-router-dom';

export function CycleForm({ senData, selectedCycle, children }) {
  const { id } = useParams();
  const formRef = useRef(null);

  useEffect(() => {
    if (selectedCycle) {
      formRef.current.setData(selectedCycle);
    }
  }, [selectedCycle]);

  const handleSubmit = useCallback(
    async data => {
      data = {
        rentalId: id,
        ...data,
      };

      try {
        await CicleValidation.validate(data, {
          abortEarly: false,
        });

        await senData(data);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [formRef]
  );

  return (
    <Content ref={formRef} onSubmit={handleSubmit}>
      <div>
        <span>
          <InputCurrency title="Valor" required={true} name="amount" />
        </span>
        <span>
          <InputNumber
            title="Quantidade de ciclos"
            required={true}
            name="cycleLength"
          />
        </span>
      </div>
      <div>
        <span>
          <InputSelect
            name="cycleType"
            title="Tipo de Ciclo"
            required={true}
            placeholder="Selecione o tipo do ciclo"
            options={[
              { value: 'increment', label: 'Incremento' },
              { value: 'discount', label: 'Desconto' },
              { value: 'fine', label: 'Multa para Wizzer' },
              { value: 'iptu', label: 'IPTU' },
              { value: 'fireInsurance', label: 'Seguro' },
            ]}
          />
        </span>
      </div>
      <div>
        <span>
          <InputText
            title="Descrição"
            required={true}
            name="cycleDescription"
          />
        </span>
      </div>
      {children}
    </Content>
  );
}
