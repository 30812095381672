import styled from 'styled-components';

export const CycleContainer = styled.li`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.container.background};
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid #055eff;

  &::before {
    content: '';
    width: 8px;
    position: absolute;
    height: 100%;
    background: green;
    left: 0;
    top: 0;
    border-radius: 8px 0 0 8px;
    background: #d8010e;
  }

  &.status-active {
    &::before {
      background: #0b8e63;
    }
  }

  .header-cycle {
    display: flex;
    align-items: center;
    padding: 16px 24px;
  }
  .title-cycle {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    p {
      font-size: 16px;
    }
    svg {
      width: 24px;
      height: 24px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .chevron {
    margin-left: auto;
  }

  .details-cycle {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 24px;
    transition: all 0.3s ease-in-out;
  }
  .details-title {
    font-size: 18px;
  }

  .show-details {
    margin-top: 24px;
    opacity: 1;
    visibility: visible;
    padding: 16px 24px;

    p {
      font-size: 16px;
    }
  }
  .hide-details {
    margin-top: 0px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    p {
      font-size: 0;
    }
    div {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
`;
