import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CardContainer, Favorite, Delete, Rotate, Download } from './styles';
import { MdRotateRight, MdDownload, MdFavorite } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { ModalScreen } from 'components/Shared';
import { FocusedImage } from './Components/FocusedImage';
import useFetch from 'services/ReactQuery';

export function CardPhoto({ propertyData, photo, setImageToEdit }) {
  const [modal, setModal] = useState(false);
  const [rotateModal, setRotateModal] = useState(false);
  const { mutateAsync: deleteFile } = useFetch.Delete();
  const { mutateAsync: changeMainImage } = useFetch.Put();
  const {
    id: photoID,
    main: isFavorite,
    urlImage: imageURL = null,
    urlImage1140x550: imageURL_1140x550,
  } = photo;

  async function handleDeleteImage() {
    try {
      await deleteFile({
        url: `/property/${propertyData.id}/file/delete/${photoID}`,
        key: `/property/${propertyData.id}`,
      });
      toast.success('Imagem excluida com sucesso');
    } catch (err) {
      toast.error('Erro ao excluir a imagem');
    }
  }

  async function handleMainImage() {
    try {
      toast.warn('Alterando');
      await changeMainImage({
        url: `/property/${propertyData.id}/file/${photoID}/main`,
        key: `/property/${propertyData.id}`,
      });
      toast.success('Imagem principal alterada com sucesso');
    } catch (err) {
      toast.error('Erro ao alterar imagem principal');
    }
  }

  async function downloadImage() {
    try {
      toast.warn('Aguarde enquanto a imagem é baixada');
      const image = await fetch(imageURL ? imageURL : imageURL_1140x550, {
        method: 'GET',
        credentials: 'omit',
        headers: {
          Accept:
            'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
          'Accept-Encoding': ' gzip, deflate, br',
          'Accept-Language': 'pt-BR,pt',
          'Cache-Control': 'no-cache',
          Connection: 'keep-alive',
          Host: 'wz-thanos.s3-sa-east-1.amazonaws.com',
        },
      });
      const imageBlob = await image.blob();
      const imgURL = URL.createObjectURL(imageBlob);
      const link = document.createElement('a');
      link.href = imgURL;
      link.download = 'image file name here';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.log(err);
      alert('Erro! Tente novamente');
    }
  }

  return (
    <CardContainer>
      <img
        src={imageURL || imageURL_1140x550}
        onClick={() => setModal(!modal)}
      />
      <Favorite
        onClick={handleMainImage}
        className={isFavorite ? 'active' : ''}
      >
        <MdFavorite />
      </Favorite>
      <Delete onClick={handleDeleteImage}>
        <TiDelete />
      </Delete>
      <Download onClick={downloadImage}>
        <MdDownload />
      </Download>
      <Rotate onClick={() => setImageToEdit(photo)}>
        <MdRotateRight />
      </Rotate>
      {rotateModal && (
        <FocusedImage image={photo} setFocusedMediaId={setRotateModal} />
      )}
      <ModalScreen open={modal} setOpen={setModal}>
        <img src={imageURL} />
      </ModalScreen>
    </CardContainer>
  );
}
