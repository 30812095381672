import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NegotiationContainer = styled(Link)`
  display: flex;
  position: relative;
  padding: 16px 24px;
  border: ${props => props.theme.border};
  background-color: ${props => props.theme.container.background};
  border-radius: 8px;
  margin-bottom: 8px;
  transition: 0.2s ease-in-out;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 8px;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background: ${props => props.backgroundcolor};
    transition: 0.2s ease-in-out;
  }

  .data-negotiation-property {
    display: flex;
    align-items: center;
    width: 100%;
    .photo {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 112px;
      height: 100%;
      border-radius: 8px;
      background-color: ${props => props.backgroundcolor};
      margin-right: 16px;
      > svg {
        width: 36px;
        height: 36px;
        color: ${props => props.fontcolor};
      }
    }
    .data-negotiation-property-information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .data-negotiation-property-address {
        font-size: 16px;
        color: ${props => props.theme.colors.primary};
        font-style: normal;
      }
      .data-negotiation-property-address-complement {
        font-size: 14px;
        color: ${props => props.theme.colors.primary};
      }
      .data-negotiation-property-responsible {
        font-size: 14px;
        width: fit-content;
        color: ${props => props.theme.colors.primary};
      }
    }
  }
  .data-negotiation-others {
    display: flex;
    width: 100%;
    gap: 80px;
    .data-negotiation-tenant {
      display: flex;
      flex-direction: column;
      width: 100%;
      .data-negotiation-tenant-title {
        font-size: 16px;
        margin-bottom: 16px;
      }
      .data-negotiation-tenant-information {
        display: flex;
        align-items: center;
        text-decoration: none;
        .data-negotiation-tenant-photo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 52px;
          height: 48px;
          border-radius: 50%;
          background-color: rgb(5, 94, 255);
          margin-right: 16px;
          color: white;
        }
        .data-negotiation-tenant-data {
          display: flex;
          flex-direction: column;
          width: 100%;

          p {
            font-size: 14px;
            margin-bottom: 8px;
            color: ${props => props.theme.colors.primary};

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .data-negotiation-status {
      display: flex;
      flex-direction: column;
      .data-negotiation-status-title {
        font-size: 16px;
        margin-bottom: 16px;
      }
      .data-negotiation-status-value {
        margin-top: auto;
        margin-bottom: 8px;
        padding: 16px;
        background: ${props => props.backgroundcolor};
        border-radius: 8px;
        p {
          font-size: 16px;
          color: ${props => props.fontcolor};
        }
      }
    }
  }

  &:hover {
    &:after {
      background: rgb(5, 94, 255);
    }
  }

  @media screen and (max-width: 990px) {
    flex-direction: column-reverse;
    align-items: center;
    .data-negotiation-property {
      .photo {
        display: none;
      }
    }
    .data-negotiation-others {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 16px;
      gap: 0;

      .data-negotiation-tenant {
        padding: 8px;
        border: 1px solid #d8e3ee;
        border-radius: 8px;

        .data-negotiation-tenant-data {
          word-wrap: break-word;
        }
      }

      .data-negotiation-status {
        display: flex;
        margin-bottom: 16px;
        .data-negotiation-status-value {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;
