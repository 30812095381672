import React from 'react';

import { Container, Buttons, Button, LinkButton } from 'components/Shared';

import { TypeForm } from '../../Components/Forms/TypeForm';

export function Type({
  setCurrentProgress,
  currentProgress,
  setTypeData,
  typeData,
}) {
  function sendData(data) {
    setTypeData(data);
    setCurrentProgress(currentProgress + 1);
  }

  return (
    <Container>
      <TypeForm typeData={typeData} sendData={sendData}>
        <Buttons>
          <LinkButton typeColor="disable" type="button" to="/dashboard">
            Cancelar
          </LinkButton>
          <Button type="submit" typeColor="primary">
            Confirmar e Avançar
          </Button>
        </Buttons>
      </TypeForm>
    </Container>
  );
}
