import React from 'react';

import { ReelsContainer, StateCard } from './styles';
import { getStates } from './Service';
import { FaHome } from 'react-icons/fa';
import { useEffect } from 'react';

export function States({ setCitiesByState }) {
  const { data, isLoading } = getStates();

  function organizeCitiesByFavorite(state) {
    state.cities.sort((a, b) => (a.favorite > b.favorite ? -1 : 1));
    setCitiesByState(state);
  }

  useEffect(() => {
    if (data) {
      const findMG = data.find(state => state.sigla === 'MG');
      organizeCitiesByFavorite(findMG);
    }
  }, [data]);

  return (
    <>
      {isLoading || (
        <ReelsContainer>
          {data.map(state => (
            <StateCard
              onClick={() => organizeCitiesByFavorite(state)}
              key={state.id}
              className={`${state.cities.length > 0 && 'active'}`}
            >
              <p>{state.sigla}</p>
              <span>
                <FaHome />
                <p>{state.cities.length}</p>
              </span>
            </StateCard>
          ))}
        </ReelsContainer>
      )}
    </>
  );
}
