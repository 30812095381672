import { useQuery, useMutation, useQueryClient } from 'react-query';
import jarvis from 'util/jarvis';

const useFetch = {
  Get: ({ url, params, ...rest }) => {
    const { data, isLoading, isError } = useQuery(
      [url, params],
      async () => {
        const response = await jarvis.get(url, { params });
        return response.data;
      },
      { ...rest }
    );
    return {
      data,
      isLoading,
      isError,
    };
  },
  Put: () => {
    const queryClient = useQueryClient();
    return useMutation(
      async data => {
        const { url, body } = data;
        await jarvis.put(url, body);
        return data;
      },
      {
        onSuccess: data => {
          const { key } = data;
          queryClient.invalidateQueries([key]);
        },
      }
    );
  },
  Post: () => {
    const queryClient = useQueryClient();
    return useMutation(
      async data => {
        const { url, body } = data;
        await jarvis.post(url, body);
        return data;
      },
      {
        onSuccess: data => {
          const { key } = data;
          queryClient.invalidateQueries([key]);
        },
      }
    );
  },
  Delete: () => {
    const queryClient = useQueryClient();
    return useMutation(
      async data => {
        const { url } = data;
        const response = await jarvis.delete(url);
        return {
          ...data,
          response,
        };
      },
      {
        onSuccess: data => {
          const { key } = data;
          queryClient.invalidateQueries([key]);
        },
        onSettled: data => {
          const { response, key } = data;
          if (response.status === 204) {
            queryClient.invalidateQueries([key]);
          }
        },
      }
    );
  },
  Patch: () => {
    const queryClient = useQueryClient();
    return useMutation(
      async data => {
        const { url } = data;
        const response = await jarvis.patch(url);
        return {
          ...data,
          response,
        };
      },
      {
        onSuccess: data => {
          const { key } = data;
          queryClient.invalidateQueries([key]);
        },
        onSettled: data => {
          const { response, key } = data;
          if (response.status === 204) {
            queryClient.invalidateQueries([key]);
          }
        },
      }
    );
  },
};

export default useFetch;
