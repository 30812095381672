import jarvis from "util/jarvis";
import { useQuery } from "react-query";

export function TenantAdminQuery(currentParams) {
    const { data, isLoading } = useQuery(
      ['/users', currentParams],
      async () => {
        return jarvis.get(`/users`, { params: currentParams }).then(res => {
          return res.data;
        });
      },
      { refetchOnWindowFocus: false, enabled: currentParams.search !== null }
    );
  
    return {
      tenantData: data,
      tenantIsLoading: isLoading,
    };
  }
  
  export function TenantBrokerQuery(currentParams) {
    const { data, isLoading } = useQuery(
      ['/users/broker', currentParams],
      async () => {
        return jarvis
          .get(`/users/broker`, {
            params: {
              search: currentParams.search,
              filter: {
                role: 'tenant',
              },
            },
          })
          .then(res => {
            return res.data;
          });
      },
      { refetchOnWindowFocus: false, enabled: currentParams.search !== null }
    );
  
    return {
      tenantData: data,
      tenantIsLoading: isLoading,
    };
  }