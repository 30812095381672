import React, { useState, useRef } from 'react';

import {
  AddresForm,
  ContentVerificationData,
  Buttons,
  Button,
  ModalScreen,
} from 'components/Shared';
import { EssencialAgentSummary } from 'components/User/UserDetails/Components/Summaries/AgentsSummary/EssencialAgentSummary';
import { AddressSummary } from 'components/User/UserDetails/Components/Summaries/AddressSummary';
import { DocumentsSummary } from 'components/User/UserDetails/Components/Summaries/DocumentsSummary';
import { EssencialAgentForm } from 'components/User/Components/Forms/AgentForm/Components/EssencialAgantForm';
import { DocumentsAgentForm } from 'components/User/Components/Forms/AgentForm/Components/DocumentsAgentForm';
import { SubAgentsList } from './Components/SubAgentsList';
import { toast } from 'react-toastify';

import {
  SendFormAddress,
  SendFormEditAddress,
  SendFormEditEssencial,
  SendDeleteAgent,
  createSubLegal,
  sendAgentDocument,
  SendAgentFilesToDocuments,
  sendEditAgentDocuments,
  sendToDeleteFiles,
} from 'components/User/CreateUser/Agent/Services';

export function SpouseAgentForm({
  agentInformation,
  userRole,
  userID,
  subAgentInformation,
  setSubAgentInformation,
}) {
  const [page, setPage] = useState();
  const [editEssencial, setEditEssencial] = useState(false);
  const [editDocuments, setEditDocuments] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [deleteAgent, setDeleteAgent] = useState(false);
  const [subAgentSelected, setSubAgentSelected] = useState(false);
  const formRef = useRef(null);

  function handleNewData(data) {
    const newArray = subAgentInformation.map(item => {
      if (item === subAgentSelected) {
        const newData = {
          ...subAgentSelected,
          ...data,
        };
        return newData;
      }
      return item;
    });
    setSubAgentInformation(newArray);
  }

  async function sendDataEssencial(data) {
    try {
      if (editEssencial) {
        const responseData = await SendFormEditEssencial(
          data,
          subAgentSelected.id,
          subAgentSelected.profile.id
        );
        const editAgentData = subAgentInformation
          .filter(item => item !== subAgentSelected)
          .concat(responseData);
        setSubAgentInformation(editAgentData);
        setEditEssencial(false);
        setPage(null);
        toast.success('Responsável adicionado com sucesso!');
      } else {
        const responseData = await createSubLegal(
          data,
          userID,
          agentInformation.id
        );
        setSubAgentInformation([...subAgentInformation, responseData]);
        toast.success('Responsável adicionado com sucesso!');
      }
      setPage(null);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao adicionar responsável!');
    }
  }

  async function sendDataDocuments(data) {
    if (editDocuments) {
      try {
        const responseDocuments = await sendEditAgentDocuments(
          data,
          subAgentSelected.documents,
          subAgentSelected.id
        );
        const hasFileForm = data.documents.find(item => item.file);
        let allFiles = [];
        if (hasFileForm.file.length > 0) {
          subAgentSelected.documents.map(item => {
            if (item.file) {
              const findFile = item.file.filter(file => file);
              return allFiles.push(findFile);
            }
          });
          const unionFiles = allFiles.flat();
          if (unionFiles.length > 0) {
            await sendToDeleteFiles(unionFiles);
          }
          await SendAgentFilesToDocuments(data, responseDocuments.documents);
        }
        handleNewData(responseDocuments);
        setPage(null);
        setEditDocuments(false);
        toast.success('Documentos do responsável adicionado com sucesso!');
      } catch (error) {
        console.log(error);
        toast.error('Erro ao adicionar documentos do responsável!');
      }
    } else {
      try {
        const responseDocuments = await sendAgentDocument(
          data,
          subAgentSelected?.id
        );
        await SendAgentFilesToDocuments(data, responseDocuments.documents);
        handleNewData(responseDocuments);
        setPage(null);
        toast.success('Documentos do responsável adicionado com sucesso!');
      } catch (error) {
        toast.error('Erro ao adicionar documentos do responsável!');
      }
    }
  }
  async function sendDataAddress(data) {
    if (editAddress) {
      try {
        await SendFormEditAddress(
          data,
          subAgentSelected?.id,
          subAgentSelected.address.id
        );
        handleNewData(data);
        setPage(null);
        toast.success('Endereço do responsável adicionado com sucesso!');
      } catch (err) {
        toast.error('Erro ao adicionar endereço do responsável!');
      }
    } else {
      try {
        await SendFormAddress(data, subAgentSelected?.id);
        toast.success('Endereço do responsável adicionado com sucesso!');
        setPage(null);
        handleNewData(data);
      } catch (err) {
        toast.error('Erro ao adicionar endereço do responsável!');
      }
    }
  }

  async function handleDeleteAgent() {
    try {
      await SendDeleteAgent(subAgentSelected.id);
      const newArray = subAgentInformation.filter(
        item => item !== subAgentSelected
      );
      setSubAgentInformation(newArray);
      setDeleteAgent(false);
      setPage(null);
      toast.success('Responsável deletado com sucesso!');
    } catch (err) {
      toast.error('Erro ao deletar responsável!');
    }
  }

  const handlePage = {
    essencial:
      !editEssencial && subAgentSelected ? (
        <EssencialAgentSummary agentInformation={subAgentSelected}>
          <Buttons>
            <Button
              typeColor="primary"
              type="button"
              onClick={() => setEditEssencial(true)}
            >
              Editar
            </Button>
          </Buttons>
        </EssencialAgentSummary>
      ) : (
        <ContentVerificationData>
          <EssencialAgentForm
            formRef={formRef}
            sendDataForm={sendDataEssencial}
            getEssencialAgent={subAgentSelected}
            userRole={userRole}
          >
            <Buttons>
              <Button
                type="button"
                typeColor="disable"
                onClick={() => setEditEssencial(false)}
              >
                Cancelar
              </Button>
              <Button typeColor="primary" type="submit">
                Salvar
              </Button>
            </Buttons>
          </EssencialAgentForm>
        </ContentVerificationData>
      ),
    documents:
      !editDocuments && subAgentSelected?.documents ? (
        <DocumentsSummary
          documents={subAgentSelected?.documents}
          title={'Documentos do responável'}
        >
          <Buttons>
            <Button onClick={() => setEditDocuments(true)}>Editar</Button>
          </Buttons>
        </DocumentsSummary>
      ) : (
        <ContentVerificationData>
          <DocumentsAgentForm
            getDocuments={subAgentSelected}
            sendDataForm={sendDataDocuments}
            formRef={formRef}
          >
            <Buttons>
              <Button
                type="button"
                typeColor="disable"
                onClick={() => setEditDocuments(false)}
              >
                Cancelar
              </Button>
              <Button typeColor="primary" type="submit">
                Salvar
              </Button>
            </Buttons>
          </DocumentsAgentForm>
        </ContentVerificationData>
      ),
    address:
      !editAddress && subAgentSelected?.address ? (
        <AddressSummary
          address={subAgentSelected?.address}
          title={'Endereço do responsável'}
        >
          <Buttons>
            <Button onClick={() => setEditAddress(true)}>Editar</Button>
          </Buttons>
        </AddressSummary>
      ) : (
        <ContentVerificationData>
          <AddresForm
            dataAddress={subAgentSelected}
            sendData={sendDataAddress}
            formRef={formRef}
          >
            <Buttons>
              <Button
                type="button"
                typeColor="disable"
                onClick={() => setEditAddress(false)}
              >
                Cancelar
              </Button>
              <Button typeColor="primary" type="submit">
                Salvar
              </Button>
            </Buttons>
          </AddresForm>
        </ContentVerificationData>
      ),
  };

  return (
    <>
      <SubAgentsList
        agentPrincipal={agentInformation}
        dataAgent={subAgentInformation}
        setAgentInformation={setSubAgentSelected}
        setFormPage={setPage}
        setDeleteAgent={setDeleteAgent}
        setEdit={setEditEssencial}
      />
      {page && <>{handlePage[page]}</>}
      <ModalScreen open={deleteAgent}>
        <div>
          <h1>Excluir Responsável</h1>
          <p style={{ textAlignLast: 'center' }}>
            <strong>
              {subAgentSelected?.name} {subAgentSelected?.lastName}
            </strong>
          </p>
          <p style={{ textAlignLast: 'center' }}>
            Será excluido permanentemente do sistema. Deseja continuar?
          </p>
          <Buttons>
            <Button typeColor="secondary" onClick={() => setDeleteAgent(false)}>
              Cancelar
            </Button>
            <Button
              typeColor="cancel"
              type="button"
              onClick={() => handleDeleteAgent()}
            >
              Excluir
            </Button>
          </Buttons>
        </div>
      </ModalScreen>
    </>
  );
}
