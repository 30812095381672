import React from 'react';
import { LoadingScreen, ErrorScreen } from 'components/Shared';

import useFetch from 'services/ReactQuery';

export function GetContracts(negotiationId, params) {
  const { data, isLoading, isError } = useFetch.Get({
    url: `/contracts/${negotiationId}`,
    params: params,
  });

  return {
    data: data,
    isLoading:
      (isLoading && <LoadingScreen />) || (isError && <ErrorScreen />) || null,
  };
}
