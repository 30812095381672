import styled from 'styled-components';

export const ReelsContainer = styled.div`
  display: flex;
  padding: 16px 16px;
  width: 100%;
  gap: 8px;
  border: ${props => props.theme.border};
  border-radius: 8px;
  overflow-x: scroll;
  background: ${props => props.theme.container.background};
  &::-webkit-scrollbar {
    padding: 0 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${props => props.theme.backgrounds.secondary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.backgrounds.primary};
    border-radius: 8px;
  }
`;

export const StateCard = styled.button`
  padding: 12px;
  background: #b7b9bd;
  color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 96px;
  min-width: 96px;

  &.active {
    background: #09ba7f;
  }

  > p {
    font-size: 16px;
    font-weight: 500;
  }

  > span {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    p {
      font-size: 16px;
    }
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;
