import React, { useCallback, useState, useEffect } from 'react';

import { InputNumber } from 'components/Shared/Inputs/InputNumber';
import { InputForm } from 'components/Shared/Inputs/InputForm';
import { InputSelect } from 'components/Shared/Inputs/InputSelect';
import { states } from '../../Options/states';

import { handleZipcode } from 'services/service.address';

export function AddressInputs({ formRef, children, dataAddress }) {
  const [disabledState, setDisabledState] = useState(false);
  const filedData = dataAddress;

  useEffect(() => {
    if (filedData) {
      formRef.current.setData(filedData);
    }
  }, [filedData, formRef]);

  const getAddress = useCallback(
    async data => {
      const response = await handleZipcode(data);
      if (response) {
        formRef.current.setData(response);
        setDisabledState(true);
      }
    },
    [formRef]
  );

  return (
    <>
      <div>
        <span>
          <InputForm
            name="address.zipcode"
            maxLength="9"
            title="CEP"
            placeholder="Digite o CEP"
            required={true}
            onBlur={zipcodeInput => getAddress(zipcodeInput.target.value)}
          />
        </span>
        <span>
          <InputForm
            name="address.street"
            title="Rua"
            placeholder="Digite a rua"
            required={true}
          />
        </span>
      </div>
      <div>
        <span>
          <InputForm
            name="address.district"
            title="Bairro"
            placeholder="Digite o bairro"
            required={true}
          />
        </span>
        <span>
          <InputNumber
            name="address.number"
            title="Número"
            placeholder="Digite o número"
            required={true}
            type="number"
          />
        </span>
      </div>
      <div>
        <span>
          <InputForm
            name="address.city"
            title="Cidade"
            placeholder="Digite a cidade"
            required={true}
            disabled={disabledState}
          />
        </span>
        <span>
          <InputSelect
            name="address.state"
            title="Estado"
            placeholder="Coloque o estado"
            required={true}
            options={states}
            isDisabled={disabledState}
          />
        </span>
      </div>
      <div className="half">
        <span>
          <InputForm
            name="address.complement"
            title="Complemento"
            placeholder="Digite o complemento"
            required={false}
          />
        </span>
      </div>
      {children}
    </>
  );
}
