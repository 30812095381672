import React, { useState, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { Buttons, Button } from 'components/Shared';
import { ContentVerificationData } from 'components/Shared';
import { SendBankData } from 'components/User/CreateUser/BankAccount/Services';
import { BankAccountForm } from 'components/User/Components/Forms/BankAccountForm';
import { BankSummary } from '../../Summaries/BankSummary';
import { toast } from 'react-toastify';

export function BankAccount({ bank, setBank }) {
  const formRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const { id: userID } = useParams();

  async function sendData(data) {
    if (bank) {
      try {
        await SendBankData(data, userID);
        setBank(data);
        setEdit(false);
        toast.success('Dados bancários adicionados com sucesso!');
      } catch (error) {
        toast.error('Erro ao adicionar dados bancários!');
      }
    } else {
      try {
        await SendBankData(data, userID);
        setBank(data);
        setEdit(false);
        toast.success('Dados bancários adicionados com sucesso!');
      } catch (error) {
        toast.error('Erro ao adicionar dados bancários!');
      }
    }
  }

  return (
    <>
      {!edit && bank ? (
        <BankSummary bank={bank} title="Conta Bancária">
          <Buttons>
            <Button typeColor="primary" onClick={() => setEdit(true)}>
              Editar
            </Button>
          </Buttons>
        </BankSummary>
      ) : (
        <ContentVerificationData>
          <BankAccountForm
            formRef={formRef}
            dataBank={bank}
            sendData={sendData}
          >
            <Buttons>
              {edit && (
                <Button
                  typeColor="disable"
                  type="button"
                  onClick={() => setEdit(false)}
                >
                  Cancelar
                </Button>
              )}
              <Button
                style={{ marginLeft: 'auto' }}
                typeColor="primary"
                type="submit"
              >
                Enviar
              </Button>
            </Buttons>
          </BankAccountForm>
        </ContentVerificationData>
      )}
    </>
  );
}
