import styled from 'styled-components';

export const Image = styled.img`
  width: 70%;
  margin-bottom: 2rem;
`;

export const Typography = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 1rem;
  cursor: pointer;
`;
