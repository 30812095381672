import jarvis from 'util/jarvis';

export async function SendForm(Body) {
  const { data } = await jarvis.post('/signup', Body);
  return data;
}

export async function SendEditEssencialForm(Body, id) {
  const { data } = await jarvis.put(`/user/${id}`, Body);
  return data;
}
