import styled from 'styled-components';

export const UserList = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  padding: 16px 24px;
  border: ${props => props.theme.border};
  background: ${props => props.theme.colors.secondary};
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    align-items: center;
  }

  > span {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }

  .photo {
    display: flex;
    width: 56px;
    height: 56px;
    align-items: center;
    justify-content: center;
    background: rgb(5, 94, 255);
    border-radius: 50%;
    margin-right: 16px;
    > p {
      padding: 16px;
      color: #fff;
      font-size: 24px;
    }
  }

  .info {
    > p {
      font-size: 16px;
      word-break: break-all;
    }
  }
  .selected {
    background: rgb(5, 94, 255);
  }
`;
