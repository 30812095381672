import styled from 'styled-components';

export const PowerButton = styled.div`
  position: relative;
  width: 46px;
  height: 24px;
  background: #dfe0e2;
  border-radius: 32px;
  margin-right: 16px;
  > input {
    display: none;
  }
  > label {
    width: 16px;
    height: 16px;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 4px;
    z-index: 1;
    background: #989ba1;
    border-radius: 32px;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
    transform: translateY(-50%);
  }
  > input:checked + label {
    background: #055eff;
    left: 26px;
  }
`;
