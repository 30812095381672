class Role {
  isOwner = null;
  isTenant = null;
  isBroker = null;
  isAdmin = null;
  isGuest = null;

  constructor(role) {
    this.isAdmin = () => role === 'admin';
    this.isTenant = () => role === 'tenant';
    this.isBroker = () => role === 'broker';
    this.isOwner = () => role === 'owner';
    this.isGuest = () => role === 'guest';
  }

  getRole() {
    return this;
  }
}

export default Role;
