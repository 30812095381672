import React from 'react';

import { Li, ListTitle, Navigation } from '../../styles';

export function RouteButton({
  title,
  routing,
  setSelected,
  selected,
  handleClose,
}) {
  return (
    <>
      <ListTitle>{title}</ListTitle>
      {routing.map((route, index) => {
        function showIcon(icon) {
          if (typeof icon === 'function') {
            return icon();
          }
          return <img src={icon} />;
        }

        return (
          <Li key={index}>
            <Navigation
              to={route.path}
              className={selected === route.path ? 'selected' : ''}
              onClick={() => {
                setSelected(route.path);
                handleClose();
              }}
            >
              {showIcon(route.icon)}

              <label>{route.title}</label>
            </Navigation>
          </Li>
        );
      })}
    </>
  );
}
