import React, { useRef, useState } from 'react';

import { Buttons, Container, Button, AddresForm } from '../../../Shared';
import { FinalizationModal } from '../../Components/Forms/Modal/FinalizationModal';
import { SendAddressForm } from './services';
import { toast } from 'react-toastify';

export function Address({
  setCurrentProgress,
  setDataAddress,
  dataEssencial,
  dataAddress,
  currentProgress,
  role,
  id,
}) {
  const formRef = useRef(null);
  const [finalize, setFinalize] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function handleActionsButtons() {
    if (finalize === true) {
      setShowModal(true);
    } else {
      if (role === 'tenant') {
        setCurrentProgress(currentProgress + 2);
      } else {
        setCurrentProgress(currentProgress + 1);
      }
    }
  }

  async function sendData(data) {
    if (dataAddress) {
      //edit
    } else {
      try {
        await SendAddressForm(data, id);
        toast.success('Endereço cadastrado com sucesso!');
        setDataAddress(data);
        handleActionsButtons();
      } catch (err) {
        toast.error('Erro ao cadastrar endereço!');
      }
    }
  }

  return (
    <>
      <Container>
        <AddresForm
          formRef={formRef}
          sendData={sendData}
          dataAddress={dataAddress}
        >
          <Buttons>
            <Button typeColor="secondary" onClick={() => setFinalize(true)}>
              Finalizar Cadastro
            </Button>
            <Button type="submit" typeColor="primary">
              Confimar e Avançar
            </Button>
          </Buttons>
          <FinalizationModal
            open={showModal}
            setOpen={setShowModal}
            dataEssencial={dataEssencial}
            userId={id}
          />
        </AddresForm>
      </Container>
    </>
  );
}
