import styled from 'styled-components';

export const Container = styled.button`
  padding: 16px;
  width: 100%;
  height: 48px;
  background: #055eff;
  border-radius: 8px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #fff;
`;
