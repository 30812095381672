import React from 'react';

import { Filter, AsyncFilter } from './styles';
import { useTheme } from 'styled-components';

export function SelectedFilter({ ...rest }) {
  const { colors } = useTheme();

  return (
    <Filter
      styles={{
        input: provided => ({
          ...provided,
          color: colors.primary,
        }),
      }}
      {...rest}
    />
  );
}

export function SelectedAsyncFunction({ ...rest }) {
  const { colors } = useTheme();

  return (
    <AsyncFilter
      styles={{
        input: provided => ({
          ...provided,
          color: colors.primary,
        }),
      }}
      {...rest}
    />
  );
}
