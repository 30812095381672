import styled from 'styled-components';
import Calendar from '../../../Input/InputCalendar/Calendar';

export const Input = styled(Calendar)`
  width: 100%;
  max-height: 54px;
  border: ${props => props.theme.border};
  background: '#000';
  border-radius: 8px;
  transition: 'all 100ms';
  padding: 10px;

  ::placeholder {
    color: #b4b4b5;
    font-size: 16px;
    line-height: 19px;
  }

  :focus {
    background: rgba(5, 94, 255, 0.03);
    border: 1px solid #055eff;
    box-sizing: border-box;
  }

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;
