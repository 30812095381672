import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { GrStatusCriticalSmall } from 'react-icons/gr';
import { RoleType } from 'util/Translate/RoleType';

function InputCheckBox({ name, options, ...rest }) {
  const inputRef = useRef([]);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue(refs) {
        const all = refs.map(ref => {
          return {
            ...JSON.parse(ref.value),
            checked: ref.checked,
          };
        });
        return all;
      },
      setValue(refs, value) {
        const item = refs.find(ref => ref.value === `${value}`);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      {options.map((option, index) => {
        return (
          <div key={option.id}>
            <span>
              <input
                style={{ marginRight: 8 }}
                ref={elRef => (inputRef.current[index] = elRef)}
                type="checkbox"
                id={options.id}
                name={fieldName}
                value={JSON.stringify(option)}
                defaultChecked={defaultValue === JSON.stringify(option)}
                {...rest}
              />
              {option.name} {option.lastName}
            </span>
            <span>{option.email}</span>
            <span>{RoleType(option.type || option.role)}</span>
            <span>
              <GrStatusCriticalSmall
                style={{
                  color: `${(option.role && '#0DAF7A') ||
                    (option.status ? '#0DAF7A' : '#D8010E')}`,
                }}
              />
            </span>
          </div>
        );
      })}
    </>
  );
}

InputCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputCheckBox;
