import styled from 'styled-components';
import Input from '../../../Input/InputText';

export const InputRow = styled(Input)`
  width: 100%;
  height: 96px;
  border: ${props => props.theme.border};
  background: ${props => props.theme.container.background};
  color: ${props => props.theme.colors.primary};
  border-radius: 8px;
  transition: 'all 100ms';
  padding: 10px;
  margin-top: 8px;

  ::placeholder {
    color: #b4b4b5;
    font-size: 16px;
    line-height: 19px;
  }

  :focus {
    background: rgba(5, 94, 255, 0.03);
    border: 1px solid #055eff;
    box-sizing: border-box;
  }

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;
