import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  border-radius: 24px;
  background: ${props => props.theme.container.background};
  border: ${props => props.theme.border};
  width: 35%;

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;
