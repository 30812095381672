import styled from 'styled-components';

export const Button = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
