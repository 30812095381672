import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { Content } from 'components/Shared';

import { typeValidation } from './validation';

import { handleTypes } from './service';

import { PropTypeButton } from './styles';
import houseGrey from 'assets/form/house-grey.svg';
import houseBlue from 'assets/form/house-blue.svg';
import apartmentGrey from 'assets/form/apartment-grey.svg';
import apartmentBlue from 'assets/form/apartment-blue.svg';

export function TypeForm({ children, sendData, typeData }) {
  const [propType, setPropType] = useState('');
  const [data, setData] = useState();

  useEffect(() => {
    async function getData() {
      const filedData = await handleTypes();
      setData(filedData);
    }
    getData();
  }, []);

  useEffect(() => {
    if (typeData && data) {
      const propertyType = data.find(ref => ref.value === typeData[0]);
      setPropType(propertyType.label);
    }
  }, [typeData, data]);

  async function handleSubmit() {
    try {
      await typeValidation.validate(propType, {
        abortEarly: false,
      });
      const type = {
        id: data.find(type => type.label === propType).value,
      };
      sendData(type);
    } catch (err) {
      console.log(err);
      toast.error('Selecione um tipo de imóvel.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <Content onSubmit={handleSubmit}>
      <div>
        <PropTypeButton
          active={propType === 'Apartamento'}
          type="button"
          onClick={() => setPropType('Apartamento')}
        >
          <img
            src={propType === 'Apartamento' ? apartmentBlue : apartmentGrey}
            alt={'apartment'}
          />
          Apartamento
        </PropTypeButton>
        <PropTypeButton
          active={propType === 'Casa'}
          type="button"
          onClick={() => setPropType('Casa')}
        >
          {' '}
          <img
            src={propType === 'Casa' ? houseBlue : houseGrey}
            alt={'house'}
          />
          Casa
        </PropTypeButton>
      </div>
      {children}
    </Content>
  );
}
