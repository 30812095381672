import * as Yup from 'yup';

export const taxValidation = Yup.object().shape({
  negotiation: Yup.object().shape({
    admFeeId: Yup.number()
      .typeError('Selecione a taxa de administração')
      .required('Selecione a taxa de administração'),
    readjustmentId: Yup.number()
      .typeError('Selecione o reajuste')
      .required('Selecione o reajuste'),
    guaranteeId: Yup.number()
      .typeError('Selecione a garantia')
      .required('Selecione a garantia'),
  }),
});
