import React from 'react';

import { RentalPropertyContainer } from './styles';
import { FaKey } from 'react-icons/fa';
import { GoLinkExternal } from 'react-icons/go';
import { useHistory } from 'react-router-dom';

export function RentalProperty({ status, negotiation }) {
  const { property, owner, tenant } = negotiation;
  const { address } = property;

  const history = useHistory();

  const fontColor = {
    running: '#fff',
    canceled: '#fff',
    analyzing: '#353D40',
    waiting_payment: '#353D40',
  };

  const backgroundColor = {
    running: '#0B8E63',
    canceled: '#D8010E',
    analyzing: '#FFDE67',
    waiting_payment: 'orange',
  };

  return (
    <RentalPropertyContainer
      fontColor={fontColor[status]}
      backgroundColor={backgroundColor[status]}
    >
      <span>
        <FaKey />
      </span>
      <div>
        <address className="title-address">
          {address.street}, {address.number} - {address.district}
        </address>
        <address>
          {address.complement} {address.city}/{address.state}
        </address>
        <p>
          Proprietário: {owner.name} {owner.lastName}{' '}
          <button
            onClick={() =>
              history.push(`/dashboard/clientes/detalhes/${owner.id}`)
            }
          >
            <GoLinkExternal />
          </button>
        </p>
        <p>
          Inquilino: {tenant.name} {tenant.lastName}{' '}
          <button
            onClick={() =>
              history.push(`/dashboard/clientes/detalhes/${tenant.id}`)
            }
          >
            <GoLinkExternal />
          </button>
        </p>
      </div>
    </RentalPropertyContainer>
  );
}
