import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    margin-top: 24px;
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 900;

    > svg {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  > span {
    margin: 4px 0;
  }

  > table {
    width: min-content;

    > tr {
      > th {
        text-align: left;
        min-width: 128px;
        padding: 3px 32px 3px 0;
      }

      > td {
        text-align: left;
        min-width: 128px;
        padding: 3px 32px 3px 0;
      }
    }
  }
`;
