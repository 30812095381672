import jarvis from 'util/jarvis';
import { useQuery } from 'react-query';

export function UserDataDetails(userId) {
  const { data, isLoading } = useQuery(
    ['/user', userId],
    async () => {
      return jarvis.get(`/user/${userId}`).then(res => {
        return res.data;
      });
    },
    { refetchOnWindowFocus: false }
  );

  return {
    data: data,
    isLoading: isLoading,
  };
}
