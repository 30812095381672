import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Pagination } from 'components/Pagination';
import { Titles } from 'components/Titles';

import { CardProperty } from 'components/Property/Components/CardProperty';
import { getProperties } from './service';
import { InfoLabels, ContainerList } from './styles';

import {
  Search,
  Box,
  FilterContainer,
  RecentlyButton,
  SelectedFilter,
  EmptyList,
} from 'components/Shared';

import { adminFilterList, brokerFilterList } from './Options';

export function PropertyList() {
  const { profile } = useSelector(state => state.user);
  const [currentParams, setCurrentParams] = useState({
    page: 1,
    perPage: 10,
    order: 'DESC',
    search: null,
    filter: { status: '' },
  });

  const { data, isLoading } = getProperties(currentParams);

  async function handlePageChange({ selected }) {
    const newParams = { ...currentParams, page: selected };
    setCurrentParams(newParams);
  }

  function handleSearch(search) {
    const newParams = { ...currentParams, page: 1, search: search };
    setCurrentParams(newParams);
  }

  const handleSearchDebounced = AwesomeDebouncePromise(handleSearch, 300);

  function handleSearchChange(e) {
    handleSearchDebounced(e.target.value);
  }

  return (
    <Box>
      <Titles title="Imóveis" subtitle="Lista de imóveis" />
      <FilterContainer>
        <SelectedFilter
          name="status"
          className="field-select"
          onChange={field => {
            setCurrentParams({
              ...currentParams,
              page: 1,
              filter: { status: field.value },
            });
          }}
          options={profile.isAdmin ? adminFilterList : brokerFilterList}
          defaultValue={{
            value: '',
            label: 'Todos',
          }}
        />

        {profile.isAdmin ? (
          <Search
            id="search"
            type="text"
            placeholder="Pesquisar imóvel"
            onChange={handleSearchChange}
          />
        ) : (
          <Search
            type="text"
            placeholder="Procurar"
            onChange={handleSearchChange}
          />
        )}
      </FilterContainer>

      <InfoLabels>
        <span>
          Total de itens -{' '}
          <strong>
            {data?.total} ite
            {data?.total > 1 ? 'ns' : 'm'}
          </strong>
        </span>
        <RecentlyButton
          order={currentParams.order}
          onClick={() => {
            setCurrentParams({
              ...currentParams,
              page: 1,
              order: currentParams.order === 'DESC' ? 'ASC' : 'DESC',
            });
          }}
        />
      </InfoLabels>

      {isLoading || (
        <>
          {data.total > 0 ? (
            <>
              <ContainerList>
                {data.data.map(property => (
                  <CardProperty key={property.id} property={property} />
                ))}
              </ContainerList>
              <Pagination
                currentPage={currentParams.page || 1}
                totalCount={data.total}
                pagesSize={currentParams.perPage}
                onPageChange={handlePageChange}
              />
            </>
          ) : (
            <EmptyList />
          )}
        </>
      )}
    </Box>
  );
}
