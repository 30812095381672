import React from 'react';

import { Container, ListContainer, SubList } from './styles';
import InputCheckBox from './Components/InputCheckBox';

export function SelectSignatariusList({ Signatarius, name }) {
  function findDocument(documents) {
    const document = documents.find(document => document.type);
    return document;
  }

  function documentTypeOf(documentValue) {
    if (documentValue)
      return documentValue.replace(/\D/g, '').length <= 11 ? 'cpf' : 'cnpj';
    else return 'cpf';
  }

  return (
    <Container>
      <ListContainer>
        <div className="headerList">
          <span>Nome</span>
          <span>E-mail</span>
          <span>Tipo</span>
          <span>Status</span>
        </div>
        <InputCheckBox
          name={`${name}.user`}
          options={[Signatarius]}
          disabled={
            documentTypeOf(findDocument(Signatarius.documents).document) !==
            'cpf'
          }
        />
        {Signatarius?.legalAgents?.length > 0 && (
          <InputCheckBox
            name={`${name}.legalAgents`}
            options={Signatarius?.legalAgents}
          />
        )}
        {Signatarius?.legalAgents?.map((subSignatarius, index) => {
          if (subSignatarius.legalAgents.length > 0) {
            return (
              <SubList key={subSignatarius.id}>
                <InputCheckBox
                  name={`${name}.legalAgents[${index}].selected`}
                  options={subSignatarius.legalAgents}
                />
              </SubList>
            );
          }
        })}
      </ListContainer>
    </Container>
  );
}
