import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import wizzerLogo from 'assets/LogoWizzer.svg';

import wizzerLogoHome from 'assets/wizzerLogoHome.svg';

import { routeByRole } from '../RouteRoles';

import { Container, MenuHeader, List } from './styles';

import { RouteButton } from './Components/RouteButton';
import { MinimizeButton } from './Components/MinimizeButton';

export const Dashboard = ({ close, setClose, setOpen, open }) => {
  const { role } = useSelector(state => state.user.profile);
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(pathname);

  const handleClose = useCallback(() => {
    window.scroll(0, 0);
    if (window.innerWidth <= 990) {
      setClose(false);
    }
  }, []);

  return (
    <Container
      id="main-menu"
      className={`${open ? '' : 'minimize'} ${close ? 'close' : ''}`}
      open={open}
    >
      <MinimizeButton open={open} setOpen={setOpen} />
      <MenuHeader>
        <NavLink to="/dashboard" onClick={() => handleClose()}>
          <img
            className="image-max"
            src={open ? wizzerLogo : wizzerLogoHome}
            alt="Wizzer"
          />
        </NavLink>
      </MenuHeader>
      <List>
        {routeByRole(role).map((item, index) => {
          return (
            <RouteButton
              key={index}
              title={item.title}
              routing={item.routes}
              handleClose={handleClose}
              setSelected={setSelected}
              selected={selected}
            />
          );
        })}
      </List>
    </Container>
  );
};
