import React, { useCallback, useEffect, useState } from 'react';

import {
  Content,
  SpaceLine,
  InputForm,
  InputCalendar,
  InputPhone,
  InputSelect,
  TitleContainer,
} from 'components/Shared';

import { handleSubmit } from './Context';
import { CoRepresentativeOwner, CoRepresentativeTenant } from './Option';

export function EssencialAgentForm({
  getEssencialAgent,
  sendDataForm,
  userRole,
  children,
  formRef,
}) {
  const [descriptionType, setDescriptionType] = useState();

  useEffect(() => {
    if (getEssencialAgent) {
      formRef.current.setData(getEssencialAgent);
    }
  }, [getEssencialAgent, formRef]);

  const submit = useCallback(
    async data => {
      await handleSubmit(data, sendDataForm, formRef);
    },
    [sendDataForm, formRef]
  );

  return (
    <Content ref={formRef} onSubmit={submit}>
      <TitleContainer>
        <h2>Dados do Responsável</h2>
      </TitleContainer>
      <div className="half">
        <span>
          <InputSelect
            name="type"
            title="Selecione o tipo de responsável"
            placeholder="Selecione o tipo de responsável"
            required={true}
            options={
              userRole === 'tenant'
                ? CoRepresentativeTenant
                : CoRepresentativeOwner
            }
            onChange={e => setDescriptionType(e)}
          />
        </span>
      </div>
      {descriptionType?.value === 'other' && (
        <div className="half">
          <span>
            <InputForm
              name="descriptionType"
              title="Descrição do tipo de responsável"
              placeholder="Descrição do tipo de responsável"
              required={true}
            />
          </span>
        </div>
      )}
      <div>
        <span>
          <SpaceLine />
        </span>
      </div>
      <div>
        <span>
          <InputForm
            name="name"
            title="Nome"
            placeholder="Digite o nome"
            required={true}
          />
        </span>
        <span>
          <InputForm
            name="lastName"
            title="Sobrenome"
            placeholder="Digite o sobrenome"
            required={true}
          />
        </span>
      </div>
      <div>
        <span>
          <InputPhone
            name="phones[0].phone"
            title="Telefone"
            placeholder="Digite o telefone"
            required={true}
          />
        </span>
        <span>
          <InputPhone
            name="phones[1].phone"
            title="Telefone Adicional"
            placeholder="Digite o telefone"
            required={false}
          />
        </span>
      </div>
      <div>
        <span>
          <InputForm
            name="email"
            title="E-mail"
            placeholder="Digite o e-mail"
            required={true}
          />
        </span>
        <span>
          <InputCalendar
            name="profile.birthdayDate"
            title="Data de Nascimento"
            placeholder="Coloque a data de nascimento"
            required={true}
          />
        </span>
      </div>
      <div>
        <span>
          <InputForm
            name="profile.career"
            title="Profissão"
            placeholder="Digite a carreira"
            required={true}
          />
        </span>
        <span>
          <InputForm
            name="profile.nationality"
            title="Nacionalidade"
            placeholder="Digite a nacionalidade"
            required={true}
          />
        </span>
      </div>
      {children}
    </Content>
  );
}
