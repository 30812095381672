import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Buttons,
  Button,
  ContentVerificationData,
  ContentData,
  Filter,
} from 'components/Shared';
import { UserDataSummary } from 'components/User/UserDetails/Components/Summaries/UserDataSummary';
import { EssencialDataForm } from 'components/User/Components/Forms/EssencialDataForm';
import { UserProfileForm } from 'components/User/Components/Forms/UserProfileForm';
import {
  SendProfileData,
  SendEditProfileForm,
} from 'components/User/CreateUser/UserProfile/Services';
import { SendEditEssencialForm } from 'components/User/CreateUser/EssencialData/Services';

export function Essencial({
  essencialData,
  setEssencialData,
  brokerResponsible,
  profile,
  role,
  setProfile,
}) {
  const formRef = useRef(null);
  const { id: userID } = useParams();
  const [edit, setEdit] = useState(false);
  const [form, setForm] = useState('essencial');
  const [fill, setFill] = useState(false);

  async function handleSendEssencialData(data) {
    try {
      await SendEditEssencialForm(data, userID);
      setEdit(false);
      setEssencialData(data);
      toast.success('Dados essenciais atualizados com sucesso!');
    } catch (error) {
      toast.error('Erro ao atualizar dados essenciais!');
    }
  }

  async function handleSendProfile(data) {
    if (edit && profile) {
      try {
        const profileResponse = await SendEditProfileForm(
          data.profile,
          userID,
          profile.id
        );
        setProfile(profileResponse.profile);
        setEdit(false);
        toast.success('Perfil atualizado com sucesso!');
      } catch (err) {
        toast.error('Erro ao atualizar perfil!');
      }
    } else {
      try {
        const profileResponse = await SendProfileData(data.profile, userID);
        setProfile(profileResponse);
        setEdit(false);
        toast.success('Perfil adicionado com sucesso!');
      } catch (err) {
        toast.error('Erro ao adicionar perfil!');
      }
    }
  }

  return (
    <>
      {!edit && essencialData ? (
        <UserDataSummary
          essencialData={essencialData}
          brokerResponsible={brokerResponsible}
          profile={profile}
        >
          <Buttons>
            <Button typeColor="primary" onClick={() => setEdit(true)}>
              Editar
            </Button>
          </Buttons>
        </UserDataSummary>
      ) : (
        <ContentVerificationData>
          <h1>Dados do Usuário</h1>
          <ContentData>
            <Filter
              filter={[
                { value: 'essencial', label: 'Dados Essenciais' },
                { value: 'profile', label: 'Dados de Perfil' },
              ]}
              active={form}
              handleFilter={filter => setForm(filter.value)}
            />
          </ContentData>
          {form === 'essencial' ? (
            <EssencialDataForm
              dataEssencial={essencialData}
              formRef={formRef}
              role={fill}
              setRole={setFill}
              sendData={handleSendEssencialData}
            >
              <Buttons>
                <Button
                  typeColor="disable"
                  type="button"
                  onClick={() => setEdit(false)}
                >
                  Cancelar
                </Button>
                <Button typeColor="primary" type="submit">
                  Salvar
                </Button>
              </Buttons>
            </EssencialDataForm>
          ) : (
            <UserProfileForm
              dataProfile={{ profile }}
              formRef={formRef}
              userRole={role}
              userName={essencialData?.name}
              sendData={handleSendProfile}
            >
              <Buttons>
                <Button
                  typeColor="disable"
                  type="button"
                  onClick={() => setEdit(false)}
                >
                  Cancelar
                </Button>
                <Button typeColor="primary" type="submit">
                  Salvar
                </Button>
              </Buttons>
            </UserProfileForm>
          )}
        </ContentVerificationData>
      )}
    </>
  );
}
