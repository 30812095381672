import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useFetch from 'services/ReactQuery';

import greatJob from 'assets/images/greatJob.svg';

import { Box } from 'components/Shared';
import { ContainerBrokerCongrats } from './styles';
import { UploadList } from 'components/Property/PropertyDetails/Components/PhotoGalery/Components/UploadList';
import { Buttons, LinkButton } from 'components/Shared';
import { toast } from 'react-toastify';

export default function CongratsBroker() {
  const { id } = useParams();
  const { mutateAsync: sendPropertyImages } = useFetch.Post();
  const history = useHistory();

  async function sendImages(formData) {
    try {
      await sendPropertyImages({
        url: `/property/${id}/file`,
        body: formData,
        key: `property/${id}`,
      });
      toast.success('Imagens enviadas com sucesso');
      history.push(`/dashboard/imoveis/detalhes/${id}`);
    } catch (err) {
      toast.error('Erro ao enviar as imagens');
    }
  }

  return (
    <Box>
      <ContainerBrokerCongrats>
        <img src={greatJob} alt="gratsBroker" />
        <h2>EXCELENTE TRABALHO!</h2>
        <p>Você preencheu as principais informações do imóvel.</p>
        <p>
          Você deseja terminar o cadastro por aqui ou adicionar as fotos agora?
        </p>
        <p>
          Lembrando que o imóvel será anunciado somente após você adicionar a
          foto.
        </p>
      </ContainerBrokerCongrats>
      <Buttons style={{ marginTop: 24 }}>
        <UploadList serviceFn={sendImages} />
        <LinkButton
          typeColor="success"
          to={`/dashboard/imoveis/detalhes/${id}`}
        >
          Finalizar cadastro do imóvel
        </LinkButton>
      </Buttons>
    </Box>
  );
}
