export default {
  title: 'light',
  colors: {
    primary: '#353d40',
    secondary: '#F0F5FF',
  },
  backgrounds: {
    primary: '#055eff',
    secondary: '#ccdeff',
    third: '#F0F5FF',
  },
  border: '1px solid #B7B9BD',
  dashboard: {
    background: '#01102D',
    color: '#F0F5FF',
  },
  body: '#F0F6FF',
  header: {
    background: 'linear-gradient(145deg, #01102D, #01102D)',
    boxShadow: '5px 5px 10px #7f8287,-5px -5px 10px #01102D',
  },
  container: {
    background: '#EBF2FF',
    border: '1px solid #B7B9BD',
  },
  label: {
    color: '#989ba1',
  },
  hover: {
    color: '#055eff',
  },
};
