import React from 'react'

import {
  Container
} from './styles'

export function Cover () {
  return (
    <Container />
  )
}
