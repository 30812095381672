import React from 'react';

import { useHistory } from 'react-router-dom';
import { ContentData, ContentGroup, TitleContainer } from 'components/Shared';
import { DocumentsTranlate } from 'util/Translate/documents';
import { formatedDocument } from 'util/Format/Document';
import { GoLinkExternal } from 'react-icons/go';

export function TenantSummary({ tenant, children }) {
  const history = useHistory();
  return (
    <>
      <TitleContainer>
        <h1>Dados do Inquilino</h1>{' '}
        <button
          onClick={() =>
            history.push(`/dashboard/clientes/detalhes/${tenant.id}`)
          }
        >
          <GoLinkExternal />
        </button>
      </TitleContainer>
      <ContentGroup>
        <div>
          <h2>Dados Principais</h2>
          <ContentData>
            <p>
              Nome:{' '}
              <strong>
                {tenant.name} {tenant.lastName}
              </strong>
            </p>
            <p>
              Email: <strong>{tenant.email}</strong>{' '}
            </p>
            {tenant.phones?.length > 0 &&
              tenant.phones.map(phone => {
                return (
                  <p key={phone.id}>
                    Telefone: <strong>{phone.phone}</strong>{' '}
                  </p>
                );
              })}
          </ContentData>
        </div>
        <div>
          {tenant.documents?.length > 0 && (
            <>
              <h2>Documentos</h2>
              <ContentData>
                {tenant.documents?.length > 0 &&
                  tenant.documents.map(document => {
                    return (
                      <p key={document.id}>
                        {DocumentsTranlate[document.type]}:{' '}
                        <strong>
                          {formatedDocument(document.document, document.type)}
                        </strong>
                      </p>
                    );
                  })}
              </ContentData>
            </>
          )}
        </div>
      </ContentGroup>
      {children}
    </>
  );
}
