import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const UserLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border: ${props => props.theme.container.border};
  border-radius: 8px;
  margin-bottom: 16px;
  text-decoration: none;
  background: ${props => props.theme.container.background};

  &:last-child {
    margin-bottom: 0;
  }

  .photo {
    display: flex;
    width: 56px;
    height: 56px;
    align-items: center;
    justify-content: center;
    background: rgb(5, 94, 255);
    border-radius: 50%;
    margin-right: 16px;
    > p {
      padding: 16px;
      color: #fff;
      font-size: 24px;
    }
  }

  .info {
    > p {
      color: ${props => props.theme.colors.primary};
      font-size: 16px;
    }
  }
`;
