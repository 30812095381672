import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Content,
  InputForm,
  InputPhone,
  InputSelect,
  InputSelectPaginate,
} from '../../../../Shared';

import { loadOptionsUserBroker } from './Services';

import { optionsRoles, optionsRolesBroker } from './Options';
import { handleSubmit } from './Context';

export function EssencialDataForm({
  children,
  dataEssencial,
  formRef,
  role,
  setRole,
  sendData,
}) {
  const { profile } = useSelector(state => state.user);
  const filedData = dataEssencial;
  const { create } = useParams();

  useEffect(() => {
    if (create === 'tenant') {
      setRole({ value: 'tenant', label: 'Inquilino' });
      if (!dataEssencial) {
        formRef.current.setFieldValue('role', 'tenant');
      }
    }
  }, [create, dataEssencial, setRole, formRef]);

  useEffect(() => {
    if (filedData) {
      const foundRole = optionsRoles.find(
        role => role.value === dataEssencial.role
      );
      setRole(foundRole);

      formRef.current.setData(filedData);

      if (filedData.responsibleCustomerId) {
        formRef.current.setFieldValue('responsibleCustomerId', {
          value: filedData.responsibleCustomerId,
          label: filedData.responsibleCustomerName,
        });
      }
    }
  }, [formRef, filedData]);

  const submit = useCallback(
    async data => {
      await handleSubmit(data, profile, sendData, formRef);
    },
    [sendData, formRef, profile]
  );

  return (
    <>
      <Content ref={formRef} onSubmit={submit}>
        <div>
          <span>
            <InputSelect
              onChange={e => setRole(e)}
              name="role"
              title="Tipo de Usuario"
              placeholder="Selecione o tipo de usuario"
              required={true}
              options={profile.isBroker ? optionsRolesBroker : optionsRoles}
            />
          </span>
          <span>
            <InputForm
              name="name"
              title="Nome"
              placeholder="Digite o nome"
              required={true}
            />
          </span>
        </div>
        <div>
          <span>
            <InputForm
              name="lastName"
              title="Sobrenome"
              placeholder="Digite o sobrenome"
              required={true}
            />
          </span>
          <span>
            <InputForm
              name="email"
              title="E-mail"
              placeholder="Digite o E-mail"
              required={true}
            />
          </span>
        </div>
        <div>
          <span>
            <InputPhone
              name="phones[0].phone"
              title="Telefone"
              placeholder="Digite o telefone"
              required={true}
            />
          </span>
          <span>
            <InputPhone
              name="phones[1].phone"
              title="Telefone Adicional"
              placeholder="Digite o telefone"
              required={false}
            />
          </span>
        </div>
        {profile?.role === 'admin' && (
          <>
            {role.value !== 'broker' && (
              <div className="half">
                <span>
                  <InputSelectPaginate
                    title="Corretor Responsável"
                    name="responsibleCustomerId"
                    placeholder="Selecione o corretor"
                    loadOptions={loadOptionsUserBroker}
                    additional={{
                      page: 1,
                    }}
                  />
                </span>
              </div>
            )}
          </>
        )}
        {role.value === 'broker' && (
          <div className="half">
            <span>
              <InputForm
                title="CRECI"
                name="documents[0].document"
                placeholder="Digite o creci"
              />
            </span>
          </div>
        )}
        {children}
      </Content>
    </>
  );
}
