import React, { useState } from 'react';

import { TransactionContainer } from './styles';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import FormatCurrency from 'util/FormatCurrency';
import { Button, Buttons, ConfirmExecution } from 'components/Shared';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { TransactionInnerCard } from './TransactionInnerCard';
import { MdPayments } from 'react-icons/md';
import { CycleTypeTranslate } from 'util/Translate/CyclesType';
import useFetch from 'services/ReactQuery';
import { toast } from 'react-toastify';

export function TransactionDetails({ transaction }) {
  const {
    boletoUrl,
    details = {},
    id,
    rentId,
    totalAmount,
    transferValue,
    transactionStatus,
    paymentExpirationDate,
  } = transaction;
  const { typeTransaction, rangeOfDays, cycles } = details || {};

  const [showDetails, setShowDetails] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const { mutateAsync: cancelTrancation } = useFetch.Put();

  function formatValuesCard() {
    return [
      {
        title: 'Valor total',
        value: FormatCurrency(totalAmount / 100),
      },
      {
        title: 'Valor do Proprietário',
        value: FormatCurrency(transferValue / 100),
      },
      {
        title: 'Valor da Wizzer',
        value: FormatCurrency((totalAmount - transferValue) / 100),
      },
      {
        title: 'Transação',
        value:
          typeTransaction === 'proportional'
            ? `Proporcional de ${rangeOfDays} dias`
            : 'Recorrente',
      },
    ];
  }

  function formatValuesCycles() {
    if (Array.isArray(cycles)) {
      return cycles.map(cycle => ({
        title: `${CycleTypeTranslate(cycle.cycleType)} ${
          cycle.cycleCurrent
        } de ${cycle.cycleLength}`,
        value: FormatCurrency(cycle.amount / 100),
      }));
    } else {
      return [];
    }
  }

  function formatDateData(date) {
    const currentDate = new Date(date);
    return {
      date: format(currentDate, "dd 'de' MMMM 'de' yyyy", { locale: pt }),
      month: format(currentDate, 'MMMM', { locale: pt }),
    };
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  async function handleDeleteTransaction() {
    try {
      await cancelTrancation({
        url: `/transaction/remove/${id}`,
        key: `/transactions/${rentId}`,
      });
      toast.success('Transação cancelada com sucesso!');
    } catch (err) {
      toast.error('Erro ao cancelar transação!');
    }
  }

  const statusClassName = status =>
    ({
      paid: 'status-active',
      waiting_payment: 'status-warning',
      canceled: '',
    }[status] || '');

  return (
    <TransactionContainer className={statusClassName(transactionStatus)}>
      <div
        className="header-transaction"
        onClick={() => setShowDetails(!showDetails)}
      >
        <div className="title-transaction">
          <MdPayments />
          <p>
            Vencimento - {formatDateData(paymentExpirationDate).date} , Mês
            Recorrente -{' '}
            {capitalizeFirstLetter(formatDateData(paymentExpirationDate).month)}
          </p>
          <span className="chevron">
            {showDetails ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </div>
      </div>
      <div
        className={`details-transaction ${
          showDetails ? 'show-details' : 'hide-details'
        }`}
      >
        <span className="category-transaction">
          <p>
            <strong>Detalhes do Transação:</strong>
          </p>
          <TransactionInnerCard data={formatValuesCard()} />
        </span>
        {Array.isArray(cycles) && cycles.length > 0 && (
          <span className="category-transaction">
            <p>
              <strong>Ciclos inclusos:</strong>
            </p>
            <TransactionInnerCard data={formatValuesCycles()} />
          </span>
        )}
        <Buttons style={{ justifyContent: 'start' }}>
          <Button typeColor="secondary" onClick={() => window.open(boletoUrl)}>
            Vizualizar Boleto
          </Button>
          <Button typeColor="cancel" onClick={() => setShowDelete(!showDelete)}>
            Excluir
          </Button>
        </Buttons>
        <ConfirmExecution
          setOpen={setShowDelete}
          open={showDelete}
          fn={handleDeleteTransaction}
          validationkey={`Cancelar transaçao ${id}`}
        />
      </div>
    </TransactionContainer>
  );
}
