import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    border-bottom: ${props => props.theme.border};
    padding: 24px;
    gap: 16px;
    &:first-child {
      margin-bottom: 16px;
    }
    &:last-child {
      border: none;
    }
    > span {
      display: flex;
      flex: 1 1 100%;
      align-items: center;

      > svg {
        width: 16px;
        height: 16px;
        color: red;
      }

      &:nth-child(n + 3) {
        flex: 1 1 40%;
      }
    }
    > div {
      display: flex;
      flex: 1 1 100%;
      align-items: center;

      > svg {
        width: 16px;
        height: 16px;
        color: red;
      }

      &:nth-child(n + 3) {
        flex: 1 1 40%;
      }
    }
  }
`;

export const SubList = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    padding: 0 0px 16px 24px;
    gap: 16px;
    &:first-child {
      margin-bottom: 16px;
    }
    &:last-child {
      border: none;
    }
    > span {
      display: flex;
      flex: 1 1 100%;
      align-items: center;

      > svg {
        width: 16px;
        height: 16px;
        color: red;
      }

      &:nth-child(n + 3) {
        flex: 1 1 40%;
      }
    }
    > div {
      display: flex;
      flex: 1 1 100%;
      align-items: center;

      > svg {
        width: 16px;
        height: 16px;
        color: red;
      }

      &:nth-child(n + 3) {
        flex: 1 1 40%;
      }
    }
  }
`;
