import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useField } from '@unform/core';
import { useTheme } from 'styled-components';

const SelectInput = ({ name, options, required, title, ...rest }) => {
  const selectRef = useRef(null);
  const { border } = useTheme();
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map(option => option.value);
        } else {
          if (!ref.props?.value) {
            return '';
          }
          return ref.props.value.value;
        }
      },
      setValue: (ref, value) => {
        if (value) {
          ref.setValue(value);
        }
      },
      clearValue(ref) {
        ref.setValue();
      },
    });
  }, [fieldName, registerField, rest.isMulti, selectRef]);

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <p style={{ fontWeight: 500 }}>{title}</p>
          {required && (
            <strong
              style={{ color: '#D8010E', fontWeight: 500, marginLeft: 8 }}
            >
              *
            </strong>
          )}
          {error && (
            <div style={{ marginLeft: 'auto' }}>
              <span role="img" aria-label="error" style={{ fontSize: 12 }}>
                ❌
              </span>
            </div>
          )}
        </div>
        <AsyncPaginate
          defaultValue={
            defaultValue &&
            options.find(option => option.value === defaultValue)
          }
          selectRef={selectRef}
          options={options}
          onFocus={() => clearError()}
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              border: error ? '1px solid #d8010e' : border,
            }),
          }}
          className={error ? 'has-error' : ''}
          classNamePrefix="react-select"
          noOptionsMessage={() => 'Sem opções'}
          {...rest}
        />
        <span
          className="error"
          style={{
            color: '#D8010E',
            fontSize: '14px',
            visibility: error ? 'visible' : 'hidden',
            height: 14,
          }}
        >
          {error}
        </span>
      </div>
    </>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SelectInput;
