import React from 'react';

import { Container, Photo, Details } from './styles';
import { MdEmail } from 'react-icons/md';
import { FaWhatsappSquare } from 'react-icons/fa';
import { phoneMask, telMask } from 'util/maskForms';

export function UserData({ user }) {
  function handlePhone(number) {
    if (number.replace(/\D/g, '').length < 11) {
      return telMask(number);
    } else {
      return phoneMask(number);
    }
  }

  function goToWhatsApp(number) {
    window.open(`https://api.whatsapp.com/send?phone=${number}`);
  }

  return (
    <Container>
      <Photo>
        <span>{user.initials}</span>
      </Photo>
      <Details>
        <h2>
          {user.name} {user.lastName}
        </h2>
        <p>
          <MdEmail /> {user.email}
        </p>
        {user.phones.map(phone => (
          <p
            className="hovered"
            key={phone.phone}
            onClick={() => goToWhatsApp(phone.phone)}
          >
            <FaWhatsappSquare /> {handlePhone(phone.phone)}
          </p>
        ))}
        {user.brokerResponsible && (
          <p>
            Corretor:
            <strong>
              {user.brokerResponsible.name} {user.brokerResponsible.lastName}
            </strong>
          </p>
        )}
      </Details>
    </Container>
  );
}
