import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    0% {

        transform: translate(-5%, -5%) rotate(35deg);
    }
    50% {
        transform: translate(5%, 5%) rotate(25deg);

    }
    100% {
        transform: translate(-5%, -5%) rotate(35deg);
    }
`;

export const Container = styled.div`
  display: flex;
  padding: 32px;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 256px;
    height: 256px;
    border-radius: 50%;
    border: 10px solid #0b8e63;
    > svg {
      width: 128px;
      height: 128px;
      color: #0b8e63;
      &.planeAnimation {
        animation: ${rotate} 6s linear infinite;
      }
    }
  }
`;
