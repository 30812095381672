export const RoleType = role =>
  ({
    tenant: 'Locatário',
    owner: 'Locador',
    legal_representative: 'Representante Legal',
    realtor_property: 'Corretor de imóveis',
    co_responsible: 'Fiança Paga',
    spouse: 'Conjugue',
    procurator: 'Procurador',
    guarantor: 'Avalista',
    surety: 'Fiador',
  }[role] || 'Aprovar');
