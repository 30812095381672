import styled from 'styled-components';

export const ReelsContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  width: 100%;
  gap: 16px;
  border: ${props => props.theme.border};
  border-radius: 8px;
  overflow: overlay;
  background: ${props => props.theme.container.background};
`;

export const ButtonReel = styled.button`
  display: flex;
  flex-direction: column;
  min-width: 176px;
  padding: 8px 16px;
  border: 1px solid #055eff;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.backgrounds.secondary};
  font-size: 16px;
  flex: 1 1 0;
  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  margin-top: 24px;
  border: ${props => props.theme.border};
  border-radius: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border: 1px solid #055eff;
  border-radius: 8px;
  padding: 16px 24px;
  gap: 8px;
  > p {
    font-size: 16px;
  }
  > button {
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    background: #25d366;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Confirmation = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  > span {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    > p {
      font-size: 16px;
    }
  }
  > button {
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    background: #055eff;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const ConfirmationContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  > div {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 90%;
    gap: 24px;
    align-items: center;
    justify-content: center;
    > h2 {
      color: #fff;
      text-align: center;
    }
    > p {
      color: #fff;
      font-size: 16px;
    }
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  > h1 {
    text-align: center;
  }
  > p {
    font-size: 16px;
  }
`;

export const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
  > img {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    object-fit: contain;
  }

  > img:hover {
    cursor: pointer;
  }

  > img:active {
    transform: scale(1.1);
  }

  > img:focus {
    outline: none;
  }

  > img:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 600px) {
    > img {
      width: 100%;
    }
  }
`;
