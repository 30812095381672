import React from 'react';

import {
  SignatariusContainer,
  Signatariu,
  CoSignatarius,
  SubSignatarius,
} from './styles';
import { BsFillPersonFill } from 'react-icons/bs';
import { isEmpty } from 'hooks/isEmpty';
import { RoleType } from 'util/Translate/RoleType';

export function SignatariusList({ signatarius, selectedCoSignatarius, type }) {
  return (
    <SignatariusContainer>
      <Signatariu
        className={`${isEmpty(selectedCoSignatarius?.user) ? 'disabled' : ''}`}
      >
        <span>
          <BsFillPersonFill />
        </span>
        <div>
          <p className="name">
            <strong>
              {signatarius.name} {signatarius.lastName}
            </strong>
          </p>
          {!isEmpty(selectedCoSignatarius?.user) && (
            <p className="assign">Assinar como {type}</p>
          )}
        </div>
      </Signatariu>
      {selectedCoSignatarius?.legalAgents &&
        selectedCoSignatarius.legalAgents.map(coSignatarius => {
          return (
            <span key={coSignatarius.id}>
              <CoSignatarius>
                <span />
                <div>
                  <span>
                    <BsFillPersonFill />
                  </span>
                  <div>
                    <p className="name">
                      <strong>
                        {coSignatarius.name} {coSignatarius.lastName}
                      </strong>
                    </p>
                    <p className="assign">
                      Assinar como {RoleType(coSignatarius.type)}
                    </p>
                  </div>
                </div>
              </CoSignatarius>
              {coSignatarius.selected &&
                coSignatarius.selected.map(legalAgent => {
                  return (
                    <SubSignatarius key={legalAgent.id}>
                      <span />
                      <div>
                        <span>
                          <BsFillPersonFill />
                        </span>
                        <div>
                          <p className="name">
                            <strong>
                              {legalAgent.name} {legalAgent.lastName}
                            </strong>
                          </p>
                          <p className="assign">
                            Assinar como {RoleType(legalAgent.type)}
                          </p>
                        </div>
                      </div>
                    </SubSignatarius>
                  );
                })}
            </span>
          );
        })}
    </SignatariusContainer>
  );
}
