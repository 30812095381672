import styled from 'styled-components';

export const CardFile = styled.label`
  display: flex;
  padding: 16px 24px;
  background: ${props => props.theme.backgrounds.primary};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: ${props => props.theme.border};
  border-radius: 8px;
  cursor: pointer;
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    > p {
      color: white;
    }
    > svg {
      width: 16px;
      height: 16px;
      color: white;
      margin-right: 8px;
    }
  }
  > input {
    display: none;
  }
`;
