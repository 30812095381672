import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import history from '../services/service.history';
import axios from 'axios';
import { toast } from 'react-toastify';

import {
  Container,
  Content,
  Logo,
  Form,
  Strong,
  Button,
  Link,
  Cover,
  Footer,
} from '../components/unauth';
import { InputForm } from 'components/Shared';

export function ResetPassword() {
  const location = useLocation();
  const token = location.search.replace('?token=', '');
  const loading = useSelector(state => state.auth.loading);

  async function handleSubmit(data) {
    try {
      await axios.post('/reset', { token, password: data.password });
      toast.success('Senha atualizada com sucesso!');
      history.push({ pathname: '/' });
    } catch (err) {
      toast.error('Não foi possível resetar sua senha, tente novamente.');
    }
  }

  return (
    <Container>
      <Content>
        <Form className="form" onSubmit={handleSubmit}>
          <Logo />
          <Strong>Recuperação de Senha</Strong>
          <InputForm
            name="password"
            title="Sua Nova Senha"
            type="password"
            placeholder="Senha"
            required={true}
          />
          <InputForm
            name="password"
            title="Confirmar Nova Senha"
            type="password"
            placeholder="Senha"
            required={true}
          />
          <Button type="submit">
            {loading ? 'Carregando...' : 'ALTERAR SENHA'}
          </Button>
          <Link to="/">Voltar</Link>
        </Form>
        <Cover />
      </Content>
      <Footer>
        © {new Date().getFullYear()} Wizzer Imóveis - Brasil - Todos os direitos
        reservados
      </Footer>
    </Container>
  );
}
