import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  border: ${props => props.theme.border};
  border-radius: 8px;
  padding: 4px;
  justify-content: space-between;

  .hasButton {
    margin-top: 0;
  }

  .middleContent {
    margin-bottom: 0;
  }

  @media screen and (max-width: 990px) {
    flex-direction: column;
    justify-content: auto;

    .hasButton {
      margin-top: 8px;
    }

    .middleContent {
      margin-bottom: 8px;
    }
  }
`;
