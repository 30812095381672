import React, { useEffect, useCallback } from 'react';

import { Content, SpaceLine, TitleContainer } from 'components/Shared';
import { Details } from 'components/Negotiations/CreateNegotiation/Components/Forms/NegotiationForm/Components/Details';
import { negotiationValidation } from './Validation';
import { validationError } from 'validations/forms';
import { ContractInformation } from 'components/Negotiations/CreateNegotiation/Components/Forms/NegotiationForm/Components/ContractInformation';

export function NegotiationForm({
  negotiationData,
  getDataForm,
  formRef,
  children,
}) {
  useEffect(() => {
    if (negotiationData) {
      formRef.current.setData(negotiationData);
    } else {
      formRef.current.setFieldValue('negotiation.periodMinChargeFine', '4');
      formRef.current.setFieldValue('negotiation.periodContractualFine', '12');
    }
  }, [negotiationData, formRef]);

  const handleSubmit = useCallback(
    async data => {
      data = {
        negotiation: {
          ...data.negotiation,
          status: 'analyzing',
          insuranceFire:
            data.negotiation.insuranceFire === 'true'
              ? true
              : data.negotiation.insuranceFire === 'false'
              ? false
              : null,
          incomeTax:
            data.negotiation.incomeTax === 'true'
              ? true
              : data.negotiation.incomeTax === 'false'
              ? false
              : null,
          dayPayment: data.negotiation.dayPayment
            ? parseInt(data.negotiation.dayPayment)
            : null,
        },
      };
      try {
        await negotiationValidation.validate(data, {
          abortEarly: false,
        });

        getDataForm(data);
      } catch (err) {
        console.error(err);
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [getDataForm, formRef]
  );

  return (
    <Content style={{ width: '100%' }} ref={formRef} onSubmit={handleSubmit}>
      <TitleContainer>
        <h2>Dados da Negociação</h2>
      </TitleContainer>
      <Details />
      <SpaceLine />
      <TitleContainer>
        <h2>Informações contratuais:</h2>
      </TitleContainer>
      <ContractInformation />
      {children}
    </Content>
  );
}
