import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const ReelsContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  width: 100%;
  gap: 16px;
  border: ${props => props.theme.border};
  border-radius: 8px;
  overflow: overlay;
  background: ${props => props.theme.container.background};
`;

export const ButtonReel = styled(NavLink)`
  display: flex;
  flex-direction: column;
  min-width: 176px;
  padding: 8px 16px;
  border: 1px solid #055eff;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 16px;
  flex: 1 1 0;
  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const Action = styled(NavLink)`
  width: 100%;
  margin: 16px 0;
  border: 1px solid rgba(196, 196, 196, 0.51);
  border-radius: 4px;
  padding: 16px;
  background: #001438;
  color: #fff;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;

  > span {
    flex: 1;
  }

  > svg {
    font-size: 18px;
  }
`;
export const Content = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  gap: 24px;

  @media screen and (max-width: 990px) {
    padding: 16px;
    flex-direction: column;
  }
`;
