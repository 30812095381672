import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import { MenuContainer, ListContainer } from '../../../Styles/styles';
import { Button, LoadingScreen, Search } from 'components/Shared';
import { PropertySelected } from '../../Property/PropertySelected';
import { PropertyListSelect } from '../../Property/PropertyListSelect';

import { PropertyAdminQuery, PropertyBrokerQuery } from './Services';

export function NegotiationDataProperty({
  selectedProperty,
  setSelectedProperty,
}) {
  const { profile } = useSelector(state => state.user);

  const [propertyCurrentParams, setPropertyCurrentParams] = useState({
    search: null,
  });

  const { propertyData, propertyIsLoading } = profile.isAdmin
    ? PropertyAdminQuery(propertyCurrentParams)
    : PropertyBrokerQuery(propertyCurrentParams, profile.id);

  const debounceProperty = AwesomeDebouncePromise(updateQueryProperty, 500);
  function updateQueryProperty(event) {
    setPropertyCurrentParams({
      ...propertyCurrentParams,
      search: event,
    });
  }

  return (
    <>
      {!selectedProperty && (
        <>
          <MenuContainer>
            <p>
              <strong>Pesquisar Imóvel:</strong>
            </p>
          </MenuContainer>
          <Search
            style={{
              marginLeft: 0,
              marginBottom: 8,
              margintop: 16,
              maxWidth: 'none',
            }}
            id="search"
            type="text"
            placeholder="Pesquisar Imóvel"
            onChange={e => {
              debounceProperty(e.target.value);
            }}
          />
          {propertyData && !selectedProperty && (
            <ListContainer>
              {propertyIsLoading ? (
                <LoadingScreen />
              ) : (
                <>
                  {propertyData?.data.map(property => (
                    <PropertyListSelect
                      key={property.id}
                      property={property}
                      setSelectedProperty={setSelectedProperty}
                      selectedProperty={selectedProperty}
                    />
                  ))}
                </>
              )}
            </ListContainer>
          )}
        </>
      )}
      {selectedProperty && (
        <>
          <MenuContainer>
            <p>
              <strong>Imóvel a ser alugado:</strong>
            </p>
            <Button
              typeColor="clean"
              onClick={() => {
                setSelectedProperty('');
              }}
            >
              Trocar
            </Button>
          </MenuContainer>
          <PropertySelected property={selectedProperty} />
        </>
      )}
    </>
  );
}
