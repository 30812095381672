import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: ${props => props.theme.border};
  border-radius: 8px;

  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
`;

export const NavButtons = styled.div`
  display: flex;
  padding: 24px;
  border-radius: 8px;
  background-color: ${props => props.theme.backgrounds.secondary};
  gap: 16px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    > div {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: ${props => props.theme.colors.primary};
      gap: 4px;
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.hover.color};
      }
      > svg {
        height: 16px;
        width: 16px;
      }
    }
    .active {
      color: ${props => props.theme.hover.color};
    }
  }

  .alert {
    color: #d8010e;
    align-self: flex-start;
  }
  @media screen and (max-width: 990px) {
    display: none;
  }
`;

export const SelectedFilter = styled.div`
  display: none;
  align-self: center;
  width: 50vh;
  margin: 0 8px;
  max-width: 280px;
  padding: 4px;
  border-radius: 8px;
  background-color: ${props => props.theme.container.background};
  .field-select {
    flex: 1;
  }
  @media screen and (max-width: 990px) {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;
