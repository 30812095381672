import jarvis from 'util/jarvis';

export async function SendProfileData(profile, id) {
  const { data } = await jarvis.post(`/profile/${id}`, {
    profile: {
      ...profile,
    },
  });
  return data;
}

export async function SendEditProfileForm(profile, id, profileID) {
  profile = {
    ...profile,
    id: profileID,
  };
  const { data } = await jarvis.put(`/user/profile/${id}`, profile);
  return data;
}

export async function SendProfilePhoto(avatarFile, id) {
  const dataFile = new FormData();
  dataFile.append('file', avatarFile, avatarFile.name);

  const { data } = await jarvis.put(`/avatar/${id}`, dataFile);
  return data;
}
