import styled from 'styled-components';

export const ContentVerificationData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  border: ${props => props.theme.border};
  > h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  > h2 {
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
`;

export const ContentData = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    font-size: 16px;
    margin-bottom: 8px;
    word-break: break-all;
  }
`;

export const ContentGroup = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  > div {
    flex: 1 1 30rem;
    > h2 {
      font-size: 16px;
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }
`;

export const LineSpace = styled.div`
  width: 100%;
  height: 1px;
  background: #dfe0e3;
  margin: 24px 0;
`;
