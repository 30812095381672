import React from 'react';
import { DocumentsTranlate } from 'util/Translate/documents';
import { RoleType } from 'util/Translate/RoleType';

import {
  ContentVerificationData,
  ContentGroup,
  ContentData,
} from 'components/Shared';
import UseDocument from 'hooks/useDocument';

export function VerificationContract({
  children,
  negotiationData,
  getContractsInformation,
}) {
  const { tenant = {}, owner = {}, property: { address } = {} } =
    negotiationData || {};
  const { type, otherInformation = null, entryInsception = null, foro = null } =
    getContractsInformation || {};

  const signersOwner = owner.legalAgents?.filter(
    signers =>
      signers.type === 'legal_representative' || signers.type === 'spouse'
  );

  const signersTenant = tenant.legalAgents?.filter(
    item =>
      item.type === 'surety' ||
      item.type === 'co_responsible' ||
      item.type === 'spouse' ||
      item.type === 'guarantor' ||
      item.type === 'legal_representative'
  );

  const translateContract = {
    administration: 'Administração',
    location: 'Locação',
  };

  return (
    <ContentVerificationData>
      <h1>Contrato tipo: {translateContract[type]}</h1>
      {type === 'location' && (
        <ContentGroup>
          <div>
            <h2>Inquilino</h2>
            <ContentData>
              <p>
                Nome:{' '}
                <strong>
                  {tenant.name} {tenant.lastName}
                </strong>
              </p>
              {tenant.documents &&
                tenant.documents.map(document => {
                  return (
                    <p key={document.id}>
                      {DocumentsTranlate[document.type]}:{' '}
                      <strong>{UseDocument(document)}</strong>
                    </p>
                  );
                })}
            </ContentData>
          </div>
          {signersTenant && (
            <ul>
              <h2>Co-Inquilinos</h2>
              {signersTenant.map(selectedTenant => {
                return (
                  <ContentData
                    style={{ display: 'list-item' }}
                    key={selectedTenant.id}
                  >
                    <p>
                      Nome:{' '}
                      <strong>
                        {selectedTenant.name} {selectedTenant.lastName}
                      </strong>
                    </p>
                    <p>
                      Tipo: <strong>{RoleType(selectedTenant.type)}</strong>
                    </p>
                    {selectedTenant.documents &&
                      selectedTenant.documents.map(document => {
                        return (
                          <p key={document.id}>
                            {DocumentsTranlate[document.type]}:{' '}
                            <strong>{UseDocument(document)}</strong>
                          </p>
                        );
                      })}

                    {selectedTenant.legalAgents &&
                      selectedTenant.legalAgents.map(coTenant => {
                        return (
                          <ContentGroup
                            style={{ marginLeft: 16, display: 'list-item' }}
                            key={coTenant.id}
                          >
                            <div>
                              <ContentData>
                                <p>
                                  Nome:{' '}
                                  <strong>
                                    {coTenant.name} {coTenant.lastName}
                                  </strong>
                                </p>
                                <p>
                                  Tipo:{' '}
                                  <strong>{RoleType(coTenant.type)}</strong>
                                </p>
                                {coTenant.documents &&
                                  coTenant.documents.map(document => {
                                    return (
                                      <p key={document.id}>
                                        {DocumentsTranlate[document.type]}:{' '}
                                        <strong>{UseDocument(document)}</strong>
                                      </p>
                                    );
                                  })}
                              </ContentData>
                            </div>
                          </ContentGroup>
                        );
                      })}
                  </ContentData>
                );
              })}
            </ul>
          )}
        </ContentGroup>
      )}
      <ContentGroup>
        <div>
          <h2>Proprietario</h2>
          <ContentData>
            <p>
              Nome:{' '}
              <strong>
                {owner.name} {owner.lastName}
              </strong>
            </p>
            {owner.documents &&
              owner.documents.map(document => {
                return (
                  <p key={document.id}>
                    {DocumentsTranlate[document.type]}:{' '}
                    <strong>{UseDocument(document)}</strong>
                  </p>
                );
              })}
          </ContentData>
        </div>
        {signersOwner?.length && (
          <div>
            <h2>Co-Proprietarios</h2>
            {signersOwner?.map(selectedOwner => {
              return (
                <ContentData
                  style={{ display: 'list-item' }}
                  key={selectedOwner.id}
                >
                  <p>
                    Nome:{' '}
                    <strong>
                      {selectedOwner.name} {selectedOwner.lastName}
                    </strong>
                  </p>
                  <p>
                    Tipo: <strong>{RoleType(selectedOwner.type)}</strong>
                  </p>
                  {selectedOwner.documents &&
                    selectedOwner.documents.map(document => {
                      return (
                        <p key={document.id}>
                          {DocumentsTranlate[document.type]}:{' '}
                          <strong>{UseDocument(document)}</strong>
                        </p>
                      );
                    })}
                  {selectedOwner.legalAgents &&
                    selectedOwner.legalAgents?.map((coOwner, index) => {
                      return (
                        <ContentGroup
                          key={index}
                          style={{ marginLeft: 16, display: 'list-item' }}
                        >
                          <div>
                            <ContentData>
                              <p>
                                Nome:{' '}
                                <strong>
                                  {coOwner.name} {coOwner.lastName}
                                </strong>
                              </p>
                              <p>
                                Tipo: <strong>{RoleType(coOwner.type)}</strong>
                              </p>
                              {coOwner.documents &&
                                coOwner.documents.map(document => {
                                  return (
                                    <p key={document.id}>
                                      {DocumentsTranlate[document.type]}:{' '}
                                      <strong>{UseDocument(document)}</strong>
                                    </p>
                                  );
                                })}
                            </ContentData>
                          </div>
                        </ContentGroup>
                      );
                    })}
                </ContentData>
              );
            })}
          </div>
        )}
      </ContentGroup>
      <ContentGroup>
        <div>
          <h2>Imóvel</h2>
          <ContentData>
            <p>
              Cep: <strong>{address.zipcode}</strong>
            </p>
            <p>
              Endereço:{' '}
              <strong>
                {address.street}, {address.number}
              </strong>
            </p>
            {address?.complement && (
              <p>
                Complemento: <strong>{address.complement}</strong>
              </p>
            )}
            <p>
              Bairro: <strong>{address.district}</strong>
            </p>
            <p>
              Cidade: <strong>{address.city}</strong>
            </p>
            <p>
              Estado: <strong>{address.state}</strong>
            </p>
          </ContentData>
        </div>
      </ContentGroup>
      {(otherInformation || entryInsception || foro) && (
        <ContentGroup>
          <div>
            <h2>Outras informações</h2>
            <ContentData>
              {otherInformation !== '' && (
                <p>
                  <strong>Outras informações:</strong>
                  {otherInformation}
                </p>
              )}
              {entryInsception && (
                <p>
                  <strong>Vistoria de entrada:</strong> {entryInsception}
                </p>
              )}
              {foro && (
                <p>
                  <strong>Foro:</strong>
                  {foro}
                </p>
              )}
            </ContentData>
          </div>
        </ContentGroup>
      )}
      {children}
    </ContentVerificationData>
  );
}
