import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

function Input({ name, required, title, style, ...rest }) {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue = '',
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          ...style,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            ...style,
          }}
        >
          <p style={{ fontWeight: 500, ...style }}>{title}</p>
          {required && (
            <strong
              style={{ color: '#D8010E', fontWeight: 500, marginLeft: 8 }}
            >
              *
            </strong>
          )}
          {error && (
            <div style={{ marginLeft: 'auto', ...style }}>
              <span
                role="img"
                aria-label="error"
                style={{ fontSize: 12, ...style }}
              >
                ❌
              </span>
            </div>
          )}
        </div>
        <textarea
          style={{
            border: error ? '1px solid #D8010E' : '',
            resize: 'none',
            ...style,
          }}
          ref={inputRef}
          defaultValue={defaultValue}
          className={error ? 'has-error' : ''}
          {...rest}
          onFocus={() => clearError()}
        />
        <span
          className="error"
          style={{
            color: '#D8010E',
            fontSize: '14px',
            visibility: error ? 'visible' : 'hidden',
            marginTop: 8,
            height: 14,
            ...style,
          }}
        >
          {error}
        </span>
      </div>
    </>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Input;
