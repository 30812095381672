import React from 'react';

import { BsShieldFillCheck } from 'react-icons/bs';
import { GiReceiveMoney } from 'react-icons/gi';
import { HiAdjustments } from 'react-icons/hi';
import {
  Container,
  InformationContent,
  StatusButtons,
  DescriptionMobile,
} from './styles';

export function InnerList({ type, data, handleStatus }) {
  const iconType = {
    guarantee: <BsShieldFillCheck />,
    fee: <GiReceiveMoney />,
    readjustment: <HiAdjustments />,
  };

  const handleLink = {
    guarantee: `/dashboard/editar-garantia/${data.id}`,
    fee: `/dashboard/editar-de-taxa-de-administracao/${data.id}`,
    readjustment: `/dashboard/editar-reajuste/${data.id}`,
  };

  return (
    <Container>
      <InformationContent to={handleLink[type]}>
        <span className={data.status === 'activated' ? 'active' : ''}>
          {iconType[type]}
        </span>
        <div>
          <h3>
            {data.name} {type === 'fee' ? `${data.percentage}%` : ''}
          </h3>
          <p>{data.description}</p>
        </div>
        <div></div>
      </InformationContent>
      <DescriptionMobile>
        <p>{data.description}</p>
      </DescriptionMobile>
      <StatusButtons>
        <button
          type="button"
          className={data.status === 'activated' ? 'active' : ''}
          onClick={() => handleStatus(data.id)}
        >
          Ativado
        </button>
        <button
          type="button"
          className={data.status === 'disabled' ? 'active' : ''}
          onClick={() => handleStatus(data.id)}
        >
          Desativado
        </button>
      </StatusButtons>
    </Container>
  );
}
