export function parseBoolFromStringBoolean(string) {
  const bool = string?.toLowerCase();

  switch (bool) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
}
