import styled from 'styled-components'

export const Container = styled.img`
  width: 100%;
  max-width: 160px;
  margin-bottom: 16px;

  @media screen and (max-width: 990px) {
    max-width: 140px;
  }
`
