import React from 'react';
import { useHistory } from 'react-router-dom';

import { ContainerNegotiation } from './styles';

export function NegotiationList({ negotiation }) {
  const { property, id } = negotiation;
  const { address } = property;
  const history = useHistory();
  return (
    <ContainerNegotiation
      onClick={() => history.push(`/dashboard/negociacao-detalhes/${id}`)}
    >
      <span>
        {address.street}, {address.number}
        {address?.complement ? `, ${address.complement}` : ''} -{' '}
        {address.district}, {address.city} - {address.state} / ID: {id}
      </span>
    </ContainerNegotiation>
  );
}
