import React from 'react';
import noimage from '../../../../../assets/images/no-image.svg';

export function getStatuses(role) {
  if (role === 'admin') {
    return {
      new_request: 'Novos',
      dealing: 'Negociando',
      pending_signature: 'Análise',
      approved: 'Anunciados',
      rented: 'Alugados',
      canceled: 'Cancelados',
    };
  } else {
    return {
      dealing: 'Negociando',
      new_request: 'Análise',
      approved: 'Anunciados',
      rented: 'Alugados',
      canceled: 'Cancelados',
    };
  }
}

export function getStatusColors(role) {
  if (role === 'admin') {
    return {
      new_request: '#055EFF',
      dealing: '#FFAD00',
      pending_signature: '#FFAD00',
      approved: '#FFAD00',
      rented: '#09BA7F',
      canceled: '#D8010E',
    };
  } else {
    return {
      dealing: '#FFAD00',
      new_request: '#FFAD00',
      approved: '#FFAD00',
      rented: '#09BA7F',
      canceled: '#D8010E',
    };
  }
}

export function renderMainImage(images) {
  let mainImage = noimage;
  let mainFile = images.find(file => file.main);

  if (mainFile) {
    mainFile = images[0];
  }

  mainImage = mainFile?.urlImage350x250;

  if (mainImage) {
    return <img src={mainImage} alt="foto" />;
  } else {
    return (
      <img style={{ filter: 'brightness(0.7)' }} src={noimage} alt="foto" />
    );
  }
}

export function speficificationValues(property) {
  return (
    (property?.specification?.condo ? property?.specification?.condo : 0) +
    (property?.specification?.iptu_price
      ? property?.specification?.iptu_price
      : 0)
  );
}
