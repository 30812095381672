import * as Yup from 'yup';

export const documentsValidation = Yup.object().shape({
  documents: Yup.array().of(
    Yup.object().shape({
      type: Yup.string(),
      document: Yup.string().when('type', {
        is: val => val === 'reg_commercial',
        then: Yup.string(),
        otherwise: Yup.string().required('Campo obrigatório'),
      }),
    })
  ),
});
