import React from 'react';

import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { phoneMask, telMask } from 'util/maskForms';

import {
  ContentData,
  ContentGroup,
  ContentVerificationData,
} from 'components/Shared';

export function UserDataSummary({
  essencialData,
  brokerResponsible,
  profile,
  children,
}) {
  const {
    career = {},
    birthdayDate = {},
    animal = {},
    nationality = {},
    numberOfChildren = {},
    animalDescription = {},
    description = {},
    maritualStatus = {},
  } = profile || {};

  function handlePhone(number) {
    if (number.replace(/\D/g, '').length < 11) {
      return telMask(number);
    } else {
      return phoneMask(number);
    }
  }

  const translateMaritual = {
    married: 'Casado',
    single: 'Solteiro',
    divorced: 'Divorciado',
    widowed: 'Viúvo',
    union: 'União Estável',
  };

  function formatDateData(date) {
    const currentDate = new Date(date);
    return format(currentDate, "dd'/'MM'/'yyyy", { locale: pt });
  }

  return (
    <ContentVerificationData>
      <h1>Dados do Usuário</h1>
      <ContentGroup>
        <div>
          <h2>Dados Essenciais</h2>
          <ContentData>
            <p>
              Nome:{' '}
              <strong>
                {essencialData.name} {essencialData.lastName}
              </strong>
            </p>
            <p>
              Email: <strong>{essencialData.email}</strong>
            </p>
            <p>
              Telefone:{' '}
              <strong> {handlePhone(essencialData.phones[0].phone)}</strong>
            </p>
          </ContentData>
        </div>
        {brokerResponsible && (
          <div>
            <h2>Corretor Responsável</h2>
            <ContentData>
              <p>
                Nome:{' '}
                <strong>
                  {brokerResponsible.name} {brokerResponsible.lastName}
                </strong>
              </p>
              <p>
                Email: <strong>{brokerResponsible.email}</strong>
              </p>
            </ContentData>
          </div>
        )}
      </ContentGroup>
      {profile && (
        <>
          <ContentGroup>
            <div>
              <h2>Informações adicionais</h2>
              <ContentData>
                {career && (
                  <p>
                    Profissão: <strong>{career}</strong>
                  </p>
                )}
                {birthdayDate && (
                  <p>
                    Data de Nascimento:{' '}
                    <strong>{formatDateData(birthdayDate)}</strong>
                  </p>
                )}
                {maritualStatus && (
                  <p>
                    Estado Civil:{' '}
                    <strong>{translateMaritual[maritualStatus]}</strong>
                  </p>
                )}
                {nationality && (
                  <p>
                    Nacionalidade: <strong>{nationality}</strong>
                  </p>
                )}
              </ContentData>
            </div>
            {numberOfChildren && (
              <div>
                <h2>Filhos</h2>
                <ContentData>
                  <p>
                    Quantidade de Filhos: <strong>{numberOfChildren}</strong>
                  </p>
                </ContentData>
              </div>
            )}
          </ContentGroup>
          {animal && profile && (
            <ContentGroup>
              <div>
                <h2>Animais</h2>
                <ContentData>
                  <p>
                    Quantidade de animais:{' '}
                    <strong>{animal ? 'sim' : 'não'}</strong>
                  </p>
                  {animalDescription && (
                    <p>
                      Descrição do animal: <strong>{animalDescription}</strong>
                    </p>
                  )}
                </ContentData>
              </div>
            </ContentGroup>
          )}
          <ContentGroup>
            {profile?.description && (
              <div>
                <h2>Descrição:</h2>
                <ContentData>
                  <p>{description}</p>
                </ContentData>
              </div>
            )}
          </ContentGroup>
        </>
      )}
      {children}
    </ContentVerificationData>
  );
}
