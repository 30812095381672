import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;

  .profile-container {
    padding: 8px 16px;
    background: ${props => props.theme.container.background};
    display: flex;
    align-items: center;
    border: 1px solid #055eff;
    border-radius: 16px;
    gap: 8px;
  }

  .profile-img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  .profile-name {
    font-size: 16px;
  }

  @media (max-width: 990px) {
    .profile-container {
      padding: 10px 12px;
    }
    .profile-name {
      display: none;
    }
    .profile-chevron {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  border: ${props => props.theme.border};
  border-radius: 8px;
  flex-direction: column;
  position: absolute;
  max-width: 180px;
  background: ${props => props.theme.container.background};
  transition: all 0.3s ease-in-out;
  top: 42px;
  left: -32px;
  z-index: 9;

  &.off-modal {
    width: 0px;
    height: 0px;
    opacity: 0;
    visibility: hidden;
    button,
    a {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.show-modal {
    visibility: visible;
    opacity: 1;
    width: 180px;
    height: 188px;
  }

  > button,
  a {
    background: ${props => props.theme.container.background};
    text-decoration: none;
    border-radius: 8px;
    padding: 22px 16px;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #989ba1;

    > svg {
      margin-right: 8px;
    }
  }

  > hr {
    width: 80%;
    margin: 0 auto;
    border-top: 1px solid #e1e1e1;
  }

  @media (max-width: 990px) {
    left: -140px;
    top: 48px;
  }
`;
