import React from 'react';

import Route from '../../../routes/Route';
import { Settings } from 'components/Settings';
import { SuportOwner } from 'components/incorporadora/suporte';
import { PhotosOwner } from 'components/incorporadora/photos';
import { UnitDetails } from 'components/incorporadora';
import { InviteGuest } from 'components/incorporadora/convite';

export function OwnerRoutes() {
  return (
    <>
      <Route path="/dashboard/configuracoes" component={Settings} isPrivate />
      <Route path="/dashboard/suporte" component={SuportOwner} isPrivate />
      <Route path="/dashboard/fotos" component={PhotosOwner} isPrivate />
      <Route path="/dashboard/convites" component={InviteGuest} isPrivate />
      <Route path="/dashboard/informacoes" component={UnitDetails} isPrivate />
    </>
  );
}
