import styled from 'styled-components';

export const PropertyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 16px 24px;
  border: ${props => props.theme.border};
  border-radius: 8px;
  margin-bottom: 8px;
  background: ${props => props.theme.colors.secondary};
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
  > span {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .selectedTitle {
    transition: all 0.3s ease;
  }

  .secondTitle {
    margin-top: 16px;
  }
  .photo {
    display: flex;
    width: 112px;
    height: 56px;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.backgrounds.primary};
    border-radius: 8px;
    margin-right: 16px;
    > p {
      padding: 16px;
      font-size: 24px;
    }
    > svg {
      width: 24px;
      height: 24px;
      color: white;
    }
  }

  .info {
    > p {
      font-size: 16px;
      word-break: break-all;
    }
  }
  .selected {
    background: rgb(5, 94, 255);
  }

  .disabled {
    width: 0px;
    height: 0px;
    visibility: hidden;
  }

  .clientContainer {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
  }

  .clientPhoto {
    display: flex;
    width: 56px;
    height: 56px;
    align-items: center;
    justify-content: center;
    background: rgb(5, 94, 255);
    border-radius: 50%;
    margin-right: 16px;
    > p {
      padding: 16px;
      color: #fff;
      font-size: 24px;
    }
  }

  .responsibleContainer {
    margin-top: 24px;
    gap: 24px;

    @media (max-width: 990px) {
      flex-direction: column;
      align-items: start;
    }
  }
  .responsible {
    > span {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    > div {
      margin-top: 8px;
    }
  }
`;
