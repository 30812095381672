import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import { Main } from '../pages/Main';
import { Client } from '../pages/ClientLogin';
import { GuestLogin } from '../pages/GuestLogin';

import { ForgotPassword } from '../pages/ForgotPassword';
import { ResetPassword } from '../pages/ResetPassword';
import { SetPassword } from '../pages/setPassword';
import { Dashboard } from '../pages/Dashboard';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/login" component={Client} />
      <Route path="/guest/:id" component={GuestLogin} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/new-password" component={SetPassword} isPrivate />
    </Switch>
  );
}
