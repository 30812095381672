import React, { useEffect, useCallback, useState } from 'react';
import { Content } from 'components/Shared';
import { Tax } from 'components/Negotiations/CreateNegotiation/Components/Forms/NegotiationForm/Components/Tax';
import { taxValidation } from './Validation';
import { validationError } from 'validations/forms';

export function TaxForm({ negotiationData, getDataForm, formRef, children }) {
  const [guaranteeLabel, setGuaranteeLabel] = useState('');
  const [admFeeLabel, setAdmFeeLabel] = useState('');
  const [readjustmentLabel, setReadjustmentLabel] = useState('');

  useEffect(() => {
    if (negotiationData) {
      formRef.current.setFieldValue(
        'negotiation.guaranteeId',
        negotiationData.labels.guarantee
      );
      formRef.current.setFieldValue(
        'negotiation.admFeeId',
        negotiationData.labels.fee
      );
      formRef.current.setFieldValue(
        'negotiation.readjustmentId',
        negotiationData.labels.readjustment
      );
    }
  }, [negotiationData, formRef]);

  const handleSubmit = useCallback(
    async data => {
      data = {
        negotiation: {
          ...data.negotiation,
        },
        labels: {
          guarantee: guaranteeLabel,
          fee: admFeeLabel,
          readjustment: readjustmentLabel,
        },
      };
      try {
        await taxValidation.validate(data, {
          abortEarly: false,
        });

        getDataForm(data);
      } catch (err) {
        const validationErrors = {};
        if (err instanceof validationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [guaranteeLabel, admFeeLabel, readjustmentLabel, getDataForm, formRef]
  );

  return (
    <Content ref={formRef} onSubmit={handleSubmit}>
      <Tax
        setReadjustmentLabel={setReadjustmentLabel}
        setGuaranteeLabel={setGuaranteeLabel}
        setAdmFeeLabel={setAdmFeeLabel}
      />
      {children}
    </Content>
  );
}
