import React from 'react';
import { FilterSelect } from 'components/Shared';

import AwesomeDebouncePromise from 'awesome-debounce-promise';

import {
  ContentVerificationData,
  FilterContainer,
  LoadingScreen,
  Search,
} from 'components/Shared';
import { ContainerList, SelectedFilter } from './styles';
import { CardProperty } from 'components/Property/Components/CardProperty';

export function PropertySummary({
  properties,
  setCurrentParams,
  currentParams,
  isLoading,
}) {
  const handleSearchDebounced = AwesomeDebouncePromise(handleSearch, 300);

  function handleSearchChange(e) {
    handleSearchDebounced(e.target.value);
  }

  function handleSearch(search) {
    const newParams = { ...currentParams, page: 1, search: search };
    setCurrentParams(newParams);
  }

  return (
    <ContentVerificationData>
      <h1>Imóveis atrelados</h1>
      <FilterContainer>
        <SelectedFilter>
          <FilterSelect
            className="field-select"
            options={[
              {
                value: '',
                label: 'Todos',
              },
              { value: 'new_request', label: 'Novos' },
              { value: 'dealing', label: 'Negociando' },
              {
                value: 'pending_signature',
                label: 'Aguard. ass. contrato',
              },
              { value: 'approved', label: 'Aprovados' },
              { value: 'rented', label: 'Alugados' },
              { value: 'canceled', label: 'Cancelados' },
            ]}
            defaultValue={{
              value: '',
              label: 'Todos',
            }}
            onChange={field => {
              setCurrentParams({
                ...currentParams,
                page: 1,
                filter: { status: field.value },
              });
            }}
          />
        </SelectedFilter>
        <Search placeholder="Pesquisar" onChange={handleSearchChange} />
      </FilterContainer>
      <ContainerList>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            {properties.map(property => {
              return (
                <div key={property.id}>
                  <CardProperty property={property} />
                </div>
              );
            })}
          </>
        )}
      </ContainerList>
    </ContentVerificationData>
  );
}
