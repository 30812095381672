import * as Yup from 'yup';

export const userCreation = Yup.object().shape({
  role: Yup.string().required('Selecione o tipo de usuario'),
  name: Yup.string().required('Digite o seu Nome'),
  lastName: Yup.string().required('Digite o seu sobrenome'),
  email: Yup.string()
    .email('Insira um e-mail valido')
    .required('Digite o seu e-mail'),

  phones: Yup.array()
    .min(1, 'Insira pelo menos um numero de telefone')
    .of(
      Yup.object().shape({
        phone: Yup.string().test(
          'phone',
          'Digite um numero de telefone válido',
          function() {
            return (
              this.path === 'phones[1].phone' || this.parent.phone.length >= 10
            );
          }
        ),
      })
    ),
});
