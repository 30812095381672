import styled from 'styled-components';

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  max-width: 300px;
  background: ${props => props.theme.container.background};
  border: ${props => props.theme.border};
  justify-content: space-between;
  border-radius: 8px;
  margin-left: auto;

  > input {
    width: 100%;
    border: 0;
    background: transparent;
    font-size: 16px;
    line-height: 100%;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.container.background};

    & ::placeholder {
      color: ${props => props.theme.colors.primary};
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
    }
  }

  > svg {
    color: ${props => props.theme.colors.primary};
  }
  @media screen and (max-width: 990px) {
    max-width: none;
    margin-left: none;
    margin-top: 8px;
    width: 100%;
    > input {
      width: 100%;
    }
  }
`;
