import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import Route from '../../routes/Route';

import { Header } from '../../components/Header';
import { Menu } from 'components/Menu/';

import { Home } from '../../components/Home';

import { AdminRoutes } from './AdminRoutes';
import { BrokerRoutes } from './BrokerRoutes';
import { OwnerRoutes } from './OwnerRoutes';
import { GuestRoutes } from './GuestRoutes';

import { Container, Space, AllContent } from './styles';
import { useEffect } from 'react';

export function Dashboard() {
  const { isAdmin, isBroker, isOwner, isGuest } = useSelector(
    state => state.user.profile
  );

  const [open, setOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 990) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);

  return (
    <Router>
      <Container>
        <Menu setOpen={setOpen} open={open} />
        <AllContent className={`${isMobile ? '' : open ? 'max' : 'min'} `}>
          <Header />
          <Space id="content-main">
            <Switch>
              <Route
                path="/dashboard"
                component={() => Home()}
                isPrivate
                exact
              />

              {isAdmin && <AdminRoutes />}
              {isBroker && <BrokerRoutes />}
              {isOwner && <OwnerRoutes />}
              {isGuest && <GuestRoutes />}
            </Switch>
          </Space>
        </AllContent>
      </Container>
    </Router>
  );
}
