import React from 'react';

import { getAllTypes } from './Services';

import { ContentScroll, Container } from 'components/Shared';
import { TypesList } from './Components/TypesList';

import { TypesTitle } from './styles';

export function TypesPage() {
  const { data, isLoading } = getAllTypes();

  return (
    <Container>
      {isLoading || (
        <>
          <TypesTitle>
            <h2>Gerenciar Tipos</h2>
            <p>
              As opções de tipos de imóveis ativadas aparecerão no filtro do
              site.
            </p>
          </TypesTitle>

          <ContentScroll>
            {data.map((type, index) => {
              return <TypesList key={index} type={type} />;
            })}
          </ContentScroll>
        </>
      )}
    </Container>
  );
}
