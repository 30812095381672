import React, { useRef, useState } from 'react';

import { Container, Buttons, Button } from '../../../Shared';
import { UserDocumentsForm } from '../../Components/Forms/UserDocumentsForm';
import { FinalizationModal } from '../../Components/Forms/Modal/FinalizationModal';
import { SendDocumentsData, SendDocumentFile } from './Services';
import { toast } from 'react-toastify';

export function UserDocuments({
  setDataDocuments,
  setCurrentProgress,
  currentProgress,
  dataDocuments,
  dataEssencial,
  id,
}) {
  const [finalize, setFinalize] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef(null);

  function handleActionsButtons(type) {
    if (finalize === true) {
      setShowModal(true);
    } else {
      if (type === 'cpf') {
        setCurrentProgress(currentProgress + 1);
      } else {
        setCurrentProgress(currentProgress + 2);
      }
    }
  }

  async function sendData(data) {
    const findFiles = data.documents.find(doc => doc.file).file;
    const documentsWithId = ({ documents, response }) =>
      documents.map((document, index) => {
        return {
          ...document,
          id: response[index].id,
        };
      });

    if (dataDocuments) {
      try {
        const documentResponse = await SendDocumentsData(
          data,
          id,
          dataDocuments
        );
        const cpfOrCnpjResponse = documentResponse.find(
          doc => doc.type === 'cpf' || doc.type === 'cnpj'
        );

        const newDocuments = documentsWithId({
          documents: data,
          response: documentResponse,
        });

        setDataDocuments(newDocuments);
        handleActionsButtons(cpfOrCnpjResponse.type);
        toast.success('Documentos adicionados com sucesso!');
      } catch (err) {
        toast.error('Erro ao enviar documentos');
      }
    } else {
      try {
        const responseDocuments = await SendDocumentsData(data, id);
        const cpfOrCnpjResponse = responseDocuments.find(
          doc => doc.type === 'cpf' || doc.type === 'cnpj'
        );
        const newDocuments = documentsWithId({
          documents: data.documents,
          response: responseDocuments,
        });
        if (findFiles.length > 0) {
          await SendDocumentFile(newDocuments);
        }
        setDataDocuments(newDocuments);
        handleActionsButtons(cpfOrCnpjResponse.type);
        toast.success('Documentos adicionados com sucesso!');
      } catch (error) {
        toast.error('Erro ao adicionar documentos!');
      }
    }
  }

  return (
    <>
      <Container>
        <UserDocumentsForm
          dataDocuments={dataDocuments}
          formRef={formRef}
          sendData={sendData}
        >
          <Buttons>
            <Button
              onClick={() => setFinalize(true)}
              type="submit"
              typeColor="secondary"
            >
              Finalizar Cadastro
            </Button>
            <Button type="submit" typeColor="primary">
              Confimar e Avançar
            </Button>
          </Buttons>
          <FinalizationModal
            open={showModal}
            setOpen={setShowModal}
            dataEssencial={dataEssencial}
            userId={id}
          />
        </UserDocumentsForm>
      </Container>
    </>
  );
}
