import React, { useEffect } from 'react';

import { InputRadio, InputForm, InputText } from 'components/Shared';

export function AdditionalInputs({ formRef, additionalData }) {
  const filedData = additionalData;

  useEffect(() => {
    if (filedData) {
      formRef.current.setData(filedData);
    }
  }, [filedData, formRef]);

  return (
    <>
      <div>
        <span>
          <InputRadio
            name="specification.isServiceArea"
            title="Tem área de serviço?"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="specification.isBalcony"
            title="Tem varanda?"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
      </div>
      <div>
        <span>
          <InputRadio
            name="specification.isGourmetArea"
            title="Tem área gourmet?"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="specification.allowAnimals"
            title="Aceita animais?"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
      </div>
      <div>
        <span>
          <InputRadio
            name="specification.isConcierge"
            title="Tem portaria?"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="specification.isElevator"
            title="Tem elevador?"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
      </div>
      <div className="half">
        <span>
          <InputRadio
            name="specification.isRecreationArea"
            title="Tem área de lazer?"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
      </div>
      <div>
        <span>
          <InputForm
            className="half"
            name="specification.floor"
            title="Qual o andar? (caso seja apartamento)"
            placeholder="Andar"
            required={false}
          />
        </span>
      </div>
      <div>
        <span>
          <InputForm
            name="specification.area"
            title="Qual a metragem?"
            placeholder="M2"
            required={false}
          />
        </span>
        <span>
          <InputForm
            name="specification.powerInstallationNumber"
            title="Qual o código de instação da CEMIG?"
            required={false}
          />
        </span>
      </div>
      <div>
        <span>
          <InputText
            name="specification.keyOrigin"
            title="Onde eu encontro as chaves do imóvel?"
          />
        </span>
      </div>
      <div>
        <span>
          <InputText name="observation" title="Observações:" />
        </span>
        <span>
          <InputText
            name="specification.description"
            title="Descrição:"
            placeholder="Essa informação aparecerá no anúncio do site"
          />
        </span>
      </div>
      <div>
        <h3>O que há nas proximidades?</h3>
      </div>
      <div>
        <span>
          <InputRadio
            name="nearbies.school"
            title="Escola"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="nearbies.nursery"
            title="Creche"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
      </div>
      <div>
        <span>
          <InputRadio
            name="nearbies.drugstore"
            title="Farmácia"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="nearbies.bakery"
            title="Padaria"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
      </div>
      <div>
        <span>
          <InputRadio
            name="nearbies.cafeteria"
            title="Lanchonete"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="nearbies.delegacy"
            title="Delegacia"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
      </div>
      <div>
        <span>
          <InputRadio
            name="nearbies.supermarket"
            title="Supermercado"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
        <span>
          <InputRadio
            name="nearbies.hospital"
            title="Hospital"
            required={false}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
        </span>
      </div>
    </>
  );
}
