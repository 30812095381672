import styled from 'styled-components';

export const ContainerError = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px 16px;

  img {
    flex: 0.3;
    filter: brightness(0.4);
  }

  .message {
    display: flex;
    position: absolute;
    font-weight: 600;
    color: #d8010e;
    bottom: -16px;
    animation: fadeIn 6s linear, text-zoom 8s linear forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  // text-zoom animation
  @keyframes text-zoom {
    0% {
      font-size: 150%;
    }
    25% {
      font-size: 200%;
    }
    50% {
      font-size: 250%;
    }
    75% {
      font-size: 280%;
    }
    100% {
      font-size: 280%;
    }
  }

  @media (max-width: 990px) {
    padding: 8px;
  }
`;
