import React, { useRef, useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { Container, Buttons, Button } from '../../../Shared';
import { SendForm } from './Services';
import { AttentionModal } from '../../Components/Forms/Modal/AttentionModal';
import { EssencialDataForm } from '../../Components/Forms/EssencialDataForm';
import { toast } from 'react-toastify';

export function EssencialData({
  setCurrentProgress,
  currentProgress,
  setDataEssencial,
  dataEssencial,
  role,
  setRole,
  setUnlock,
  setId,
  id,
}) {
  const [open, setOpen] = useState(false);
  const formRef = useRef(null);
  const { create } = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (dataEssencial) {
      setUnlock(true);
    }
  }, [dataEssencial, setUnlock]);

  function handleRedirect(data) {
    if (create === 'tenant') {
      if (data) {
        history.push(`/dashboard/criar-negociacao/${id}`, {
          tenant: {
            id: id,
            ...data,
          },
          property: location.state.property,
        });
      } else {
        history.push(`/dashboard/criar-negociacao`, {
          property: location.state.property,
        });
      }
    } else {
      if (id) {
        history.push(`/dashboard/clientes/detalhes/${id}`);
      } else {
        history.push(`/dashboard`);
      }
    }
  }

  async function sendData(data) {
    await SendForm(data)
      .then(response => {
        setId(response.id);
        setUnlock(true);
        setOpen(true);
        setDataEssencial(data);
        toast.success('Usuário criado com sucesso!');
      })
      .catch(error => {
        const err = error.response.data.name;
        if (err === 'a user already exist with this email') {
          toast.error('Este email já está cadastrado!');
        }
      });
  }

  return (
    <>
      <Container>
        <EssencialDataForm
          dataEssencial={dataEssencial}
          formRef={formRef}
          sendData={sendData}
          setRole={setRole}
          role={role}
        >
          <Buttons>
            <Button
              typeColor="disable"
              type="button"
              onClick={() => handleRedirect(null)}
            >
              Cancelar
            </Button>
            <Button type="submit" typeColor="primary">
              Confimar e Avançar
            </Button>
          </Buttons>
          <AttentionModal setOpen={setOpen} open={open}>
            <Buttons>
              <Button
                type="button"
                typeColor="secondary"
                onClick={() => {
                  handleRedirect(dataEssencial);
                }}
              >
                Finalizar Cadastro
              </Button>
              <Button
                type="button"
                onClick={() => setCurrentProgress(currentProgress + 1)}
                typeColor="primary"
              >
                Continuar Preenchendo
              </Button>
            </Buttons>
          </AttentionModal>
        </EssencialDataForm>
      </Container>
    </>
  );
}
