import * as Yup from 'yup';

export const negotiationValidation = Yup.object().shape({
  negotiation: Yup.object().shape({
    amount: Yup.number()
      .typeError('Digite o valor da negociação')
      .required('Digite o valor da negociação'),
    periodMonths: Yup.number()
      .typeError('Digite o período da negociação')
      .required('Digite o período da negociação'),
    admFeeId: Yup.number()
      .typeError('Selecione a taxa de administração')
      .required('Selecione a taxa de administração'),
    insuranceFire: Yup.boolean()
      .typeError('Selecione se o aluguel inclui seguro')
      .required('Selecione se o aluguel inclui seguro'),
    dayPayment: Yup.number()
      .typeError('Selecione o dia do pagamento')
      .required('Selecione o dia do pagamento'),
    incomeTax: Yup.boolean()
      .typeError('Selecione se o aluguel declara imposto de renda')
      .required('Selecione se o aluguel declara imposto de renda'),
    readjustmentId: Yup.number()
      .typeError('Selecione o reajuste')
      .required('Selecione o reajuste'),
    guaranteeId: Yup.number()
      .typeError('Selecione a garantia')
      .required('Selecione a garantia'),
    startDate: Yup.date()
      .typeError('Selecione uma data')
      .required('Date is required'),
  }),
});
