import { useDispatch } from 'react-redux';
import axios from 'axios';
import jarvis from '../util/jarvis';
import TokenService from './service.token';

import { refreshToken } from '../store/modules/auth/actions';

class ApiService {
  constructor() {
    this.interceptor401 = null;
    this.interceptor403 = null;

    axios.defaults.baseURL = process.env.REACT_APP_API;
    axios.defaults.headers['X-Api-key'] = process.env.REACT_APP_KEY;
    axios.defaults.headers['X-Client-Id'] = process.env.REACT_APP_CLIENT_ID;
    jarvis.defaults.headers['X-Api-key'] = process.env.REACT_APP_KEY;
    jarvis.defaults.headers['X-Client-Id'] = process.env.REACT_APP_CLIENT_ID;
  }

  setHeaderAuthorization() {
    axios.defaults.headers.Authorization = `Bearer ${TokenService.getAccessToken()}`;
    jarvis.defaults.headers[
      'x-access-token'
    ] = `${TokenService.getAccessToken()}`;
  }

  deleteHeaderAuthorization() {
    axios.defaults.headers.common = {};
    jarvis.defaults.headers['x-access-token'] = '';
  }

  mount401Interceptor() {
    this.interceptor401 = axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.request.status === 401) {
          if (error.request.statusText === 'Unauthorized') {
            TokenService.deleteAccessToken();
            TokenService.deleteRefreshToken();

            this.deleteHeaderAuthorization();

            window.location.reload('/');

            throw error;
          } else {
            try {
              const dispatch = useDispatch;

              dispatch(refreshToken());

              return axios({
                method: error.config.method,
                url: error.config.url,
                data: error.config.data,
              });
            } catch (err) {
              throw error;
            }
          }
        }

        throw error;
      }
    );
    this.interceptor403 = jarvis.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.request.status === 403) {
          if (error.request.statusText === 'Forbidden') {
            TokenService.deleteAccessToken();
            TokenService.deleteRefreshToken();

            this.deleteHeaderAuthorization();

            window.location.reload('/');

            throw error;
          } else {
            try {
              const dispatch = useDispatch;

              dispatch(refreshToken());

              return axios({
                method: error.config.method,
                url: error.config.url,
                data: error.config.data,
              });
            } catch (err) {
              throw error;
            }
          }
        }

        throw error;
      }
    );
  }

  unmount401Interceptor() {
    axios.interceptors.response.eject(this.interceptor401);
  }

  async customRequest(data) {
    this.deleteHeaderAuthorization();

    const response = await axios(data);

    this.setHeaderAuthorization();

    return response;
  }
}

export default new ApiService();
