import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > ul {
    display: flex;
    flex-direction: row;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 48px;
    > div {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }

  @media (max-width: 990px) {
    > ul {
      display: none;
    }
  }
`;

export const Progression = styled.div`
  display: flex;
  content: '';
  width: 100%;
  text-decoration: none;
  margin-right: 16px;
  flex-direction: column;
  align-items: center;
  background: transparent;
  > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 8px;
    color: #dfe0e2;
  }
  > span {
    width: 100%;
    border: 3px solid #dfe0e2;
    border-radius: 16px;
  }

  &.filled {
    > p {
      color: #0daf7a;
    }
    > span {
      border: 3px solid #0daf7a;
    }
  }

  &.active {
    > p {
      color: #055eff;
    }
    > span {
      border: 3px solid #055eff;
    }
  }

  &.disabled {
    display: none;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const Back = styled.div`
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 88px;
  > svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  @media (max-width: 990px) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  line-height: 150%;
  font-weight: 700;
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  line-height: 140%;
  font-weight: 400;
`;

export const SelectMobile = styled.div`
  &.mobileSelect {
    display: none;
    flex-direction: column;
    width: 100%;
    > button {
      display: flex;
      align-items: left;
      width: 100%;
      padding: 8px 16px;
      border-radius: 8px;
      font-size: 16px;
      line-height: 140%;
      position: relative;
      border: ${props => props.theme.border};
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #b3b3b3;
        margin-left: auto;
        align-self: center;
      }
      > ul {
        list-style-type: none;
        width: 100%;
        padding: 8px 16px;
        border-radius: 8px;
        background: #fff;
        border: 1px solid #b3b3b3;
        z-index: 1;
        left: 0;
        top: 42px;
        position: absolute;
        > li {
          font-size: 16px;
          font-weight: 500;
          line-height: 140%;
          margin-bottom: 8px;
          color: #dfe0e2;
          cursor: pointer;
          text-align: left;
          &.active {
            color: #055eff;
          }
        }
      }
    }
    @media (max-width: 990px) {
      display: flex;
    }
  }
`;
