import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .address {
    display: flex;
    justify-content: space-between;
    background: ${({ theme }) => theme.backgrounds.secondary};
    padding: 16px 24px;
    gap: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    > div {
      display: flex;
      gap: 8px;
      align-items: center;
      > p {
        font-size: 18px;
      }
    }
  }

  .blueprint {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    gap: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: ${({ theme }) => theme.border};
    border-right: ${({ theme }) => theme.border};
    border-bottom: ${({ theme }) => theme.border};

    .specification {
      display: flex;
      gap: 16px;
      > p {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 990px) {
    .address {
      flex-direction: column;
      > div {
        flex-direction: column;
      }
    }
  }
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    > a {
      font-size: 16px;
      padding: 8px 16px;
      border-radius: 8px;
      background: ${({ theme }) => theme.backgrounds.primary};
      color: #fff;
      text-decoration: none;
    }
  }

  @media (max-width: 990px) {
    > div {
      gap: 8px;
      > a {
        display: flex;
        flex: 1 0 100%;
        font-size: 16px;
        padding: 8px 16px;
        justify-content: center;
      }
    }
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;
