import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px 24px;
  border: ${props => props.theme.border};
  border-radius: 8px;
  gap: 16px;
  > h2 {
    font-size: 24px;
  }
  > div {
    display: flex;
    gap: 16px;
    @media screen and (max-width: 990px) {
      flex-direction: column;
    }
  }
`;
