import React from 'react';
import { BsFillHouseFill } from 'react-icons/bs';

import { Container } from './styles';
export function PropertyDetails({ property, broker, owner, status }) {
  const { address } = property;

  const fontColor = {
    approved: '#fff',
    rented: '#fff',
    canceled: '#fff',
    pending_signature: '#353D40',
    dealing: '#353D40',
    new_request: '#353D40',
  };

  const backgroundColor = {
    approved: '#0B8E63',
    rented: '#0B8E63',
    canceled: '#D8010E',
    pending_signature: '#FFDE67',
    dealing: '#FFDE67',
    new_request: '#FFDE67',
  };

  return (
    <Container
      fontColor={fontColor[status]}
      backgroundColor={backgroundColor[status]}
    >
      <span>
        <BsFillHouseFill />
      </span>
      <div>
        <address className="title-address">
          {address.street}, {address.number} - {address.district}
        </address>
        <address>
          {address.complement} {address.city}/{address.state}
        </address>
        <p>
          Corretor responsável: {broker.name} {broker.lastName}
        </p>
        <p>
          Proprietário: {owner.name} {owner.lastName}
        </p>
      </div>
    </Container>
  );
}
