import React, { useRef, useCallback } from 'react';

import { Modal, BlackScreen, FormContent } from './style';
import { validateInput } from './validation';
import { InputForm } from '../Inputs/InputForm';
import { validationError } from '../../../validations/forms';
import { toast } from 'react-toastify';
import { Button } from 'components/Shared';

export function ConfirmExecution({ setOpen, open, fn, validationkey }) {
  const formRef = useRef();

  const handleSubmit = useCallback(async data => {
    const validate = validationkey?.replaceAll(' ', '')?.toLowerCase();
    const inputValue = data.validation?.replaceAll(' ', '')?.toLowerCase();

    if ((validate !== inputValue) === true) {
      throw new Error('chave digitada está invalida');
    }

    try {
      await validateInput.validate(data, {
        abortEarly: false,
      });
      if (validate === inputValue) {
        await fn();
        setOpen(false);
      }
    } catch (err) {
      toast.error('chave digitada está invalida');
      const validationErrors = {};
      if (err instanceof validationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  });

  return (
    <>
      <BlackScreen onClick={() => setOpen(false)} open={open} />
      <Modal open={open}>
        <div>
          <h1>Confirme sua ação</h1>
          <p>
            Cuidado, está ação que está prestes a realizar é irreversível. Para
            continuar está ação basta digitar a chave:{' '}
            <strong>{validationkey}</strong>
          </p>
          <FormContent ref={formRef} onSubmit={handleSubmit}>
            <InputForm
              name="validation"
              title={`Digite ${validationkey}`}
              placeholder={`${validationkey}`}
              required={true}
            />
            <div className="buttons">
              <Button
                type="button"
                typeColor="secondary"
                onClick={() => setOpen(false)}
              >
                Voltar
              </Button>
              <Button typeColor="cancel" type="submit">
                Confirmar
              </Button>
            </div>
          </FormContent>
        </div>
      </Modal>
    </>
  );
}
